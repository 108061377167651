import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTIONSTRING,
    enableAutoRouteTracking: true,
    disableInstrumentationKeyValidation: true,
    extensions: [reactPlugin],
  },
});

if (process.env.NODE_ENV !== "test") {
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

export { reactPlugin, appInsights };
