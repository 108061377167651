import React, { ReactElement, ReactNode } from "react";
import useEmergencyAlarmSettings, {
  IEmergencyAlarmSettingsHook,
} from "./emergency-alarm-settings-hook";

const EmergencyAlarmSettingsContext =
  React.createContext<IEmergencyAlarmSettingsHook>({} as never);

interface IProps {
  children: ReactNode;
}

export const EmergencyAlarmSettingsProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <EmergencyAlarmSettingsContext.Provider value={useEmergencyAlarmSettings()}>
      {props.children}
    </EmergencyAlarmSettingsContext.Provider>
  );
};

export const useEmergencyAlarmSettingsContextProvider =
  (): IEmergencyAlarmSettingsHook =>
    React.useContext<IEmergencyAlarmSettingsHook>(
      EmergencyAlarmSettingsContext,
    );
