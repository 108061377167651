import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface IProps {
  atLeastOnePermissionOf?: Permission[];
  showForTenantManagerOnly?: boolean;
  children: ReactElement;
}

function AuthorisedRoute(props: Readonly<IProps>): ReactElement {
  const {
    hasAtLeastOnePermissionOf,
    shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant,
  } = useAuth();

  if (
    !hasAtLeastOnePermissionOf(props.atLeastOnePermissionOf) ||
    shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant(
      props.showForTenantManagerOnly,
    )
  ) {
    return <Navigate to="/" replace />;
  }

  return props.children;
}

export default AuthorisedRoute;
