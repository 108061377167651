import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import Permission from "features/autorisation/domain/models/permission";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./explanation-links.scss";
import ExplanationLink from "../domain/models/explanationLink";
import DataGrid, {
  DataGridOverflowMenuItem,
} from "components/data-grid/data-grid";
import { GridColDef, GridRenderEditCellParams } from "@mui/x-data-grid";
import PopUp from "components/pop-up/pop-up.component";
import OrganisationUnit from "../domain/models/organisationUnit";
import {
  Button,
  Checkbox,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useExplanationLinksContextProvider } from "../context/explanation-links-provider";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import { BusinessOutlined } from "@mui/icons-material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  setConfirmActionCancelChanges,
  setShouldShowConfirmation,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useDispatch } from "react-redux";
import SelectField from "components/formFields/select-field.component";
import ExplanationLinkCommand from "../domain/models/explanationLink-command";
import { RegexExpressions } from "constants/regex/regex-expressions";
import SettingsNavigation from "../../settings/views/settings-navigation";

function ExplanationLinks(): ReactElement {
  const { t } = useTranslation("explanationLinks");
  const dispatch = useDispatch();

  const registerOptions = {
    title: {
      required: t("info.requiredHelperText"),
    },
    uri: {
      required: t("info.requiredHelperText"),
      validate: (value: string) => {
        const regex = new RegExp(RegexExpressions.URL);
        return regex.test(value.trim()) || t("info.uriFormatHelperText");
      },
    },
    organisationUnits: {
      required: t("info.requiredHelperText"),
    },
  };

  const {
    viewingMode,
    mutationIsLoading,
    currentSelectedExplanationLink,

    explanationLinks,
    explanationLinksResponseIsLoading,
    explanationLinksResponseIsSuccess,

    rowModesModel,
    handleAddExplanationLink,
    handleEditClick,
    handleSaveClick,
    handleCancelEdit,

    handleDeleteExplanationLink,
    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    readOrganisationUnitsForSettingsData,
    readOrganisationUnitsForSettingsIsSuccess,
    organisationUnits,
  } = useExplanationLinksContextProvider();

  const defaultValues = {
    title: "",
    uri: "",
    organisationUnits: organisationUnits,
  };

  const form = useForm({ mode: "onBlur", defaultValues });

  const control = form.control;
  const errors = form.formState.errors;
  const organisationUnitsWatch = form.watch("organisationUnits");

  useEffect(() => {
    form.reset({
      title: currentSelectedExplanationLink?.title ?? "",
      uri: currentSelectedExplanationLink?.uri ?? "",
      organisationUnits: organisationUnits,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedExplanationLink, organisationUnits]);

  useEffect(() => {
    if (form.formState.isDirty) {
      dispatch(
        setConfirmActionCancelChanges({
          confirmActionCancelChanges: () => {
            if (currentSelectedExplanationLink) {
              handleCancelEdit(currentSelectedExplanationLink.id);
            }
          },
        }),
      );
    }

    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  function submitForm(fieldValues: FieldValues) {
    const explanationLink: ExplanationLinkCommand = {
      id: currentSelectedExplanationLink?.id,
      title: fieldValues.title,
      uri: fieldValues.uri,
      organisationUnitIds: fieldValues.organisationUnits,
    };

    handleSaveClick(explanationLink);
  }

  function renderEditTitle(props: GridRenderEditCellParams) {
    return (
      <Controller
        name="title"
        rules={registerOptions.title}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="title"
            label={t("edit.title")}
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title?.message?.toString()}
            inputRef={(input: HTMLInputElement) => {
              if (props.hasFocus) {
                input && input.focus();
              }
            }}
          />
        )}
      />
    );
  }

  function renderEditUri(props: GridRenderEditCellParams) {
    return (
      <Controller
        name="uri"
        rules={registerOptions.uri}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="uri"
            label={t("edit.url")}
            variant="outlined"
            error={!!errors.uri}
            helperText={errors.uri?.message?.toString()}
            inputRef={(input: HTMLInputElement) => {
              if (props.hasFocus) {
                input && input.focus();
              }
            }}
          />
        )}
      />
    );
  }

  function getNames(selected: any) {
    let selectedNames: Array<string> = [];
    const selectedIds = selected as Array<string>;

    if (selectedIds !== null) {
      selectedIds.forEach((item: string) => {
        const organisation = readOrganisationUnitsForSettingsData?.find(
          (organisationUnit) => organisationUnit.id === item,
        );
        if (organisation) {
          selectedNames.push(organisation.name);
        }
      });
    }
    return selectedNames.join(", ");
  }

  function renderEditOrganisationUnit(props: GridRenderEditCellParams) {
    return (
      <Controller
        name="organisationUnits"
        rules={registerOptions.organisationUnits}
        control={control}
        render={({ field }) => (
          <SelectField
            multiple
            {...field}
            id="organisationUnit"
            label={t("edit.organisationUnitAccess")}
            variant="outlined"
            error={!!errors.organisationUnits}
            helperText={errors?.organisationUnits?.message?.toString()}
            onClick={() => form.trigger("organisationUnits")}
            inputRef={(input: HTMLInputElement) => {
              if (props.hasFocus) {
                input && input.focus();
              }
            }}
            startAdornment={
              <InputAdornment position="start">
                <BusinessOutlined fontSize="small" />
              </InputAdornment>
            }
            renderValue={(selected: any) => getNames(selected)}
          >
            {readOrganisationUnitsForSettingsData &&
              readOrganisationUnitsForSettingsIsSuccess &&
              readOrganisationUnitsForSettingsData.map((organisationUnit) => (
                <MenuItem
                  key={`organisation-unit-${organisationUnit.id}`}
                  value={organisationUnit.id}
                >
                  <Checkbox
                    sx={{ padding: "0 9px" }}
                    checked={
                      organisationUnitsWatch.indexOf(organisationUnit.id) > -1
                    }
                  />
                  {organisationUnit.name}
                </MenuItem>
              ))}
          </SelectField>
        )}
      />
    );
  }

  function checkIsDisabled(): boolean {
    return viewingMode === "editing" || viewingMode === "creation";
  }

  function getOverflowMenuItems(): Array<
    DataGridOverflowMenuItem<ExplanationLink>
  > {
    let menuItems: Array<DataGridOverflowMenuItem<ExplanationLink>> = [
      {
        label: t("settingsTable.action.edit"),
        action: (explanationLink) => handleEditClick(explanationLink),
        disabled: checkIsDisabled,
      },
      "divider",
      {
        label: t("settingsTable.action.delete"),
        action: (explanationLink) =>
          handleDeleteExplanationLink(explanationLink),
        disabled: checkIsDisabled,
      },
    ];

    return menuItems;
  }

  function getActionButtons(): Array<ReactElement> {
    let buttons: Array<ReactElement> = [
      <Button
        key={`Actionbutton_cancel${currentSelectedExplanationLink?.id}`}
        variant="text"
        onClick={() => {
          if (currentSelectedExplanationLink) {
            handleCancelEdit(currentSelectedExplanationLink.id);
          }
        }}
      >
        {t("edit.cancelFormButton")}
      </Button>,
      <LoadingButton
        key={`Actionbutton_save_${currentSelectedExplanationLink?.id}`}
        variant="contained"
        type="button"
        onClick={form.handleSubmit(submitForm)}
      >
        {t("edit.saveFormButton")}
      </LoadingButton>,
    ];

    return buttons;
  }

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: t("settingsTable.column.title"),
      flex: 1,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <Typography variant="subtitle1">{params.value}</Typography>
      ),
      renderEditCell: renderEditTitle,
    },
    {
      field: "uri",
      headerName: t("settingsTable.column.url"),
      flex: 1,
      sortable: false,
      editable: true,
      type: "custom",
      renderCell: (params) => (
        <Typography variant="subtitle1">
          <a href={params.value} target="_blank" rel="noreferrer">
            {params.value}
          </a>
        </Typography>
      ),
      renderEditCell: renderEditUri,
    },
    {
      field: "organisationUnits",
      headerName: t("settingsTable.column.organisationUnit"),
      flex: 1,
      sortable: false,
      editable: true,
      renderCell: (params) => (
        <div className="bread-crumb-parent-container">
          {params.value.map((organisationUnit: OrganisationUnit) => (
            <div
              className="bread-crumb-container"
              key={`OrganisationUnit_Breadcrumb_${organisationUnit.id}`}
            >
              {organisationUnit.parentsBreadCrumb && (
                <BreadCrumb
                  showEllipsis={organisationUnit.parentsBreadCrumb.length > 2}
                  allElementNames={organisationUnit.parentsBreadCrumb}
                >
                  {organisationUnit.parentsBreadCrumb.map(
                    (parentTitle: string) => {
                      return (
                        <Typography
                          key={`OrganisationUnit_Breadcrumb_${parentTitle}`}
                          variant="caption"
                        >
                          {parentTitle}
                        </Typography>
                      );
                    },
                  )}
                </BreadCrumb>
              )}
            </div>
          ))}
        </div>
      ),
      renderEditCell: renderEditOrganisationUnit,
    },
  ];

  return (
    <>
      <PageHeader
        title={t("pageHeaderTitle")}
        description={t("pageHeaderDescription")}
        addActionPermission={Permission.SettingsExplanationLink}
        isAddActionVisible={true}
        onAdd={handleAddExplanationLink}
        navigationComponent={<SettingsNavigation />}
        isAddActionDisabled={
          viewingMode === "editing" ||
          viewingMode === "creation" ||
          explanationLinksResponseIsLoading
        }
      />
      {explanationLinksResponseIsLoading && <LoadingIndicator />}
      {explanationLinksResponseIsSuccess && explanationLinks && (
        <DataGrid
          data={explanationLinks}
          columns={columns}
          rowModesModel={rowModesModel}
          editButtons={getActionButtons()}
          overflowMenuOptions={getOverflowMenuItems()}
        />
      )}
      <PopUp
        isOpen={isDeleteConfirmationPopupOpen}
        title={t("deleteExplanationLinkConfirmation.title")}
        body={t("deleteExplanationLinkConfirmation.bodyText")}
        primaryButtonText={t("deleteExplanationLinkConfirmation.confirmButton")}
        secondaryButtonText={t(
          "deleteExplanationLinkConfirmation.cancelButton",
        )}
        handleOnClose={closeDeleteConfirmationPopup}
        secondaryButtonAction={closeDeleteConfirmationPopup}
        primaryButtonAction={() => deleteConfirmationAction()}
        isLoadingPrimaryAction={mutationIsLoading}
      />
    </>
  );
}

export default ExplanationLinks;
