import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import PermissionsPermission from "features/autorisation/domain/models/permissions-permission";

const baseUrl = "/api/v1/Permission";

export const permissionApi = createApi({
  reducerPath: "permission",
  baseQuery,
  tagTypes: ["Permission"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getPermissions: build.query<Array<PermissionsPermission>, string>({
      query: (idpId) => ({
        url: `${baseUrl}/GetPermissions/${idpId}`,
        method: "GET",
      }),
      transformResponse: (response: {
        permissions: Array<PermissionsPermission>;
      }) => response.permissions,
    }),
    updatePermissions: build.mutation<
      Array<PermissionsPermission>,
      { idpId: string; permissions: Array<PermissionsPermission> }
    >({
      query: ({ idpId, permissions }) => ({
        url: `${baseUrl}/UpdatePermissions`,
        method: "PUT",
        body: { idpId, permissions },
      }),
      transformResponse: (response: {
        permissions: Array<PermissionsPermission>;
      }) => response.permissions,
    }),
  }),
});

export const { useLazyGetPermissionsQuery, useUpdatePermissionsMutation } =
  permissionApi;
