import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "components/page-header/page-header";
import "./temporary-access.component.scss";
import TemporaryAccessTable from "./temporary-access-table.component";
import TemporaryUserDetailsPopup from "./temporary-user-details-popup.component";
import { useTemporaryAccessContextProvider } from "../providers/temporary-access-provider";
import PopUp from "components/pop-up/pop-up.component";
import TemporaryAccessFilters from "./temporary-access-filters";

function TemporaryAccess(): ReactElement {
  const { t } = useTranslation("temporaryAccess");

  const {
    openTemporaryUserDetailsPopup,
    temporaryUserDetailsPopupIsOpen,

    revokeTemporaryAccessIsLoading: revokeTemporaryUserIsLoading,

    closeRevokeTemporaryUserConfirmationPopup,
    revokeTemporaryUserConfirmationAction,
    revokeTemporaryUserConfirmationPopupIsOpen,
  } = useTemporaryAccessContextProvider();

  return (
    <AutorisationWrapper
      atLeastOnePermissionOf={[Permission.ManageTemporaryAccess]}
    >
      <div className="temporary-users-container">
        <PageHeader
          title={t("pageHeaderTitle")}
          isAddActionVisible={true}
          onAdd={() => openTemporaryUserDetailsPopup("creation")}
          filterComponent={
            <div className="temporary-access-filter-component-container">
              <TemporaryAccessFilters />
            </div>
          }
        />
        <TemporaryAccessTable />
        {temporaryUserDetailsPopupIsOpen && <TemporaryUserDetailsPopup />}
        <PopUp
          isOpen={revokeTemporaryUserConfirmationPopupIsOpen}
          title={t("revokeTemporaryUserConfirmation.title")}
          body={t("revokeTemporaryUserConfirmation.body")}
          primaryButtonText={t("revokeTemporaryUserConfirmation.confirmButton")}
          secondaryButtonText={t(
            "revokeTemporaryUserConfirmation.cancelButton",
          )}
          handleOnClose={closeRevokeTemporaryUserConfirmationPopup}
          secondaryButtonAction={closeRevokeTemporaryUserConfirmationPopup}
          primaryButtonAction={() => revokeTemporaryUserConfirmationAction?.()}
          isLoadingPrimaryAction={revokeTemporaryUserIsLoading}
        />
      </div>
    </AutorisationWrapper>
  );
}

export default TemporaryAccess;
