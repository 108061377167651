import { useAuth } from "features/authentication/providers/authentication.provider";
import { ReactElement, useEffect } from "react";

import "./tenant-access-indicator.scss";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import routes from "features/routing/routes";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useAppDispatch } from "redux-base/store";
import usePrevious from "hooks/previous-hook";

export default function TenantAccessIndicator(): ReactElement {
  const {
    isTemporaryTenantAccessActive,
    revertTemporaryTenantAccess,
    profile,
  } = useAuth();
  const { t } = useTranslation("tenants");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const previousTemporaryTenantAccessActive = usePrevious(
    isTemporaryTenantAccessActive,
  );

  useEffect(() => {
    if (previousTemporaryTenantAccessActive && !isTemporaryTenantAccessActive) {
      navigate(isTemporaryTenantAccessActive ? "/" : routes.tenants.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemporaryTenantAccessActive]);

  if (!isTemporaryTenantAccessActive) {
    return <></>;
  }

  function onExitClick() {
    dispatch(
      showConfirmationPopup({
        confirmActionNextAction: () => {
          sessionStorage.clear();
          revertTemporaryTenantAccess();
        },
      }),
    );
  }

  return (
    <div className="tenant-access-indicator">
      <div className="tenant-header">
        <Typography variant="body1">
          {t("tenantAccessIndicator.customerRealmLabel")}
        </Typography>
        <Typography>&nbsp;</Typography>
        <Typography variant="h5">{profile?.customerRealmName}</Typography>
      </div>
      <Button onClick={onExitClick}>{t("tenantAccessIndicator.exit")}</Button>
    </div>
  );
}
