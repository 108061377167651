import { Typography } from "@mui/material";
import { TimelineItem } from "./timeline-item";
import "./timeline.component.scss";
import { ReactElement } from "react";
import Constants from "style/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Duration from "./duration.component";
import { HistoryItemType } from "features/history/domain/models/history-item-type";
import Line from "./line.component";
import { TimelineItemType } from "models/timeline-item-type";

interface IProps {
  historyItemType: HistoryItemType;
  items: TimelineItem[];
  showOpenChip: boolean;
}

function Timeline(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("timeline");

  return (
    <div className="timeline-component-container">
      {props.items.map((item, index) => (
        <div
          key={`timeline-item-${item.id}`}
          className={`timeline-item-row ${
            item.isHighlighted ? "highlighted" : ""
          }`}
        >
          <Line
            item={item}
            isFirstItem={index === 0}
            isLastItem={index === props.items.length - 1}
            showOpenChip={props.showOpenChip}
          />
          <div
            className={`information-column ${index === 0 ? "first-item" : ""} ${
              item.isHighlighted ? "highlighted" : ""
            }`}
          >
            <Typography variant="subtitle1">
              {props.historyItemType === HistoryItemType.Status && (
                <>{t("state_changed_to")} </>
              )}
              {item.title}
            </Typography>
            {item.timeLineType !== TimelineItemType.Created &&
              item.users.map((user) => (
                <Typography
                  key={`timeline-item-${item.id}-user-${user}`}
                  variant="caption"
                >
                  {user}
                </Typography>
              ))}
          </div>
          <div
            className={`information-column time-column ${
              index === 0 ? "first-item" : ""
            } ${item.isHighlighted ? "highlighted" : ""}`}
          >
            <Typography
              variant="overline"
              sx={{ color: Constants.Colors.onSurface }}
            >
              {moment(item.createdOn).format("DD-MM-YYYY · HH:mm").toString()}
            </Typography>
            {index !== 0 && (
              <Duration
                typographyVariant="overline"
                prefix={t("after")}
                firstItem={props.items[0]}
                secondItem={item}
                durationInMinutes={0}
                usePreCalculatedTime={false}
              />
            )}
          </div>
        </div>
      ))}

      {props.showOpenChip && (
        <div className="open-chip">
          <Typography
            variant="overline"
            sx={{ color: Constants.Colors.onSurface }}
          >
            {t("open")}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default Timeline;
