import PageHeader from "components/page-header/page-header";
import Permission from "features/autorisation/domain/models/permission";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import usePrevious from "hooks/previous-hook";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import theme from "style/theme";
import { ViewingMode } from "utils/viewing-utils";
import OrganisationDetails from "./organisation-details/organisation-details.component";
import OrganisationTree from "./organisation-tree/organisation-tree.component";
import "./organisation.scss";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";

function Organisation(): ReactElement {
  const { t } = useTranslation("organisation");
  const [viewingMode, setViewingMode] = useState<ViewingMode>("none");
  const [detailNode, setDetailNode] = useState<
    OrganisationTreeNode | undefined
  >();
  const [selectedNode, setSelectedNode] = useState<
    OrganisationTreeNode | undefined
  >();
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);
  const [siblings, setSiblings] = useState<OrganisationTreeNode[]>([]);

  function handleSelectionOfOrganisationUnit(
    newSelectedNode: OrganisationTreeNode | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
    siblings: OrganisationTreeNode[],
    viewingMode: ViewingMode,
  ) {
    setSelectedNode(newSelectedNode);
    setDetailNode(newSelectedNode);
    setUpstreamParentBranch(upstreamParentBranch);
    setSiblings(siblings);
    setViewingMode(viewingMode);
  }

  function handleStartCreation() {
    setDetailNode(undefined);
    setViewingMode("creation");
  }

  const previousSelectedNode = usePrevious(selectedNode);

  function handleCancel() {
    if (viewingMode === "creation") {
      setDetailNode(previousSelectedNode);
    }
    setViewingMode("viewing");
  }

  return (
    <OrganisationUnitTreeProvider.WithFaults>
      <div className="organisation-container">
        <div
          className={`organisation-structure-container ${
            viewingMode !== "none" && "detail-is-opened"
          }`}
          style={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
          }}
        >
          <PageHeader
            title={t("pageTitle")}
            addActionPermission={Permission.CreateOrganisationUnit}
            isAddActionVisible={
              viewingMode === "viewing" || viewingMode === "none"
            }
            onAdd={handleStartCreation}
            isAddActionDisabled={
              selectedNode === undefined || selectedNode.depth === 20
            }
          />
          <OrganisationTree
            onSelectOrganisationUnit={(
              selectedNode,
              upstreamParentBranch,
              siblings,
            ) =>
              handleSelectionOfOrganisationUnit(
                selectedNode,
                upstreamParentBranch,
                siblings,
                "viewing",
              )
            }
            onDeselectOrganisationUnit={() =>
              handleSelectionOfOrganisationUnit(undefined, [], [], "none")
            }
            useConfirmation
          />
        </div>
        <div
          className={`organisation-unit-detail-container ${
            viewingMode !== "none" && "detail-is-opened"
          }`}
          style={{
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.shortest,
            }),
          }}
        >
          <OrganisationDetails
            viewingMode={viewingMode}
            upstreamParentBranch={upstreamParentBranch}
            siblings={siblings}
            cancelAction={handleCancel}
            successAction={() => setViewingMode("viewing")}
            onViewingModeChanged={(viewingMode) => setViewingMode(viewingMode)}
            selectedNode={detailNode}
            parentNode={
              viewingMode === "creation"
                ? selectedNode
                : selectedNode?.parentOrganisationUnit
            }
          />
        </div>
      </div>
    </OrganisationUnitTreeProvider.WithFaults>
  );
}

export default Organisation;
