import { ViewingMode } from "utils/viewing-utils";
import "./role-organisation-picker.component.scss";
import { ReactElement, useEffect, useState } from "react";
import OrganisationTree from "features/organisation/views/organisation-tree/organisation-tree.component";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";

interface IProps {
  viewingMode: ViewingMode;
  initialSelectedOrganisationUnit?: string;
}

function RoleOrganisationPickerComponent(
  props: Readonly<IProps>,
): ReactElement {
  const form = useFormContext();
  const { t } = useTranslation("role");

  const [showValidation, setShowValidation] = useState<boolean>(false);

  function onSelectOrganisationUnit(
    selectedNode: OrganisationTreeNode,
    _upstreamParentBranch: OrganisationTreeNode[],
    _siblings: OrganisationTreeNode[],
  ): void {
    form.setValue("accessOrganisationUnitId", selectedNode.id);
    setShowValidation(false);
  }

  function onDeselectOrganisationUnit(): void {
    form.setValue("accessOrganisationUnitId", "");
    setShowValidation(true);
  }

  useEffect(() => {
    form.setValue(
      "accessOrganisationUnitId",
      props.initialSelectedOrganisationUnit ?? "",
    );
    setShowValidation(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.viewingMode]);

  useEffect(() => {
    if (
      !showValidation &&
      form.formState.isSubmitted &&
      props.viewingMode !== "viewing"
    ) {
      setShowValidation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isSubmitted]);

  return (
    <>
      {props.viewingMode !== "viewing" && showValidation && (
        <span className="validationError">
          {t("validation.noOrganisationUnitSelected")}
        </span>
      )}
      <OrganisationUnitTreeProvider.All>
        <OrganisationTree
          disableSelection={props.viewingMode === "viewing"}
          initialSelectedOrganisationUnit={
            props.initialSelectedOrganisationUnit
          }
          onSelectOrganisationUnit={onSelectOrganisationUnit}
          onDeselectOrganisationUnit={onDeselectOrganisationUnit}
        />
      </OrganisationUnitTreeProvider.All>
    </>
  );
}

export default RoleOrganisationPickerComponent;
