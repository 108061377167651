import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadTenantsResponse from "features/tenants/domain/models/read-tenants-response";
import TenantAccess from "features/tenants/domain/models/tenant-access";
import UpdateTenantCommand from "../models/update-tenant-command";

const baseUrl = "/api/v1/tenant";

export const tenantApi = createApi({
  reducerPath: "tenant",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Tenants"],
  endpoints: (build) => ({
    readTenants: build.query<ReadTenantsResponse, void>({
      query: () => ({
        url: `${baseUrl}/ReadTenants`,
        method: "GET",
      }),
      providesTags: ["Tenants"],
    }),
    updateTenant: build.mutation<void, UpdateTenantCommand>({
      query: (body) => ({
        url: `${baseUrl}/UpdateTenant`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Tenants"],
    }),
    accessTenant: build.query<TenantAccess, string>({
      query: (tenantName) => ({
        url: `${baseUrl}/AccessTenant/${tenantName}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useReadTenantsQuery,
  useUpdateTenantMutation,
  useLazyAccessTenantQuery,
} = tenantApi;
