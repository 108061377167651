import React, { ReactElement, ReactNode } from "react";
import useExplanationLinks, {
  IExplanationLinksHook,
} from "./explanation-links-hook";

const ExplanationLinksContext = React.createContext<IExplanationLinksHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const ExplanationLinksProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <ExplanationLinksContext.Provider value={useExplanationLinks()}>
      {props.children}
    </ExplanationLinksContext.Provider>
  );
};

export const useExplanationLinksContextProvider = (): IExplanationLinksHook =>
  React.useContext<IExplanationLinksHook>(ExplanationLinksContext);
