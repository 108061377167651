import KeyValuePair from "models/key-value-pair";

export default interface GatewayFilterValue {
  filterValueType: FilterValueType;
  values: KeyValuePair[];
}

export enum FilterValueType {
  Type = "Type",
  OrganisationUnit = "OrganisationUnit",
  Monitor = "Monitor",
}
