import { Button, Chip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import { ReactElement, useEffect, useState } from "react";
import "./filter-chip-date-time.component.scss";
import {
  MobileDatePicker,
  MobileDateTimePicker,
  PickersInputComponentLocaleText,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import { DateFilters } from "./models/date-filters";
import moment, { Moment } from "moment";

interface IProps {
  placeHolder: string;
  currentDateFilters: DateFilters;
  onDateFiltersChanged: (newDateFilters: DateFilters) => void;
  includeTime: boolean;
  onlyHistory: boolean;
}

export default function FilterChipDateTime(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("filterChip");

  const [isOpen, setIsOpen] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const currentDateFilters: DateFilters = {
    dateFrom: props.currentDateFilters.dateFrom
      ? moment(props.currentDateFilters.dateFrom)
      : null,
    dateTo: props.currentDateFilters.dateTo
      ? moment(props.currentDateFilters.dateTo)
      : null,
  };

  const [dateFilters, setDateFilters] =
    useState<DateFilters>(currentDateFilters);

  function handleOnChipClicked(): void {
    setIsOpen(!isOpen);
  }

  function isAnyDateFilterFilled(): boolean {
    return dateFilters.dateFrom !== null || dateFilters.dateTo !== null;
  }

  function validateDateFilters(): boolean {
    if (
      dateFilters.dateFrom != null &&
      dateFilters.dateTo != null &&
      dateFilters.dateFrom > dateFilters.dateTo
    ) {
      return false;
    }

    if (!props.onlyHistory) {
      return true;
    }

    if (
      dateFilters.dateFrom != null &&
      (!dateFilters.dateFrom.isValid() ||
        dateFilters.dateFrom > moment() ||
        (dateFilters.dateTo != null &&
          dateFilters.dateFrom > dateFilters.dateTo))
    ) {
      return false;
    }

    if (
      dateFilters.dateTo != null &&
      (!dateFilters.dateTo.isValid() ||
        dateFilters.dateTo > moment() ||
        (dateFilters.dateFrom != null &&
          dateFilters.dateTo < dateFilters.dateFrom))
    ) {
      return false;
    }

    return true;
  }

  const dateTimePickerLocaleText: PickersInputComponentLocaleText<moment.Moment> =
    {
      fieldYearPlaceholder: () =>
        t("dateTimePickerLocale.fieldYearPlaceholder"),
      fieldDayPlaceholder: () => t("dateTimePickerLocale.fieldDayPlaceholder"),
      fieldHoursPlaceholder: () =>
        t("dateTimePickerLocale.fieldHoursPlaceholder"),
      fieldMinutesPlaceholder: () =>
        t("dateTimePickerLocale.fieldMinutesPlaceholder"),
      toolbarTitle: t("dateTimePickerLocale.toolbarTitle"),
      cancelButtonLabel: t("dateTimePickerLocale.cancelButton"),
      clearButtonLabel: t("dateTimePickerLocale.clearButton"),
      okButtonLabel: t("dateTimePickerLocale.okButton"),
    };

  useEffect(() => {
    setDateFilters(currentDateFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentDateFilters]);

  return (
    <OutsideAlerter
      functionToExecute={() => {
        if (!datePickerIsOpen) {
          setDateFilters(currentDateFilters);
          setIsOpen(false);
        }
      }}
    >
      <div className="filter-chip-date">
        <Chip
          data-testid={`filterChip${props.placeHolder}`}
          label={props.placeHolder}
          variant="outlined"
          clickable
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={handleOnChipClicked}
          onClick={handleOnChipClicked}
          className={`${isAnyDateFilterFilled() ? "active" : ""}`}
        />
        <Paper elevation={1} className={`options ${!isOpen ? "hidden" : ""}`}>
          <div className="date-time-picker-row">
            {props.includeTime ? (
              <>
                <MobileDateTimePicker
                  maxDateTime={
                    dateFilters.dateTo ??
                    (props.onlyHistory ? moment() : undefined)
                  }
                  label={t("from")}
                  format="DD-MM-YYYY HH:mm"
                  localeText={dateTimePickerLocaleText}
                  value={dateFilters.dateFrom}
                  onOpen={() => setDatePickerIsOpen(true)}
                  onClose={() => setDatePickerIsOpen(false)}
                  onChange={(value: Moment | null) =>
                    setDateFilters({
                      ...dateFilters,
                      dateFrom: value,
                    })
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ["cancel", "clear", "accept"],
                    },
                  }}
                />
                <MobileDateTimePicker
                  minDateTime={dateFilters.dateFrom ?? undefined}
                  maxDateTime={props.onlyHistory ? moment() : undefined}
                  label={t("to")}
                  format="DD-MM-YYYY HH:mm"
                  localeText={dateTimePickerLocaleText}
                  value={dateFilters.dateTo}
                  onOpen={() => setDatePickerIsOpen(true)}
                  onClose={() => setDatePickerIsOpen(false)}
                  onChange={(value: Moment | null) =>
                    setDateFilters({
                      ...dateFilters,
                      dateTo: value,
                    })
                  }
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ["cancel", "clear", "accept"],
                    },
                  }}
                />
              </>
            ) : (
              <>
                <MobileDatePicker
                  maxDate={
                    dateFilters.dateTo ??
                    (props.onlyHistory ? moment() : undefined)
                  }
                  label={t("from")}
                  format="DD-MM-YYYY"
                  localeText={dateTimePickerLocaleText}
                  value={dateFilters.dateFrom}
                  onOpen={() => setDatePickerIsOpen(true)}
                  onClose={() => setDatePickerIsOpen(false)}
                  onChange={(value: Moment | null) =>
                    setDateFilters({
                      ...dateFilters,
                      dateFrom: value,
                    })
                  }
                  slotProps={{
                    actionBar: {
                      actions: ["cancel", "clear", "accept"],
                    },
                  }}
                />
                <MobileDatePicker
                  minDate={dateFilters.dateFrom ?? undefined}
                  maxDate={props.onlyHistory ? moment() : undefined}
                  label={t("to")}
                  format="DD-MM-YYYY"
                  localeText={dateTimePickerLocaleText}
                  value={dateFilters.dateTo}
                  onOpen={() => setDatePickerIsOpen(true)}
                  onClose={() => setDatePickerIsOpen(false)}
                  onChange={(value: Moment | null) =>
                    setDateFilters({
                      ...dateFilters,
                      dateTo: value,
                    })
                  }
                  slotProps={{
                    actionBar: {
                      actions: ["cancel", "clear", "accept"],
                    },
                  }}
                />
              </>
            )}
          </div>

          <div className="buttons-row">
            <Button
              sx={{ padding: "8px 24px 8px 24px" }}
              variant="outlined"
              onClick={() => {
                setDateFilters(currentDateFilters);
                setIsOpen(false);
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (validateDateFilters()) {
                  props.onDateFiltersChanged(dateFilters);
                  setIsOpen(false);
                }
              }}
            >
              {t("apply")}
            </Button>
          </div>
        </Paper>
      </div>
    </OutsideAlerter>
  );
}
