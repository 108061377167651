import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Resident from "../domain/models/resident";
import { ViewingMode } from "utils/viewing-utils";
import {
  useLazyReadBulkAvailableScenariosQuery,
  useUpdateBulkScenarioIsActiveMutation,
} from "../domain/reducers/resident.reducer";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { BulkUpdateOrganisationUnitScenarioIsActiveCommand } from "../domain/models/bulk-update-organisation-unit-scenario-is-active";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useAppDispatch } from "redux-base/store";
import BulkScenarioActiveState from "../domain/models/bulk-scenario-active-state";

export interface IResidentsBulkEditHook {
  submitChanges: (fieldValues: FieldValues) => void;
  selectedResidents: Resident[];
  selectResidents: (
    selectedResidents: Resident[],
    allResidents: Resident[],
  ) => void;
  allRowsAreSelected: boolean;
  viewingMode: ViewingMode;
  setViewingMode: Dispatch<SetStateAction<ViewingMode>>;
  form: UseFormReturn<BulkUpdateOrganisationUnitScenarioIsActiveCommand>;
  availableScenariosLoading: boolean;
  updateBulkScenarioIsActiveIsLoading: boolean;
}

const useResidentsBulkEdit = (): IResidentsBulkEditHook => {
  const [selectedResidents, setSelectedResidents] = useState<Resident[]>([]);
  const [allRowsAreSelected, setAllRowsAreSelected] = useState(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("none");
  const dispatch = useAppDispatch();

  const form = useForm<BulkUpdateOrganisationUnitScenarioIsActiveCommand>({
    mode: "onBlur",
    defaultValues: {
      bulkScenarioActiveStates: [],
      organisationUnitIds: [],
    },
  });

  const [
    triggerReadBulkAvailableScenarios,
    {
      data: availableScenariosResponse,
      isLoading: availableScenariosLoading,
      isFetching: availableScenariosFetching,
    },
  ] = useLazyReadBulkAvailableScenariosQuery();

  const [
    updateBulkScenarioIsActive,
    {
      error: updateBulkScenarioIsActiveError,
      isLoading: updateBulkScenarioIsActiveIsLoading,
      isSuccess: updateBulkScenarioIsActiveIsSuccess,
      isError: updateBulkScenarioIsActiveIsError,
    },
  ] = useUpdateBulkScenarioIsActiveMutation();

  useEffect(() => {
    if (selectedResidents.length > 0) {
      setViewingMode("editing");
      const organisationUnitIds = selectedResidents.map(
        (resident) => resident.organisationUnitId,
      );
      triggerReadBulkAvailableScenarios(
        {
          organisationUnitIds: organisationUnitIds,
        },
        false,
      );
    } else {
      setViewingMode("none");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResidents]);

  useEffect(() => {
    form.reset({
      bulkScenarioActiveStates: (
        availableScenariosResponse?.availableScenarios ?? []
      ).map((scenarioResponse) => {
        return {
          scenarioType: scenarioResponse.scenarioType,
          isActive: scenarioResponse.isAnyActive,
        } as BulkScenarioActiveState;
      }),
      organisationUnitIds: selectedResidents.map(
        (resident) => resident.organisationUnitId,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableScenariosResponse]);

  useEffect(() => {
    setSelectedResidents([]);
    setAllRowsAreSelected(false);
  }, [updateBulkScenarioIsActiveIsSuccess]);

  useEffect(() => {
    if (updateBulkScenarioIsActiveError) {
      dispatch(
        setErrorMessage({
          error: updateBulkScenarioIsActiveError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBulkScenarioIsActiveIsError]);

  const selectResidents = (
    selectedResidents: Resident[],
    allResidents: Resident[],
  ) => {
    setSelectedResidents(selectedResidents);
    setAllRowsAreSelected(
      selectedResidents.length !== 0 &&
        selectedResidents.length === allResidents.length,
    );
  };

  const submitChanges = (fieldValues: FieldValues) => {
    const command: BulkUpdateOrganisationUnitScenarioIsActiveCommand = {
      organisationUnitIds: fieldValues.organisationUnitIds,
      bulkScenarioActiveStates: fieldValues.bulkScenarioActiveStates,
    };

    updateBulkScenarioIsActive(command);
  };

  return {
    selectedResidents,
    selectResidents,
    allRowsAreSelected,
    viewingMode,
    setViewingMode,
    form,
    availableScenariosLoading:
      availableScenariosLoading || availableScenariosFetching,
    submitChanges,
    updateBulkScenarioIsActiveIsLoading,
  };
};

export default useResidentsBulkEdit;
