import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./alarm-setting-indicator.scss";

interface IProps {
  isDefault: boolean | undefined;
}

const AlarmSettingIndicator = ({ isDefault }: IProps) => {
  const { t } = useTranslation("emergencyAlarm");

  return (
    <div className={`chip ${!isDefault && "is-custom"}`}>
      <Typography>
        {t(`table.columns.${isDefault ? "inherit" : "divergent"}`)}
      </Typography>
    </div>
  );
};

export default AlarmSettingIndicator;
