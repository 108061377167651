import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function Doorbell3POutlineIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M7 22C6.45 22 5.97933 21.8043 5.588 21.413C5.196 21.021 5 20.55 5 20V4C5 3.45 5.196 2.979 5.588 2.587C5.97933 2.19567 6.45 2 7 2H17C17.55 2 18.021 2.19567 18.413 2.587C18.8043 2.979 19 3.45 19 4V20C19 20.55 18.8043 21.021 18.413 21.413C18.021 21.8043 17.55 22 17 22H7ZM7 20H17V4H7V20ZM12 19C12.55 19 13.021 18.8043 13.413 18.413C13.8043 18.021 14 17.55 14 17C14 16.45 13.8043 15.9793 13.413 15.588C13.021 15.196 12.55 15 12 15C11.45 15 10.9793 15.196 10.588 15.588C10.196 15.9793 10 16.45 10 17C10 17.55 10.196 18.021 10.588 18.413C10.9793 18.8043 11.45 19 12 19ZM12 18C11.7167 18 11.4793 17.904 11.288 17.712C11.096 17.5207 11 17.2833 11 17C11 16.7167 11.096 16.4793 11.288 16.288C11.4793 16.096 11.7167 16 12 16C12.2833 16 12.521 16.096 12.713 16.288C12.9043 16.4793 13 16.7167 13 17C13 17.2833 12.9043 17.5207 12.713 17.712C12.521 17.904 12.2833 18 12 18ZM12 13.5C12.3 13.5 12.5417 13.4083 12.725 13.225C12.9083 13.0417 13 12.8 13 12.5H11C11 12.8 11.0917 13.0417 11.275 13.225C11.4583 13.4083 11.7 13.5 12 13.5ZM8 12H16V11H15V8.7C15 7.95 14.8083 7.27933 14.425 6.688C14.0417 6.096 13.5 5.7 12.8 5.5V5.2C12.8 4.96667 12.725 4.775 12.575 4.625C12.425 4.475 12.2333 4.4 12 4.4C11.7667 4.4 11.575 4.475 11.425 4.625C11.275 4.775 11.2 4.96667 11.2 5.2V5.5C10.5 5.75 9.95833 6.15833 9.575 6.725C9.19167 7.29167 9 7.95 9 8.7V11H8V12Z" />
    </SvgIcon>
  );
}
