import { Close } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Dialog, IconButton, Typography } from "@mui/material";
import Scenario from "features/organisation/models/scenario";
import styles from "features/organisation/views/organisation-details/organisation-details-linkable-scenarios-modal.module.scss";
import LinkableScenariosTable from "features/scenario/linkable-scenario/views/linkable-scenarios-table.component";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onLinkButtonClicked: (selectedScenarios: Scenario[]) => void;
  scenariosToHide: Scenario[];
}

const OrganisationDetailsLinkableScenariosModal = ({
  isOpen,
  onClose,
  onLinkButtonClicked,
  scenariosToHide,
}: Readonly<Props>): JSX.Element => {
  const { t } = useTranslation("organisation");

  const [selectedScenarios, setSelectedScenarios] = useState<Scenario[]>([]);

  const handleOnClose = () => {
    onClose();
    setSelectedScenarios([]);
  };

  const handleOnLinkButtonClicked = () => {
    onLinkButtonClicked(selectedScenarios);
    setSelectedScenarios([]);
  };

  const dialogStyle = {
    "& .MuiPaper-root": {
      height: "960px",
      width: "100%",
      maxWidth: "777px",
      paddingBottom: "0",
    },
  };

  const hasSelectedScenarios = selectedScenarios.length > 0;

  return (
    <Dialog
      className={styles.modal}
      open={isOpen}
      onClose={handleOnClose}
      sx={dialogStyle}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Typography variant="h3">
            {t("linkableScenariosModal.title")}
          </Typography>
          <div className={styles.actions}>
            {!hasSelectedScenarios && (
              <Button variant="contained" disabled={true}>
                {t("linkableScenariosModal.disabledLinkButtonText")}
              </Button>
            )}
            {hasSelectedScenarios && (
              <Button
                className={styles.linkButton}
                variant="contained"
                onClick={handleOnLinkButtonClicked}
                startIcon={<CheckIcon />}
                data-testid="linkSelectedScenariosButton"
              >
                {t("linkableScenariosModal.enabledLinkButtonText", {
                  amountOfSelectedScenarios: selectedScenarios.length,
                })}
              </Button>
            )}
            <IconButton
              aria-label="close"
              className={styles.closeButton}
              onClick={handleOnClose}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <LinkableScenariosTable
          onSelectedScenariosChanged={(scenarios) =>
            setSelectedScenarios(scenarios)
          }
          scenariosToHide={scenariosToHide}
        />
      </div>
    </Dialog>
  );
};

export default OrganisationDetailsLinkableScenariosModal;
