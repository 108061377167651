import { Checkbox, Chip, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import { ReactElement, useState } from "react";
import KeyValuePair from "models/key-value-pair";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import "./multi-value-filter-chip.component.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  options: KeyValuePair[];
  onOptionsSelected: (keys: string[]) => void;
  placeHolder: string;
  selectedOptions: KeyValuePair[];
}

export default function MultiValueFilterChip(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("multiValueFilterChip");
  const [isOpen, setIsOpen] = useState(false);

  function handleOnChipClicked() {
    setIsOpen(!isOpen);
  }

  function handleOnCheckboxChanged(key: string) {
    const newSelectedOptions = [
      ...props.selectedOptions.map((pair) => pair.key),
    ];
    const selectedIndex = newSelectedOptions.indexOf(key);

    if (selectedIndex === -1) {
      newSelectedOptions.push(key);
    } else {
      newSelectedOptions.splice(selectedIndex, 1);
    }

    props.onOptionsSelected(newSelectedOptions);
  }

  const getLabel = (): string =>
    props.selectedOptions.length > 0
      ? props.selectedOptions.map((x) => x.value).join(", ")
      : props.placeHolder;

  const sortedOptions = props.options
    .slice()
    .sort((a, b) => a.value.localeCompare(b.value));

  return (
    <OutsideAlerter functionToExecute={() => setIsOpen(false)}>
      <div className="multi-value-filter-chip">
        <Chip
          data-testid={`filterChip${props.placeHolder}`}
          label={getLabel()}
          variant="outlined"
          clickable
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={handleOnChipClicked}
          onClick={handleOnChipClicked}
          className={`${props.selectedOptions.length > 0 ? "active" : ""}`}
        />
        {isOpen && (
          <Paper elevation={1} className="options">
            {sortedOptions.length === 0 ? (
              <Typography className="no-filter-options">
                {t("noFilterOptions")}
              </Typography>
            ) : (
              sortedOptions.map((option) => {
                const isSelected = props.selectedOptions.some(
                  (o) => o.key === option.key,
                );
                return (
                  <MenuItem
                    data-testid={`filterOption${option.key.replace(" ", "_")}`}
                    onClick={() => handleOnCheckboxChanged(option.key)}
                    key={option.key}
                    sx={{ padding: "0px 4px" }}
                  >
                    <Checkbox
                      sx={{
                        padding: 0,
                        margin: "8px",
                      }}
                      size="small"
                      onChange={() => handleOnCheckboxChanged(option.key)}
                      checked={isSelected}
                      key={option.key}
                    />
                    {option.value}
                  </MenuItem>
                );
              })
            )}
          </Paper>
        )}
      </div>
    </OutsideAlerter>
  );
}
