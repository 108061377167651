import KeyValuePair from "models/key-value-pair";

export default interface ResidentSettingsFilterValue {
  filterValueType: FilterValueType;
  values: KeyValuePair[];
}

export enum FilterValueType {
  OrganisationUnit = "OrganisationUnit",
  Scenario = "Scenario",
  LinkedDevice = "LinkedDevice",
}
