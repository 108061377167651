import { ReactElement } from "react";
import "./temporary-access-filters.scss";
import FilterChip from "components/filter-chip/filter-chip.component";
import KeyValuePair from "models/key-value-pair";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import Constants from "style/constants";
import { TemporaryAccessType } from "../domain/models/temporary-access-type";
import { TemporaryAccesReasonType } from "../domain/models/temporary-access-reason-type";
import { TemporaryAccesStatus } from "../domain/models/temporary-access-status";
import { RefreshRounded } from "@mui/icons-material";
import SearchBar from "components/search/searchbar";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import { useTemporaryAccessContextProvider } from "../providers/temporary-access-provider";
import { FilterValueType } from "../domain/models/temporary-access-filter-value";
import FilterChipDateTime from "components/filter-chip/filter-chip-date-time.component";

function TemporaryAccessFilters(): ReactElement {
  const { t } = useTranslation("temporaryAccess");
  const {
    refresh,
    temporaryAccessFilterHook,
    readOrganisationUnitsForTemporaryAccessData,
    readOrganisationUnitsForTemporaryAccessIsSuccess,
  } = useTemporaryAccessContextProvider();

  const typeFilterValues: KeyValuePair[] = [
    {
      key: TemporaryAccessType.User,
      value: t("filterValues.type.user"),
    },
    {
      key: TemporaryAccessType.Location,
      value: t("filterValues.type.location"),
    },
  ];

  const reasonFilterValues: KeyValuePair[] = [
    {
      key: TemporaryAccesReasonType.Vacation,
      value: t("filterValues.reasonType.vacation"),
    },
    {
      key: TemporaryAccesReasonType.Sickness,
      value: t("filterValues.reasonType.sickness"),
    },
    {
      key: TemporaryAccesReasonType.Calamity,
      value: t("filterValues.reasonType.calamity"),
    },
    {
      key: TemporaryAccesReasonType.Other,
      value: t("filterValues.reasonType.other"),
    },
  ];

  const statusFilterValues: KeyValuePair[] = [
    {
      key: TemporaryAccesStatus.Active,
      value: t("filterValues.status.active"),
    },
    {
      key: TemporaryAccesStatus.Expired,
      value: t("filterValues.status.expired"),
    },
    {
      key: TemporaryAccesStatus.Revoked,
      value: t("filterValues.status.revoked"),
    },
  ];

  return (
    <div className="temporary-access-filters-container">
      <div className="temporary-access-filters">
        <SearchBar
          onSearchValueChanged={temporaryAccessFilterHook.submitSearch}
          value={temporaryAccessFilterHook.searchBarValue}
        />

        <FilterChip
          key={`temporary-access-filter-chip-type`}
          options={typeFilterValues}
          onOptionSelected={(key?: string) =>
            temporaryAccessFilterHook.selectOption(
              FilterValueType.Type,
              key ? [key] : [],
            )
          }
          placeHolder={t("temporaryAccessTable.column.type")}
          initialSelectedOption={typeFilterValues.find((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Type)
              ?.values.includes(x.key),
          )}
        />

        {readOrganisationUnitsForTemporaryAccessIsSuccess &&
          readOrganisationUnitsForTemporaryAccessData && (
            <MultiValueFilterChip
              key={`temporary-access-filter-chip-Organisation-unit`}
              options={readOrganisationUnitsForTemporaryAccessData.map((x) => {
                const keyvaluePair: KeyValuePair = {
                  key: x.id,
                  value: x.name,
                };
                return keyvaluePair;
              })}
              onOptionsSelected={(keys: string[]) => {
                temporaryAccessFilterHook.selectOption(
                  FilterValueType.OrganisationUnit,
                  keys,
                );
              }}
              placeHolder={t("filters.location")}
              selectedOptions={readOrganisationUnitsForTemporaryAccessData
                .map((x) => {
                  const keyvaluePair: KeyValuePair = {
                    key: x.id,
                    value: x.name,
                  };
                  return keyvaluePair;
                })
                .filter((x) =>
                  temporaryAccessFilterHook.activeFilters
                    .find(
                      (x) =>
                        x.filterValueType === FilterValueType.OrganisationUnit,
                    )
                    ?.values.includes(x.key),
                )}
            />
          )}

        <FilterChipDateTime
          key={"temporary-access-filter-chip-period"}
          placeHolder={t("filters.period")}
          currentDateFilters={temporaryAccessFilterHook.dateFilter}
          onDateFiltersChanged={(newDateFilters) =>
            temporaryAccessFilterHook.setDateFilter(newDateFilters)
          }
          includeTime={false}
          onlyHistory={false}
        />

        <MultiValueFilterChip
          key={`temporary-access-filter-chip-reason`}
          options={reasonFilterValues}
          onOptionsSelected={(keys: string[]) =>
            temporaryAccessFilterHook.selectOption(FilterValueType.Reason, keys)
          }
          placeHolder={t("temporaryAccessTable.column.reason")}
          selectedOptions={reasonFilterValues.filter((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Reason)
              ?.values.includes(x.key),
          )}
        />

        <MultiValueFilterChip
          key={`temporary-access-filter-chip-status`}
          options={statusFilterValues}
          onOptionsSelected={(keys: string[]) =>
            temporaryAccessFilterHook.selectOption(FilterValueType.Status, keys)
          }
          placeHolder={t("filters.status")}
          selectedOptions={statusFilterValues.filter((x) =>
            temporaryAccessFilterHook.activeFilters
              .find((x) => x.filterValueType === FilterValueType.Status)
              ?.values.includes(x.key),
          )}
        />

        <div className="clear-filters">
          <Typography
            variant="body1"
            color={Constants.Colors.primary}
            onClick={temporaryAccessFilterHook.clearFilters}
          >
            {t("filters.clearFilters")}
          </Typography>
        </div>
      </div>

      {refresh && (
        <IconButton className="refresh-button" onClick={refresh}>
          <RefreshRounded />
        </IconButton>
      )}
    </div>
  );
}

export default TemporaryAccessFilters;
