import { CheckRounded, Close } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { ReactElement } from "react";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import "./tenant-details-header.scss";
import { useTenantsContextProvider } from "features/tenants/providers/tenants-provider";

function TenantDetailsHeader(): ReactElement {
  const { t } = useTranslation("tenants");

  const {
    viewingMode,
    setViewingMode,
    closeDetails,
    cancelEditDetails,
    isUpdatingTenant,
  } = useTenantsContextProvider();

  return (
    <div className="tenant-details-header-container">
      {viewingMode === "editing" && (
        <Button
          data-testid="tenantDetailCancelButton"
          variant="text"
          onClick={() => cancelEditDetails()}
          disabled={isUpdatingTenant}
        >
          {t("tenantDetails.cancelFormButton")}
        </Button>
      )}
      {viewingMode === "editing" && (
        <LoadingButton
          data-testid="tenantDetailSubmitButton"
          startIcon={<CheckRounded />}
          variant="contained"
          type="submit"
          loading={isUpdatingTenant}
          loadingPosition="start"
        >
          {t("tenantDetails.saveFormButton")}
        </LoadingButton>
      )}
      {viewingMode === "viewing" && (
        <AutorisationWrapper atLeastOnePermissionOf={[Permission.UpdateTenant]}>
          <Button
            data-testid="tenantDetailEditButton"
            startIcon={<ModeEditOutlineOutlinedIcon className="icon-on-text" />}
            variant="text"
            onClick={() => setViewingMode("editing")}
            disabled={isUpdatingTenant}
          >
            {t("tenantDetails.editFormButton")}
          </Button>
        </AutorisationWrapper>
      )}

      <IconButton
        aria-label="close"
        className="close-button"
        onClick={() => closeDetails()}
        data-testid="tenantPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
}

export default TenantDetailsHeader;
