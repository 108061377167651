import { ReactElement } from "react";
import "./history-details-left-content.component.scss";
import { useHistoryContextProvider } from "features/history/providers/history-provider";
import AlarmCard from "components/alarm-card/alarm-card.component";
import { HistoryItemType } from "features/history/domain/models/history-item-type";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AlarmPriority } from "features/history/domain/models/alarm-priority";
import { AlarmType } from "features/history/domain/models/alarm-type";
import EmergencyAlarmCard from "components/alarm-card/emergency-alarm-card.component";

function HistoryDetailsLeftContent(): ReactElement {
  const { t } = useTranslation("history");
  const { currentSelectedItem, readAlarmData, readEmergencyAlarmData } =
    useHistoryContextProvider();

  return (
    <div className="history-details-left-content-container">
      {currentSelectedItem && (
        <>
          {currentSelectedItem.historyType === HistoryItemType.Alarm &&
            currentSelectedItem?.type !== AlarmType.EmergencyAlarm &&
            readAlarmData && (
              <AlarmCard
                isDiverted={readAlarmData.isDiverted}
                priority={readAlarmData.priority ?? AlarmPriority.Lowest}
                type={readAlarmData.alarmType}
                status={readAlarmData.status}
                translatedType={t(`type.Alarm.${readAlarmData.alarmType}`)}
                nearbyText={readAlarmData.nearbyText}
                statusLastChangedAt={readAlarmData.statusLastChangedAt}
                statusLastChangedByFullName={
                  readAlarmData.statusLastChangedByFullName
                }
                statusLastChangedByEmail={
                  readAlarmData.statusLastChangedByEmail
                }
                isResidentialLocation={readAlarmData.isResidentialLocation}
                isAssistanceType={
                  readAlarmData.alarmType === AlarmType.Assistance
                }
                reporterIdentityType={readAlarmData.reporterIdentityType}
                reportedByFullName={readAlarmData.reportedByFullName}
                organisationUnitName={readAlarmData.organisationUnitName}
                message={readAlarmData.message}
                descendantOrganisationUnitNames={
                  readAlarmData.descendantOrganisationUnitNames
                }
              />
            )}
          {currentSelectedItem.historyType === HistoryItemType.Alarm &&
            currentSelectedItem?.type === AlarmType.EmergencyAlarm &&
            readEmergencyAlarmData && (
              <EmergencyAlarmCard
                priority={
                  readEmergencyAlarmData.priority ?? AlarmPriority.Lowest
                }
                type={readEmergencyAlarmData.alarmType}
                status={readEmergencyAlarmData.status}
                translatedType={t(
                  `type.Alarm.${readEmergencyAlarmData.alarmType}`,
                )}
                nearbyText={readEmergencyAlarmData.nearbyText}
                externalLocationCoordinates={
                  readEmergencyAlarmData.externalLocationCoordinates
                }
                statusLastChangedAt={readEmergencyAlarmData.statusLastChangedAt}
                reportedByFullName={readEmergencyAlarmData.reportedByFullName}
                message={readEmergencyAlarmData.message}
                acceptedByUsers={readEmergencyAlarmData.acceptedByUsers}
              />
            )}

          <div className="location">
            <Typography variant="h5">{t("details.location")}</Typography>
            <div>
              {currentSelectedItem?.organisationUnitsWithAncestors.map(
                (organisationUnits, i) => (
                  <BreadCrumb
                    key={`breadcrumb-${currentSelectedItem.id}-${i}`}
                    showEllipsis={organisationUnits.length > 2}
                    allElementNames={organisationUnits.map((unit) => unit.name)}
                    data-testid={`history-breadcrumb-child-${currentSelectedItem.id}`}
                  >
                    {organisationUnits?.map((unit, index) => {
                      return (
                        <Typography
                          data-testid={`history-breadcrumb-child-${index}`}
                          key={`History_Breadcrumb_Child_${unit.name}-${index}`}
                          variant="caption"
                          className="bread-crumb-item"
                        >
                          {unit.name}
                        </Typography>
                      );
                    })}
                  </BreadCrumb>
                ),
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HistoryDetailsLeftContent;
