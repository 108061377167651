import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function Custom2Icon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.1C11.4333 4.43333 10.7583 3.91667 9.975 3.55C9.19167 3.18333 8.36667 3 7.5 3C5.93333 3 4.625 3.52067 3.575 4.562C2.525 5.604 2 6.90833 2 8.475C2 9.24167 2.12933 9.975 2.388 10.675C2.646 11.375 3.05433 12.1 3.613 12.85C4.171 13.6 4.87933 14.4 5.738 15.25C6.596 16.1 7.625 17.0667 8.825 18.15L12 21V18.3C10.4 16.9 9.08333 15.704 8.05 14.712C7.01667 13.7207 6.2 12.8543 5.6 12.113C5 11.371 4.58333 10.7167 4.35 10.15C4.11667 9.58333 4 9.025 4 8.475C4 7.475 4.34567 6.64567 5.037 5.987C5.729 5.329 6.55 5 7.5 5C8.28333 5 9.00433 5.22933 9.663 5.688C10.321 6.146 12 7.5 12 7.5V5.1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.1C12.5667 4.43333 13.2417 3.91667 14.025 3.55C14.8083 3.18333 15.6333 3 16.5 3C18.0667 3 19.375 3.52067 20.425 4.562C21.475 5.604 22 6.90833 22 8.475C22 9.24167 21.8707 9.975 21.612 10.675C21.354 11.375 20.9457 12.1 20.387 12.85C19.829 13.6 19.1207 14.4 18.262 15.25C17.404 16.1 16.375 17.0667 15.175 18.15L12 21V18.3C13.6 16.9 14.9167 15.704 15.95 14.712C16.9833 13.7207 17.8 12.8543 18.4 12.113C19 11.371 19.4167 10.7167 19.65 10.15C19.8833 9.58333 20 9.025 20 8.475C20 7.475 19.6543 6.64567 18.963 5.987C18.271 5.329 17.45 5 16.5 5C15.7167 5 14.9957 5.22933 14.337 5.688C13.679 6.146 12 7.5 12 7.5V5.1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 8L11.7257 11.1092H15L12.0001 17L12.2743 13.0728H9L12.0001 8Z"
      />
    </SvgIcon>
  );
}
