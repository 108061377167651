import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadExplanationLinksResponse from "../models/read-explanationLinks-response";
import ExplanationLink from "../models/explanationLink";
import ExplanationLinkCommand from "../models/explanationLink-command";

const baseUrl = "/api/v1/Settings";

export const explanationLinksApi = createApi({
  reducerPath: "explanationLinks",
  baseQuery,
  tagTypes: ["ExplanationLinks"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    createExplanationLink: build.mutation<
      ExplanationLink,
      ExplanationLinkCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/CreateExplanationLink`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ExplanationLinks"],
    }),
    readExplanationLinks: build.query<ReadExplanationLinksResponse, void>({
      query: () => ({
        url: `${baseUrl}/ReadExplanationLinks`,
        method: "GET",
      }),
      providesTags: ["ExplanationLinks"],
    }),
    updateExplanationLink: build.mutation<
      ExplanationLink,
      ExplanationLinkCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/UpdateExplanationLink`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ExplanationLinks"],
    }),
    deleteExplanationLink: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteExplanationLink/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExplanationLinks"],
    }),
  }),
});

export const {
  useCreateExplanationLinkMutation,
  useReadExplanationLinksQuery,
  useUpdateExplanationLinkMutation,
  useDeleteExplanationLinkMutation,
} = explanationLinksApi;
