import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";
import Constants from "style/constants";

interface IProps {
  disabled?: boolean;
  sx?: SxProps<Theme>;
  color?: string;
}

export default function OutOfBedIcon(props: Readonly<IProps>): ReactElement {
  const color =
    props.disabled !== undefined && props.disabled
      ? Constants.Colors.inactive
      : props.color ?? "#7342BD";

  return (
    <SvgIcon sx={props.sx} viewBox="0 0 24 24">
      <path
        d="M10.5276 11H5.45455C4.90226 11 4.45455 11.4477 4.45455 12V15.803C4.45455 16.28 4.06788 16.6667 3.59091 16.6667C3.11394 16.6667 2.72727 16.28 2.72727 15.803V8.86364C2.72727 8.38666 2.34061 8 1.86364 8C1.38666 8 1 8.38666 1 8.86364V20.1364C1 20.6133 1.38666 21 1.86364 21C2.34061 21 2.72727 20.6133 2.72727 20.1364V19.4C2.72727 18.8477 3.17499 18.4 3.72727 18.4H10.6002L12.3335 16.6667H7.18182C6.62953 16.6667 6.18182 16.219 6.18182 15.6667V13.7333C6.18182 13.181 6.62953 12.7333 7.18182 12.7333H11.1166C10.8345 12.4895 10.5 12.0784 10.5 11.5C10.5 11.303 10.5106 11.138 10.5276 11Z"
        fill={color}
      />
      <path
        d="M20.5991 3.8C20.5991 4.79375 19.7928 5.6 18.7991 5.6C17.8053 5.6 16.9991 4.79375 16.9991 3.8C16.9991 2.80625 17.8053 2 18.7991 2C19.7928 2 20.5991 2.80625 20.5991 3.8Z"
        fill={color}
      />
      <path
        d="M15.7466 9.47375C15.7091 9.48875 15.6753 9.50375 15.6378 9.51875L15.6341 9.5225L15.3341 9.65375C14.7191 9.9275 14.2466 10.445 14.0328 11.0863L13.9353 11.3788C13.7253 12.005 13.0428 12.3462 12.4166 12.1362C11.7903 11.9262 11.4491 11.2475 11.6591 10.6175L11.7566 10.325C12.1803 9.04625 13.1253 8.00375 14.3591 7.45625L14.6591 7.325C15.4391 6.98 16.2828 6.8 17.1378 6.8C18.8103 6.8 20.3178 7.805 20.9628 9.34625L21.5366 10.7263L22.3428 11.1275C22.9353 11.4238 23.1753 12.1438 22.8791 12.7362C22.5828 13.3287 21.8628 13.5688 21.2703 13.2725L20.2691 12.7738C19.8828 12.5788 19.5791 12.2525 19.4141 11.855L19.0541 10.9925L18.3303 13.4487L20.1866 15.4738C20.3891 15.695 20.5353 15.9613 20.6066 16.2538L21.4691 19.7075C21.6303 20.3488 21.2366 21.0013 20.5953 21.1625C19.9541 21.3237 19.3016 20.93 19.1403 20.2888L18.3153 16.985L16.7926 15.3244L15.7578 17.9338C15.6678 18.1588 15.5328 18.365 15.3603 18.5375L13.0503 20.8513C12.5816 21.32 11.8203 21.32 11.3516 20.8513C10.8828 20.3825 10.8828 19.6213 11.3516 19.1525L13.5753 16.925L14.5128 14.585L15.1128 13.0887C15.007 12.6906 15.0038 12.2657 15.1128 11.855L15.7466 9.47375Z"
        fill={color}
      />
    </SvgIcon>
  );
}
