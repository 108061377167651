import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import "./tabs.component.scss";
import Permission from "features/autorisation/domain/models/permission";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { ReactElement, useState } from "react";

export interface TabModel {
  title: string;
  permission?: Permission;
  value: number;
}

interface IProps {
  onTabChanged: (tabValue: number) => void;
  tabs: TabModel[];
}

export default function Tabs(props: Readonly<IProps>): ReactElement {
  const { hasPermission } = useAuth();
  const [currentValue, setCurrentValue] = useState(0);

  const handleChange = (_: React.MouseEvent<HTMLElement>, newValue: number) => {
    setCurrentValue(newValue);
    props.onTabChanged(newValue);
  };

  const permissableTabs = props.tabs.filter(
    (tab) => !tab.permission || hasPermission(tab.permission),
  );

  return (
    <>
      {permissableTabs.length > 1 && (
        <div className="page-tab-container">
          <ToggleButtonGroup
            color="primary"
            value={currentValue}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            {permissableTabs.map((tab) => {
              return (
                <ToggleButton
                  data-testid={"tab-" + tab.title}
                  key={tab.value}
                  value={tab.value}
                  disabled={tab.value === currentValue}
                >
                  <div className="button-container">
                    {tab.value === currentValue && (
                      <CheckRoundedIcon className="icon" fontSize="small" />
                    )}
                    {tab.title}
                  </div>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
    </>
  );
}
