import PopUp from "components/pop-up/pop-up.component";
import { ReactElement } from "react";
import { useConfirmationPopupContextProvider } from "../context/confirmation-popup-provider";

function ProvidedConfirmationPopup(): ReactElement {
  const { isPopupVisible, confirm, text, title, confirmText, cancelText } =
    useConfirmationPopupContextProvider();

  return (
    <PopUp
      isOpen={isPopupVisible}
      title={title}
      body={text}
      primaryButtonText={confirmText}
      secondaryButtonText={cancelText}
      handleOnClose={() => confirm(false)}
      secondaryButtonAction={() => confirm(false)}
      primaryButtonAction={() => confirm(true)}
    />
  );
}

export default ProvidedConfirmationPopup;
