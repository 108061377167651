import {
  Hearing,
  PinDropOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { SxProps, Theme } from "@mui/material";
import { InBathroomIcon } from "components/custom-icons/in-bathroom-icon";
import IntruderIcon from "components/custom-icons/intruder-icon";
import { OpenDoorIcon } from "components/custom-icons/open-door-icon";
import OutOfBedIcon from "components/custom-icons/out-of-bed-icon";
import { OutOfRoomIcon } from "components/custom-icons/out-of-room-icon";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import { ScenarioType } from "models/scenario-type";
import Constants from "style/constants";

interface Props {
  organisationUnitScenario?: OrganisationUnitScenario;
  scenarioType?: ScenarioType;
  sx?: SxProps<Theme>;
  isVisible?: boolean;
}

const OrganisationUnitScenarioIcon = ({
  organisationUnitScenario,
  scenarioType,
  sx,
  isVisible,
}: Readonly<Props>): JSX.Element => {
  const type = organisationUnitScenario?.scenarioType ?? scenarioType;

  const getColor = (): string => {
    if (isVisible === undefined || !!isVisible) {
      return organisationUnitScenario !== undefined &&
        !organisationUnitScenario.isActive
        ? Constants.Colors.inactive
        : Constants.Colors.primary;
    }

    return "transparent";
  };

  switch (type) {
    case ScenarioType.CurrentLocation:
      return (
        <PinDropOutlined
          sx={{
            ...sx,
            color: getColor(),
          }}
        />
      );
    case ScenarioType.Bed:
      return <OutOfBedIcon sx={sx} color={getColor()} />;
    case ScenarioType.Room:
      return <OutOfRoomIcon sx={sx} color={getColor()} />;
    case ScenarioType.Door:
      return <OpenDoorIcon sx={sx} color={getColor()} />;
    case ScenarioType.Bathroom:
      return <InBathroomIcon sx={sx} color={getColor()} />;
    case ScenarioType.Intruder:
      return <IntruderIcon sx={sx} color={getColor()} />;
    case ScenarioType.Video:
      return (
        <VideocamOutlined
          sx={{
            ...sx,
            color: getColor(),
          }}
        />
      );
    case ScenarioType.Acoustic:
      return (
        <Hearing
          sx={{
            ...sx,
            color: getColor(),
          }}
        />
      );
    default:
      return <></>;
  }
};

export default OrganisationUnitScenarioIcon;
