import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Switch, Typography } from "@mui/material";
import OrganisationUnitScenarioIcon from "../resident-alarm-scenarios/organisation-unit-scenario-icon";
import { useTranslation } from "react-i18next";
import styles from "./scenario-row.component.module.scss";

export interface IProps {
  index: number;
}

function ScenarioRow(props: Readonly<IProps>): ReactElement {
  const form = useFormContext();
  const { t } = useTranslation("residentsSettings");
  return (
    <div className={styles.container}>
      {form.getValues("bulkScenarioActiveStates").length > props.index && (
        <>
          <Controller
            name={`bulkScenarioActiveStates.${props.index}.isActive`}
            control={form.control}
            render={({ field }) => (
              <Switch
                {...field}
                type="checkbox"
                value={field.value}
                checked={field.value}
              />
            )}
          />
          <OrganisationUnitScenarioIcon
            scenarioType={
              form.getValues("bulkScenarioActiveStates")[props.index]
                .scenarioType
            }
          />
          <Typography variant="h5">
            {t(
              `details.${form
                .getValues("bulkScenarioActiveStates")
                [props.index].scenarioType.toLowerCase()}`,
            )}
          </Typography>
        </>
      )}
    </div>
  );
}
export default ScenarioRow;
