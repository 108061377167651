import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { ViewingMode } from "utils/viewing-utils";

interface IProps extends ControllerProps {
  viewingMode: ViewingMode;
  displayValue?: string;
  display?: ReactElement;
  label?: string;
  alwaysShowHeader?: boolean;
  hideOnViewingWhenEmpty?: boolean;
}

function ViewingModeController(props: Readonly<IProps>): ReactElement {
  let newProps: Readonly<IProps> = {
    ...props,
    render: ({ field, fieldState, formState }) => {
      switch (props.viewingMode) {
        case "creation":
        case "editing":
          return (
            <>
              {props.alwaysShowHeader && props.label && (
                <Typography
                  variant="h5"
                  data-testid="viewingModeControllerLabel"
                >
                  {props.label}
                </Typography>
              )}
              {props.render({ field, fieldState, formState })}
            </>
          );
        case "viewing":
          if (
            props.hideOnViewingWhenEmpty &&
            (!field.value || field.value === "")
          ) {
            return <></>;
          }

          return (
            <>
              {props.label && (
                <Typography
                  variant="h5"
                  data-testid="viewingModeControllerLabel"
                >
                  {props.label}
                </Typography>
              )}
              {props.display ? (
                props.display
              ) : (
                <Typography
                  variant="subtitle1"
                  data-testid="viewingModeControllerDisplayValue"
                  sx={{ whiteSpace: "pre-line", wordBreak: "break-all" }}
                >
                  {props.displayValue ?? field.value}
                </Typography>
              )}
            </>
          );
        default:
          return <></>;
      }
    },
  };

  return <Controller {...newProps}></Controller>;
}

export default ViewingModeController;
