import Permission from "features/autorisation/domain/models/permission";

type RouteNames =
  | "organisation"
  | "integrations"
  | "devices"
  | "systems"
  | "gateways"
  | "connectors"
  | "roles"
  | "residentsSettings"
  | "history"
  | "dashboard"
  | "dashboardAlarms"
  | "dashboardResidents"
  | "tenants"
  | "appInformation"
  | "temporaryAccess"
  | "settings"
  | "settingsExplanationLink"
  | "settingsConnectionType"
  | "settingsEmergencyAlarm"
  | "settingsMalfunctionNotifications";

type RouteSettings = {
  path: string;
  atLeastOnePermissionOf?: Permission[];
  showForTenantManagerOnly?: boolean;
};

// Note: Route order is used to determine the index route
const routes: Record<RouteNames, RouteSettings> = {
  organisation: {
    path: "/organisation",
    atLeastOnePermissionOf: [Permission.ReadOrganisationUnit],
  },
  residentsSettings: {
    path: "/residentssettings",
    atLeastOnePermissionOf: [Permission.ReadResidentsSettings],
  },
  history: {
    path: "/history",
    atLeastOnePermissionOf: [Permission.ReadHistory],
  },
  integrations: {
    path: "/integrations",
    atLeastOnePermissionOf: [
      Permission.ReadExternalSystem,
      Permission.ReadSystemsGatewaysConnectors,
      Permission.ReadDevice,
    ],
  },
  devices: {
    path: "/integrations/devices",
    atLeastOnePermissionOf: [Permission.ReadDevice],
  },
  systems: {
    path: "/integrations/systems",
    atLeastOnePermissionOf: [Permission.ReadSystemsGatewaysConnectors],
  },
  gateways: {
    path: "/integrations/gateways",
    atLeastOnePermissionOf: [
      Permission.ReadSystemsGatewaysConnectors,
      Permission.ReadExternalSystem,
    ],
  },
  connectors: {
    path: "/integrations/connectors",
    atLeastOnePermissionOf: [Permission.ReadSystemsGatewaysConnectors],
  },
  roles: {
    path: "/roles",
    atLeastOnePermissionOf: [Permission.ReadRole],
  },
  dashboard: {
    path: "/dashboard",
    atLeastOnePermissionOf: [
      Permission.DashboardAlarms,
      Permission.DashboardResidents,
    ],
  },
  dashboardAlarms: {
    path: "/dashboard/alarms",
    atLeastOnePermissionOf: [Permission.DashboardAlarms],
  },
  dashboardResidents: {
    path: "/dashboard/residents",
    atLeastOnePermissionOf: [Permission.DashboardResidents],
  },
  tenants: {
    path: "/tenants",
    atLeastOnePermissionOf: [Permission.ReadTenant],
    showForTenantManagerOnly: true,
  },
  appInformation: {
    path: "/appInformation",
  },
  temporaryAccess: {
    path: "/temporaryAccess",
    atLeastOnePermissionOf: [Permission.ManageTemporaryAccess],
  },
  settings: {
    path: "/settings",
    atLeastOnePermissionOf: [
      Permission.SettingsExplanationLink,
      Permission.SettingsEmergencyAlarm,
      Permission.SettingsMalfunctionNotifications,
      Permission.UpdateTenant,
      Permission.ReadTenant,
    ],
  },
  settingsExplanationLink: {
    path: "/settings/explanationLink",
    atLeastOnePermissionOf: [Permission.SettingsExplanationLink],
  },
  settingsConnectionType: {
    path: "/settings/connection",
    atLeastOnePermissionOf: [Permission.UpdateTenant, Permission.ReadTenant],
  },
  settingsEmergencyAlarm: {
    path: "/settings/emergencyalarm",
    atLeastOnePermissionOf: [Permission.SettingsEmergencyAlarm],
  },
  settingsMalfunctionNotifications: {
    path: "/settings/malfunctionnotifications",
    atLeastOnePermissionOf: [Permission.SettingsMalfunctionNotifications],
  },
};

export default routes;
