import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import "./configuration-row.scss";
import { ReactElement, useEffect, useState } from "react";
import OrganisationUnitScenario from "features/residents/residents-settings/domain/models/organisation-unit-scenario";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import { getClosestTimeFrame } from "utils/timeframe-utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OrganisationUnitScenarioIcon from "../resident-alarm-scenarios/organisation-unit-scenario-icon";
import { useResidentSettingsContextProvider } from "../../context/resident-settings-provider";
import TimeFrame from "../../domain/models/configurations/time-frame";
import TemporaryAcousticScenarioConfiguration from "../../domain/models/configurations/temporary-acoustic-configuration";
import moment from "moment";

interface IProps {
  index: number;
  scenarioTitle: string;
  organisationUnitScenario: OrganisationUnitScenario;
  originalOrganisationUnitScenario: OrganisationUnitScenario;
  children: ReactElement | ReactElement[];
  scenarioActiveAllDay: boolean;
  timeFrames: TimeFrame[] | null;
  showAlarmInfo: boolean;
  alarmTimeOut?: number | null;
  isAlarmActive?: boolean;
  subtitlePrefix: string;
  whenActiveAlarm?: boolean;
  temporaryAcousticConfiguration?: TemporaryAcousticScenarioConfiguration;
  isStatusActive?: boolean;
}

function ConfigurationRow(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const [showConfigurationRow, setShowConfigurationRow] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      (residentDetailsHook.viewingMode === "viewing" &&
        !props.organisationUnitScenario.isActive &&
        showConfigurationRow) ||
      !residentDetailsHook.currentResident
    ) {
      setShowConfigurationRow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentDetailsHook.viewingMode, residentDetailsHook.currentResident]);

  function getDescriptiveTimeFrameText(): ReactElement {
    if (props.temporaryAcousticConfiguration) {
      const formattedTimeUntil = moment(
        props.temporaryAcousticConfiguration.validUntil,
      ).format("HH:mm");
      return (
        <Typography variant="body2" className="timeframe-text">
          {t("scenarioConfiguration.scenarioTemporaryActiveUntil", {
            time: formattedTimeUntil,
          })}
        </Typography>
      );
    }

    if (props.isStatusActive && !props.isAlarmActive) {
      return <></>;
    }

    if (props.scenarioActiveAllDay) {
      return (
        <Typography variant="body2" className="timeframe-text">
          {props.subtitlePrefix}
        </Typography>
      );
    }
    if (props.timeFrames === null) {
      return <></>;
    }

    const closestTimeFrame = getClosestTimeFrame(props.timeFrames);

    if (closestTimeFrame.formattedTimeFrameText === "") {
      return <></>;
    }

    return closestTimeFrame.isInTimeFrame ? (
      <Typography variant="body2" className="timeframe-text">
        {`${props.subtitlePrefix} ${t(
          "scenarioConfiguration.scenarioActiveUntil",
          {
            time: closestTimeFrame.formattedTimeFrameText,
          },
        )}`}
      </Typography>
    ) : (
      <Typography variant="body2" className="timeframe-text not-in-timeframe">
        {`${props.subtitlePrefix} ${t(
          "scenarioConfiguration.scenarioActiveFrom",
          {
            time: closestTimeFrame.formattedTimeFrameText,
          },
        )}`}
      </Typography>
    );
  }

  function getDescriptiveAlarmTimeText(): string {
    if (props.alarmTimeOut == null || props.alarmTimeOut === 0) {
      return t("scenarioConfiguration.alarmTimeNow");
    }

    if (props.alarmTimeOut === -1) {
      return t("scenarioConfiguration.alarmIntentionOutOfBed");
    }

    const hours = props.alarmTimeOut / 3600;
    const roundedHours = Math.floor(hours);
    const minutes = (hours - roundedHours) * 60;
    const roundedMinutes = Math.round(minutes);

    if (roundedMinutes > 0 && roundedHours === 0) {
      return t("scenarioConfiguration.afterMinutes", {
        minutes: roundedMinutes,
      });
    }

    if (roundedMinutes === 0 && roundedHours > 0) {
      return t("scenarioConfiguration.afterHours", { hours: roundedHours });
    }

    return t("scenarioConfiguration.afterHoursMinutes", {
      hours: roundedHours,
      minutes: roundedMinutes,
    });
  }

  function toggleConfigurationRow() {
    if (props.organisationUnitScenario.isActive || showConfigurationRow) {
      setShowConfigurationRow(!showConfigurationRow);
    }
  }

  function getScenarioAlarmIndicatorText(): string {
    let alarmIndicatorTextKey = "disabled";
    if (props.showAlarmInfo && props.organisationUnitScenario.isActive) {
      alarmIndicatorTextKey = props.isAlarmActive ? "alarm" : "noAlarm";
    } else if (
      !props.showAlarmInfo &&
      props.organisationUnitScenario.isActive
    ) {
      return "";
    }

    return t(`scenarioConfiguration.${alarmIndicatorTextKey}`);
  }

  function getContainerClassNames(): string {
    const classNames = ["configuration-row-container"];

    if (props.organisationUnitScenario.isActive) {
      classNames.push("scenario-active");

      if (
        props.scenarioActiveAllDay ||
        props.temporaryAcousticConfiguration ||
        (props.timeFrames !== null &&
          getClosestTimeFrame(props.timeFrames).isInTimeFrame)
      ) {
        classNames.push("is-in-timeframe");
      }

      if (props.isAlarmActive) {
        classNames.push("alarm-active");
      }
    }

    return classNames.join(" ");
  }

  return (
    <Accordion expanded={showConfigurationRow} disableGutters>
      <AccordionSummary
        sx={{
          padding: 0,
          margin: "8px 0 0 0",
          ".MuiAccordionSummary-content": {
            margin: "0",
          },
          flexDirection: "row-reverse",
        }}
        expandIcon={<ExpandMoreIcon onClick={toggleConfigurationRow} />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
      >
        <div className={getContainerClassNames()}>
          <Controller
            name={`organisationUnitScenarios.${props.index}.isActive`}
            control={residentDetailsHook.form.control}
            render={({ field }) => (
              <Switch
                className={"switch"}
                type="checkbox"
                disabled={residentDetailsHook.viewingMode === "viewing"}
                checked={field.value}
                onChange={(_, checked) => {
                  if (checked || residentDetailsHook.form.formState.isValid) {
                    setShowConfigurationRow(checked);
                  }
                  residentDetailsHook.form.setValue(
                    `organisationUnitScenarios.${props.index}.isActive`,
                    checked,
                    { shouldDirty: true },
                  );
                }}
              />
            )}
          />
          <OrganisationUnitScenarioIcon
            organisationUnitScenario={props.organisationUnitScenario}
          />
          <Grid
            className="configuration-row-column"
            onClick={toggleConfigurationRow}
            onKeyDown={(e) => e.key === "Enter" && toggleConfigurationRow()}
          >
            <div className="configuration-row-in-column">
              <Typography variant="h5" className="scenario-title">
                {props.scenarioTitle}
              </Typography>
              <Typography variant="body2" className="grey-when-inactive">
                {getScenarioAlarmIndicatorText()}
              </Typography>
            </div>
            {props.organisationUnitScenario.isActive && (
              <div className="configuration-row-in-column grey-when-inactive">
                {getDescriptiveTimeFrameText()}

                {props.showAlarmInfo && props.isAlarmActive && (
                  <Typography variant="body2">
                    {getDescriptiveAlarmTimeText()}
                  </Typography>
                )}
              </div>
            )}
          </Grid>
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 0 8px 0", margin: 0 }}>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

export default ConfigurationRow;
