import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import Resident from "./resident";
import BaseAlarmScenarionConfiguration from "./configurations/base-alarm-scenario-configuration";
import { ScenarioType } from "models/scenario-type";

export default interface ReadResidentsResponse {
  total: number;
  residents: Resident[];
}

export function deserialize(
  readResidentsResponse: ReadResidentsResponse,
): ReadResidentsResponse {
  readResidentsResponse?.residents.forEach((resident) =>
    resident.organisationUnitScenarios.forEach((organisationUnitScenario) => {
      if (organisationUnitScenario.scenarioType !== ScenarioType.Video) {
        const configuration = jsonParseWithLowerCaseKeys(
          organisationUnitScenario.configuration,
        ) as BaseAlarmScenarionConfiguration;
        configuration.sendAlarmAllDay = configuration.sendAlarmAllDay ?? false;
        configuration.isStatusActive = configuration.isStatusActive ?? true;
        organisationUnitScenario.configuration = JSON.stringify(configuration);
      }
    }),
  );

  return readResidentsResponse;
}
