import { createTheme } from "@mui/material";

const globalTheme = createTheme({
  palette: {
    primary: {
      main: "#7342BD",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#3055C9",
    },
    text: {
      secondary: "#1D1B1E",
      primary: "#1D1B1E",
    },
    background: {
      default: "#FFFBFF",
    },
    error: {
      main: "#BA1A1A",
    },
  },
  typography: {
    h1: {
      //Headline Large
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 32,
      lineHeight: "40px",
    },
    h2: {
      //Headline Medium
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 28,
      lineHeight: "36px",
    },
    h3: {
      //Headline Small
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: "32px",
    },

    h4: {
      //Title Large
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 22,
      lineHeight: "28px",
    },
    h5: {
      //Title Medium
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "24px",
    },
    h6: {
      //Title Small
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
    },

    body1: {
      //Label Large
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
    },
    body2: {
      //Label Medium
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16px",
    },
    overline: {
      //Label Small
      color: "#001551",
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: 11,
      lineHeight: "16px",
      textTransform: "none",
      letterSpacing: "0.5px",
    },

    caption: {
      //Body Small
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "16px",
    },

    subtitle1: {
      //Body Medium
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
    },

    subtitle2: {
      //Body Large
      color: "#1D1B1E",
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  transitions: {
    duration: {
      enteringScreen: 400,
      leavingScreen: 400,
    },
  },
});

export default globalTheme;
