import { SerializedError } from "@reduxjs/toolkit";
import {
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import authenticationService from "features/authentication/services/authentication.service";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: async (headers) => {
    headers.set(
      "authorization",
      `Bearer ${await authenticationService.getAccessToken()}`,
    );
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

export type ApiError = FetchBaseQueryError | SerializedError;

export type ErrorResponse = {
  error: ApiError;
};

export type OkResponse<T> = {
  data: T;
};

export type ApiResponse<T> = OkResponse<T> | ErrorResponse;

export function isErrorResponse<T>(
  apiResponse: ApiResponse<T>,
): apiResponse is ErrorResponse {
  return (apiResponse as ErrorResponse).error !== undefined;
}

export function isOkResponse<T>(
  apiResponse: ApiResponse<T>,
): apiResponse is OkResponse<T> {
  return (apiResponse as OkResponse<T>).data !== undefined;
}

export function isFetchBaseQueryError(
  error: ApiError,
): error is FetchBaseQueryError {
  return (error as FetchBaseQueryError).status !== undefined;
}
