import { ReactElement, useState } from "react";
import { IconButton } from "@mui/material";
import {
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import "./copy-button.component.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  contentToCopy: string | undefined;
}

function CopyButton(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("copyButton");

  const [showCopiedNotice, setShowCopiedNotice] = useState(false);

  async function copyContentToClipboard(): Promise<void> {
    await navigator.clipboard.writeText(props.contentToCopy!);
    setShowCopiedNotice(true);
    setTimeout(() => setShowCopiedNotice(false), 500);
  }

  return (
    <>
      {navigator.clipboard && props.contentToCopy && (
        <IconButton
          onClick={copyContentToClipboard}
          title={t("copyToClipboardButton")}
          data-testid="copy-client-secret-to-clipboard"
          className="copy-content-button"
        >
          {showCopiedNotice ? <CheckIcon /> : <ContentCopyOutlinedIcon />}
        </IconButton>
      )}
    </>
  );
}

export default CopyButton;
