import { SvgIcon, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

interface IProps {
  sx?: SxProps<Theme>;
}

export default function OfficeBuildingMarker(
  props: Readonly<IProps>,
): ReactElement {
  return (
    <SvgIcon sx={props.sx} viewBox="0 0 24 24">
      <path d="M15 10C15 9.44771 15.4477 9 16 9C16.5523 9 17 9.44771 17 10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10ZM17 6C17 5.44772 16.5523 5 16 5C15.4477 5 15 5.44772 15 6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6ZM11 14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14C13 13.4477 12.5523 13 12 13C11.4477 13 11 13.4477 11 14ZM13 6C13 5.44772 12.5523 5 12 5C11.4477 5 11 5.44772 11 6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6ZM11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9C11.4477 9 11 9.44771 11 10ZM9 6C9 5.44772 8.55228 5 8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6ZM9 10C9 9.44771 8.55228 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11C8.55228 11 9 10.5523 9 10ZM14.8588 21.4989C14.6765 21.2161 14.3437 21 14.0073 21H13.9872C13.442 21 13 20.558 13 20.0128V18.5C13 17.9477 12.5523 17.5 12 17.5C11.4477 17.5 11 17.9477 11 18.5V20C11 20.5523 10.5523 21 10 21H6C5.44772 21 5 20.5523 5 20V4C5 3.44772 5.44772 3 6 3H18C18.5523 3 19 3.44772 19 4V10.2792C19 10.7037 19.3351 11.0522 19.7475 11.1528C20.3443 11.2983 21 10.8932 21 10.2789V2C21 1.44772 20.5523 1 20 1H4C3.44772 1 3 1.44772 3 2V22C3 22.5523 3.44772 23 4 23H14.0331C14.8125 23 15.281 22.154 14.8588 21.4989ZM7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18C9 17.4477 8.55228 17 8 17C7.44772 17 7 17.4477 7 18ZM9 14C9 13.4477 8.55228 13 8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15C8.55228 15 9 14.5523 9 14ZM22 16.5C22 18.3467 20.2343 20.8493 19.2111 22.1444C18.8423 22.6112 18.1577 22.6112 17.7889 22.1444C16.7657 20.8493 15 18.3467 15 16.5C15 14.6 16.6 13 18.5 13C20.4 13 22 14.6 22 16.5ZM19.7094 16.6823C19.706 16.6273 19.7012 16.5722 19.6963 16.5174C19.6449 15.9461 19.0724 15.4 18.5 15.4C17.9 15.4 17.3 15.9 17.3 16.6C17.3 17.2 17.8 17.8 18.5 17.8C19.168 17.8 19.7449 17.2536 19.7094 16.6823Z" />
    </SvgIcon>
  );
}
