import { Grid, Typography } from "@mui/material";
import { useAuth } from "features/authentication/providers/authentication.provider";
import "./user-information.component.scss";
import { ReactElement } from "react";

interface IProps {
  onClick: () => void;
}

function UserInformation(props: Readonly<IProps>): ReactElement {
  const { profile } = useAuth();

  return (
    <Grid
      className="user-information-container"
      onClick={props.onClick}
      onKeyDown={(e) => e.key === "Enter" && props.onClick()}
    >
      <div className="avatar-container">
        <Typography variant="body1">{profile?.initials}</Typography>
      </div>
      <div className="name-container">
        <Typography className="name" variant="h5">
          {profile?.fullName}
        </Typography>
      </div>
    </Grid>
  );
}

export default UserInformation;
