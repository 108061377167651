import { useEffect, useState } from "react";
import { ConnectionTypeResponse } from "../domain/models/connection-type";
import {
  useUpdateConnectionTypeMutation,
  useReadConnectionTypeQuery,
} from "../domain/reducers/connectionType.reducer";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";

export interface IConnectionTypeHook {
  connectionType?: ConnectionTypeResponse | null;
  updateConnectionType: (type: ConnectionTypeResponse) => void;
  isLoading: boolean;
}

const useConnectionType = (): IConnectionTypeHook => {
  const dispatch = useDispatch();
  const [connectionType, setConnectionType] =
    useState<ConnectionTypeResponse>();

  const {
    data: connectionTypeResponse,
    isLoading,
    isError: connectionTypeIsError,
    error: connectionTypeError,
  } = useReadConnectionTypeQuery();

  const [
    updateConnectionType,
    { error: updateConnectionTypeError, isError: updateConnectionTypeIsError },
  ] = useUpdateConnectionTypeMutation();

  const handleConnectionType = (type: ConnectionTypeResponse) => {
    if (connectionType?.connectionType === type.connectionType) {
      return;
    }

    setConnectionType(type);
    updateConnectionType(type);
  };

  useEffect(() => {
    connectionTypeResponse && setConnectionType(connectionTypeResponse);
  }, [connectionTypeResponse]);

  useEffect(() => {
    if (connectionTypeIsError || updateConnectionTypeIsError) {
      const error = connectionTypeIsError
        ? connectionTypeError
        : updateConnectionTypeError;

      setConnectionType(connectionTypeResponse);
      dispatch(setErrorMessage({ error }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionTypeIsError, updateConnectionTypeIsError]);

  return {
    connectionType: connectionType,
    updateConnectionType: handleConnectionType,
    isLoading,
  };
};

export default useConnectionType;
