import { Chip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import { ReactElement, useState } from "react";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import "./filter-chip-organisation-unit.component.scss";
import MultiSelectOrganisationTree from "features/organisation/views/multi-select-organisation-tree/multi-select-organisation-tree.component";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";

interface IProps {
  onOptionsSelected: (keys: SelectedOrganisationTreeNode[]) => void;
  placeHolder: string;
  selectedOptions: SelectedOrganisationTreeNode[];
}

export default function FilterChipOrganisationUnit(
  props: Readonly<IProps>,
): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  function onSelectOrganisationUnit(selectedNode: OrganisationTreeNode) {
    let selectedOrganisationUnitsCopy = [...props.selectedOptions];
    const indexOfNode = selectedOrganisationUnitsCopy.findIndex(
      (x) => x.id === selectedNode.id,
    );

    if (indexOfNode === -1) {
      selectedOrganisationUnitsCopy.push({
        id: selectedNode.id,
        name: selectedNode.name,
        depth: selectedNode.depth,
      });
      addChildrenToSelectedOrganisationUnits(
        selectedOrganisationUnitsCopy,
        selectedNode.childOrganisationUnits,
      );
    } else {
      selectedOrganisationUnitsCopy.splice(indexOfNode, 1);
      removeChildrenFromSelectedOrganisationUnits(
        selectedOrganisationUnitsCopy,
        selectedNode.childOrganisationUnits,
      );
    }

    selectedOrganisationUnitsCopy = selectedOrganisationUnitsCopy.sort(
      (a, b) => a.depth - b.depth || a.name.localeCompare(b.name),
    );

    props.onOptionsSelected(selectedOrganisationUnitsCopy);
  }

  function addChildrenToSelectedOrganisationUnits(
    selectedOrganisationUnitsCopy: SelectedOrganisationTreeNode[],
    childOrganisationUnits: OrganisationTreeNode[],
  ) {
    childOrganisationUnits.forEach((child) => {
      if (!selectedOrganisationUnitsCopy.some((x) => x.id === child.id)) {
        selectedOrganisationUnitsCopy.push({
          id: child.id,
          name: child.name,
          depth: child.depth,
        });
      }

      addChildrenToSelectedOrganisationUnits(
        selectedOrganisationUnitsCopy,
        child.childOrganisationUnits,
      );
    });
  }

  function removeChildrenFromSelectedOrganisationUnits(
    selectedOrganisationUnitsCopy: SelectedOrganisationTreeNode[],
    childOrganisationUnits: OrganisationTreeNode[],
  ) {
    childOrganisationUnits.forEach((child) => {
      const indexOfNode = selectedOrganisationUnitsCopy.findIndex(
        (x) => x.id === child.id,
      );

      if (indexOfNode !== -1) {
        selectedOrganisationUnitsCopy.splice(indexOfNode, 1);
      }

      removeChildrenFromSelectedOrganisationUnits(
        selectedOrganisationUnitsCopy,
        child.childOrganisationUnits,
      );
    });
  }

  function handleOnChipClicked() {
    setIsOpen(!isOpen);
  }

  const getLabel = (): string =>
    props.selectedOptions.length > 0
      ? props.selectedOptions.map((x) => x.name).join(", ")
      : props.placeHolder;

  return (
    <OutsideAlerter functionToExecute={() => setIsOpen(false)}>
      <div className="filter-chip filter-chip-organisation-unit">
        <Chip
          data-testid={`filterChip${props.placeHolder}`}
          label={getLabel()}
          variant="outlined"
          clickable
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={handleOnChipClicked}
          onClick={handleOnChipClicked}
          className={`${props.selectedOptions.length === 0 ? "" : "active"}`}
        />
        <Paper elevation={1} className={`options ${!isOpen ? "hidden" : ""}`}>
          <MultiSelectOrganisationTree
            onSelectOrganisationUnit={onSelectOrganisationUnit}
            selectedOrganisationUnitIds={props.selectedOptions.map((x) => x.id)}
          />
        </Paper>
      </div>
    </OutsideAlerter>
  );
}
