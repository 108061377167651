import { useAuth } from "features/authentication/providers/authentication.provider";
import routes from "features/routing/routes";
import { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToDashboardDetails(): ReactElement {
  const { hasAtLeastOnePermissionOf, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let navigateTo = "/";
    if (
      hasAtLeastOnePermissionOf(routes.dashboardAlarms.atLeastOnePermissionOf)
    ) {
      navigateTo = routes.dashboardAlarms.path;
    } else if (
      hasAtLeastOnePermissionOf(
        routes.dashboardResidents.atLeastOnePermissionOf,
      )
    ) {
      navigateTo = routes.dashboardResidents.path;
    }

    navigate(navigateTo, { replace: true });
  }, [user, hasAtLeastOnePermissionOf, navigate]);

  return <></>;
}
