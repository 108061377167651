import { CheckRounded, Close } from "@mui/icons-material";
import { Button, Dialog, IconButton, Typography } from "@mui/material";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./organisation-picker.scss";
import OrganisationTree from "features/organisation/views/organisation-tree/organisation-tree.component";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onOrganisationUnitSelected: (
    selectedNode: OrganisationTreeNode,
    upstreamParentBranch: OrganisationTreeNode[],
  ) => void;
}

export default function OrganisationPicker(props: Readonly<IProps>) {
  const { t } = useTranslation("organisation");

  const [selectedNode, setSelectedNode] = useState<
    OrganisationTreeNode | undefined
  >();
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);

  function onSelectOrganisationUnit(
    selectedNode: OrganisationTreeNode | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): void {
    setSelectedNode(selectedNode);
    setUpstreamParentBranch(upstreamParentBranch);
  }

  function onDeselectOrganisationUnit() {
    onSelectOrganisationUnit(undefined, []);
  }

  function handleSelect() {
    props.onOrganisationUnitSelected(selectedNode!, upstreamParentBranch);
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      className="organisation-picker"
      PaperProps={{ classes: { root: "organisation-picker-paper" } }}
    >
      <div
        className="organisation-picker-header"
        data-testid="organisationPicker"
      >
        <Typography variant="h2">{t("picker.title")}</Typography>
        <Button
          startIcon={<CheckRounded />}
          variant="contained"
          disabled={!selectedNode}
          onClick={handleSelect}
          data-testid="organisationPickerSelectButton"
        >
          {t("picker.selectButton")}
        </Button>
        <IconButton
          aria-label="close"
          className="close-button"
          onClick={props.onClose}
          data-testid="organisationPickerCloseButton"
        >
          <Close />
        </IconButton>
      </div>
      <OrganisationTree
        onSelectOrganisationUnit={onSelectOrganisationUnit}
        onDeselectOrganisationUnit={onDeselectOrganisationUnit}
      />
    </Dialog>
  );
}
