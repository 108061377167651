import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { useLazyGetSubscriptionInfoQuery } from "features/subscription-info/domain/reducers/subscription.reducer";
import { useEffect, useRef } from "react";
import { MessageType } from "./domain/models/message-type";
import { Notification } from "./domain/models/notification";
import authenticationService from "features/authentication/services/authentication.service";
import { useLazyOnReconnectQuery } from "./domain/queries/notification-queries";

export interface ISignalRHook {
  onNotification: (
    newMethod: (notification: Notification) => void,
  ) => () => void;
}

const notificationMethodName = "notification";

let notificationHubConnection: HubConnection | null;

const useSignalR = (): ISignalRHook => {
  const { user } = useAuth();
  const userRef = useRef(user);
  const listenersRef = useRef<((notification: Notification) => void)[]>([]);

  const [triggerGetSubscriptionInfoQuery, { data: subscriptionInfo }] =
    useLazyGetSubscriptionInfoQuery();

  const { refetch: refetchOnReconnect } = useLazyOnReconnectQuery();

  const handleNotification = (notification: Notification): void => {
    if (notification.messageType === MessageType.Logout) {
      authenticationService.logout();
    }
  };

  useEffect(() => {
    const offNotification = onNotification(handleNotification);

    return () => {
      offNotification();
    };
  }, []);

  useEffect(() => {
    userRef.current = user;

    if (user) {
      triggerGetSubscriptionInfoQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    subscriptionInfo ? setupHubConnections() : destroyHubConnections();

    return () => {
      destroyHubConnections();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionInfo]);

  const getToken = (): string => userRef.current?.access_token ?? "";

  const setupNotificationHubConnection = (): void => {
    if (notificationHubConnection) {
      return;
    }

    notificationHubConnection = new HubConnectionBuilder()
      .withUrl(`${subscriptionInfo?.connectionString ?? ""}/notification`, {
        accessTokenFactory: getToken,
      })
      .withKeepAliveInterval(30000)
      .withAutomaticReconnect(getRetryDelays())
      .build();

    notificationHubConnection.start();

    notificationHubConnection.on(notificationMethodName, (message: string) => {
      const notification: Notification = JSON.parse(message);
      listenersRef.current.forEach((listener) => listener(notification));
    });

    notificationHubConnection.onreconnected(() => refetchOnReconnect());
  };

  const setupHubConnections = (): void => {
    setupNotificationHubConnection();
  };

  const destroyNotificationHubConnection = (): void => {
    if (!notificationHubConnection) {
      return;
    }

    notificationHubConnection.off(notificationMethodName);
    notificationHubConnection.stop();
    notificationHubConnection = null;
  };

  const destroyHubConnections = (): void => {
    destroyNotificationHubConnection();
  };

  const onNotification = (
    newMethod: (notification: Notification) => void,
  ): (() => void) => {
    listenersRef.current = [...listenersRef.current, newMethod];

    return () => {
      listenersRef.current = listenersRef.current.filter(
        (listener) => listener !== newMethod,
      );
    };
  };

  const getRetryDelays = (): number[] => {
    const secondsInHour = 3600;
    const intervalInSeconds = 5;
    const hours = 2;

    return Array.from(
      { length: (hours * secondsInHour) / intervalInSeconds },
      () => intervalInSeconds * 1000,
    );
  };

  return {
    onNotification,
  };
};

export default useSignalR;
