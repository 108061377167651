import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { ViewingMode } from "utils/viewing-utils";

export default class ResidentialLocationValidator {
  static checkSingleResidence(
    viewingMode: ViewingMode,
    parentNode: OrganisationTreeNode | undefined,
    selectedNode: OrganisationTreeNode | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): boolean {
    if (
      !parentNode?.id ||
      (!this.hasResidentialLocationInParents(
        parentNode.id,
        upstreamParentBranch,
      ) &&
        !this.hasResidentialLocationInChildren(
          viewingMode === "editing"
            ? selectedNode?.childOrganisationUnits
            : undefined,
        ))
    ) {
      return true;
    }

    return false;
  }
  private static hasResidentialLocationInChildren(
    childOrganisationUnits: OrganisationTreeNode[] | undefined,
  ): boolean {
    if (!childOrganisationUnits) {
      return false;
    }

    let anyChildIsResidantialLocation = false;

    for (const child of childOrganisationUnits) {
      if (child.isResidentialLocation) {
        anyChildIsResidantialLocation = true;
        break;
      }

      if (this.hasResidentialLocationInChildren(child.childOrganisationUnits)) {
        anyChildIsResidantialLocation = true;
        break;
      }
    }

    return anyChildIsResidantialLocation;
  }
  private static hasResidentialLocationInParents(
    parentId: string | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): boolean {
    if (!parentId) {
      return false;
    }

    const parent = upstreamParentBranch.find((node) => node.id === parentId);
    if (parent?.isResidentialLocation) {
      return true;
    }

    return this.hasResidentialLocationInParents(
      parent?.parentId,
      upstreamParentBranch,
    );
  }

  static hasLocationInParents(
    parentId: string | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): boolean {
    if (!parentId) {
      return false;
    }
    const parent = upstreamParentBranch.find((node) => node.id === parentId);
    if (parent?.isLocation) {
      return true;
    }

    return this.hasLocationInParents(parent?.parentId, upstreamParentBranch);
  }
}
