import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import App from "./App";
import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import "style/fonts.scss";
import "translations/i18n";
import { store } from "redux-base/store";
import { Provider } from "react-redux";
import theme from "style/theme";
import Constants from "style/constants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <style>
        {`
              :root {
                --palette-primary-main:       ${theme.palette.primary.main};
                --palette-secondary-main:     ${theme.palette.secondary.main};
                --palette-error-main:         ${theme.palette.error.main};
                --palette-background-default: ${theme.palette.background.default};
                --typography-body1-color:     ${theme.typography.body1.color};
                --palette-common-black:       ${theme.palette.common.black};
                --palette-common-white:       ${theme.palette.common.white};
                --palette-surface:            ${Constants.surface};
                --palette-surface1:           ${Constants.surface1};
                --palette-surface2:           ${Constants.surface2};
                --palette-surface3:           ${Constants.surface3};
                --palette-surface4:           ${Constants.surface4};
                --palette-surface5:           ${Constants.surface5};
                --elevation-light1:           ${Constants.elevationLight1};
                --elevation-light2:           ${Constants.elevationLight2};
                --on-primary:                 ${Constants.Colors.onPrimary};
                --primary-container:          ${Constants.Colors.primaryContainer};
                --on-primary-container:       ${Constants.Colors.onPrimaryContainer};
                --on-secondary:               ${Constants.Colors.onSecondary};
                --secondary-container:        ${Constants.Colors.secondaryContainer};
                --on-secondary-container:     ${Constants.Colors.onSecondaryContainer};
                --on-tertiary:                ${Constants.Colors.onTertiary};
                --tertiary-container:         ${Constants.Colors.tertiaryContainer};
                --on-tertiary-container:      ${Constants.Colors.onTertiaryContainer};
                --on-error:                   ${Constants.Colors.onError};
                --error-container:            ${Constants.Colors.errorContainer};
                --on-error-container:         ${Constants.Colors.onErrorContainer};
                --on-background:              ${Constants.Colors.onBackground};
                --on-surface:                 ${Constants.Colors.onSurface};
                --surface-variant:            ${Constants.Colors.surfaceVariant};
                --on-surface-variant:         ${Constants.Colors.onSurfaceVariant};
                --palette-inverse-surface:    ${Constants.Colors.inverseSurface};
                --palette-on-inverse-surface: ${Constants.Colors.onInverseSurface};
                --outline:                    ${Constants.Colors.outline};
              }
         `}
      </style>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
