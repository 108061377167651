import { Connector } from "features/connectors/domain/connector";
import ConnectorFilter, {
  ConnectorFilterValueType,
} from "features/connectors/domain/connector-filter";
import { ReadConnectorsQuery } from "features/connectors/domain/read-connectors-query";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import useTableHook, { ITableHook } from "hooks/table-hook";
import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

export interface ConnectorFiltersHook {
  activeFilters: ConnectorFilter[];
  searchBarValue: string;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  submitSearch: (query: string) => void;
  selectOption: (
    filterValueType: ConnectorFilterValueType,
    keys: string[],
  ) => void;
  clearFilters: () => void;

  readConnectorsQuery: ReadConnectorsQuery;

  table: ITableHook<Connector>;
}

const useConnectorFilters = (): ConnectorFiltersHook => {
  const [activeFilters, setActiveFilters] = useState<ConnectorFilter[]>([]);
  const [readConnectorsQuery, setReadConnectorsQuery] =
    useState<ReadConnectorsQuery>({
      filters: Array<ConnectorFilter>(),
    } as ReadConnectorsQuery);

  const table = useTableHook<Connector>({
    defaultSort: {
      isAscending: true,
      property: "name",
    },
    sessionStorageKey: "connectors-sort",
  });

  const [
    connectorFiltersFromSessionStorage,
    setConnectorFiltersFromSessionStorage,
  ] = useSessionStorage<ConnectorFilter[]>("connector-filters", []);
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-connector-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-connector-filters", "");

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    table.sortFromSessionStorage,
    connectorFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    table.resetPaging();
  };

  const selectOption = (
    filterValueType: ConnectorFilterValueType,
    keys: string[],
  ) => {
    let updatedFiltersList = [...readConnectorsQuery.filters];
    const filterIndex = updatedFiltersList.findIndex(
      (x) => x.filterValueType === filterValueType,
    );

    if (filterIndex !== -1) {
      updatedFiltersList[filterIndex] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setConnectorFiltersFromSessionStorage(updatedFiltersList);
    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) =>
    setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);

  const clearFilters = () => {
    setActiveFilters([]);
    setConnectorFiltersFromSessionStorage([]);
    setSelectedOrganisationUnitsFromSessionStorage([]);
    setReadConnectorsQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    table.resetPaging();
  };

  const buildAndSaveQuery = () => {
    setActiveFilters(connectorFiltersFromSessionStorage);
    setReadConnectorsQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      sort: table.sortFromSessionStorage ?? prevState.sort,
      filters: connectorFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  };

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readConnectorsQuery,

    selectOption,
    submitSearch,
    clearFilters,

    table,
  };
};

export default useConnectorFilters;
