import {
  BedRounded,
  LinkOutlined,
  VisibilityOffOutlined,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import SubscriptionIcon from "components/custom-icons/subcription-icon";
import Divider from "components/divider/divider.component";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PopUp from "components/pop-up/pop-up.component";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import {
  confirmationActionExecuted,
  setConfirmActionCancelChanges,
  setShouldShowConfirmation,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import CreateOrganisationUnitCommand from "features/organisation/domain/models/create-organisation-unit-command";
import EditOrganisationUnitCommand from "features/organisation/domain/models/edit-organisation-unit-command";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import OrganisationUnitDevice from "features/organisation/domain/models/organisation-unit-device";
import {
  useCreateOrganisationUnitMutation,
  useDeleteOrganisationUnitMutation,
  useUpdateOrganisationUnitMutation,
} from "features/organisation/domain/reducers/organisation-unit.reducer";
import NameValidator from "features/organisation/domain/validators/name-validator";
import ResidentialLocationValidator from "features/organisation/domain/validators/residential-location-validator";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import { ReactElement, useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  FormProvider,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { ViewingMode } from "utils/viewing-utils";
import Header from "./header.component";
import OrganisationDetailsDevicesTable from "./organisation-details-devices-table.component";
import OrganisationDetailsLinkableDevicesModal from "./organisation-details-linkable-devices-modal.component";
import "./organisation-details.component.scss";
import SettingsRow from "./settings-row.component";
import OrganisationDetailsScenariosList from "features/organisation/views/organisation-details/organisation-details-scenarios-list.component";
import Scenario from "features/organisation/models/scenario";
import OrganisationDetailsLinkableScenariosModal from "features/organisation/views/organisation-details/organisation-details-linkable-scenarios-modal.component";
import { DevicesProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import { useOrganisationUnitsProvider } from "features/organisation/context/organisation-units-provider";
import SubscriptionChannelValidator from "features/organisation/domain/validators/subscription-channel-validator";
import OfficeBuildingMarker from "components/custom-icons/office-building-marker";

interface IProps {
  viewingMode: ViewingMode;
  upstreamParentBranch: OrganisationTreeNode[];
  siblings: OrganisationTreeNode[];
  selectedNode?: OrganisationTreeNode;
  parentNode?: OrganisationTreeNode;
  cancelAction: () => void;
  successAction: () => void;
  onViewingModeChanged: (viewingMode: ViewingMode) => void;
}

function OrganisationDetails(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("organisation");
  const dispatch = useDispatch();

  const [
    shouldShowDeleteConfirmationPopup,
    setShouldShowDeleteConfirmationPopup,
  ] = useState<boolean>(false);
  const [
    shouldShowIsLocationConfirmationPopup,
    setShouldShowIsLocationConfirmationPopup,
  ] = useState<boolean>(false);
  const [shouldShowErrorDeletePopup, setShouldShowErrorDeletePopup] =
    useState<boolean>(false);
  const [errorDeletePopupContent, setErrorDeletePopupContent] =
    useState<string>("");
  const [linkableDevicesModalIsOpen, setLinkableDevicesModalIsOpen] =
    useState<boolean>(false);
  const [linkableScenariosModalIsOpen, setLinkableScenariosModalIsOpen] =
    useState<boolean>(false);
  const [unlinkedDevices, setUnlinkedDevices] = useState<
    OrganisationUnitDevice[]
  >([]);
  const [unlinkedScenarios, setUnlinkedScenarios] = useState<Scenario[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [diversionTimeoutEnabled, setDiversionTimeoutEnabled] = useState(false);
  const [diversionTimeout, setDiversionTimeout] = useState(900);

  const [createOrganisationUnit] = useCreateOrganisationUnitMutation();
  const [deleteOrganisationUnit] = useDeleteOrganisationUnitMutation();
  const [updateOrganisationUnit] = useUpdateOrganisationUnitMutation();

  const { organisationUnitDetailsHook } = useOrganisationUnitsProvider();

  const defaultFormValues = {
    name: "",
    isLocation: false,
    residentialLocation: false,
    isSubscriptionChannel: false,
    isHidden: false,
    diversionTimeout: 900,
    devices: [] as OrganisationUnitDevice[],
    scenarios: [] as Scenario[],
    intentionOutOfBedEnabled: false,
  };

  const diversionTimeoutOptions = [
    <MenuItem
      data-testid={`diversion-timeout-item-fifteen-minutes`}
      key={`diversion-timeout-item-fifteen-minutes`}
      value={900}
    >
      {t("diversionTimeoutOptions.fifteenMinutes")}
    </MenuItem>,
    <MenuItem
      data-testid={`diversion-timeout-item-thirty-minutes`}
      key={`diversion-timeout-item-thirty-minutes`}
      value={1800}
    >
      {t("diversionTimeoutOptions.thirtyMinutes")}
    </MenuItem>,
    <MenuItem
      data-testid={`diversion-timeout-item-fourty-five-minutes`}
      key={`diversion-timeout-item-fourty-five-minutes`}
      value={2700}
    >
      {t("diversionTimeoutOptions.fourtyFiveMinutes")}
    </MenuItem>,
  ];

  const form = useForm({
    mode: "onBlur",
    defaultValues: defaultFormValues,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    formState: { isDirty },
    trigger,
    clearErrors,
    reset,
    watch,
  } = form;

  const watchIsLocation = watch("isLocation");

  useEffect(() => {
    if (watchIsLocation) {
      setValue("isSubscriptionChannel", true);
      setValue("residentialLocation", false);
      setValue("isHidden", false);
      setValue("scenarios", []);
    } else if (props.viewingMode !== "viewing") {
      setValue("isSubscriptionChannel", false);
    }
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIsLocation]);

  useEffect(() => {
    if (isDirty) {
      dispatch(
        setConfirmActionCancelChanges({
          confirmActionCancelChanges: () => {
            handleCancel();
          },
        }),
      );
    }

    dispatch(setShouldShowConfirmation({ shouldShowConfirmation: isDirty }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    clearErrors();
    if (props.selectedNode) {
      organisationUnitDetailsHook.loadOrganisationUnit(props.selectedNode.id);
    } else {
      reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedNode]);

  useEffect(() => {
    clearErrors();
    setUnlinkedDevices([]);
    setUnlinkedScenarios([]);

    if (props.viewingMode === "creation") {
      reset(defaultFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.viewingMode]);

  useEffect(() => {
    if (
      !organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit &&
      organisationUnitDetailsHook.selectedOrganisationUnit &&
      props.viewingMode === "viewing"
    ) {
      reset({
        name: organisationUnitDetailsHook.selectedOrganisationUnit.name,
        isLocation:
          organisationUnitDetailsHook.selectedOrganisationUnit.isLocation,
        residentialLocation:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .isResidentialLocation,
        isSubscriptionChannel:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .isSubscriptionChannel,
        isHidden: organisationUnitDetailsHook.selectedOrganisationUnit.isHidden,
        devices: organisationUnitDetailsHook.selectedOrganisationUnit.devices,
        scenarios:
          organisationUnitDetailsHook.selectedOrganisationUnit.scenarios,
        diversionTimeout:
          organisationUnitDetailsHook.selectedOrganisationUnit.diversionTimeout,
        intentionOutOfBedEnabled:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .intentionOutOfBedEnabled,
      });

      setDiversionTimeoutEnabled(
        organisationUnitDetailsHook.selectedOrganisationUnit
          .diversionTimeout !== null,
      );

      if (
        organisationUnitDetailsHook.selectedOrganisationUnit
          .diversionTimeout !== null
      ) {
        setDiversionTimeout(
          organisationUnitDetailsHook.selectedOrganisationUnit
            .diversionTimeout as number,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit]);

  function toggleDiversionTimeout() {
    setDiversionTimeoutEnabled(!diversionTimeoutEnabled);
    if (diversionTimeoutEnabled) {
      setValue("diversionTimeout", diversionTimeout);
    } else {
      setDiversionTimeout(900);
    }
  }

  async function submitForm(fieldValues: FieldValues) {
    setIsSubmitting(true);
    if (!props.selectedNode?.isLocation && fieldValues.isLocation) {
      setShouldShowIsLocationConfirmationPopup(true);
    } else {
      await handleSubmitForm();
    }
  }

  async function handleSubmitForm(): Promise<void> {
    let result: ApiResponse<OrganisationUnit> | undefined;

    switch (props.viewingMode) {
      case "creation":
        result = await submitCreation();
        break;
      case "editing":
        result = await submitEdit();
        break;
    }

    if (result) {
      if (isErrorResponse(result)) {
        dispatch(
          setErrorMessage({
            error: result.error,
          }),
        );
      } else if (result.data) {
        dispatch(confirmationActionExecuted());
        props.successAction();
      }
    }
    setIsSubmitting(false);
  }

  async function submitCreation(): Promise<ApiResponse<OrganisationUnit>> {
    const createOrganisationUnitCommand = {
      name: getValues("name").trim(),
      isLocation: getValues("isLocation"),
      isResidentialLocation: getValues("residentialLocation"),
      isSubscriptionChannel: getValues("isSubscriptionChannel"),
      isHidden: getValues("isHidden"),
      parentId: props.parentNode?.id,
      deviceIds: getValues("devices").map(
        (device: OrganisationUnitDevice) => device.id,
      ),
      scenarioIds: getValues("scenarios").map(
        (scenario: Scenario) => scenario.id,
      ),
      intentionOutOfBedEnabled: getValues("intentionOutOfBedEnabled"),
    } as CreateOrganisationUnitCommand;

    return (await createOrganisationUnit(
      createOrganisationUnitCommand,
    )) as ApiResponse<OrganisationUnit>;
  }

  async function submitEdit(): Promise<ApiResponse<OrganisationUnit>> {
    const editOrganisationUnitCommand = {
      id: props.selectedNode?.id,
      name: getValues("name").trim(),
      isLocation: getValues("isLocation"),
      isResidentialLocation: getValues("residentialLocation"),
      isSubscriptionChannel: getValues("isSubscriptionChannel"),
      isHidden: getValues("isHidden"),
      diversionTimeout: diversionTimeoutEnabled ? diversionTimeout : null,
      parentId: props.parentNode?.id,
      depth: props.selectedNode?.depth,
      deviceIds: getValues("devices").map(
        (device: OrganisationUnitDevice) => device.id,
      ),
      scenarioIds: getValues("scenarios").map(
        (scenario: Scenario) => scenario.id,
      ),
      intentionOutOfBedEnabled: getValues("intentionOutOfBedEnabled"),
    } as EditOrganisationUnitCommand;

    return (await updateOrganisationUnit(
      editOrganisationUnitCommand,
    )) as ApiResponse<OrganisationUnit>;
  }

  function handleCancel(): void {
    if (
      props.viewingMode === "editing" &&
      organisationUnitDetailsHook.selectedOrganisationUnit
    ) {
      reset({
        name: organisationUnitDetailsHook.selectedOrganisationUnit.name,
        isLocation:
          organisationUnitDetailsHook.selectedOrganisationUnit.isLocation,
        residentialLocation:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .isResidentialLocation,
        isSubscriptionChannel:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .isSubscriptionChannel,
        isHidden: organisationUnitDetailsHook.selectedOrganisationUnit.isHidden,
        devices: organisationUnitDetailsHook.selectedOrganisationUnit.devices,
        scenarios:
          organisationUnitDetailsHook.selectedOrganisationUnit.scenarios,
        diversionTimeout:
          organisationUnitDetailsHook.selectedOrganisationUnit.diversionTimeout,
        intentionOutOfBedEnabled:
          organisationUnitDetailsHook.selectedOrganisationUnit
            .intentionOutOfBedEnabled,
      });

      setDiversionTimeoutEnabled(
        organisationUnitDetailsHook.selectedOrganisationUnit
          .diversionTimeout !== null,
      );
    } else {
      reset(defaultFormValues);
    }
    props.cancelAction();
  }

  function handleOnRemoveButtonClicked(): void {
    let errorDeleteMessages = [];

    if (
      props.selectedNode?.childOrganisationUnits &&
      props.selectedNode.childOrganisationUnits.length > 0
    ) {
      errorDeleteMessages.push(t("deleteErrors.isParent"));
    }

    if (
      organisationUnitDetailsHook.selectedOrganisationUnit?.devices &&
      organisationUnitDetailsHook.selectedOrganisationUnit.devices.length > 0
    ) {
      errorDeleteMessages.push(t("deleteErrors.hasLinkedDevices"));
    }

    if (
      organisationUnitDetailsHook.selectedOrganisationUnit?.gateways &&
      organisationUnitDetailsHook.selectedOrganisationUnit.gateways.length > 0
    ) {
      errorDeleteMessages.push(t("deleteErrors.hasLinkedGateways"));
    }

    if (
      organisationUnitDetailsHook.selectedOrganisationUnit?.scenarios &&
      organisationUnitDetailsHook.selectedOrganisationUnit.scenarios.length > 0
    ) {
      errorDeleteMessages.push(t("deleteErrors.hasLinkedScenarios"));
    }

    if (
      organisationUnitDetailsHook.selectedOrganisationUnit?.roles &&
      organisationUnitDetailsHook.selectedOrganisationUnit.roles.length > 0
    ) {
      errorDeleteMessages.push(t("deleteErrors.hasLinkedRole"));
    }

    if (errorDeleteMessages.length > 0) {
      setErrorDeletePopupContent(errorDeleteMessages.join("\r\n \r\n"));
      setShouldShowErrorDeletePopup(true);
    } else {
      setShouldShowDeleteConfirmationPopup(true);
    }
  }

  function handleOnCancelButtonClicked(): void {
    if (organisationUnitDetailsHook.selectedOrganisationUnit) {
      setValue(
        "devices",
        organisationUnitDetailsHook.selectedOrganisationUnit.devices,
      );
      setValue(
        "scenarios",
        organisationUnitDetailsHook.selectedOrganisationUnit.scenarios,
      );
    }
    dispatch(setShouldShowConfirmation({ shouldShowConfirmation: false }));
    handleCancel();
  }

  async function handleDeleteOrganisationUnit(): Promise<void> {
    const result = (await deleteOrganisationUnit(
      props.selectedNode!.id,
    )) as ApiResponse<void>;

    if (isErrorResponse(result)) {
      dispatch(
        setErrorMessage({
          error: result.error,
        }),
      );
    } else {
      props.onViewingModeChanged("none");
    }

    setShouldShowDeleteConfirmationPopup(false);
  }

  function handleOnViewingModeChanged(viewingMode: ViewingMode): void {
    props.onViewingModeChanged(viewingMode);
  }

  function handleOnDeviceConnectionButtonClicked(): void {
    setLinkableDevicesModalIsOpen(true);
  }

  function handleOnScenarioConnectionButtonClicked(): void {
    setLinkableScenariosModalIsOpen(true);
  }

  function handleOnDevicesLinkButtonClicked(
    selectedDevices: OrganisationUnitDevice[],
  ): void {
    setUnlinkedDevices((current) =>
      current.filter((unlinkedDevice) =>
        organisationUnitDetailsHook.isSelectedDevice(
          unlinkedDevice,
          selectedDevices,
        ),
      ),
    );

    setValue("devices", [...getValues("devices")].concat(selectedDevices), {
      shouldDirty: true,
    });

    setLinkableDevicesModalIsOpen(false);
  }

  function handleOnScenariosLinkButtonClicked(
    selectedScenarios: Scenario[],
  ): void {
    setUnlinkedScenarios((current) =>
      current.filter((unlinkedScenario) =>
        organisationUnitDetailsHook.isSelectedScenario(
          unlinkedScenario,
          selectedScenarios,
        ),
      ),
    );

    setValue(
      "scenarios",
      [...getValues("scenarios")].concat(selectedScenarios),
      {
        shouldDirty: true,
      },
    );

    setLinkableScenariosModalIsOpen(false);
  }

  function handleOnUnlinkDeviceButtonClicked(
    selectedDevice: OrganisationUnitDevice,
  ): void {
    setUnlinkedDevices([...unlinkedDevices, selectedDevice]);
    setValue(
      "devices",
      [...getValues("devices")].filter(
        (device) => device.id !== selectedDevice.id,
      ),
      { shouldDirty: true },
    );
  }

  function handleOnUnlinkScenarioButtonClicked(
    selectedScenario: Scenario,
  ): void {
    setUnlinkedScenarios([...unlinkedScenarios, selectedScenario]);
    setValue(
      "scenarios",
      [...getValues("scenarios")].filter(
        (scenario) => scenario.id !== selectedScenario.id,
      ),
      { shouldDirty: true },
    );
  }

  function validateName(): boolean | string {
    return NameValidator.isValid(
      getValues("name"),
      props.viewingMode,
      props.parentNode,
      props.siblings,
    )
      ? true
      : t("uniqueNameHelperText", {
          name: getValues("name"),
          parentName: props.parentNode?.name,
        });
  }

  function validateIsSubscriptionChannel(): boolean | string {
    if (getValues("isSubscriptionChannel") && !getValues("isLocation")) {
      let checkChildrenResult =
        SubscriptionChannelValidator.hasLocationInChildren(
          props.selectedNode?.childOrganisationUnits,
        );
      if (checkChildrenResult) {
        return t("isSubscriptionChannelHelperText");
      }

      let checkParentLocationResult =
        SubscriptionChannelValidator.hasLocationInParents(
          props.parentNode?.id,
          props.upstreamParentBranch,
        );
      if (!checkParentLocationResult) {
        return t("noLocationInUpstreamParentBranchSubscriptionHelperText");
      }
    }
    return true;
  }

  function validateResidentialLocation(): boolean | string {
    if (getValues("residentialLocation")) {
      let checkSingleResidenceResult =
        ResidentialLocationValidator.checkSingleResidence(
          props.viewingMode,
          props.parentNode,
          props.selectedNode,
          props.upstreamParentBranch,
        );
      if (!checkSingleResidenceResult) {
        return t("singleResidentialLocationHelperText");
      }

      let checkHasLocationInParents =
        ResidentialLocationValidator.hasLocationInParents(
          props.parentNode?.id,
          props.upstreamParentBranch,
        );
      if (!checkHasLocationInParents) {
        return t("noLocationInUpstreamParentBranchResidentialHelperText");
      }
    }

    return true;
  }

  const registerOptions = {
    name: {
      required: t("requiredHelperText"),
      validate: validateName,
    },
    isSubscriptionChannel: {
      validate: validateIsSubscriptionChannel,
      onChange: () => trigger("isSubscriptionChannel"),
    },
    residentialLocation: {
      validate: validateResidentialLocation,
      onChange: () => trigger("residentialLocation"),
    },
  };

  return (
    <div className="organisation-details">
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submitForm)}>
          <Header
            headerText={
              props.viewingMode !== "creation"
                ? props.selectedNode?.name
                : undefined
            }
            viewingMode={props.viewingMode}
            selectedNodeIsroot={props.selectedNode?.depth === 1}
            hasErrors={
              !!errors.name ||
              !!errors.isSubscriptionChannel ||
              !!errors.residentialLocation
            }
            isScrolling={organisationUnitDetailsHook.isScrolling}
            isSubmitting={isSubmitting}
            onCancelButtonClicked={handleOnCancelButtonClicked}
            onViewingModeChanged={handleOnViewingModeChanged}
            onRemoveButtonClicked={handleOnRemoveButtonClicked}
          />

          <div
            className={`body ${
              props.viewingMode === "viewing" && "is-viewing"
            }`}
            onScroll={organisationUnitDetailsHook.handleScroll}
          >
            {props.viewingMode !== "viewing" && (
              <Controller
                name="name"
                control={control}
                rules={registerOptions.name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputProps={{ "data-testid": "nameInput" }}
                    id="nameInput"
                    label={t("nameFormLabel")}
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name?.message?.toString()}
                  />
                )}
              />
            )}

            {(props.selectedNode?.depth !== 1 ||
              props.viewingMode === "creation") && (
              <div className="organisation-bread-crumb-container">
                <OrganisationBreadCrumb
                  rootNodeId={props.parentNode?.id}
                  upstreamParentBranch={props.upstreamParentBranch}
                  showIcon
                />
              </div>
            )}

            {props.selectedNode?.isDiversionChannel && (
              <>
                <div className="diversion-channel-description-container">
                  <NotificationImportantOutlinedIcon />
                  <Typography
                    className="diversion-channel-description"
                    variant="subtitle1"
                  >
                    {t("diversionChannelDescription")}
                  </Typography>
                </div>
                <div className="organisation-settings-container">
                  <SettingsRow
                    text={t("diversionChannelToggleDescription")}
                    formField={
                      <Switch
                        type="checkbox"
                        value={true}
                        checked={true}
                        disabled={true}
                      />
                    }
                  />
                </div>
                <div className="organisation-settings-container">
                  <SettingsRow
                    text={t("diversionChannelOpenedAlarmToggleDescription")}
                    formField={
                      <Switch
                        type="checkbox"
                        value={diversionTimeoutEnabled}
                        checked={diversionTimeoutEnabled}
                        // prettier-ignore
                        // @ts-ignore
                        inputProps={{ "data-testid": "isDiversionTimeoutEnabled" }}
                        disabled={props.viewingMode === "viewing"}
                        onChange={() => {
                          toggleDiversionTimeout();
                        }}
                      />
                    }
                  />
                </div>
                {diversionTimeoutEnabled && (
                  <div className="organisation-settings-container">
                    <Controller
                      name="diversionTimeout"
                      control={control}
                      render={({ field }) => (
                        <FormControl className="diversion-timeout-top-margin">
                          <InputLabel>
                            {t("diversionChannelOpenedAlarmToggleDescription")}
                          </InputLabel>
                          <Select
                            {...field}
                            inputProps={{
                              "data-testid": "diversionTimeoutInput",
                            }}
                            id="diversionTimeoutInput"
                            variant="outlined"
                            label={t(
                              "diversionChannelOpenedAlarmToggleDescription",
                            )}
                            value={organisationUnitDetailsHook.getDiversionTimeoutSelectValueOrDefault(
                              field.value,
                            )}
                            defaultValue={900}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              setDiversionTimeout(e.target.value as number);
                            }}
                            disabled={props.viewingMode === "viewing"}
                          >
                            {diversionTimeoutOptions.map(
                              (menuItem) => menuItem,
                            )}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                )}
              </>
            )}

            <Divider startText={t("settingsDividerText")} />

            {organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit ? (
              <div className="organisation-settings-loader">
                <LoadingIndicator />
              </div>
            ) : (
              <div className="organisation-settings-container">
                {!organisationUnitDetailsHook.organisationUnitTreeBranchContainsLocation(
                  props.selectedNode,
                  props.parentNode,
                  props.upstreamParentBranch,
                ) && (
                  <Controller
                    name="isLocation"
                    control={control}
                    render={({ field }) => (
                      <SettingsRow
                        icon={<OfficeBuildingMarker />}
                        text={t("isLocationSwitchText")}
                        formField={
                          <Switch
                            {...field}
                            type="checkbox"
                            value={field.value}
                            checked={field.value}
                            // prettier-ignore
                            // @ts-ignore
                            inputProps={{ "data-testid": "isLocationSwitch" }}
                            disabled={
                              props.viewingMode === "viewing" ||
                              (props.selectedNode?.childOrganisationUnits
                                .length !== 0 &&
                                props.selectedNode?.isLocation)
                            }
                          />
                        }
                      />
                    )}
                  />
                )}
                <Controller
                  name="isSubscriptionChannel"
                  control={control}
                  rules={registerOptions.isSubscriptionChannel}
                  render={({ field }) => (
                    <SettingsRow
                      icon={<SubscriptionIcon />}
                      text={t("isSubscriptionChannelSwitchText")}
                      hasError={!!errors.isSubscriptionChannel}
                      helperText={errors.isSubscriptionChannel?.message?.toString()}
                      formField={
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          // prettier-ignore
                          // @ts-ignore
                          inputProps={{ "data-testid": "isSubscriptionChannelSwitch" }}
                          disabled={
                            props.viewingMode === "viewing" ||
                            getValues("isLocation") === true
                          }
                        />
                      }
                    />
                  )}
                />
                <Controller
                  name="residentialLocation"
                  control={control}
                  rules={registerOptions.residentialLocation}
                  render={({ field }) => (
                    <SettingsRow
                      icon={<BedRounded />}
                      text={t("residentialLocationSwitchText")}
                      hasError={!!errors.residentialLocation}
                      helperText={errors.residentialLocation?.message?.toString()}
                      formField={
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          // prettier-ignore
                          // @ts-ignore
                          inputProps={{ "data-testid": "residentialSwitch" }}
                          disabled={
                            props.viewingMode === "viewing" ||
                            getValues("isLocation") === true
                          }
                        />
                      }
                    />
                  )}
                />
                <Controller
                  name="isHidden"
                  control={control}
                  render={({ field }) => (
                    <SettingsRow
                      icon={<VisibilityOffOutlined />}
                      text={t("isHiddenSwitchText")}
                      formField={
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          // prettier-ignore
                          // @ts-ignore
                          inputProps={{ "data-testid": "isHiddenSwitch" }}
                          disabled={
                            props.viewingMode === "viewing" ||
                            getValues("isLocation") === true
                          }
                        />
                      }
                    />
                  )}
                />
              </div>
            )}

            <AutorisationWrapper
              atLeastOnePermissionOf={[Permission.ReadDevice]}
            >
              <>
                <Divider
                  startText={t("devicesDividerText")}
                  endElement={
                    props.viewingMode === "creation" ||
                    props.viewingMode === "editing" ? (
                      <AutorisationWrapper
                        atLeastOnePermissionOf={[Permission.LinkDevice]}
                      >
                        <Button
                          startIcon={<LinkOutlined />}
                          variant="outlined"
                          onClick={handleOnDeviceConnectionButtonClicked}
                          data-testid="linkDeviceButton"
                        >
                          {t("linkDeviceButtonText")}
                        </Button>
                      </AutorisationWrapper>
                    ) : (
                      <></>
                    )
                  }
                />

                {organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit && (
                  <LoadingIndicator />
                )}
                {!organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit &&
                  (props.selectedNode || props.parentNode) && (
                    <Controller
                      name="devices"
                      control={control}
                      render={({ field }) => (
                        <>
                          <DevicesProvider>
                            <OrganisationDetailsDevicesTable
                              noDevicesText={t("devicesTable.noDevicesLinked")}
                              linkedDevices={field.value}
                              unlinkedDevices={unlinkedDevices}
                              viewingMode={props.viewingMode}
                              onUnlinkButtonClicked={
                                handleOnUnlinkDeviceButtonClicked
                              }
                              onRelinkButtonClicked={(
                                selectedDevice: OrganisationUnitDevice,
                              ) =>
                                handleOnDevicesLinkButtonClicked([
                                  selectedDevice,
                                ])
                              }
                            />
                          </DevicesProvider>
                          <OrganisationDetailsLinkableDevicesModal
                            isOpen={linkableDevicesModalIsOpen}
                            onClose={() => setLinkableDevicesModalIsOpen(false)}
                            onLinkButtonClicked={
                              handleOnDevicesLinkButtonClicked
                            }
                            organisationUnitId={
                              props.selectedNode?.id ?? props.parentNode!.id
                            }
                            devicesToHide={field.value}
                          />
                        </>
                      )}
                    />
                  )}
              </>
            </AutorisationWrapper>

            {!getValues("isLocation") && (
              <Divider
                startText={t("scenariosDividerText")}
                endElement={
                  props.viewingMode === "creation" ||
                  props.viewingMode === "editing" ? (
                    <AutorisationWrapper
                      atLeastOnePermissionOf={[Permission.LinkScenario]}
                    >
                      <Button
                        startIcon={<LinkOutlined />}
                        variant="outlined"
                        onClick={handleOnScenarioConnectionButtonClicked}
                        data-testid="linkScenarioButton"
                      >
                        {t("linkScenarioButtonText")}
                      </Button>
                    </AutorisationWrapper>
                  ) : (
                    <></>
                  )
                }
              />
            )}

            {organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit && (
              <LoadingIndicator />
            )}
            {!organisationUnitDetailsHook.isLoadingSelectedOrganisationUnit &&
              !getValues("isLocation") &&
              (props.selectedNode || props.parentNode) && (
                <Controller
                  name="scenarios"
                  control={control}
                  render={({ field }) => (
                    <>
                      <OrganisationDetailsScenariosList
                        linkedScenarios={field.value}
                        unlinkedScenarios={unlinkedScenarios}
                        viewingMode={props.viewingMode}
                        onUnlinkButtonClicked={
                          handleOnUnlinkScenarioButtonClicked
                        }
                        onRelinkButtonClicked={(selectedScenario: Scenario) =>
                          handleOnScenariosLinkButtonClicked([selectedScenario])
                        }
                      />
                      <OrganisationDetailsLinkableScenariosModal
                        isOpen={linkableScenariosModalIsOpen}
                        onClose={() => setLinkableScenariosModalIsOpen(false)}
                        onLinkButtonClicked={handleOnScenariosLinkButtonClicked}
                        scenariosToHide={field.value}
                      />
                    </>
                  )}
                />
              )}

            <PopUp
              isOpen={shouldShowDeleteConfirmationPopup}
              title={t("deleteConfirmationPopup.title", {
                name: props.selectedNode?.name,
              })}
              body={t("deleteConfirmationPopup.body")}
              primaryButtonText={t("deleteConfirmationPopup.primaryButtonText")}
              secondaryButtonText={t(
                "deleteConfirmationPopup.secondaryButtonText",
              )}
              handleOnClose={() => setShouldShowDeleteConfirmationPopup(false)}
              secondaryButtonAction={() =>
                setShouldShowDeleteConfirmationPopup(false)
              }
              primaryButtonAction={handleDeleteOrganisationUnit}
            />

            <PopUp
              isOpen={shouldShowErrorDeletePopup}
              title={t("deleteErrorPopup.title", {
                name: props.selectedNode?.name,
              })}
              body={errorDeletePopupContent}
              primaryButtonText={t("deleteErrorPopup.primaryButtonText")}
              handleOnClose={() => setShouldShowErrorDeletePopup(false)}
              primaryButtonAction={() => setShouldShowErrorDeletePopup(false)}
            />

            <PopUp
              isOpen={shouldShowIsLocationConfirmationPopup}
              title={t("isLocationConfirmationPopup.title")}
              body={t("isLocationConfirmationPopup.body")}
              primaryButtonText={t(
                "isLocationConfirmationPopup.primaryButtonText",
              )}
              secondaryButtonText={t(
                "isLocationConfirmationPopup.secondaryButtonText",
              )}
              handleOnClose={() => {
                setShouldShowIsLocationConfirmationPopup(false);
                setIsSubmitting(false);
              }}
              secondaryButtonAction={() => {
                setShouldShowIsLocationConfirmationPopup(false);
                setIsSubmitting(false);
              }}
              primaryButtonAction={() => {
                handleSubmitForm();
                setShouldShowIsLocationConfirmationPopup(false);
              }}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default OrganisationDetails;
