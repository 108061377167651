import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadScenariosResponse from "features/scenario/domain/models/read-scenarios-response";

const baseUrl = "/api/v1/scenario";

export const scenariosApi = createApi({
  reducerPath: "scenarios",
  baseQuery,
  tagTypes: ["scenarios"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readScenarios: build.query<ReadScenariosResponse, void>({
      query: () => ({
        url: `${baseUrl}/ReadScenarios`,
        method: "GET",
      }),
    }),
  }),
});

export const { useReadScenariosQuery } = scenariosApi;
