// Redefine the scss variables that are not available in MUI 2 yet, to make them available in typescript
namespace Constants {
  export const surface = "#FBFBFB";
  export const surface1 = `linear-gradient(0deg, rgba(181, 181, 181, 0.05), rgba(181, 181, 181, 0.05)), ${surface}`;
  export const surface2 = `linear-gradient(0deg, rgba(181, 181, 181, 0.08), rgba(181, 181, 181, 0.08)), ${surface}`;
  export const surface3 = `linear-gradient(0deg, rgba(181, 181, 181, 0.11), rgba(181, 181, 181, 0.11)), ${surface}`;
  export const surface4 = `linear-gradient(0deg, rgba(181, 181, 181, 0.12), rgba(181, 181, 181, 0.12)), ${surface}`;
  export const surface5 = `linear-gradient(0deg, rgba(181, 181, 181, 0.14), rgba(181, 181, 181, 0.14)), ${surface}`;

  export const elevationLight1 =
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)";
  export const elevationLight2 =
    "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)";

  export namespace Colors {
    export const primary = "#7342BD";

    export const onPrimary = "#FFFFFF";
    export const primaryContainer = "#ECDCFF";
    export const onPrimaryContainer = "#270057";

    export const onSecondary = "#FFFFFF";
    export const secondaryContainer = "#DCE1FF";
    export const onSecondaryContainer = "#001551";

    export const onTertiary = "#FFFFFF";
    export const tertiaryContainer = "#FFD9E0";
    export const onTertiaryContainer = "#32101A";

    export const error = "#BA1A1A";
    export const onError = "#FFFFFF";
    export const errorContainer = "#FFDAD6";
    export const onErrorContainer = "#410002";

    export const success = "#3BDC68";

    export const onBackground = "#1D1B1E";
    export const onSurface = "#1D1B1E";

    export const surfaceVariant = "#E8E0EB";
    export const onSurfaceVariant = "#49454E";

    export const inverseSurface = "#323033";
    export const onInverseSurface = "#F5EFF4";

    export const outline = "#7B757F";

    //Custom colors
    export const brightPurple = "#6D3EFF";
    export const brightOrange = "#FF5E00";
    export const inactive = "#A9A6A9";
  }
}

export default Constants;
