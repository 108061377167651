import { ReactElement, useEffect, useState } from "react";
import {
  GridCellParams,
  GridColDef,
  GridRowModes,
  GridRowModesModel,
  DataGrid as MuiDataGrid,
  gridClasses,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { IconButton, Stack, Typography, alpha } from "@mui/material";
import Constants from "style/constants";
import { MoreHorizRounded } from "@mui/icons-material";
import OverflowMenu, {
  OverflowMenuItem,
} from "components/overflow-menu/overflow-menu-component";

export interface IDataGridOverflowMenuItem<DataType> {
  label: string;
  action: (item: DataType) => void;
  disabled?: (item: DataType) => boolean;
  isVisible?: (item: DataType) => boolean;
}
export type DataGridOverflowMenuItem<DataType> =
  | IDataGridOverflowMenuItem<DataType>
  | "divider";

interface IProps<DataType extends object> {
  data: DataType[];
  columns: GridColDef[];
  rowModesModel: GridRowModesModel;
  overflowMenuOptions?: Array<DataGridOverflowMenuItem<DataType>>;
  editButtons?: Array<ReactElement>;
}

export default function DataGrid<DataType extends object>(
  props: Readonly<IProps<DataType>>,
): ReactElement {
  const { t } = useTranslation("dataGrid");

  const noItems = () => {
    return (
      <Stack alignItems="center" padding={"12px 0px"} justifyContent="center">
        <Typography variant="subtitle1">{t("noItemsText")}</Typography>
      </Stack>
    );
  };

  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [clickedRow, setClickedRow] = useState<DataType | null>(null);
  const [columns, setColumns] = useState<GridColDef[]>(props.columns);

  useEffect(() => {
    let columnsProp = props.columns;
    if (props.overflowMenuOptions || props.editButtons) {
      columnsProp.push({
        field: "actions",
        type: "actions",
        flex: 1,
        align: "right",
        renderCell(params) {
          const isInEditMode =
            props.rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
          if (isInEditMode && props.editButtons) {
            return (
              <>
                {props.editButtons.map((element: ReactElement) => {
                  return element;
                })}
              </>
            );
          }
          if (props.overflowMenuOptions) {
            return (
              <IconButton
                className={`more-icon-button`}
                onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
                  onMoreClick(event, params.row)
                }
              >
                <MoreHorizRounded className="more-icon" />
              </IconButton>
            );
          }
        },
      });
      setColumns(columnsProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.overflowMenuOptions]);

  function onMoreClick(
    event: React.MouseEvent<HTMLElement>,
    row: DataType,
  ): void {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsOverflowMenuOpen(true);
    setClickedRow(row);
  }

  function closeOverflowMenu(): void {
    setIsOverflowMenuOpen(false);
    setClickedRow(null);
  }

  function mapMenuItems(
    menuItems: Array<DataGridOverflowMenuItem<DataType>>,
    rowData: DataType,
  ): Array<OverflowMenuItem> {
    return menuItems
      .filter((item) => {
        if (typeof item === "string") {
          return true;
        }
        return item.isVisible ? item.isVisible(rowData) : true;
      })
      .map((item) => mapMenuItem(item, rowData));
  }

  function mapMenuItem(
    item: DataGridOverflowMenuItem<DataType>,
    rowData: DataType,
  ): OverflowMenuItem {
    if (item === "divider") {
      return "divider";
    }

    return {
      label: item.label,
      action: () => item.action(rowData),
      disabled: item.disabled?.(rowData) ?? false,
    };
  }

  const styling = () => {
    return {
      boxShadow: 0,
      border: 0,
      [`& .${gridClasses.cell}:focus-within, & .${gridClasses.cell}.${gridClasses.cell}--editing:focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
        {
          outline: "none",
        },
      [`& .${gridClasses.columnHeader}`]: {
        padding: 2,
      },
      [`& .${gridClasses.cell}.datagrid-cell`]: {
        padding: 2,
      },
      [`& .${gridClasses.row}:hover`]: {
        backgroundColor: alpha(Constants.Colors.secondaryContainer, 0.16),
      },
      [`& .${gridClasses.columnHeaderTitle}`]: {
        fontSize: "14px",
      },
      [`& .${gridClasses.columnSeparator}`]: {
        display: "none",
      },
    };
  };

  return (
    <>
      <MuiDataGrid
        rows={props.data}
        columns={columns}
        rowModesModel={props.rowModesModel}
        editMode="row"
        sx={styling}
        sortingOrder={["asc", "desc"]}
        getCellClassName={(params: GridCellParams<any, any, number>) => {
          if (params.field !== "actions") {
            return "datagrid-cell";
          }
          return "";
        }}
        getRowHeight={() => "auto"}
        disableColumnMenu
        disableColumnResize
        disableColumnSorting
        disableRowSelectionOnClick
        hideFooter={true}
        initialState={{
          sorting: {
            sortModel: [{ field: props.columns[0].field, sort: "asc" }],
          },
        }}
        slots={{
          noRowsOverlay: noItems,
          noResultsOverlay: noItems,
        }}
      />
      {props.overflowMenuOptions && clickedRow && (
        <OverflowMenu
          isOpen={isOverflowMenuOpen}
          onClose={closeOverflowMenu}
          anchorEl={anchorEl}
          menuItems={mapMenuItems(props.overflowMenuOptions, clickedRow)}
        />
      )}
    </>
  );
}
