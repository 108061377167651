import { ReactElement, ReactNode, createContext, useContext } from "react";
import useTenantsProvider, {
  ITenantsProviderHook,
} from "features/tenants/hooks/tenants-hook";

const TenantsContext = createContext<ITenantsProviderHook>(
  {} as ITenantsProviderHook,
);

export type TenantsProviderProps = {
  children: ReactNode;
};

const TenantsProvider = ({ children }: TenantsProviderProps): ReactElement => (
  <TenantsContext.Provider value={useTenantsProvider()}>
    {children}
  </TenantsContext.Provider>
);

const useTenantsContextProvider = (): ITenantsProviderHook =>
  useContext<ITenantsProviderHook>(TenantsContext);

export { TenantsProvider, useTenantsContextProvider };
