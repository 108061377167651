import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import "./external-link.component.scss";
import { OpenInNew } from "@mui/icons-material";

interface IProps {
  link: string;
  linkTranslationKey: string;
  linkCompanyTranslationKey: string;
}

function ExternalLink(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("appInformation");

  return (
    <Link
      className="external-link-container"
      target="_blank"
      href={props.link}
      rel="noopener"
      underline="none"
    >
      <OpenInNew accentHeight={18} />
      <div className="link-text">
        <Typography variant="subtitle2">
          {t(props.linkTranslationKey)}
        </Typography>
        <Typography variant="subtitle1">
          {t(props.linkCompanyTranslationKey)}
        </Typography>
      </div>
    </Link>
  );
}

export default ExternalLink;
