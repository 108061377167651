import { ReactElement, useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { TokenType, BackgroundType } from "powerbi-models";
import { useReadEmbeddedReportQuery } from "features/dashboard/domain/reducers/dashboard.reducer";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PowerBIReport from "features/dashboard/domain/models/powerBIReport";
import { useAppDispatch } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import SonevoCloudTheme from "./sonevo-cloud-theme.json";

import "./embedded-report.scss";

interface IProps {
  reportName: PowerBIReport;
}

export default function EmbeddedReport(props: Readonly<IProps>): ReactElement {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>();
  const componentRef = useRef<HTMLDivElement>(null);
  const relativeZoomLevel = 0.00074; // this is based on the embedded view size, at the tipping point of having no vertical scroll bars

  const {
    isSuccess,
    data: embedInfo,
    error,
  } = useReadEmbeddedReportQuery(props.reportName);

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        setZoomLevel(
          Math.max(1, componentRef.current.offsetWidth * relativeZoomLevel),
        );
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorMessage({
          error: error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      {!isSuccess || !embedInfo || (!loaded && <LoadingIndicator />)}
      {isSuccess && embedInfo && (
        <div ref={componentRef} className="embedded-report-container">
          <PowerBIEmbed
            cssClassName={"embedded-report"}
            eventHandlers={new Map([["loaded", () => setLoaded(true)]])}
            embedConfig={{
              theme: { themeJson: SonevoCloudTheme },
              type: "report",
              id: embedInfo.reportId,
              embedUrl: embedInfo.embedUrl,
              accessToken: embedInfo.embedToken,
              tokenType: TokenType.Embed,
              settings: {
                panes: {
                  pageNavigation: {
                    visible: false,
                  },
                  filters: {
                    expanded: false,
                  },
                },
                background: BackgroundType.Transparent,
                zoomLevel: zoomLevel,
              },
            }}
          />
        </div>
      )}
    </>
  );
}
