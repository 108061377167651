import PopUp from "components/pop-up/pop-up.component";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeConfirmationPopup,
  confirmationActionExecuted,
  ConfirmationPopupState,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";

function ConfirmationPopup(): ReactElement {
  const { t } = useTranslation("confirmationPopup");
  const dispatch = useDispatch();
  const confirmationPopupState = useSelector(
    (state: any) => state.confirmationPopup,
  );

  const [localShowConfirmation, setLocalShowConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    updateLocalShowConfirmation(confirmationPopupState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationPopupState]);

  function updateLocalShowConfirmation(
    confirmationPopupState: ConfirmationPopupState,
  ): void {
    const showConfirmation = confirmationPopupState.showConfirmation;
    const shouldShowConfirmation =
      confirmationPopupState.shouldShowConfirmation;

    if (shouldShowConfirmation || !showConfirmation) {
      setLocalShowConfirmation(showConfirmation);
    }

    if (
      (!shouldShowConfirmation || !showConfirmation) &&
      confirmationPopupState.confirmActionNextAction
    ) {
      confirmationPopupState.confirmActionNextAction();
      dispatch(closeConfirmationPopup());
    }
  }

  function primaryAction(): void {
    if (confirmationPopupState.confirmActionCancelChanges) {
      confirmationPopupState.confirmActionCancelChanges();
    }

    if (confirmationPopupState.confirmActionNextAction) {
      confirmationPopupState.confirmActionNextAction();
    }

    dispatch(confirmationActionExecuted());
  }

  return (
    <PopUp
      isOpen={localShowConfirmation}
      title={t("title")}
      body={t("body")}
      primaryButtonText={t("primaryButtonText")}
      secondaryButtonText={t("secondaryButtonText")}
      handleOnClose={() => dispatch(closeConfirmationPopup())}
      secondaryButtonAction={() => dispatch(closeConfirmationPopup())}
      primaryButtonAction={primaryAction}
    />
  );
}

export default ConfirmationPopup;
