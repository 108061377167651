import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { ReactElement, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import LinkableDevice from "features/device/linkable-device/domain/models/linkable-device";
import "./resident-details-linkable-devices-modal.component.scss";
import LinkableTransmittersTable from "features/device/linkable-transmitters/views/linkable-transmitters-table.component";
import { ResidentDevice } from "features/residents/residents-settings/domain/models/resident-device";

interface IProps {
  organisationUnitId: string;
  isOpen: boolean;
  onClose: () => void;
  onLinkButtonClicked: (selectedDevices: ResidentDevice[]) => void;
  devicesToHide: ResidentDevice[];
}

function ResidentDetailsLinkableDevicesModal(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [selectedDevices, setSelectedDevices] = useState<ResidentDevice[]>([]);

  function handleOnClose() {
    props.onClose();
    setSelectedDevices([]);
  }

  function handleOnLinkButtonClicked() {
    props.onLinkButtonClicked(selectedDevices);
    setSelectedDevices([]);
  }

  return (
    <Dialog
      className="resident-details-linkable-devices-modal"
      open={props.isOpen}
      onClose={handleOnClose}
      PaperProps={{ classes: { root: "linkable-devices-modal-paper" } }}
    >
      <div className="linkable-devices-modal-container">
        <div className="linkable-devices-modal-header-container">
          <Typography className="header-title" variant="h3">
            {t("linkableDevicesModal.title")}
          </Typography>
          <div className="linkable-devices-modal-header-actions-container">
            {selectedDevices.length < 1 && (
              <Button variant="contained" disabled={true}>
                {t("linkableDevicesModal.disabledLinkButtonText")}
              </Button>
            )}
            {selectedDevices.length > 0 && (
              <Button
                className="link-button"
                variant="contained"
                onClick={handleOnLinkButtonClicked}
                startIcon={<CheckIcon className="check-icon" />}
                data-testid="linkSelectedDevicesButton"
              >
                {t("linkableDevicesModal.enabledLinkButtonText", {
                  amountOfSelectedDevices: selectedDevices.length,
                })}
              </Button>
            )}
            <IconButton
              aria-label="close"
              className="close-button"
              onClick={handleOnClose}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <LinkableTransmittersTable
          organisationUnitId={props.organisationUnitId}
          onSelectedDevicesChanged={(devices) => setSelectedDevices(devices)}
          devicesToHide={props.devicesToHide as LinkableDevice[]}
        />
      </div>
    </Dialog>
  );
}

export default ResidentDetailsLinkableDevicesModal;
