import { NestedKeyof, ResolveByNestedKey } from "utils/nested-keyof-utils";

export function descendingComparator<
  T extends object,
  K extends NestedKeyof<T>,
>(a: T, b: T, orderBy: K) {
  if (ResolveByNestedKey<T>(orderBy, b) < ResolveByNestedKey<T>(orderBy, a)) {
    return -1;
  }
  if (ResolveByNestedKey<T>(orderBy, b) > ResolveByNestedKey<T>(orderBy, a)) {
    return 1;
  }
  return 0;
}

export function descendingCaseInsensitiveStringComparator<
  T extends object,
  K extends NestedKeyof<T>,
>(a: T, b: T, orderBy: K) {
  let valueOfA = (
    (ResolveByNestedKey<T>(orderBy, a) as string) ?? ""
  ).toLowerCase();
  let valueOfB = (
    (ResolveByNestedKey<T>(orderBy, b) as string) ?? ""
  ).toLowerCase();

  if (valueOfB < valueOfA) {
    return -1;
  }
  if (valueOfB > valueOfA) {
    return 1;
  }
  return 0;
}
