import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import { ReactElement } from "react";

interface IProps {
  atLeastOnePermissionOf?: Permission[];
  showForTenantManagerOnly?: boolean;
  children: ReactElement | ReactElement[] | undefined;
}

function AutorisationWrapper(props: Readonly<IProps>): ReactElement {
  const {
    hasPermission,
    shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant,
  } = useAuth();

  if (
    shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant(
      props.showForTenantManagerOnly,
    )
  ) {
    return <></>;
  }

  return (
    <>
      {props.atLeastOnePermissionOf !== undefined
        ? props.atLeastOnePermissionOf.some((permission) =>
            hasPermission(permission),
          ) && props.children
        : props.children}
    </>
  );
}

export default AutorisationWrapper;
