import { alpha, createTheme, lighten } from "@mui/material";
import Constants from "./constants";
import globalTheme from "./global-theme";

const theme = createTheme(
  {
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
        styleOverrides: {
          root: {
            padding: "8px 16px",
            borderRadius: 32,
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
            textTransform: "none",
            height: "fit-content",
            width: "fit-content",
          },
          containedPrimary: {
            color: globalTheme.palette.primary.contrastText,
            background: globalTheme.palette.primary.main,
            "&:hover": {
              background: globalTheme.palette.primary.light,
            },
          },
          textPrimary: {
            color: globalTheme.palette.primary.main,
          },
          outlinedPrimary: {
            color: globalTheme.palette.primary.main,
            borderColor: Constants.Colors.outline,
            padding: "8px 24px 8px 16px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: globalTheme.palette.primary.main,
            },
            borderColor: Constants.Colors.outline,
            fontFamily: globalTheme.typography.subtitle1.fontFamily,
            fontSize: globalTheme.typography.subtitle1.fontSize,
            fontWeight: globalTheme.typography.subtitle1.fontWeight,
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: alpha(Constants.Colors.onSurface, 0.12),
            },
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          sx: {
            fontFamily: globalTheme.typography.subtitle1.fontFamily,
            fontSize: globalTheme.typography.subtitle1.fontSize,
            fontWeight: globalTheme.typography.subtitle1.fontWeight,
            color: Constants.Colors.onSurfaceVariant,
          },
        },
        styleOverrides: {
          outlined: {
            "&.Mui-focused": {
              color: globalTheme.palette.primary.main,
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderSpacing: "0 12px",
            "tr:nth-of-type(even)": {
              "td:first-of-type": {
                paddingLeft: 16,
              },
              "td:last-child": {
                paddingRight: 16,
              },
            },
            ".MuiTableCell-head:first-of-type": {
              paddingLeft: 16,
            },
            ".MuiTableCell-head:last-of-type": {
              paddingRight: 16,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: "none",
            maxHeight: 20,
            padding: 0,
          },
          stickyHeader: {
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
          },
          body: {
            fontFamily: globalTheme.typography.subtitle1.fontFamily,
            fontSize: globalTheme.typography.subtitle1.fontSize,
            fontWeight: globalTheme.typography.subtitle1.fontWeight,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            maxWidth: "100%",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          head: {
            "&:hover": {
              backgroundColor: "unset",
            },
          },
          root: {
            "&:hover": {
              backgroundColor: alpha(Constants.Colors.secondaryContainer, 0.16),
            },
            "&:hover .MuiTableCell-root:first-of-type": {
              borderTopLeftRadius: 56,
              borderBottomLeftRadius: 56,
            },
            "&:hover .MuiTableCell-root:last-child": {
              borderTopRightRadius: 56,
              borderBottomRightRadius: 56,
            },
            "&.Mui-selected": {
              backgroundColor: `${Constants.Colors.secondaryContainer} !important`,
            },
            "&.Mui-selected .MuiTableCell-root:first-of-type": {
              borderTopLeftRadius: 56,
              borderBottomLeftRadius: 56,
            },
            "&.Mui-selected .MuiTableCell-root:last-child": {
              borderTopRightRadius: 56,
              borderBottomRightRadius: 56,
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            overflow: "initial",
          },
          toolbar: {
            fontFamily: globalTheme.typography.subtitle1.fontFamily,
            fontSize: globalTheme.typography.subtitle1.fontSize,
            fontWeight: globalTheme.typography.subtitle1.fontWeight,
            color: Constants.Colors.onSurface,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "28px",
            padding: "32px",
            background: Constants.surface2,
            boxShadow: Constants.elevationLight2,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            background: "none",
            "&:before": {
              background: "none",
            },
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          expandIconWrapper: {
            color: Constants.Colors.onSurface,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: "18px",
            padding: "8px 16px",
            background: Constants.surface2,
            boxShadow: Constants.elevationLight2,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: "8px 12px",
            borderRadius: "18px",
            minWidth: "164px",
            margin: "9px 0px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
          },
          primary: {
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: Constants.Colors.onSecondaryContainer,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontFamily: globalTheme.typography.subtitle1.fontFamily,
            fontSize: globalTheme.typography.subtitle1.fontSize,
            fontWeight: globalTheme.typography.subtitle1.fontWeight,
            color: Constants.Colors.onSurfaceVariant,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            style: {
              marginTop: "2px",
            },
            PaperProps: {
              style: {
                borderRadius: "2px",
                background: "",
              },
            },
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            position: "absolute",
            width: "56px",
            height: "56px",
            borderRadius: "16px",
            backgroundColor: Constants.Colors.brightPurple,
            color: Constants.Colors.onPrimary,
            "&:hover": {
              backgroundColor: Constants.Colors.secondaryContainer,
              color: Constants.Colors.onSecondaryContainer,
            },
            "&.Mui-disabled": {
              background: lighten(Constants.Colors.onSurface, 0.8),
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            height: "40px",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
            color: Constants.Colors.onSecondaryContainer,
            textTransform: "none",
            width: "160px",
            border: "1px solid #7B757F",
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:disabled": {
              color: Constants.Colors.onSecondaryContainer,
              backgroundColor: Constants.Colors.secondaryContainer,
              border: "1px solid #7B757F",
            },
            "&:hover": {
              backgroundColor: alpha(globalTheme.palette.secondary.main, 0.16),
            },
            "&:last-of-type": {
              borderRadius: "0px 32px 32px 0px",
            },
            "&:first-of-type": {
              borderRadius: "32px 0px 0px 32px",
            },
            "&.Mui-selected:not(:first-of-type)": {
              borderLeft: "unset",
              marginLeft: "0px",
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            border: "1px solid #71787D",
            maxWidth: "300px",
          },
          label: {
            padding: "6px 8px 6px 16px",
            fontFamily: globalTheme.typography.body1.fontFamily,
            fontSize: globalTheme.typography.body1.fontSize,
            fontWeight: globalTheme.typography.body1.fontWeight,
            color: Constants.Colors.onSurface,
          },
          deleteIcon: {
            color: Constants.Colors.onSurface,
            "&:hover": {
              color: Constants.Colors.onSurface,
            },
          },
        },
      },
    },
  },
  globalTheme,
);

export default theme;
