import { Dispatch, SetStateAction, useState } from "react";

export interface ITemporaryUsersState {
  temporaryUserDetailsPopupIsOpen: boolean;
  setTemporaryUserDetailsPopupIsOpen: Dispatch<SetStateAction<boolean>>;

  revokeTemporaryUserConfirmationPopupIsOpen: boolean;
  setRevokeTemporaryUserConfirmationPopupIsOpen: Dispatch<
    SetStateAction<boolean>
  >;

  revokeTemporaryUserConfirmationAction: () => void;
  setRevokeTemporaryUserConfirmationAction: Dispatch<
    SetStateAction<() => void>
  >;

  selectedTemporaryUserId: string | undefined;
  setSelectedTemporaryUserId: Dispatch<SetStateAction<string | undefined>>;
}

export const useTemporaryUsersState = (): ITemporaryUsersState => {
  const [temporaryUserDetailsPopupIsOpen, setTemporaryUserDetailsPopupIsOpen] =
    useState<boolean>(false);

  const [
    revokeTemporaryUserConfirmationPopupIsOpen,
    setRevokeTemporaryUserConfirmationPopupIsOpen,
  ] = useState<boolean>(false);

  const [
    revokeTemporaryUserConfirmationAction,
    setRevokeTemporaryUserConfirmationAction,
  ] = useState(() => () => {});

  const [selectedTemporaryUserId, setSelectedTemporaryUserId] = useState<
    string | undefined
  >(undefined);

  return {
    temporaryUserDetailsPopupIsOpen,
    setTemporaryUserDetailsPopupIsOpen,

    revokeTemporaryUserConfirmationPopupIsOpen,
    setRevokeTemporaryUserConfirmationPopupIsOpen,

    revokeTemporaryUserConfirmationAction,
    setRevokeTemporaryUserConfirmationAction,

    selectedTemporaryUserId,
    setSelectedTemporaryUserId,
  };
};
