import { TimelineItem } from "./timeline-item";
import "./line.component.scss";
import { ReactElement } from "react";

interface IProps {
  item: TimelineItem;
  showOpenChip: boolean;
  isFirstItem: boolean;
  isLastItem: boolean;
}

function Line(props: Readonly<IProps>): ReactElement {
  return (
    <div className="line-with-circle">
      {!props.item.isHighlighted && !props.isFirstItem && (
        <div className="line fixed" />
      )}
      {props.item.isHighlighted && <div className="line" />}
      <div className="circle" />
      <div
        className={`line ${
          !props.showOpenChip && props.isLastItem ? "first-or-last-item" : ""
        }`}
      />
    </div>
  );
}

export default Line;
