import { ReadConnectorsResponse } from "features/connectors/domain/read-connectors-response";
import { useLazyReadConnectorsQuery } from "features/connectors/domain/reducers/connectors.reducer";
import useConnectorFilters, {
  ConnectorFiltersHook,
} from "features/connectors/hooks/connector-filters-hook";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export interface ConnectorsHook {
  connectorsResponse?: ReadConnectorsResponse;
  isLoading: boolean;
  isSuccess: boolean;

  connectorsFilterHook: ConnectorFiltersHook;
}

const useConnectors = (): ConnectorsHook => {
  const dispatch = useDispatch();
  const connectorsFilterHook = useConnectorFilters();

  const [
    triggerReadConnectors,
    { data: connectorsResponse, error, isError, isLoading, isSuccess },
  ] = useLazyReadConnectorsQuery();

  useEffect(() => {
    triggerReadConnectors(connectorsFilterHook.readConnectorsQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorsFilterHook.readConnectorsQuery]);

  useEffect(() => {
    if (isError && error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    connectorsResponse,
    isLoading,
    isSuccess,

    connectorsFilterHook,
  };
};

export default useConnectors;
