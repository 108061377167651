import { MenuItem, Typography } from "@mui/material";
import SelectField from "components/formFields/select-field.component";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import { DeviceType } from "features/device/models/device-type";
import { ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

function DeviceSettingsType(): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const { currentViewingMode, currentDevice } = useDeviceDetailsHook();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const registerOptions = {
    type: {
      required: t("info.requiredHelperText"),
    },
  };

  const deviceTypeOptions = [
    <MenuItem
      data-testid={`device-type-universal`}
      key={`device-type-universal`}
      value={DeviceType.Universal}
    >
      {t(`deviceTypes.${DeviceType.Universal}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-beacon`}
      key={`device-type-beacon`}
      value={DeviceType.BLEBeacon}
    >
      {t(`deviceTypes.${DeviceType.BLEBeacon}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-nfc`}
      key={`device-type-nfc`}
      value={DeviceType.NFC}
    >
      {t(`deviceTypes.${DeviceType.NFC}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-espa`}
      key={`device-type-espa`}
      value={DeviceType.ESPA}
    >
      {t(`deviceTypes.${DeviceType.ESPA}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-universal-intercom`}
      key={`device-type-universal-intercom`}
      value={DeviceType.UniversalIntercom}
    >
      {t(`deviceTypes.${DeviceType.UniversalIntercom}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-universal-camera`}
      key={`device-type-universal-camera`}
      value={DeviceType.UniversalCamera}
    >
      {t(`deviceTypes.${DeviceType.UniversalCamera}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-universal-audio`}
      key={`device-type-universal-audio`}
      value={DeviceType.UniversalAudio}
    >
      {t(`deviceTypes.${DeviceType.UniversalAudio}`)}
    </MenuItem>,
    <MenuItem
      data-testid={`device-type-universal-alarmtransmitter`}
      key={`device-type-universal-alarmtransmitter`}
      value={DeviceType.UniversalAlarmTransmitter}
    >
      {t(`deviceTypes.${DeviceType.UniversalAlarmTransmitter}`)}
    </MenuItem>,
  ];

  return (
    <>
      {(currentViewingMode === "viewing" ||
        currentViewingMode === "editing") && (
        <Typography variant="subtitle1">
          {currentDevice
            ? t(`deviceTypes.${currentDevice.type}`)
            : t(`deviceTypes.${DeviceType.Universal}`)}
        </Typography>
      )}

      {currentViewingMode === "creation" && currentDevice?.importDate == null && (
        <ViewingModeController
          viewingMode={currentViewingMode}
          name={`type`}
          control={control}
          rules={registerOptions.type}
          label={t("info.typeLabel")}
          render={({ field }) => (
            <SelectField
              {...field}
              inputProps={{ "data-testid": "AlarmActiveInput" }}
              id="deviceType"
              error={!!errors.type}
              helperText={errors.type?.message?.toString()}
              variant="outlined"
              className="select-field"
              label={t("info.typeLabel")}
              value={field.value ?? ""}
            >
              {deviceTypeOptions.map((menuItem) => menuItem)}
            </SelectField>
          )}
        />
      )}
    </>
  );
}

export default DeviceSettingsType;
