import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "AppInsights";

const errorHandlingMiddleware = (_: any) => (next: any) => (action: any) => {
  if (action.payload?.error) {
    appInsights.trackException({
      error: new Error(
        `An error occured while performing redux action ${action}`,
        action.payload.error,
      ),
      severityLevel: SeverityLevel.Error,
    });
  }

  return next(action);
};

export default errorHandlingMiddleware;
