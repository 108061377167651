import React, { ReactElement, ReactNode } from "react";
import useOrganisationUnits, {
  IOrganisationUnitsHook,
} from "./organisation-units-hook";

const OrganisationUnitsContext = React.createContext<IOrganisationUnitsHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const OrganisationUnitsProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <OrganisationUnitsContext.Provider value={useOrganisationUnits()}>
      {props.children}
    </OrganisationUnitsContext.Provider>
  );
};

export const useOrganisationUnitsProvider = (): IOrganisationUnitsHook =>
  React.useContext<IOrganisationUnitsHook>(OrganisationUnitsContext);
