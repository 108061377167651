import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadTemporaryAccessResponse from "features/temporary-access/domain/models/read-temporary-access-response";
import { ReadTemporaryAccessQuery } from "features/temporary-access/domain/models/read-temporay-access-query";
import { CreateTemporaryUserCommand } from "../models/create-temporary-user-command";
import { ValidateTemporaryUserPeriodCommand } from "../models/validate-temporary-user-period-command";
import { RevokeTemporaryUserCommand } from "../models/revoke-temporary-user-command";
import { RevokeTemporaryChannelAccessCommand } from "../models/revoke-temporary-channel-access-command";
import TemporaryUser from "../models/temporary-user";

const baseUrl = "/api/v1/temporaryAccess";

export const temporaryUsersApi = createApi({
  reducerPath: "temporaryUsers",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["temporaryAccess"],
  endpoints: (build) => ({
    readTemporaryAccess: build.query<
      ReadTemporaryAccessResponse,
      ReadTemporaryAccessQuery
    >({
      query: (command) => ({
        url: `${baseUrl}/ReadTemporaryAccess`,
        method: "POST",
        body: command,
      }),
      providesTags: ["temporaryAccess"],
    }),
    readTemporaryUser: build.query<TemporaryUser, string>({
      query: (id) => ({
        url: `${baseUrl}/ReadTemporaryUser/${id}`,
        method: "GET",
      }),
    }),
    createTemporaryUser: build.mutation<void, CreateTemporaryUserCommand>({
      query: (command) => ({
        url: `${baseUrl}/CreateTemporaryUser`,
        method: "POST",
        body: command,
      }),
      invalidatesTags: ["temporaryAccess"],
    }),
    validateTemporaryUserPeriod: build.mutation<
      void,
      ValidateTemporaryUserPeriodCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/ValidateTemporaryUserPeriod`,
        method: "POST",
        body,
      }),
    }),
    revokeTemporaryUser: build.mutation<void, RevokeTemporaryUserCommand>({
      query: (command) => ({
        url: `${baseUrl}/RevokeTemporaryUser`,
        method: "PUT",
        body: command,
      }),
      invalidatesTags: ["temporaryAccess"],
    }),
    revokeTemporaryChannelAccess: build.mutation<
      void,
      RevokeTemporaryChannelAccessCommand
    >({
      query: (command) => ({
        url: `${baseUrl}/RevokeTemporaryChannelAccess`,
        method: "PUT",
        body: command,
      }),
      invalidatesTags: ["temporaryAccess"],
    }),
  }),
});

export const {
  useLazyReadTemporaryAccessQuery,
  useLazyReadTemporaryUserQuery,
  useCreateTemporaryUserMutation,
  useValidateTemporaryUserPeriodMutation,
  useRevokeTemporaryUserMutation,
  useRevokeTemporaryChannelAccessMutation,
} = temporaryUsersApi;
