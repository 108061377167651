import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import {
  useDeleteSystemMutation,
  useUpdateSystemMutation,
} from "features/systems/domain/reducers/systems.reducer";
import { System } from "features/systems/domain/system";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ViewingMode } from "utils/viewing-utils";

export interface SystemDetailsHook {
  viewingMode: ViewingMode;

  mutationIsLoading: boolean;
  cancelMutation: () => void;

  selectedSystem: System | undefined;

  updateSystem: (system: System) => void;
  deleteSystem: (system: System) => void;

  isDetailsOpen: boolean;
  openDetailsInViewingMode: (system: System) => void;
  openDetailsInEditingMode: (system: System) => void;
  closeDetails: (isFormStateDirty: boolean) => void;

  isDeleteErrorPopupOpen: boolean;
  closeDeleteErrorPopup: () => void;

  isDeleteConfirmationPopupOpen: boolean;
  closeDeleteConfirmationPopup: () => void;
  deleteConfirmationAction: () => void;

  form: UseFormReturn<Partial<System>, any>;
}

const useSystemDetails = (): SystemDetailsHook => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {} as Partial<System>,
  });

  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );

  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<System>();
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);

  const [
    updateSystem,
    {
      isLoading: updateSystemIsLoading,
      isSuccess: updateSystemIsSuccess,
      isError: updateSystemIsError,
      error: updateSystemError,
      data: updateSystemData,
    },
  ] = useUpdateSystemMutation();

  const [
    deleteSystemMutation,
    {
      isSuccess: deleteSystemIsSuccess,
      isLoading: deleteSystemIsLoading,
      isError: deleteSystemIsError,
      error: deleteSystemError,
    },
  ] = useDeleteSystemMutation();

  useEffect(() => {
    setFormValuesWithSelectedSystem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSystem]);

  useEffect(() => {
    setMutationIsLoading(updateSystemIsLoading || deleteSystemIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsLoading, deleteSystemIsLoading]);

  useEffect(() => {
    if (updateSystemIsSuccess && updateSystemData) {
      setSelectedSystem(updateSystemData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsSuccess]);

  useEffect(() => {
    if (updateSystemIsError && updateSystemError) {
      dispatch(
        setErrorMessage({
          error: updateSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSystemIsError]);

  useEffect(() => {
    if (deleteSystemIsError && deleteSystemError) {
      dispatch(
        setErrorMessage({
          error: deleteSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSystemIsError]);

  useEffect(() => {
    if (deleteSystemIsSuccess) {
      setIsDeleteConfirmationPopupOpen(false);
      setIsDetailsOpen(false);
    }
  }, [deleteSystemIsSuccess]);

  const setFormValuesWithSelectedSystem = () => {
    form.reset({
      name: selectedSystem?.name ?? "",
      note: selectedSystem?.note ?? "",
      organisationUnitId: selectedSystem?.organisationUnitId ?? undefined,
    });
  };

  const openDetailsInViewingMode = (system?: System) => {
    setSelectedSystem(system);
    setIsDetailsOpen(true);
    setViewingMode("viewing");
  };

  const openDetailsInEditingMode = (system?: System) => {
    setSelectedSystem(system);
    setIsDetailsOpen(true);
    setViewingMode("editing");
    setFormValuesWithSelectedSystem();
  };

  const cancelMutation = () => {
    setViewingMode("viewing");
    setFormValuesWithSelectedSystem();
  };

  const closeDetails = (isFormStateDirty: boolean) => {
    const showConfirmation = viewingMode === "editing" && isFormStateDirty;
    if (!showConfirmation) return setIsDetailsOpen(false);

    dispatch(
      showConfirmationPopup({
        showInstantly: true,
        confirmActionNextAction: () => setIsDetailsOpen(false),
      }),
    );
  };

  const closeDeleteConfirmationPopup = () =>
    setIsDeleteConfirmationPopupOpen(false);

  const closeDeleteErrorPopup = () => setIsDeleteErrorPopupOpen(false);

  const deleteSystem = (system: System) => {
    setSelectedSystem(system);
    const hasDeviceWithOrganisationUnitId = system.gateway?.devices?.some(
      (device) => !!device.organisationUnitId,
    );

    if (hasDeviceWithOrganisationUnitId) return setIsDeleteErrorPopupOpen(true);

    setIsDeleteConfirmationPopupOpen(true);
    setDeleteConfirmationAction(() => () => confirmDeleteSystem(system));
  };

  const confirmDeleteSystem = (system: System) => {
    deleteSystemMutation(system.id!);
    setDeleteConfirmationAction(() => () => {});
    setSelectedSystem(undefined);
  };

  return {
    viewingMode,
    mutationIsLoading,
    cancelMutation,

    selectedSystem,

    updateSystem,
    deleteSystem,

    isDetailsOpen,
    openDetailsInViewingMode,
    openDetailsInEditingMode,
    closeDetails,

    isDeleteErrorPopupOpen,
    closeDeleteErrorPopup,

    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    form,
  };
};

export default useSystemDetails;
