import styles from "./residents-bulk-edit.module.scss";

import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import Header from "./header.component";
import { FormProvider } from "react-hook-form";
import ScenarioRow from "./scenario-row.component";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { Grid, Typography } from "@mui/material";
import { NotificationsNoneOutlined } from "@mui/icons-material";

function ResidentsBulkEdit(): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const { residentsBulkEditHook, setSelectedResidents } =
    useResidentSettingsContextProvider();
  const { handleSubmit } = residentsBulkEditHook.form;

  return (
    <div className={styles.container}>
      <FormProvider {...residentsBulkEditHook.form}>
        <form onSubmit={handleSubmit(residentsBulkEditHook.submitChanges)}>
          <Header
            headerText={t("bulkEditScenarios.title")}
            isSubmitting={
              residentsBulkEditHook.updateBulkScenarioIsActiveIsLoading
            }
            onCancelButtonClicked={() => {
              setSelectedResidents([]);
            }}
          />
          <div className={styles.body}>
            <Grid className={styles.summaryContent}>
              <NotificationsNoneOutlined />
              <Typography variant="h5">
                {t("details.scenariosDividerText")}
              </Typography>
            </Grid>
            {residentsBulkEditHook.availableScenariosLoading && (
              <LoadingIndicator />
            )}
            {!residentsBulkEditHook.availableScenariosLoading &&
              residentsBulkEditHook.form.getValues("bulkScenarioActiveStates")
                .length === 0 && (
                <Typography variant="h5" className={styles.noScenarios}>
                  {t("bulkEditScenarios.noScenariosAvailable")}
                </Typography>
              )}
            {!residentsBulkEditHook.availableScenariosLoading &&
              residentsBulkEditHook.form.getValues("bulkScenarioActiveStates")
                .length > 0 && (
                <>
                  {[
                    ...residentsBulkEditHook.form.getValues(
                      "bulkScenarioActiveStates",
                    ),
                  ].map((scenario, index) => {
                    return (
                      <ScenarioRow key={scenario.scenarioType} index={index} />
                    );
                  })}
                </>
              )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default ResidentsBulkEdit;
