import { useLocalStorage } from "usehooks-ts";

interface ILogin {
  setLastEnteredEmailDomain: (domain: string) => void;
  lastEnteredEmailDomain: string;
}

export const useLogin = (): ILogin => {
  const [
    lastEnteredEmailDomainFromSessionStorage,
    setLastEnteredEmailDomainFromSessionStorage,
  ] = useLocalStorage<string>("last-entered-email-domain", "");

  const setLastEnteredEmailDomain = (domain: string) => {
    setLastEnteredEmailDomainFromSessionStorage(domain);
  };

  return {
    setLastEnteredEmailDomain,
    lastEnteredEmailDomain: lastEnteredEmailDomainFromSessionStorage,
  };
};
