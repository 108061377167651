import { Divider, Menu, MenuItem } from "@mui/material";
import { ReactElement, ReactNode } from "react";
import "./overflow-menu-component.scss";

export interface IOverflowMenuItem {
  label: string;
  action: () => void;
  disabled: boolean;
}

export type OverflowMenuItem = IOverflowMenuItem | "divider";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  anchorEl?: null | Element | (() => Element);
  menuItems: Array<OverflowMenuItem>;
}

function OverflowMenu(props: Readonly<IProps>): ReactElement {
  function mapMenuItems(): ReactNode {
    return props.menuItems.map((item, index) =>
      mapMenuItem(item, `menu-item-${index}`),
    );
  }

  function mapMenuItem(item: OverflowMenuItem, key: string): ReactNode {
    return item === "divider" ? (
      <Divider key={key} />
    ) : (
      <MenuItem
        data-testid={`overflow-menu-item-${key}`}
        key={key}
        onClick={item.action}
        className="menu-item"
        disabled={item.disabled}
      >
        {item.label}
      </MenuItem>
    );
  }

  return (
    <Menu
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.isOpen}
      onClick={props.onClose}
      className="overflow-menu-paper"
    >
      {mapMenuItems()}
    </Menu>
  );
}

export default OverflowMenu;
