export enum DeviceType {
  Unknown = "Unknown",
  ArgusUnit = "ArgusUnit",
  IPunitV2 = "IPunitV2",
  DIP0101 = "DIP0101",
  Sec0104 = "Sec0104",
  Sec0105 = "Sec0105",
  S0303 = "S0303",
  GenericSIP = "GenericSIP",
  ExternalURL = "ExternalURL",
  CameraGeneric = "CameraGeneric",
  CameraAxis = "CameraAxis",
  CameraMobotix = "CameraMobotix",
  DoorbellIntercom = "DoorbellIntercom",
  DesoField = "DesoField",
  EspaInBrand = "EspaInBrand",
  EspaInDwaalDetectie = "EspaInDwaalDetectie",
  G1 = "G1",
  G2 = "G2",
  DesoClient = "DesoClient",
  Services = "Services",
  Universal = "Universal",
  UniversalCamera = "UniversalCamera",
  UniversalAudio = "UniversalAudio",
  UniversalAlarmTransmitter = "UniversalAlarmTransmitter",
  UniversalIntercom = "UniversalIntercom",
  NFC = "NFC",
  ESPA = "ESPA",
  BLEBeacon = "BLEBeacon",
}
