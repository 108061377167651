import { CommentOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import PageHeader from "components/page-header/page-header";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem } from "components/table/table";
import { useConnectorsContextProvider } from "features/connectors/context/connectors-provider";
import { Connector } from "features/connectors/domain/connector";
import IntegrationsNavigation from "features/integrations/views/integrations-navigation";
import { findUpstreamParentBranch } from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import styles from "features/systems/views/systems.module.scss";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { NestedKeyof } from "utils/nested-keyof-utils";

const Connectors = (): JSX.Element => {
  const { t } = useTranslation("connectors");
  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const { connectorsResponse, isLoading, isSuccess, connectorsFilterHook } =
    useConnectorsContextProvider();

  const { connectors, total } = connectorsResponse ?? {
    connectors: [],
    total: 0,
  };
  const showConnectorsTable = !isLoading && isSuccess;

  const organisationBreadCrumb = (connector: Connector): JSX.Element => {
    if (!organisationUnitTree) return <></>;

    return (
      <>
        {connector.organisationUnits.map((unit) => {
          if (!unit.id || !organisationUnitTree) return <></>;

          const upstreamParentBranch = findUpstreamParentBranch(
            organisationUnitTree,
            unit.id,
            [],
          );

          if (!upstreamParentBranch) return <></>;

          return (
            <OrganisationBreadCrumb
              key={unit.id}
              rootNodeId={unit.id}
              maxVisibleBreadCrumbs={2}
              upstreamParentBranch={upstreamParentBranch}
              textStyle="caption"
            />
          );
        })}
      </>
    );
  };

  const noteIcon = (hasNote: boolean) => (
    <CommentOutlined
      sx={{
        width: 16,
        height: 16,
        color: Constants.Colors.onSurfaceVariant,
        visibility: hasNote ? "visible" : "hidden",
      }}
    />
  );

  const columns: ColumnDefinition<Connector, NestedKeyof<Connector>>[] = [
    { key: "name", label: t("table.column.name") },
    {
      key: "type",
      label: t("table.column.type"),
      renderCustomContentProvider: ({ type }) =>
        t(`details.information.type.${type}`),
      disableSort: true,
    },

    {
      key: "organisationUnits",
      label: t("table.column.locations"),
      renderCustomContentProvider: organisationBreadCrumb,
      disableSort: true,
      tableCellProps: { width: "400px" },
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: (system) => noteIcon(!!system.note),
      disableSort: true,
    },
  ];

  return (
    <div className={styles.container}>
      <PageHeader
        title={t("pageTitle")}
        navigationComponent={<IntegrationsNavigation />}
      />
      {isLoading && <LoadingIndicator />}
      {showConnectorsTable && (
        <Table
          data={connectors.map<IRowItem<Connector>>((connector) => ({
            data: connector,
          }))}
          columns={columns}
          rowIdentifier={(item) => item.id!}
          initialOrderBy={
            connectorsFilterHook.table.sortFromSessionStorage!.property
          }
          initialOrderDirection={
            connectorsFilterHook.table.sortFromSessionStorage!.isAscending
              ? "asc"
              : "desc"
          }
          useBuiltInSorter
          enablePagination
          count={total}
          onPageChanged={connectorsFilterHook.table.handleOnPageChanged}
          rowsPerPage={connectorsFilterHook.table.currentRowsPerPage}
          page={connectorsFilterHook.table.currentPage}
          onRowsPerPageChanged={
            connectorsFilterHook.table.handleOnRowsPerPageChanged
          }
          onSortChanged={connectorsFilterHook.table.handleOnSortChanged}
        />
      )}
    </div>
  );
};

export default Connectors;
