import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import GetMinimumLogLevelResponse from "../models/get-minimum-log-level-response";

const baseUrl = "/api/v1/Configuration";

export const configurationApi = createApi({
  reducerPath: "configuration",
  baseQuery,
  tagTypes: ["GetMinimumLogLevel"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getMinimumLogLevel: build.query<GetMinimumLogLevelResponse, void>({
      query: () => ({
        url: `${baseUrl}/GetMinimumLogLevel`,
        method: "GET",
      }),
      providesTags: ["GetMinimumLogLevel"],
    }),
  }),
});

export const { useLazyGetMinimumLogLevelQuery } = configurationApi;
