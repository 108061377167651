import { SvgIcon, SxProps, Theme } from "@mui/material";

interface Props {
  sx?: SxProps<Theme>;
}

const GoogleMapsIcon = ({ sx }: Props): JSX.Element => {
  return (
    <SvgIcon sx={sx} viewBox="0 0 11.5 16.5">
      <path
        id="Vector"
        d="M3.17743 12.9778C3.65521 13.5868 4.09009 14.2282 4.47891 14.8974C4.81087 15.5269 4.94925 15.9537 5.19185 16.7121C5.34063 17.1311 5.47509 17.2562 5.76419 17.2562C6.07922 17.2562 6.22215 17.0434 6.33258 16.7144C6.56189 15.9982 6.74183 15.4516 7.0257 14.9351C7.58274 13.938 8.27492 13.0519 8.95504 12.2003C9.13916 11.9593 10.3296 10.5552 10.8655 9.44724C10.8655 9.44724 11.5242 8.2302 11.5242 6.53049C11.5242 4.94058 10.8746 3.83789 10.8746 3.83789L9.00438 4.33875L7.8686 7.33018L7.5876 7.74267L7.53142 7.81739L7.4567 7.91094L7.32552 8.06035L7.13806 8.24776L6.12667 9.07175L3.59806 10.5317L3.17743 12.9778Z"
        fill="#34A853"
      />
      <path
        id="Vector_2"
        d="M0.567757 9.2418C1.18489 10.6514 2.37496 11.8906 3.18014 12.978L7.4568 7.91204C7.4568 7.91204 6.85429 8.70002 5.76137 8.70002C4.54397 8.70002 3.56049 7.72788 3.56049 6.50207C3.56049 5.66146 4.06621 5.08398 4.06621 5.08398L1.16311 5.86188L0.567757 9.2418Z"
        fill="#FBBC04"
      />
      <path
        id="Vector_3"
        d="M7.50438 0.999023C8.92476 1.45698 10.1405 2.41841 10.8758 3.83613L7.45793 7.90918C7.45793 7.90918 7.96365 7.32126 7.96365 6.48587C7.96365 5.23152 6.90741 4.29445 5.76637 4.29445C4.68735 4.29445 4.06765 5.08112 4.06765 5.08112V2.51515L7.50438 0.999023Z"
        fill="#4285F4"
      />
      <path
        id="Vector_4"
        d="M1.35128 2.79946C2.19963 1.78446 3.69239 0.739258 5.75039 0.739258C6.74887 0.739258 7.5011 1.00139 7.5011 1.00139L4.06468 5.08412H1.62994L1.35128 2.79946Z"
        fill="#1A73E8"
      />
      <path
        id="Vector_5"
        d="M0.567808 9.24295C0.567808 9.24295 5.34058e-05 8.13112 5.34058e-05 6.52041C5.34058e-05 4.99807 0.591847 3.66737 1.35124 2.7998L4.06657 5.08482L0.567808 9.24295Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
};

export default GoogleMapsIcon;
