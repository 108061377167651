import { Divider, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./page-header.scss";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { ReactElement } from "react";

interface IProps {
  title: string;
  description?: string;
  isAddActionVisible?: boolean;
  addActionPermission?: Permission;
  isAddActionDisabled?: boolean;
  onAdd?: React.ReactEventHandler<{}>;
  navigationComponent?: ReactElement;
  filterComponent?: ReactElement;
}

export default function PageHeader(props: Readonly<IProps>): ReactElement {
  return (
    <div className="page-header-container">
      <div className="page-header-and-tab-container">
        <div>
          <Typography className="title" variant="h3">
            {props.title}
          </Typography>
          <Typography className="description" variant="subtitle1">
            {props.description}
          </Typography>
        </div>

        {props.navigationComponent}
      </div>
      {props.filterComponent}
      <Divider className="page-header-divider" />
      {props.isAddActionVisible && (
        <AutorisationWrapper
          atLeastOnePermissionOf={
            props.addActionPermission && [props.addActionPermission]
          }
        >
          <Fab
            data-testid="pageHeaderAddButton"
            aria-label="add"
            className="add-button"
            onClick={props.onAdd}
            disabled={props.isAddActionDisabled}
          >
            <AddIcon />
          </Fab>
        </AutorisationWrapper>
      )}
    </div>
  );
}
