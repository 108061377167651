import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import "./time-frames.component.scss";
import { Checkbox, FormControl, Grid, Typography } from "@mui/material";
import {
  PickersInputComponentLocaleText,
  TimePicker,
} from "@mui/x-date-pickers";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import moment, { Moment } from "moment";
import TimeFrame from "../../domain/models/configurations/time-frame";
import { validateTimeFrames } from "utils/timeframe-utils";
import { useFormContext } from "react-hook-form";
import { useResidentSettingsContextProvider } from "../../context/resident-settings-provider";

interface IProps {
  timeFrames: TimeFrame[] | null;
  activeAllDay: boolean;
  valuesChanged: (
    newTimeFrames: TimeFrame[] | null,
    newActiveAllDay: boolean,
  ) => void;
  index: number;
  timeFramesDescription: string;
}

export function TimeFrames(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [validationErrors, setValidationErrors] = useState<boolean[]>(
    props.timeFrames?.map((_) => false) ?? [],
  );

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const { formState } = useFormContext();

  useEffect(() => {
    if (formState.isSubmitting && props.timeFrames != null) {
      validate(props.timeFrames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  function addTimeFrame(): void {
    let newTimeFrames = [...props.timeFrames!];
    newTimeFrames.push({
      from: "00:00:00.000",
      to: "00:00:00.000",
    });

    validate(newTimeFrames);

    props.valuesChanged(newTimeFrames, props.activeAllDay);
  }

  function removeTimeFrame(index: number): void {
    let newTimeFrames = [...props.timeFrames!];
    newTimeFrames.splice(index, 1);

    validate(newTimeFrames);

    props.valuesChanged(newTimeFrames, props.activeAllDay);
  }

  function onTimeFrameChanged(
    newValue: Moment | null,
    index: number,
    isFromField: boolean,
  ): void {
    let newTimeFrames = [...props.timeFrames!];

    const formattedValue = newValue?.format("HH:mm:ss") ?? "";
    if (isFromField) {
      newTimeFrames[index].from = formattedValue;
    } else {
      newTimeFrames[index].to = formattedValue;
    }

    validate(newTimeFrames);

    props.valuesChanged(newTimeFrames, props.activeAllDay);
  }

  function validate(newTimeFrames: TimeFrame[]): void {
    const newValidationErrors = validateTimeFrames(newTimeFrames);

    setValidationErrors(newValidationErrors);

    if (newValidationErrors.every((x) => !x)) {
      residentDetailsHook.clearValidationErrorForScenario(props.index);
      props.valuesChanged(newTimeFrames, props.activeAllDay);
    } else {
      residentDetailsHook.setValidationErrorForScenario(
        { type: "pattern" },
        props.index,
      );
    }
  }

  useEffect(() => {
    setValidationErrors([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentDetailsHook.viewingMode]);

  function setAllDayTimeFrame(
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void {
    if (!checked) {
      let newTimeFrames = [...props.timeFrames!];
      newTimeFrames.push({
        from: "00:00:00.000",
        to: "00:00:00.000",
      });

      props.valuesChanged(newTimeFrames, checked);
    } else {
      let newTimeFrames: TimeFrame[] = [];
      validate(newTimeFrames);

      props.valuesChanged(newTimeFrames, checked);
    }
  }

  const timePickerLocaleText: PickersInputComponentLocaleText<moment.Moment> = {
    fieldHoursPlaceholder: () => t("timePickerLocale.fieldHoursPlaceholder"),
    fieldMinutesPlaceholder: () =>
      t("timePickerLocale.fieldMinutesPlaceholder"),
  };

  return (
    <div className="time-frames-container">
      <div className="time-frames-row">
        <Typography variant="subtitle1">
          {props.timeFramesDescription}
        </Typography>

        <div className="all-day-time-frame">
          <FormControl>
            <Checkbox
              checked={props.activeAllDay}
              onChange={setAllDayTimeFrame}
              disabled={residentDetailsHook.viewingMode === "viewing"}
            />
            <Typography variant="subtitle1">
              {t("scenarioConfiguration.scenarioAlwaysActive")}
            </Typography>
          </FormControl>
        </div>

        {props.timeFrames?.map((timeFrame, index) => (
          <Fragment key={`TimeFrame-${index}`}>
            <div className="time-pickers">
              <TimePicker
                label={t("scenarioConfiguration.from")}
                ampm={false}
                view={"minutes"}
                views={["hours", "minutes"]}
                format="HH:mm"
                localeText={timePickerLocaleText}
                value={moment(timeFrame.from, "HH:mm:ss")}
                onChange={(value) => {
                  onTimeFrameChanged(value, index, true);
                }}
                disabled={residentDetailsHook.viewingMode === "viewing"}
                className={`time-picker${
                  validationErrors[index] ? " validationError" : ""
                }`}
                slotProps={{
                  popper: {
                    className: "time-picker-popper",
                  },
                }}
              />
              <TimePicker
                label={t("scenarioConfiguration.to")}
                ampm={false}
                view={"minutes"}
                views={["hours", "minutes"]}
                format="HH:mm"
                localeText={timePickerLocaleText}
                value={moment(timeFrame.to, "HH:mm:ss")}
                onChange={(value) => {
                  onTimeFrameChanged(value, index, false);
                }}
                disabled={residentDetailsHook.viewingMode === "viewing"}
                className={`time-picker${
                  validationErrors[index] ? " validationError" : ""
                }`}
                slotProps={{
                  popper: {
                    className: "time-picker-popper",
                  },
                }}
              />
              {residentDetailsHook.viewingMode !== "viewing" &&
                props.timeFrames &&
                props.timeFrames.length > 1 && (
                  <DeleteForeverOutlined
                    onClick={() => removeTimeFrame(index)}
                    className={`delete-icon ${
                      validationErrors[index] && "validationError"
                    }`}
                  />
                )}
            </div>
            {validationErrors[index] && (
              <div className="validationText">
                <Typography variant="caption" className="typographyColor">
                  {t("scenarioConfiguration.timeFrameValidation")}
                </Typography>
              </div>
            )}
          </Fragment>
        ))}

        {residentDetailsHook.viewingMode !== "viewing" && !props.activeAllDay && (
          <Grid
            className="add-time-frame"
            onClick={addTimeFrame}
            onKeyDown={(e) => e.key === "Enter" && addTimeFrame()}
          >
            <Add />
            <Typography variant="body1">
              {t("scenarioConfiguration.addTimeFrame")}
            </Typography>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default TimeFrames;
