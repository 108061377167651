import { Dialog } from "@mui/material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import Permission, {
  mapPermissionsToArray,
  mapPermissionsToRecord,
} from "features/autorisation/domain/models/permission";
import PermissionsPermission from "features/autorisation/domain/models/permissions-permission";
import {
  useLazyGetPermissionsQuery,
  useUpdatePermissionsMutation,
} from "features/autorisation/domain/reducers/permission.reducer";
import {
  confirmationActionExecuted,
  setShouldShowConfirmation,
  showConfirmationPopup,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Role from "features/role/domain/models/role";
import {
  useCreateRoleMutation,
  useUpdateRoleMutation,
} from "features/role/domain/reducers/role.reducer";
import { ReactElement, useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { ViewingMode } from "utils/viewing-utils";
import RoleDetailsPopupHeader from "./role-details-popup-header";
import "./role-details-popup.component.scss";
import RoleInformation from "./role-information.component";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import { RoleType } from "features/role/domain/models/role-type";
import RoleDetailsPopupRightContent from "./role-details-popup-right-content";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onSaved: (role: Role) => void;
  onDelete: () => void;
  selectedRole?: Role;
  allRoles: Array<Role> | undefined;
  defaultViewingMode: ViewingMode;
}

function RoleDetailsPopup(props: Readonly<IProps>): ReactElement {
  const dispatch = useDispatch();
  const [createRole] = useCreateRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [updatePermissions] = useUpdatePermissionsMutation();
  const [viewingMode, setViewingMode] = useState(props.defaultViewingMode);
  const [hasInitiatedEditingMode, setHasInitiatedEditingMode] = useState(false);

  const [
    triggerGetPermissionsQuery,
    {
      data: permissions,
      isLoading: isLoadingPermissions,
      error: getPermissionsError,
    },
  ] = useLazyGetPermissionsQuery();

  const [isSaving, setIsSaving] = useState(false);

  function mapDefaultPermissions(): Record<Permission, boolean> {
    const map = new Map();
    for (const value of Object.values(Permission)) {
      map.set(value, false);
    }
    return Object.fromEntries(map) as Record<Permission, boolean>;
  }

  const defaultValues = {
    permissions: mapDefaultPermissions(),
    name: props.selectedRole?.name ?? "",
    idpId: props.selectedRole?.idpId ?? "",
    description: props.selectedRole?.description ?? "",
    roleType: props.selectedRole?.roleType ?? RoleType.Permissions,
    accessOrganisationUnitId:
      props.selectedRole?.accessOrganisationUnitId ?? "",
    canBeAssignedToTemporaryUsers:
      props.selectedRole?.canBeAssignedToTemporaryUsers ?? false,
  };

  const form = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const watchRoleType = useWatch({ control: form.control, name: "roleType" });

  useEffect(() => {
    if (
      props.selectedRole &&
      props.selectedRole.roleType === RoleType.Permissions
    ) {
      triggerGetPermissionsQuery(props.selectedRole.idpId);
      return;
    }

    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedRole]);

  useEffect(() => {
    if (getPermissionsError) {
      dispatch(
        setErrorMessage({
          error: getPermissionsError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPermissionsError]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  useEffect(() => {
    const newPermissions = permissions
      ? mapPermissionsToRecord(permissions)
      : mapDefaultPermissions();
    const newValues = { ...defaultValues, permissions: newPermissions };
    form.reset(newValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  async function submitForm(fieldValues: FieldValues): Promise<void> {
    if (
      fieldValues.roleType === RoleType.Access &&
      !form.getValues("accessOrganisationUnitId")
    ) {
      return;
    }

    setIsSaving(true);
    let result = await submitRole(fieldValues);

    if (isErrorResponse(result)) {
      dispatch(
        setErrorMessage({
          error: result.error,
        }),
      );

      setIsSaving(false);
      return;
    } else if (fieldValues.roleType === RoleType.Permissions) {
      const permissionsResult = await submitPermissions(fieldValues);
      if (isErrorResponse(permissionsResult)) {
        dispatch(
          setErrorMessage({
            error: permissionsResult.error,
          }),
        );
      }
    }

    if (result.data) {
      props.onSaved(result.data);
    }

    dispatch(confirmationActionExecuted());
    setViewingMode("viewing");

    setIsSaving(false);
  }

  async function submitRole(
    fieldValues: FieldValues,
  ): Promise<ApiResponse<Role>> {
    const accessOrganisationUnitId = form.getValues("accessOrganisationUnitId");
    const role: Role = {
      id: props.selectedRole?.id,
      name: fieldValues.name?.trim(),
      idpId: fieldValues.idpId.trim(),
      description: fieldValues.description?.trim(),
      roleType: fieldValues.roleType,
      accessOrganisationUnitId:
        accessOrganisationUnitId !== "" ? accessOrganisationUnitId : undefined,
      canBeAssignedToTemporaryUsers: fieldValues.canBeAssignedToTemporaryUsers,
    };

    let result: ApiResponse<Role>;
    if (viewingMode === "creation") {
      result = (await createRole(role)) as ApiResponse<Role>;
    } else {
      result = (await updateRole({
        originalIdpId: props.selectedRole!.idpId,
        role,
      })) as ApiResponse<Role>;
    }
    return result;
  }

  async function submitPermissions(
    fieldValues: FieldValues,
  ): Promise<ApiResponse<Array<PermissionsPermission>>> {
    const permissions = mapPermissionsToArray(fieldValues.permissions);
    return (await updatePermissions({
      idpId: fieldValues.idpId.trim(),
      permissions,
    })) as ApiResponse<Array<PermissionsPermission>>;
  }

  function handleCancel(closePopup: boolean): void {
    form.reset();
    if (closePopup) {
      props.onClose();
    } else {
      setViewingMode("viewing");
    }
  }

  function handleOnClose(closePopup: boolean): void {
    if (
      (viewingMode === "creation" || viewingMode === "editing") &&
      form.formState.isDirty
    ) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            handleCancel(closePopup);
          },
        }),
      );
    } else {
      handleCancel(closePopup);
    }
  }

  function handleOnEdit(): void {
    setHasInitiatedEditingMode(true);
    setViewingMode("editing");
  }

  return (
    <Dialog
      open={props.isOpen}
      onClose={() => handleOnClose(true)}
      className="role-details-popup"
      PaperProps={{ classes: { root: "role-details-popup-paper" } }}
    >
      <FormProvider {...form}>
        <DetailsPopUpContent
          onSubmit={form.handleSubmit(submitForm)}
          isDisabled={isLoadingPermissions || isSaving}
          leftContent={
            <RoleInformation
              selectedRole={props.selectedRole}
              allRoles={props.allRoles}
              viewingMode={viewingMode}
            />
          }
          rightContent={
            isLoadingPermissions ? (
              <LoadingIndicator />
            ) : (
              <RoleDetailsPopupRightContent
                roleType={watchRoleType}
                viewingMode={viewingMode}
                initialSelectedOrganisationUnit={
                  props.selectedRole?.accessOrganisationUnitId
                }
              />
            )
          }
          header={
            <RoleDetailsPopupHeader
              onClose={() => handleOnClose(true)}
              onCancel={() => handleCancel(!hasInitiatedEditingMode)}
              onEdit={handleOnEdit}
              onDelete={props.onDelete}
              viewingMode={viewingMode}
              isLoading={isSaving}
            />
          }
        />
      </FormProvider>
    </Dialog>
  );
}

export default RoleDetailsPopup;
