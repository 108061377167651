import React, { ReactNode } from "react";
import useConnectionType, { IConnectionTypeHook } from "./connectionType-hook";

const ConnectionTypeContext = React.createContext<IConnectionTypeHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const ConnectionTypeProvider = (props: Readonly<IProps>) => {
  return (
    <ConnectionTypeContext.Provider value={useConnectionType()}>
      {props.children}
    </ConnectionTypeContext.Provider>
  );
};

export const useConnectionTypeContextProvider = (): IConnectionTypeHook =>
  React.useContext<IConnectionTypeHook>(ConnectionTypeContext);
