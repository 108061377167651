import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import SendTestEventCommand from "../models/send-test-event-command";

const baseUrl = "/api/v1/Device";

export const sendTestEventApi = createApi({
  reducerPath: "sendTestEvent",
  baseQuery,
  tagTypes: ["SendTestEvent"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    sendTestEvent: build.mutation<void, SendTestEventCommand>({
      query: (body) => ({
        url: `${baseUrl}/SendTestEvent`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["SendTestEvent"],
    }),
  }),
});

export const { useSendTestEventMutation } = sendTestEventApi;
