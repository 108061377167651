import axios from "axios";
import Token from "features/autorisation/domain/models/token";
import TenantAccess from "features/tenants/domain/models/tenant-access";

import jwt_decode from "jwt-decode";
import {
  InMemoryWebStorage,
  Profile,
  User,
  UserManager,
  WebStorageStateStore,
} from "oidc-client";

const logoutMessage = "logout";

class AuthenticationService {
  private _userManager: UserManager;
  private _overrideUserManager: UserManager | undefined;
  private _newUserCallback: ((user: User) => void) | undefined;

  private _logoutChannel = new BroadcastChannel("logout");

  constructor() {
    this._userManager = new UserManager({
      authority: process.env.REACT_APP_AUTHORITY_URL,
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/login-callback`,
      post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}/logout-callback`,
      response_type: "code",
      scope: "openid profile",
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    });

    this._logoutChannel.onmessage = (messageEvent: MessageEvent<string>) => {
      if (messageEvent.data === logoutMessage) {
        this.logout();
      }
    };

    this._userManager.startSilentRenew();

    this._userManager.events.addAccessTokenExpiring(() => {
      if (!this._overrideUserManager) {
        this._userManager.signinSilent().then(
          (newUser) => {
            if (this._newUserCallback) {
              this._newUserCallback(newUser);
            }
          },
          async (_) => {
            await this._userManager.removeUser();
            window.location.href = "/login";
          },
        );
      }
    });
  }

  async signinRedirectCallback(): Promise<User> {
    return this._userManager.signinRedirectCallback();
  }

  async signinSilentCallback(): Promise<User | undefined> {
    return await this._userManager.signinSilentCallback();
  }

  signoutRedirectCallback(): void {
    this._logoutChannel.postMessage(logoutMessage);
    this._userManager.signoutRedirectCallback().catch((error) => {
      console.log("error: " + JSON.stringify(error));
    });
  }

  async getAccessToken(): Promise<string | undefined> {
    const userManager = this._overrideUserManager ?? this._userManager;
    const user = await userManager.getUser();
    return user?.access_token;
  }

  async overrideUser(accessToken: TenantAccess): Promise<void> {
    let decodedToken = jwt_decode<Token>(accessToken.accessToken);

    this._overrideUserManager = new UserManager({
      authority: process.env.REACT_APP_AUTHORITY_URL,
      client_id: process.env.REACT_APP_CLIENT_ID,
      response_type: "code",
      scope: "openid profile",
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    });
    const overridenUser = new User({
      id_token: accessToken.idToken,
      session_state: accessToken.sessionState,
      access_token: accessToken.accessToken,
      refresh_token: accessToken.refreshToken,
      token_type: accessToken.tokenType,
      scope: accessToken.scope,
      profile: {
        sub: decodedToken.sub,
        azp: decodedToken.azp,
      } as Profile,
      expires_at: decodedToken.exp,
      state: {},
    });
    this._overrideUserManager.storeUser(overridenUser);
    this._overrideUserManager.startSilentRenew();
    this._newUserCallback?.(overridenUser);

    this._overrideUserManager.events.addAccessTokenExpiring(() => {
      if (this._overrideUserManager) {
        this._overrideUserManager.signinSilent().then(
          (newUser) => {
            this._newUserCallback?.(newUser);
          },
          async () => {
            await this.revertUserOverride();
          },
        );
      }
    });
  }

  async revertUserOverride(): Promise<void> {
    this._overrideUserManager?.stopSilentRenew();
    this._overrideUserManager?.removeUser();
    this._overrideUserManager = undefined;

    const user = await this._userManager.getUser();
    if (user) {
      this._newUserCallback?.(user);
    }
  }

  setNewUserCallback(callback: (user: User) => void): void {
    this._newUserCallback = callback;
  }

  async login(
    idpHint: string,
    prompt: string,
    emailAddress: string,
  ): Promise<void> {
    return this._userManager.signinRedirect({
      extraQueryParams: {
        kc_idp_hint: idpHint,
        prompt: prompt,
        login_hint: emailAddress,
      },
    });
  }

  async logout(): Promise<void> {
    await this.backchannelLogout();
  }

  private async backchannelLogout(): Promise<void> {
    try {
      const user = await this._userManager.getUser();
      if (!user) return;

      const logoutUrl = `${this._userManager.settings.authority}/protocol/openid-connect/logout`;
      const params = new URLSearchParams();
      params.append("client_id", this._userManager.settings.client_id ?? "");
      params.append("refresh_token", user.refresh_token ?? "");

      await axios.post(logoutUrl, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      await this._userManager.removeUser();
      window.location.href = "/login";
    } catch (error) {
      console.error("Failed to logout", error);
    }
  }
}

const authenticationService = new AuthenticationService();
export default authenticationService;
