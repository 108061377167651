import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Gateway from "features/gateway/domain/models/gateway";
import { ReadGatewaysResponse } from "features/gateway/domain/models/read-gateways-response";
import { useLazyReadGatewaysQuery } from "features/gateway/domain/reducers/gateway.reducer";
import useGatewayDetails, {
  GatewayDetailsHook,
} from "features/gateway/hooks/gateway-details-hook";
import useGatewayFilters, {
  IGatewayFiltersHook,
} from "features/gateway/hooks/gateway-filters-hook";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export interface IGatewaysHook {
  gatewaysResponse: ReadGatewaysResponse | undefined;
  gatewaysResponseIsLoading: boolean;
  gatewaysResponseIsSuccess: boolean;

  gatewayDetailsHook: GatewayDetailsHook;
  gatewayFilterHook: IGatewayFiltersHook;

  refresh: () => void;

  openDetailsInCreationModeAndRefresh: () => void;
  openDetailsInViewingModeAndRefresh: (gateway: Gateway) => void;
  openDetailsInEditingModeAndRefresh: (gateway: Gateway) => void;
}

const useGateways = (): IGatewaysHook => {
  const dispatch = useDispatch();

  const [gatewayIdToOpen, setGatewayIdToOpen] = useState<string>();
  const [shouldOpenDetails, setShouldOpenDetails] = useState<boolean>(false);

  const gatewayDetailsHook = useGatewayDetails();
  const gatewayFilterHook = useGatewayFilters();

  const location = useLocation();

  const [
    triggerReadGateways,
    {
      data: gatewaysResponse,
      error: gatewaysResponseError,
      isError: gatewaysResponseIsError,
      isLoading: gatewaysResponseIsLoading,
      isSuccess: gatewaysResponseIsSuccess,
    },
  ] = useLazyReadGatewaysQuery();

  useEffect(() => {
    triggerReadGateways(gatewayFilterHook.readGatewaysQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayFilterHook.readGatewaysQuery]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const gatewayId = queryParams.get("id");

    gatewayId && setGatewayDetailsToOpen(gatewayId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (gatewayDetailsHook.currentSelectedGateway) {
      const updatedGateway = gatewaysResponse?.gateways.find(
        (gateway) =>
          gateway.id === gatewayDetailsHook.currentSelectedGateway!.id,
      );

      updatedGateway &&
        gatewayDetailsHook.changeCurrentSelectedGateway(updatedGateway);
    }

    shouldOpenDetails && openDetailsById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysResponse]);

  useEffect(() => {
    if (gatewaysResponseIsError && gatewaysResponseError) {
      dispatch(
        setErrorMessage({
          error: gatewaysResponseError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewaysResponseIsError]);

  const refresh = () => {
    triggerReadGateways(gatewayFilterHook.readGatewaysQuery);
    gatewayFilterHook.loadMonitorFilters();
  };

  const openDetailsInCreationModeAndRefresh = () => {
    refresh();
    gatewayDetailsHook.openDetailsInCreationMode();
  };

  const openDetailsInEditingModeAndRefresh = (gateway: Gateway) => {
    refresh();
    gatewayDetailsHook.openDetailsInEditingMode(gateway);
  };

  const openDetailsInViewingModeAndRefresh = (gateway: Gateway) => {
    refresh();
    gatewayDetailsHook.openDetailsInViewingMode(gateway);
  };

  const setGatewayDetailsToOpen = (id: string): void => {
    gatewayFilterHook.submitSearch("");
    gatewayFilterHook.clearFilters();

    setGatewayIdToOpen(id);
    setShouldOpenDetails(true);
  };

  const openDetailsById = () => {
    const gateway = gatewaysResponse?.gateways.find(
      (gateway) => gateway.id === gatewayIdToOpen,
    );

    gateway && openDetailsInViewingModeAndRefresh(gateway);
    setGatewayIdToOpen(undefined);
    setShouldOpenDetails(false);
  };

  return {
    gatewaysResponse,
    gatewaysResponseIsLoading,
    gatewaysResponseIsSuccess,

    gatewayDetailsHook,
    gatewayFilterHook,

    refresh,

    openDetailsInCreationModeAndRefresh,
    openDetailsInViewingModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
  };
};

export default useGateways;
