import {
  CommentOutlined,
  NotificationsNoneOutlined,
} from "@mui/icons-material";
import BroadcastOnPersonalOutlinedIcon from "@mui/icons-material/BroadcastOnPersonalOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import EditResidentCommand from "features/residents/residents-settings/domain/models/edit-resident-command";
import { useEditResidentMutation } from "features/residents/residents-settings/domain/reducers/resident.reducer";
import DevicesList from "features/residents/residents-settings/views/resident-details/devices-list/devices-list.component";
import Header from "features/residents/residents-settings/views/resident-details/header.component";
import "features/residents/residents-settings/views/resident-details/resident-details.scss";
import ResidentScenarioRow from "features/residents/residents-settings/views/resident-scenario-row/resident-scenario-row";
import { ReactElement, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ApiResponse } from "redux-base/create-api-utils";

function ResidentDetails(): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [
    updateOrganisationUnitScenarios,
    { isLoading: updateOrganisationUnitScenarioIsLoading },
  ] = useEditResidentMutation();

  const { residentDetailsHook } = useResidentSettingsContextProvider();
  const organisationUnitScenariosWatch = residentDetailsHook.form.watch(
    "organisationUnitScenarios",
  );

  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowDevices, setShouldShowDevices] = useState<boolean>(false);
  const [shouldShowScenarios, setShouldShowScenarios] =
    useState<boolean>(false);
  const [shouldShowInformation, setShouldShowInformation] =
    useState<boolean>(false);

  const hasScenarios =
    organisationUnitScenariosWatch &&
    residentDetailsHook.currentResident &&
    residentDetailsHook.currentResident.organisationUnitScenarios.length > 0;
  const shouldShowInformationNote =
    residentDetailsHook.currentResident?.note ||
    residentDetailsHook.viewingMode !== "viewing";

  const accordionSummaryStyle = {
    padding: 0,
    margin: "12px 0 0 0",
    ".MuiAccordionSummary-content": {
      margin: "0",
    },
  };
  const accordionDetailsStyle = { padding: "0 0 8px 0", margin: 0 };

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (isScrolling && event.currentTarget.scrollTop === 0) {
      setIsScrolling(false);
    } else if (!isScrolling && event.currentTarget.scrollTop > 0) {
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    if (residentDetailsHook.currentResident) {
      residentDetailsHook.form.reset({
        organisationUnitId:
          residentDetailsHook.currentResident.organisationUnitId,
        organisationUnitScenarios:
          residentDetailsHook.currentResident.organisationUnitScenarios,
        devices: residentDetailsHook.currentResident.devices,
        note: residentDetailsHook.currentResident.note,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentDetailsHook.currentResident]);

  async function submitEdit(): Promise<ApiResponse<void>> {
    const editOrganisationUnitScenariosCommand: EditResidentCommand = {
      organisationUnitId:
        residentDetailsHook.currentResident?.organisationUnitId ?? "",
      editOrganisationUnitScenarios: residentDetailsHook.form.getValues(
        "organisationUnitScenarios",
      ),
      deviceIds: residentDetailsHook.form
        .getValues("devices")
        .map((device) => device.id),
      note: residentDetailsHook.form.getValues("note")?.trim(),
    };

    return (await updateOrganisationUnitScenarios(
      editOrganisationUnitScenariosCommand,
    )) as ApiResponse<void>;
  }

  async function submitForm(): Promise<void> {
    setIsSubmitting(true);

    await submitEdit();
    residentDetailsHook.setViewingMode("viewing");

    setIsSubmitting(false);
  }

  return (
    <div className="resident-details">
      <FormProvider {...residentDetailsHook.form}>
        <form onSubmit={residentDetailsHook.form.handleSubmit(submitForm)}>
          <Header
            headerText={
              residentDetailsHook.viewingMode !== "creation"
                ? residentDetailsHook.currentResident?.organisationUnitName
                : undefined
            }
            showEditButton={residentDetailsHook.currentResident !== undefined}
            hasErrors={!residentDetailsHook.form.formState.isValid}
            isScrolling={isScrolling}
            isSubmitting={isSubmitting}
            isLoading={updateOrganisationUnitScenarioIsLoading}
            onCancelButtonClicked={() => {
              residentDetailsHook.form.reset({
                organisationUnitId:
                  residentDetailsHook.currentResident?.organisationUnitId,
                organisationUnitScenarios:
                  residentDetailsHook.currentResident
                    ?.organisationUnitScenarios,
                devices: residentDetailsHook.currentResident?.devices,
                note: residentDetailsHook.currentResident?.note,
              });

              residentDetailsHook.setViewingMode("viewing");
            }}
          />
          <div
            className={`body ${
              residentDetailsHook.viewingMode === "viewing" && "is-viewing"
            }`}
            onScroll={handleScroll}
          >
            {updateOrganisationUnitScenarioIsLoading ? (
              <div className="organisation-settings-loader">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <Accordion expanded={shouldShowInformation} disableGutters>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={() =>
                          setShouldShowInformation(!shouldShowInformation)
                        }
                      />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                  >
                    <Grid
                      className="accordion-summary-content"
                      onClick={() =>
                        setShouldShowInformation(!shouldShowInformation)
                      }
                      onKeyDown={(e) =>
                        e.key === "Enter" &&
                        setShouldShowInformation(!shouldShowInformation)
                      }
                    >
                      <CommentOutlined
                        onClick={() =>
                          setShouldShowInformation(!shouldShowInformation)
                        }
                      />
                      <Typography variant="h5">
                        {t("details.information")}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailsStyle}>
                    <div className="information">
                      <div className="noteContainer">
                        <Typography variant="subtitle1">
                          {t("details.informationNote")}
                        </Typography>
                      </div>
                      {shouldShowInformationNote ? (
                        <ViewingModeController
                          viewingMode={residentDetailsHook.viewingMode}
                          name="note"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              inputProps={{
                                maxLength: 255,
                              }}
                              id="noteInput"
                              variant="outlined"
                              multiline
                              minRows={2}
                              maxRows={4}
                              className="form-field"
                              value={`${field.value ?? ""}`}
                            />
                          )}
                        />
                      ) : (
                        <Typography variant="subtitle1">
                          {t("details.noInformationNote")}
                        </Typography>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={shouldShowScenarios} disableGutters>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={() =>
                          setShouldShowScenarios(!shouldShowScenarios)
                        }
                      />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                  >
                    <Grid
                      className="accordion-summary-content"
                      onClick={() =>
                        setShouldShowScenarios(!shouldShowScenarios)
                      }
                      onKeyDown={(e) =>
                        e.key === "Enter" &&
                        setShouldShowScenarios(!shouldShowScenarios)
                      }
                    >
                      <NotificationsNoneOutlined
                        onClick={() =>
                          setShouldShowScenarios(!shouldShowScenarios)
                        }
                      />
                      <Typography variant="h5">
                        {t("details.scenariosDividerText")}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailsStyle}>
                    {hasScenarios ? (
                      [
                        ...residentDetailsHook.form.getValues(
                          "organisationUnitScenarios",
                        ),
                      ].map((organisationUnitScenario, index) => (
                        <ResidentScenarioRow
                          key={organisationUnitScenario.id}
                          index={index}
                          organisationUnitScenario={organisationUnitScenario}
                          originalOrganisationUnitScenario={
                            residentDetailsHook.currentResident
                              ?.organisationUnitScenarios[index]
                          }
                          viewingMode={residentDetailsHook.viewingMode}
                          intentionOutOfBedEnabled={
                            residentDetailsHook.currentResident
                              ?.intentionOutOfBedEnabled ?? false
                          }
                        />
                      ))
                    ) : (
                      <Typography className="noScenarios" variant="subtitle1">
                        {t("details.noScenarios")}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>

                <Accordion expanded={shouldShowDevices} disableGutters>
                  <AccordionSummary
                    sx={accordionSummaryStyle}
                    expandIcon={
                      <ExpandMoreIcon
                        onClick={() => setShouldShowDevices(!shouldShowDevices)}
                      />
                    }
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                  >
                    <Grid
                      className="accordion-summary-content"
                      onClick={() => setShouldShowDevices(!shouldShowDevices)}
                      onKeyDown={(e) =>
                        e.key === "Enter" &&
                        setShouldShowDevices(!shouldShowDevices)
                      }
                    >
                      <BroadcastOnPersonalOutlinedIcon
                        onClick={() => setShouldShowDevices(!shouldShowDevices)}
                      />
                      <Typography variant="h5">
                        {t("details.devicesDividerText")}
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailsStyle}>
                    <DevicesList
                      organisationUnitId={
                        residentDetailsHook.currentResident
                          ?.organisationUnitId ?? ""
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default ResidentDetails;
