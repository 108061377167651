import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

interface IProps {
  color?: string;
}

export default function Custom1Icon(props: Readonly<IProps>): ReactElement {
  const color = props.color ?? "#000000";

  return (
    <SvgIcon viewBox="0 0 24 24" fill="none">
      <path
        d="M16.25 18.2143C16.6642 18.2143 17 17.8785 17 17.4643V14.75C17 14.3358 16.6642 14 16.25 14V14C15.8358 14 15.5 14.3358 15.5 14.75V17.4643C15.5 17.8785 15.8358 18.2143 16.25 18.2143V18.2143ZM16.25 20.5C16.6642 20.5 17 20.1642 17 19.75V19.75C17 19.3358 16.6642 19 16.25 19V19C15.8358 19 15.5 19.3358 15.5 19.75V19.75C15.5 20.1642 15.8358 20.5 16.25 20.5V20.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.85 7.0625C1.60917 7.0625 1.40743 6.9845 1.2448 6.8285C1.0816 6.67304 1 6.48021 1 6.25V4.625C1 4.17813 1.1666 3.79571 1.4998 3.47775C1.83243 3.15925 2.2325 3 2.7 3H16.3C16.7675 3 17.1679 3.15925 17.5011 3.47775C17.8337 3.79571 18 4.17813 18 4.625V6.25C18 6.48021 17.9184 6.67304 17.7552 6.8285C17.5926 6.9845 17.3908 7.0625 17.15 7.0625C16.9092 7.0625 16.7074 6.9845 16.5448 6.8285C16.3816 6.67304 16.3 6.48021 16.3 6.25V4.625H2.7V6.25C2.7 6.48021 2.61868 6.67304 2.45605 6.8285C2.29285 6.9845 2.09083 7.0625 1.85 7.0625ZM2.7 16C2.2325 16 1.83243 15.841 1.4998 15.5231C1.1666 15.2046 1 14.8219 1 14.375V12.75C1 12.5198 1.0816 12.3267 1.2448 12.1707C1.40743 12.0152 1.60917 11.9375 1.85 11.9375C2.09083 11.9375 2.29285 12.0152 2.45605 12.1707C2.61868 12.3267 2.7 12.5198 2.7 12.75V14.375H11.572L10.6297 16H2.7ZM15.5177 8.6875H13.4313L11.965 5.88438C11.8092 5.61354 11.5542 5.47813 11.2 5.47813C10.8458 5.47813 10.5908 5.61354 10.435 5.88438L7.8 10.9219L6.865 9.13438C6.79417 8.98542 6.68792 8.87356 6.54625 8.79881C6.40458 8.7246 6.25583 8.6875 6.1 8.6875H1.85C1.60917 8.6875 1.40743 8.76523 1.2448 8.92069C1.0816 9.07669 1 9.26979 1 9.5C1 9.73021 1.0816 9.92304 1.2448 10.0785C1.40743 10.2345 1.60917 10.3125 1.85 10.3125H5.56875L7.035 13.1156C7.10583 13.2646 7.21208 13.3764 7.35375 13.4512C7.49542 13.5254 7.64417 13.5625 7.8 13.5625C7.95583 13.5625 8.10458 13.5254 8.24625 13.4512C8.38792 13.3764 8.49417 13.2646 8.565 13.1156L11.2 8.07812L12.135 9.86562C12.2058 10.0146 12.3121 10.1264 12.4538 10.2012C12.5954 10.2754 12.7442 10.3125 12.9 10.3125H13.9278L14.2215 9.80608C14.5381 9.2601 15.001 8.88823 15.5177 8.6875Z"
        fill={color}
      />
      <path
        d="M9.87077 20.4984L15.6056 10.6087C16.0054 9.91933 17.0115 9.95165 17.3662 10.6653L22.2817 20.5549C22.6121 21.2197 22.1286 22 21.3862 22H10.7358C9.96521 22 9.48419 21.165 9.87077 20.4984Z"
        stroke={color}
        strokeWidth="1.2"
        fill="none"
      />
    </SvgIcon>
  );
}
