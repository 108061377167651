import { useState } from "react";
import { useTranslation } from "react-i18next";

export interface IConfirmationPopupHook {
  isPopupVisible: boolean;
  title: string;
  text: string;
  confirmText: string;
  cancelText: string;

  setUnsavedChanges: (unsavedChanges: boolean) => void;
  showUnsavedChangesPopup: (action: () => void) => void;
  showConfirmationPopup: (
    action: () => void,
    title: string,
    text: string,
    confirmText?: string,
    cancelText?: string,
  ) => void;
  confirm: (confirm: boolean) => void;
}

const useConfirmationPopupHook = (): IConfirmationPopupHook => {
  const { t } = useTranslation("confirmationPopup");

  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [confirmText, setConfirmText] = useState<string>("");
  const [cancelText, setCancelText] = useState<string>("");

  const [confirmationAction, setConfirmationAction] =
    useState<() => () => void>();

  const changeUnsavedChanges = (shouldShow: boolean) =>
    setUnsavedChanges(shouldShow);

  const showUnsavedChangesPopup = (action: () => void) => {
    if (unsavedChanges) {
      setConfirmationAction(() => action);
      setIsPopupVisible(true);
      setText(t("body"));
      setTitle(t("title"));
      setConfirmText(t("primaryButtonText"));
      setCancelText(t("secondaryButtonText"));
      return;
    }

    action();
  };

  const showConfirmationPopup = (
    action: () => void,
    title: string,
    text: string,
    confirmText?: string,
    cancelText?: string,
  ) => {
    setConfirmationAction(() => action);
    setIsPopupVisible(true);
    setText(text);
    setTitle(title);
    setConfirmText(confirmText ?? t("primaryButtonText"));
    setCancelText(cancelText ?? t("secondaryButtonText"));
  };

  const confirm = (confirm: boolean) => {
    setIsPopupVisible(false);

    if (confirm && confirmationAction) {
      confirmationAction();
      setConfirmationAction(undefined);
    }
  };

  return {
    isPopupVisible,
    title,
    text,
    confirmText,
    cancelText,

    setUnsavedChanges: changeUnsavedChanges,
    showUnsavedChangesPopup,
    showConfirmationPopup,
    confirm,
  };
};

export default useConfirmationPopupHook;
