import { useEffect } from "react";

function useOutsideAlerter(ref: any, functionToExecute: () => void) {
  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (ref.current && !ref.current.contains(event.target)) {
        functionToExecute();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [functionToExecute, ref]);
}

export default useOutsideAlerter;
