import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./bread-crumb.component.scss";
import { Tooltip, Typography, Zoom } from "@mui/material";
import React, { ReactElement } from "react";

interface IProps {
  showEllipsis: boolean;
  startIcon?: ReactElement;
  children: ReactElement | Array<ReactElement>;
  allElementNames: string | Array<string>;
}

function BreadCrumb(props: Readonly<IProps>): ReactElement {
  function getBreadCrumbs(): Array<ReactElement> {
    let breadCrumbs = new Array<ReactElement>();

    if (props.showEllipsis) {
      breadCrumbs.push(<Typography variant="body2">...</Typography>);
    }

    if (Array.isArray(props.children)) {
      breadCrumbs =
        props.showEllipsis && props.children.length > 2
          ? breadCrumbs.concat(props.children.slice(props.children.length - 2))
          : breadCrumbs.concat(props.children);
    } else {
      breadCrumbs.push(props.children);
    }

    return breadCrumbs;
  }

  function getTooltipElement(): ReactElement {
    const allElementNames = Array.isArray(props.allElementNames)
      ? props.allElementNames
      : [props.allElementNames];

    return (
      <div className="tooltip">
        {allElementNames.map((name, index, { length }) => (
          <React.Fragment key={`bread_crumb_tooltip_${name}`}>
            {
              <Typography
                className="tooltip-typography"
                data-testid={`breadcrumb-tooltip-child-${index}`}
                key={`Breadcrumb_Tooltip_Child_${name}`}
                variant="caption"
              >
                {name}
              </Typography>
            }
            {index + 1 < length && (
              <ChevronRightIcon className="chevron-right-icon" />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  return (
    <Tooltip
      title={getTooltipElement()}
      followCursor
      TransitionComponent={Zoom}
    >
      <div className="bread-crumb-container">
        {props.startIcon && (
          <div className="start-icon-container">{props.startIcon}</div>
        )}
        {getBreadCrumbs().map((child, index, { length }) => (
          <React.Fragment key={`bread_crumb_${index}`}>
            {child}
            {index + 1 < length && (
              <ChevronRightIcon className="chevron-right-icon" />
            )}
          </React.Fragment>
        ))}
      </div>
    </Tooltip>
  );
}

export default BreadCrumb;
