import { useEffect, useState } from "react";
import {
  useUpsertMalfunctionNotificationsMutation,
  useReadMalfunctionNotificationsQuery,
} from "../domain/reducers/malfunction-notifications.reducer";
import MalfunctionNotification from "../domain/models/malfunction-notification";
import MalfunctionNotificationCommand from "../domain/models/malfunction-notification-command";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";

export interface IMalfunctionNotificationSettingsHook {
  malfunctionNotifications: MalfunctionNotification[];
  malfunctionNotificationIsLoading: boolean;

  handleSave: (command: MalfunctionNotificationCommand) => void;
}

const useMalfunctionNotificationSettings =
  (): IMalfunctionNotificationSettingsHook => {
    const dispatch = useDispatch();
    const [malfunctionNotifications, setMalfunctionNotifications] = useState<
      MalfunctionNotification[]
    >([]);

    const {
      data: malfunctionNotificationesponse,
      error: malfunctionNotificationError,
      isError: malfunctionNotificationIsError,
      isLoading: malfunctionNotificationIsLoading,
    } = useReadMalfunctionNotificationsQuery();

    const [
      upsertMalfunctionNotifications,
      {
        error: upsertMalfunctionNotificationsError,
        isError: upsertMalfunctionNotificationsIsError,
      },
    ] = useUpsertMalfunctionNotificationsMutation();

    const handleSave = (command: MalfunctionNotificationCommand) => {
      upsertMalfunctionNotifications(command);
    };

    useEffect(() => {
      malfunctionNotificationesponse?.malfunctionNotifications &&
        setMalfunctionNotifications(
          malfunctionNotificationesponse.malfunctionNotifications,
        );
    }, [malfunctionNotificationesponse]);

    useEffect(() => {
      if (
        malfunctionNotificationIsError ||
        upsertMalfunctionNotificationsIsError
      ) {
        const error =
          malfunctionNotificationError || upsertMalfunctionNotificationsError;

        dispatch(setErrorMessage({ error }));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [malfunctionNotificationIsError, upsertMalfunctionNotificationsIsError]);

    return {
      malfunctionNotifications,
      malfunctionNotificationIsLoading,

      handleSave,
    };
  };

export default useMalfunctionNotificationSettings;
