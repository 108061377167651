import Gateway from "features/gateway/domain/models/gateway";

export interface System {
  id?: string;
  type: SystemType;
  name: string;
  note?: string;
  macAddress?: string;
  audioAvailable?: boolean;
  lastCommunicationDate?: Date;
  organisationUnitId?: string;
  gateway: Gateway;
}

export enum SystemType {
  Unknown = "",
  IS32 = "IS32",
}
