import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { useAxiosClientsContextProvider } from "shared/axios/axios-clients-provider";

const baseUrl = "/api/v1";

const onReconnect = async (axiosInstance: AxiosInstance) => {
  try {
    return await axiosInstance.post(
      `${baseUrl}/SubscriptionDevice/NotifyReconnected`,
    );
  } catch (error) {
    throw new Error("Could not call onReconnect", { cause: error });
  }
};

export const useLazyOnReconnectQuery = () => {
  const { notificationProcessorInstance } = useAxiosClientsContextProvider();
  return useQuery({
    queryKey: ["OnReconnect"],
    queryFn: () => onReconnect(notificationProcessorInstance),
    enabled: false,
    refetchOnWindowFocus: false,
  });
};
