import {
  IDeviceDetailsState,
  useDeviceDetailsState,
} from "features/device/device-details/providers/device-details-provider/hooks/states/device-details-state";
import { ViewingMode } from "utils/viewing-utils";

export interface IDeviceDetailsProviderHooks {
  deviceDetailsState: IDeviceDetailsState;
}

export const useDeviceDetailsProviderHooks = (
  deviceId: string | undefined,
  initialViewingMode: ViewingMode,
): IDeviceDetailsProviderHooks => {
  return {
    deviceDetailsState: useDeviceDetailsState(deviceId, initialViewingMode),
  };
};
