import React, { ReactElement, ReactNode } from "react";
import useAxiosClients, { IAxiosClientsHook } from "./axios-clients-hook";

const AxiosClientsContext = React.createContext<IAxiosClientsHook>({} as never);

interface IProps {
  children: ReactNode;
}

export const AxiosClientsProvider = (props: Readonly<IProps>): ReactElement => (
  <AxiosClientsContext.Provider value={useAxiosClients()}>
    {props.children}
  </AxiosClientsContext.Provider>
);

export const useAxiosClientsContextProvider = (): IAxiosClientsHook =>
  React.useContext<IAxiosClientsHook>(AxiosClientsContext);
