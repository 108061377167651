import { createSlice } from "@reduxjs/toolkit";

export interface ConfirmationPopupState {
  showConfirmation: boolean;
  shouldShowConfirmation: boolean;
  confirmActionCancelChanges?: () => void;
  confirmActionNextAction?: () => void;
}

const initialState = {
  showConfirmation: false,
  shouldShowConfirmation: false,
} as ConfirmationPopupState;

const confirmationPopupSlice = createSlice({
  name: "confirmationPopup",
  initialState: initialState,
  reducers: {
    setShouldShowConfirmation: (
      state,
      body: { payload: { shouldShowConfirmation: boolean } },
    ) => {
      state.shouldShowConfirmation = body.payload.shouldShowConfirmation;
    },
    confirmationActionExecuted: (state) => {
      state.shouldShowConfirmation = false;
      state.showConfirmation = false;
      state.confirmActionCancelChanges = undefined;
      state.confirmActionNextAction = undefined;
    },
    showConfirmationPopup: (
      state,
      body: {
        payload: {
          confirmActionNextAction?: () => void;
          showInstantly?: boolean;
        };
      },
    ) => {
      if (body.payload.showInstantly) {
        state.shouldShowConfirmation = true;
      }
      state.showConfirmation = true;
      state.confirmActionNextAction = body.payload.confirmActionNextAction;
    },
    closeConfirmationPopup: (state) => {
      state.showConfirmation = false;
      state.confirmActionNextAction = undefined;
    },
    setConfirmActionCancelChanges: (
      state,
      body: {
        payload: {
          confirmActionCancelChanges?: () => void;
        };
      },
    ) => {
      state.confirmActionCancelChanges =
        body.payload.confirmActionCancelChanges;
    },
  },
});

export const {
  showConfirmationPopup,
  closeConfirmationPopup,
  setShouldShowConfirmation,
  confirmationActionExecuted,
  setConfirmActionCancelChanges,
} = confirmationPopupSlice.actions;
export default confirmationPopupSlice.reducer;
