import Resident from "../domain/models/resident";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ErrorOption, UseFormReturn, useForm } from "react-hook-form";
import OrganisationUnitScenariosResponse from "../domain/models/organisation-unit-scenario-response";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";
import { ViewingMode } from "utils/viewing-utils";
import { useDispatch } from "react-redux";
import {
  setConfirmActionCancelChanges,
  setShouldShowConfirmation,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";

export interface IResidentDetailsHook {
  form: UseFormReturn<OrganisationUnitScenariosResponse, any>;

  currentResident?: Resident;

  viewingMode: ViewingMode;
  setViewingMode: Dispatch<SetStateAction<ViewingMode>>;

  setValidationErrorForScenario: (value: ErrorOption, index: number) => void;
  clearValidationErrorForScenario: (index: number) => void;
  scenarioConfigurationUpdated: (newConfiguration: any, index: number) => void;

  changeCurrentResident: (resident: Resident) => void;

  openDetails: (resident: Resident) => void;
  closeDetails: () => void;
}

const useResidentDetails = (): IResidentDetailsHook => {
  const dispatch = useDispatch();
  const [viewingMode, setViewingMode] = useState<ViewingMode>("none");
  const [currentResident, setCurrentResident] = useState<
    Resident | undefined
  >();

  const { showUnsavedChangesPopup, setUnsavedChanges } =
    useConfirmationPopupContextProvider();

  function setValidationErrorForScenario(
    value: ErrorOption,
    index: number,
  ): void {
    form.setError(`organisationUnitScenarios.${index}`, value);
  }

  function clearValidationErrorForScenario(index: number): void {
    form.clearErrors(`organisationUnitScenarios.${index}`);
    form.trigger(`organisationUnitScenarios.${index}`);
  }

  const defaultFormValues = {
    organisationUnitId: "",
    organisationUnitScenarios: [],
    devices: [],
    note: "",
  };
  const form = useForm<OrganisationUnitScenariosResponse>({
    mode: "onBlur",
    defaultValues: defaultFormValues,
  });

  function scenarioConfigurationUpdated(
    newConfiguration: any,
    index: number,
  ): void {
    form.setValue(
      `organisationUnitScenarios.${index}.configuration`,
      JSON.stringify(newConfiguration),
      { shouldDirty: true },
    );
  }

  function changeCurrentResident(resident: Resident) {
    setCurrentResident(resident);
  }

  function openDetails(resident: Resident) {
    showUnsavedChangesPopup(() => {
      if (currentResident !== resident) {
        setCurrentResident(resident);
        setViewingMode("viewing");
      } else {
        setCurrentResident(undefined);
        closeDetails();
      }
    });
  }

  function closeDetails() {
    setViewingMode("none");
    setCurrentResident(undefined);
    setUnsavedChanges(false);
  }

  useEffect(() => {
    const isDirty = !form.formState.isSubmitted && form.formState.isDirty;
    setUnsavedChanges(isDirty);
    if (isDirty) {
      dispatch(
        setConfirmActionCancelChanges({
          confirmActionCancelChanges: () => {
            closeDetails();
          },
        }),
      );
    }

    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty, form.formState.isSubmitted]);

  return {
    form,

    currentResident,
    viewingMode,
    setViewingMode,

    setValidationErrorForScenario,
    clearValidationErrorForScenario,
    scenarioConfigurationUpdated,

    changeCurrentResident,

    openDetails,
    closeDetails,
  };
};

export default useResidentDetails;
