import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadHistoryResponse from "../models/read-history-response";
import ReadHistoryCommand from "../models/read-history-command";
import ReadAlarmResponse from "../models/read-alarm-response";
import ReadEmergencyAlarmResponse from "../models/read-emergency-alarm-response";

const baseUrl = "/api/v1/history";

export const historyApi = createApi({
  reducerPath: "history",
  baseQuery,
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readHistory: build.query<ReadHistoryResponse, ReadHistoryCommand>({
      query: (command) => ({
        url: `${baseUrl}/ReadHistory`,
        method: "POST",
        body: command,
      }),
    }),
    readAlarm: build.query<ReadAlarmResponse, string>({
      query: (alarmId) => ({
        url: `${baseUrl}/ReadAlarm/${alarmId}`,
        method: "GET",
      }),
    }),
    readEmergencyAlarm: build.query<ReadEmergencyAlarmResponse, string>({
      query: (emergencyAlarmId) => ({
        url: `${baseUrl}/ReadEmergencyAlarm/${emergencyAlarmId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyReadHistoryQuery,
  useLazyReadAlarmQuery,
  useLazyReadEmergencyAlarmQuery,
} = historyApi;
