import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadResidentsResponse, {
  deserialize,
} from "../models/read-residents-response";
import ReadResidentsQuery from "../models/read-residents-query";
import OrganisationUnitScenariosResponse from "../models/organisation-unit-scenario-response";
import EditResidentCommand from "../models/edit-resident-command";
import { serialize } from "../models/organisation-unit-scenario";
import ReadBulkAvailableScenariosResponse from "../models/read-bulk-available-scenarios-response";
import ReadBulkAvailableScenariosQuery from "../models/read-bulk-available-scenarios-query";
import { BulkUpdateOrganisationUnitScenarioIsActiveCommand } from "../models/bulk-update-organisation-unit-scenario-is-active";

const residentBaseUrl = "/api/v1/resident";
const scenarioBaseUrl = "/api/v1/scenario";

export const residentApi = createApi({
  reducerPath: "resident",
  baseQuery,
  tagTypes: [
    "ReadResidents",
    "OrganisationUnitScenarios",
    "ReadResident",
    "ReadBulkAvailableScenarios",
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readResidents: build.query<ReadResidentsResponse, ReadResidentsQuery>({
      query: (body) => ({
        url: `${residentBaseUrl}/ReadResidents`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ReadResidentsResponse) =>
        deserialize(response),
      providesTags: ["ReadResidents"],
    }),
    getOrganisationUnitScenarios: build.query<
      OrganisationUnitScenariosResponse,
      string
    >({
      query: (id) => ({
        url: `${scenarioBaseUrl}/GetOrganisationUnitScenarios/${id}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: "OrganisationUnitScenarios",
                id: result.organisationUnitId,
              },
            ]
          : [],
    }),
    editResident: build.mutation<EditResidentCommand, EditResidentCommand>({
      query: (body) => {
        body.editOrganisationUnitScenarios =
          body.editOrganisationUnitScenarios.map((organisationUnitScenario) =>
            serialize(organisationUnitScenario),
          );
        return {
          url: `${residentBaseUrl}/EditResident`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: [
        "OrganisationUnitScenarios",
        "ReadResidents",
        "ReadResident",
      ],
    }),
    readBulkAvailableScenarios: build.query<
      ReadBulkAvailableScenariosResponse,
      ReadBulkAvailableScenariosQuery
    >({
      query: (body) => ({
        url: `${residentBaseUrl}/ReadBulkAvailableScenarios`,
        method: "POST",
        body,
      }),
    }),
    updateBulkScenarioIsActive: build.mutation<
      BulkUpdateOrganisationUnitScenarioIsActiveCommand,
      BulkUpdateOrganisationUnitScenarioIsActiveCommand
    >({
      query: (body) => {
        return {
          url: `${residentBaseUrl}/BulkUpdateOrganisationUnitScenarioIsActive`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["ReadResidents"],
    }),
  }),
});

export const {
  useLazyGetOrganisationUnitScenariosQuery,
  useEditResidentMutation,
  useLazyReadResidentsQuery,
  useLazyReadBulkAvailableScenariosQuery,
  useUpdateBulkScenarioIsActiveMutation,
} = residentApi;
