import PageHeader from "components/page-header/page-header";
import { useTranslation } from "react-i18next";
import SettingsNavigation from "../../settings/views/settings-navigation";
import { FormControlLabel, Switch } from "@mui/material";
import "./connection.scss";
import { useConnectionTypeContextProvider } from "../context/connectionType-provider";
import { ConnectionType } from "../domain/models/connection-type";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";

const Connection = () => {
  const { t } = useTranslation("connectionType");

  const {
    isLoading,
    connectionType,
    updateConnectionType: handleConnectionType,
  } = useConnectionTypeContextProvider();

  const switches = Object.entries(ConnectionType).map(([key, value]) => {
    const isChecked = connectionType?.connectionType === value;
    const label = t(`${key}Label`);

    return (
      <FormControlLabel
        key={key}
        control={
          <Switch
            checked={isChecked}
            type="checkbox"
            onChange={(_, __) =>
              handleConnectionType({ connectionType: value })
            }
          />
        }
        label={label}
      />
    );
  });

  return (
    <>
      <PageHeader
        title={t("pageHeaderTitle")}
        navigationComponent={<SettingsNavigation />}
      />
      {isLoading && <LoadingIndicator />}
      {connectionType && (
        <div className="connectionType-view-container">{switches}</div>
      )}
    </>
  );
};

export default Connection;
