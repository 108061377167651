import PopUp from "components/pop-up/pop-up.component";
import { authenticationActions } from "features/authentication/domain/reducers/authentication.reducer";
import { useAuth } from "features/authentication/providers/authentication.provider";
import routes from "features/routing/routes";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-base/store";

export default function RedirectToRoot(): ReactElement {
  const { hasAtLeastOnePermissionOf, user } = useAuth();
  const navigate = useNavigate();
  const [showNoPermissionsPopup, setShowNoPermissionsPopup] = useState(false);
  const { t } = useTranslation("noPermissions");
  const dispatch = useAppDispatch();

  async function logout(): Promise<void> {
    await dispatch(authenticationActions.logout());
  }

  useEffect(() => {
    let rootPath: string | undefined;

    for (const routeSettings of Object.values(routes)) {
      if (hasAtLeastOnePermissionOf(routeSettings.atLeastOnePermissionOf)) {
        rootPath = routeSettings.path;
        break;
      }
    }

    if (rootPath) {
      navigate(rootPath, { replace: true });
    } else {
      setShowNoPermissionsPopup(true);
    }
  }, [user, hasAtLeastOnePermissionOf, navigate]);

  if (showNoPermissionsPopup) {
    return (
      <PopUp
        isOpen
        handleOnClose={() => {}}
        title={t("noPersmissionsPopup.title")}
        body={t("noPersmissionsPopup.body")}
        primaryButtonText={t("noPersmissionsPopup.logoutButton")}
        primaryButtonAction={logout}
      />
    );
  }

  return <></>;
}
