import React, { ReactElement, ReactNode } from "react";
import useSignalR, { ISignalRHook } from "./signalr-hook";

const SignalRContext = React.createContext<ISignalRHook>({} as never);

interface IProps {
  children: ReactNode;
}

export const SignalRProvider = (props: Readonly<IProps>): ReactElement => (
  <SignalRContext.Provider value={useSignalR()}>
    {props.children}
  </SignalRContext.Provider>
);

export const useSignalRContextProvider = (): ISignalRHook =>
  React.useContext<ISignalRHook>(SignalRContext);
