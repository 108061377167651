import React, { ReactElement, ReactNode } from "react";
import useGateways, {
  IGatewaysHook,
} from "features/gateway/context/gateway-hook";

const GatewayContext = React.createContext<IGatewaysHook>({} as never);

interface Props {
  children: ReactNode;
}

export const GatewaysProvider = ({
  children,
}: Readonly<Props>): ReactElement => {
  return (
    <GatewayContext.Provider value={useGateways()}>
      {children}
    </GatewayContext.Provider>
  );
};

export const useGatewaysContextProvider = (): IGatewaysHook =>
  React.useContext<IGatewaysHook>(GatewayContext);
