import FilterListIcon from "@mui/icons-material/FilterList";
import { Typography } from "@mui/material";
import FilterChip from "components/filter-chip/filter-chip.component";
import MultiValueFilterChip from "components/multi-value-filter-chip/multi-value-filter-chip.component";
import FilterChipOrganisationUnit from "components/organisation-unit-filter-chip/filter-chip-organisation-unit.component";
import SearchBar from "components/search/searchbar";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { OrganisationUnitTreeProvider } from "features/organisation/providers/organisation-unit-tree-provider";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import { FilterValueType } from "features/residents/residents-settings/domain/models/resident-settings-filter-value";
import "features/residents/residents-settings/views/resident-filters/resident-filters.scss";
import KeyValuePair from "models/key-value-pair";
import { ScenarioType } from "models/scenario-type";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

const ResidentFilters = (): JSX.Element => {
  const { t } = useTranslation("residentsSettings");

  const { residentSettingsFiltersHook } = useResidentSettingsContextProvider();

  const scenarioFilterValues: KeyValuePair[] = [
    {
      key: ScenarioType.CurrentLocation,
      value: t("filterValues.scenario.currentLocation"),
    },
    {
      key: ScenarioType.Bed,
      value: t("filterValues.scenario.bed"),
    },
    {
      key: ScenarioType.Room,
      value: t("filterValues.scenario.room"),
    },
    {
      key: ScenarioType.Bathroom,
      value: t("filterValues.scenario.bathroom"),
    },
    {
      key: ScenarioType.Intruder,
      value: t("filterValues.scenario.intruder"),
    },
    {
      key: ScenarioType.Door,
      value: t("filterValues.scenario.door"),
    },
    {
      key: ScenarioType.Acoustic,
      value: t("filterValues.scenario.acoustic"),
    },
    {
      key: ScenarioType.Video,
      value: t("filterValues.scenario.video"),
    },
    {
      key: ScenarioType.None,
      value: t("filterValues.scenario.none"),
    },
  ];

  const linkedDeviceFilterValues: KeyValuePair[] = [
    {
      key: true.toString(),
      value: t("filterValues.linkedDevice.linked"),
    },
    {
      key: false.toString(),
      value: t("filterValues.linkedDevice.notLinked"),
    },
  ];

  return (
    <div className="resident-filters">
      <SearchBar
        onSearchValueChanged={residentSettingsFiltersHook.changeSearchBarValue}
        value={residentSettingsFiltersHook.searchBarValue}
        requireUserConfirmation
      />
      <FilterListIcon />
      <OrganisationUnitTreeProvider.AccessRightsOnly
        excludeBranchesWithoutResidents
      >
        <FilterChipOrganisationUnit
          onOptionsSelected={(keys: SelectedOrganisationTreeNode[]) => {
            residentSettingsFiltersHook.changeSelectedOrganisationUnits(keys);
          }}
          selectedOptions={
            residentSettingsFiltersHook.selectedOrganisationUnits
          }
          placeHolder={t("filters.organisationUnit")}
        />
      </OrganisationUnitTreeProvider.AccessRightsOnly>
      <MultiValueFilterChip
        options={scenarioFilterValues}
        onOptionsSelected={(keys: string[]) =>
          residentSettingsFiltersHook.selectOptions(
            FilterValueType.Scenario,
            keys,
          )
        }
        placeHolder={t("filters.scenario")}
        selectedOptions={scenarioFilterValues.filter((x) =>
          residentSettingsFiltersHook.activeFilters
            .find((x) => x.filterValueType === FilterValueType.Scenario)
            ?.values.includes(x.key),
        )}
      />
      <FilterChip
        options={linkedDeviceFilterValues}
        onOptionSelected={(key) =>
          residentSettingsFiltersHook.selectOptions(
            FilterValueType.LinkedDevice,
            key ? [key] : [],
          )
        }
        placeHolder={t("filters.linkedDevice")}
        initialSelectedOption={linkedDeviceFilterValues.find((x) =>
          residentSettingsFiltersHook.activeFilters
            .find((x) => x.filterValueType === FilterValueType.LinkedDevice)
            ?.values.includes(x.key),
        )}
      />
      <div className="clear-filters">
        <Typography
          variant="body1"
          color={Constants.Colors.primary}
          onClick={residentSettingsFiltersHook.clearFilters}
        >
          {t("filters.clearFilters")}
        </Typography>
      </div>
    </div>
  );
};

export default ResidentFilters;
