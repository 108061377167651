import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid, Typography } from "@mui/material";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import PopUp from "components/pop-up/pop-up.component";
import { useBackchannelLogoutMutation } from "features/authentication/domain/reducers/authentication.reducer";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import UserInformation from "features/menu/views/user-information.component";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-base/store";
import "./secondary-menu.components.scss";
import useSecondaryMenuItems from "./use-secondary-menu-items";
import authenticationService from "features/authentication/services/authentication.service";

function SecondaryMenu(): ReactElement {
  const [isMenuOpening, setIsMenuOpening] = useState<boolean>(false);
  const [isMenuClosing, setIsMenuClosing] = useState<boolean>(false);
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState<boolean>(false);

  const menuItems = useSecondaryMenuItems();
  const { t } = useTranslation("menu");
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile } = useAuth();
  const [backchannelLogout] = useBackchannelLogoutMutation();

  function goToRoute(route: string) {
    dispatch(
      showConfirmationPopup({
        confirmActionNextAction: () => {
          closeMenu();
          navigate(route);
        },
      }),
    );
  }

  function closeMenu() {
    if (isMenuOpening) {
      setIsMenuClosing(true);
      setIsMenuOpening(false);
    }
  }

  function animationEnded() {
    if (!isMenuOpening && isMenuClosing) {
      setIsMenuClosing(false);
    }
  }

  async function logout(): Promise<void> {
    backchannelLogout()
      .then(() => {
        authenticationService.logout();
      })
      .catch(() => {
        authenticationService.logout();
      });
  }

  return (
    <>
      <OutsideAlerter functionToExecute={closeMenu}>
        <Box
          className="secondary-menu"
          data-testid="secondary-menu"
          sx={{ zIndex: (theme) => theme.zIndex.modal }}
        >
          <Grid
            container
            direction={"row"}
            paddingBottom="16px"
            justifyContent={"space-between"}
            onClick={() => setIsMenuOpening(true)}
          >
            <Typography variant="h5" className="tenant">
              {profile?.customerRealmName}
            </Typography>
            <MoreVertIcon className="icon-color" />
          </Grid>
          <UserInformation onClick={() => setIsMenuOpening(true)} />
        </Box>
        <>
          {(isMenuOpening || isMenuClosing) && (
            <Box
              onAnimationEnd={() => animationEnded()}
              className="secondary-menu-opened"
              style={
                isMenuClosing
                  ? {
                      animation: "0.5s linear 0s running slideout",
                      maxHeight: 0,
                    }
                  : {
                      animation: "0.5s linear 0s running slidein",
                      maxHeight: "100%",
                    }
              }
              sx={{ zIndex: (theme) => theme.zIndex.modal }}
            >
              {menuItems.map((item, _) => (
                <Grid
                  data-testid={`menu-item-${item.titleTranslationKey}`}
                  key={item.titleTranslationKey}
                  container
                  direction={"row"}
                  padding="16px 8px"
                  className="menu-item"
                  onClick={() => goToRoute(item.route)}
                >
                  <Typography variant="h5">
                    {t(item.titleTranslationKey)}
                  </Typography>
                </Grid>
              ))}

              <Grid
                data-testid="menu-item-logout"
                container
                direction={"row"}
                padding="16px 8px"
                spacing={2}
                className="logout"
                onClick={() => setIsLogoutPopupOpen(true)}
                onKeyDown={(e) =>
                  e.key === "Enter" && setIsLogoutPopupOpen(true)
                }
              >
                <Grid item>
                  <LogoutRoundedIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h5">{t("logout")}</Typography>
                </Grid>
              </Grid>

              <Grid container direction={"row"}>
                <UserInformation onClick={closeMenu} />
              </Grid>
            </Box>
          )}
        </>
      </OutsideAlerter>
      <PopUp
        isOpen={isLogoutPopupOpen}
        title={t("logoutPopup.title")}
        body={t("logoutPopup.body")}
        primaryButtonText={t("logoutPopup.primaryButtonText")}
        secondaryButtonText={t("logoutPopup.secondaryButtonText")}
        handleOnClose={() => setIsLogoutPopupOpen(false)}
        secondaryButtonAction={() => setIsLogoutPopupOpen(false)}
        primaryButtonAction={logout}
      />
    </>
  );
}

export default SecondaryMenu;
