import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import Role from "features/role/domain/models/role";

const baseUrl = "/api/v1/Role";

export const roleApi = createApi({
  reducerPath: "role",
  baseQuery,
  tagTypes: ["Role"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    createRole: build.mutation<Role, Role>({
      query: (body) => ({
        url: `${baseUrl}/NewRole`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Role"],
    }),
    getRoles: build.query<Role[], void>({
      query: () => ({
        url: `${baseUrl}/GetRoles`,
        method: "GET",
      }),
      providesTags: ["Role"],
    }),
    readRolesForTemporaryAccess: build.query<Role[], void>({
      query: () => ({
        url: `${baseUrl}/ReadRolesForTemporaryAccess`,
        method: "GET",
      }),
    }),
    updateRole: build.mutation<Role, { originalIdpId: string; role: Role }>({
      query: ({ originalIdpId, role }) => ({
        url: `${baseUrl}/UpdateRole`,
        method: "PUT",
        body: { ...role, originalIdpId },
      }),
      invalidatesTags: ["Role"],
    }),
    deleteRole: build.mutation<void, string>({
      query: (idpId) => ({
        url: `${baseUrl}/DeleteRole/${idpId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),
  }),
});

export const {
  useCreateRoleMutation,
  useGetRolesQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useLazyReadRolesForTemporaryAccessQuery,
} = roleApi;
