import TreeView from "@mui/lab/TreeView";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import { useAppDispatch } from "redux-base/store";
import "./organisation-tree.component.scss";
import OrganisationUnitTreeItem from "features/organisation/views/organisation-tree-item/organisation-unit-tree-item.component";
import {
  BedRounded,
  NotificationImportantOutlined,
  VisibilityOffOutlined,
  ChecklistRounded,
  WarningAmberRounded,
} from "@mui/icons-material";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import Badge from "@mui/material/Badge";
import { SxProps, Theme } from "@mui/material";
import Constants from "style/constants";
import { FaultType } from "models/fault-type";
import OfficeBuildingMarker from "components/custom-icons/office-building-marker";

interface IProps {
  onSelectOrganisationUnit: (
    selectedNode: OrganisationTreeNode,
    upstreamParentBranch: OrganisationTreeNode[],
    siblings: OrganisationTreeNode[],
  ) => void;
  onDeselectOrganisationUnit: () => void;
  useConfirmation?: boolean;
  initialSelectedOrganisationUnit?: string;
  selectedOrganisationUnit?: string;
  disableSelection?: boolean;
  hideIcons?: boolean;
}

export default function OrganisationTree(props: Readonly<IProps>) {
  const dispatch = useAppDispatch();

  const nodesByNodeId = new Map<string, OrganisationTreeNode>();

  const { organisationUnitTree, isLoading, isSuccess, error } =
    useOrganisationUnitTreeContext();

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [selectedNode, setSelectedNode] = useState<string>(
    props.selectedOrganisationUnit ??
      props.initialSelectedOrganisationUnit ??
      "",
  );

  useEffect(() => {
    const newlySelectedNode = props.selectedOrganisationUnit ?? "";
    if (selectedNode !== newlySelectedNode) {
      setSelectedNode(newlySelectedNode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedOrganisationUnit]);

  useEffect(() => {
    initTree(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disableSelection]);

  useEffect(() => {
    initTree(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree]);

  useEffect(() => {
    if (error) {
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const initTree = (rememberExpandedNodes: boolean) => {
    const newExpandedNodes: string[] = [];
    if (organisationUnitTree) {
      const notExpanded = organisationUnitTree
        .map((x) => x.id)
        .filter((id) => !newExpandedNodes.includes(id));
      newExpandedNodes.push(...notExpanded);
    }

    let initialSelectedNode = selectedNode;
    if (props.initialSelectedOrganisationUnit) {
      setSelectedNode(props.initialSelectedOrganisationUnit);
      initialSelectedNode = props.initialSelectedOrganisationUnit;
    }

    if (initialSelectedNode) {
      const selectedOrganisationTreeNode =
        nodesByNodeId.get(initialSelectedNode)!;
      if (selectedOrganisationTreeNode) {
        if (
          !newExpandedNodes.some((nodeId) => nodeId === initialSelectedNode)
        ) {
          newExpandedNodes.push(initialSelectedNode);
        }

        let allParentNodes = createUpstreamParentBranch(
          selectedOrganisationTreeNode,
          [selectedOrganisationTreeNode],
        );

        allParentNodes.forEach((node) => {
          if (!newExpandedNodes.some((nodeId) => nodeId === node.id)) {
            newExpandedNodes.push(node.id);
          }
        });

        props.onSelectOrganisationUnit(
          selectedOrganisationTreeNode,
          allParentNodes,
          createSiblingsList(selectedOrganisationTreeNode),
        );
      } else {
        props.onDeselectOrganisationUnit();
      }
    }

    if (expandedNodes.length === 0 || !rememberExpandedNodes) {
      setExpandedNodes(newExpandedNodes);
    }
  };

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    event.persist();
    if (
      event.target instanceof SVGElement ||
      (event.target as HTMLElement).className === "MuiTreeItem-iconContainer"
    ) {
      setExpandedNodes(nodeIds);
    }
  };

  const handleSelect = (
    event: SyntheticEvent<Element, Event>,
    nodeId: string,
  ) => {
    event.persist();
    if (
      event.target instanceof HTMLElement &&
      event.target.className !== "MuiTreeItem-iconContainer"
    ) {
      if (nodesByNodeId.has(nodeId)) {
        if (props.useConfirmation) {
          dispatch(
            showConfirmationPopup({
              confirmActionNextAction: () => confirmSelect(nodeId),
            }),
          );
        } else {
          confirmSelect(nodeId);
        }
      }
    }
  };

  function confirmSelect(nodeId: string): void {
    if (nodeId === selectedNode) {
      onDeselect();
    } else {
      onSelect(nodeId);
    }
  }

  function onDeselect(): void {
    props.onDeselectOrganisationUnit();
    setSelectedNode("");
  }

  function onSelect(nodeId: string): void {
    const selectedNode = nodesByNodeId.get(nodeId)!;
    props.onSelectOrganisationUnit(
      selectedNode,
      createUpstreamParentBranch(selectedNode, [selectedNode]),
      createSiblingsList(selectedNode),
    );
    setSelectedNode(nodeId);
  }

  function createSiblingsList(
    node: OrganisationTreeNode,
  ): OrganisationTreeNode[] {
    if (node.parentId === undefined || !nodesByNodeId.has(node.parentId)) {
      return [];
    }

    const parent = nodesByNodeId.get(node.parentId)!;
    const indexOfSelectedNode = parent.childOrganisationUnits.indexOf(node);
    const copyOfChildOrganisationUnits = [...parent.childOrganisationUnits];
    copyOfChildOrganisationUnits.splice(indexOfSelectedNode, 1);
    return copyOfChildOrganisationUnits;
  }

  function createUpstreamParentBranch(
    node: OrganisationTreeNode,
    upstreamParentBranch: OrganisationTreeNode[] = [],
  ): OrganisationTreeNode[] {
    if (node.parentId === undefined || !nodesByNodeId.has(node.parentId)) {
      return upstreamParentBranch;
    }

    const parent = nodesByNodeId.get(node.parentId)!;

    upstreamParentBranch.push(parent);

    return createUpstreamParentBranch(parent, upstreamParentBranch);
  }

  const errorFaultTypes = [
    FaultType.Offline,
    FaultType.BatteryCritical,
    FaultType.OutDated,
  ];

  const smallIconTheme = (isVisible: boolean): SxProps<Theme> => ({
    fontSize: 16,
    color: isVisible ? "black" : "transparent",
  });

  const largeFaultsIconTheme = (faults: FaultType[]): SxProps<Theme> => ({
    fontSize: 24,
    color: faults.length === 0 ? "transparent" : Constants.Colors.onSurface,
  });

  const badgeTheme = (faults: FaultType[]): SxProps<Theme> => ({
    display: "flex",
    alignItems: "center",
    margin: "0px 16px",
    ".MuiBadge-badge": {
      marginRight: `-${(faults.length.toString().length - 1) * 4}px`,
      color: Constants.Colors.onInverseSurface,
      bgcolor: faults.some((fault) => errorFaultTypes.includes(fault))
        ? Constants.Colors.error
        : Constants.Colors.brightOrange,
    },
  });

  const renderStartingIcons = (node: OrganisationTreeNode): ReactElement => (
    <div className={"starting-icons"}>
      {node.isLocation && (
        <OfficeBuildingMarker sx={smallIconTheme(node.isLocation)} />
      )}
    </div>
  );

  const renderEndingIcons = (node: OrganisationTreeNode): ReactElement => (
    <div className={"ending-icons"}>
      <ChecklistRounded sx={smallIconTheme(node.isSubscriptionChannel)} />
      <BedRounded sx={smallIconTheme(node.isResidentialLocation)} />
      <VisibilityOffOutlined sx={smallIconTheme(node.isHidden)} />
      <Badge
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={badgeTheme(node.faults)}
        badgeContent={node.faults.length}
      >
        <WarningAmberRounded sx={largeFaultsIconTheme(node.faults)} />
      </Badge>
    </div>
  );

  function renderChildNodes(
    node: OrganisationTreeNode,
  ): ReactElement[] | undefined {
    nodesByNodeId.set(node.id, node);

    return node.childOrganisationUnits?.map((child) => {
      return (
        <OrganisationUnitTreeItem
          key={child.id}
          nodeId={child.id}
          labelText={child.name}
          disableSelection={props.disableSelection ?? false}
          startingIcons={props.hideIcons ? <></> : renderStartingIcons(child)}
          endingIcons={props.hideIcons ? <></> : renderEndingIcons(child)}
          isRoot={false}
          data-testid={`organisationTreeItem-${child.id}`}
        >
          {renderChildNodes(child)}
        </OrganisationUnitTreeItem>
      );
    });
  }

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (isSuccess && organisationUnitTree) {
    return (
      <div className="tree-view-container" data-testid="organisationTree">
        <TreeView
          disableSelection={props.disableSelection}
          aria-label="rich object"
          className="tree-view"
          onNodeToggle={(event, nodeIds) =>
            props.disableSelection ?? false
              ? () => {}
              : handleToggle(event, nodeIds)
          }
          onNodeSelect={handleSelect}
          expanded={expandedNodes}
          selected={selectedNode}
        >
          {organisationUnitTree.map((rootItem) => (
            <OrganisationUnitTreeItem
              key={rootItem.id}
              nodeId={rootItem.id}
              labelText={rootItem.name}
              disableSelection={props.disableSelection ?? false}
              endingIcons={
                props.hideIcons ? (
                  <></>
                ) : (
                  <>
                    {rootItem.isDiversionChannel && (
                      <NotificationImportantOutlined />
                    )}
                    {rootItem.isSubscriptionChannel && <ChecklistRounded />}
                    {rootItem.isResidentialLocation && <BedRounded />}
                    {rootItem.isHidden && <VisibilityOffOutlined />}
                  </>
                )
              }
              isRoot={false}
              data-testid={`organisationTreeItem-${rootItem.id}`}
            >
              {renderChildNodes(rootItem)}
            </OrganisationUnitTreeItem>
          ))}
        </TreeView>
      </div>
    );
  } else {
    return <></>;
  }
}
