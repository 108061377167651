import React, { ReactElement, ReactNode } from "react";
import useSendTestEvent, { ISendTestEventHook } from "./send-test-event-hook";

const SendTestEventContext = React.createContext<ISendTestEventHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const SendTestEventProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <SendTestEventContext.Provider value={useSendTestEvent()}>
      {props.children}
    </SendTestEventContext.Provider>
  );
};

export const useSendTestEventContextProvider = (): ISendTestEventHook =>
  React.useContext<ISendTestEventHook>(SendTestEventContext);
