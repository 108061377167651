import { useTranslation } from "react-i18next";
import "./role-information.component.scss";
import Role from "features/role/domain/models/role";
import { MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ViewingMode } from "utils/viewing-utils";
import { ReactElement } from "react";
import { RoleType } from "features/role/domain/models/role-type";

interface IProps {
  selectedRole?: Role;
  allRoles: Array<Role> | undefined;
  viewingMode: ViewingMode;
}

function RoleInformation(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("role");
  const {
    formState: { errors },
    control,
    getValues,
  } = useFormContext();

  function validateName(): boolean | string {
    const name = getValues("name").trim();
    if (
      props.selectedRole?.name === name ||
      !props.allRoles?.some((role) => role.name === name)
    ) {
      return true;
    }

    return t("details.information.uniqueNameHelperText");
  }

  function validateIdpId(): boolean | string {
    const idpId = getValues("idpId")?.trim();
    if (
      props.selectedRole?.idpId === idpId ||
      !props.allRoles?.some((role) => role.idpId === idpId)
    ) {
      return true;
    }

    return t("details.information.uniqueIDPIdHelperText");
  }

  const registerOptions = {
    name: {
      required: t("details.information.requiredHelperText"),
      validate: validateName,
    },
    idpId: {
      required: t("details.information.requiredHelperText"),
      validate: validateIdpId,
    },
  };

  return (
    <div
      className={`role-information${
        props.viewingMode === "viewing" ? " viewing" : ""
      }`}
    >
      {props.viewingMode === "editing" || props.viewingMode === "creation" ? (
        <>
          <Typography variant="h2" className="details-title">
            {props.viewingMode === "creation" && t("details.createRoleTitle")}
            {props.viewingMode === "editing" && t("details.editRoleTitle")}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={registerOptions.name}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{ "data-testid": "nameInput", maxLength: 255 }}
                id="nameInput"
                label={t("details.information.nameLabel")}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message?.toString()}
                className="information-item-textfield"
              />
            )}
          />
          <Controller
            name="idpId"
            control={control}
            rules={registerOptions.idpId}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{ "data-testid": "idpIdInput", maxLength: 255 }}
                id="idpIdInput"
                label={t("details.information.idpIdLabel")}
                variant="outlined"
                error={!!errors.idpId}
                helperText={errors.idpId?.message?.toString()}
                className="information-item-textfield"
              />
            )}
          />
          <Typography variant="caption" className="idp-remark">
            {t("details.information.idpRemark")}
          </Typography>
          {props.viewingMode === "creation" && (
            <Controller
              name="roleType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  inputProps={{ "data-testid": "roleTypeSelect" }}
                  id="idpIdInput"
                  variant="outlined"
                  className="information-item-textfield"
                  defaultValue={RoleType.Permissions}
                >
                  <MenuItem
                    data-testid={`role-type-permissions`}
                    key={`role-type-permissions`}
                    value={RoleType.Permissions}
                  >
                    {t("roleType.Permissions")}
                  </MenuItem>
                  <MenuItem
                    data-testid={`role-type-access`}
                    key={`role-type-access`}
                    value={RoleType.Access}
                  >
                    {t("roleType.Access")}
                  </MenuItem>
                </Select>
              )}
            />
          )}
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": "descriptionInput",
                  maxLength: 255,
                }}
                id="descriptionInput"
                label={t("details.information.descriptionLabel")}
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description?.message?.toString()}
                multiline
                minRows={3}
                maxRows={6}
                className="information-item-textfield"
              />
            )}
          />
          {(props.selectedRole?.roleType === RoleType.Permissions ||
            getValues("roleType") === RoleType.Permissions) && (
            <div className="assignable-to-temporary-user-container">
              <Typography variant="subtitle1">
                {t("details.information.canBeAssignedToTemporaryUsersLabel")}
              </Typography>
              <Controller
                name="canBeAssignedToTemporaryUsers"
                control={control}
                render={({ field }) => (
                  <Switch
                    {...field}
                    type="checkbox"
                    disabled={props.viewingMode === "viewing"}
                    checked={field.value}
                  />
                )}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            className="role-name-title"
            data-testid="roleInformationName"
          >
            {props.selectedRole?.name}
          </Typography>

          {props.selectedRole?.idpId && (
            <div className="information-item">
              <Typography variant="h5">
                {t("details.information.idpIdLabel")}
              </Typography>
              <Typography
                variant="subtitle1"
                data-testid="roleInformationIdpId"
              >
                {props.selectedRole?.idpId}
              </Typography>
            </div>
          )}

          {props.selectedRole?.roleType && (
            <div className="information-item">
              <Typography variant="h5">
                {t("details.information.roleTypeLabel")}
              </Typography>
              <Typography variant="subtitle1" data-testid="roleType">
                {t(`roleType.${props.selectedRole?.roleType}`)}
              </Typography>
            </div>
          )}

          {props.selectedRole?.description && (
            <div className="information-item">
              <Typography variant="h5">
                {t("details.information.descriptionLabel")}
              </Typography>
              <Typography
                variant="subtitle1"
                data-testid="roleInformationDescription"
              >
                {props.selectedRole?.description}
              </Typography>
            </div>
          )}

          {props.selectedRole?.roleType === RoleType.Permissions && (
            <div className="assignable-to-temporary-user-container">
              <Typography variant="subtitle1">
                {t("details.information.canBeAssignedToTemporaryUsersLabel")}
              </Typography>
              <Controller
                name="canBeAssignedToTemporaryUsers"
                control={control}
                render={() => (
                  <Switch
                    value={props.selectedRole?.canBeAssignedToTemporaryUsers}
                    type="checkbox"
                    disabled={true}
                    checked={props.selectedRole?.canBeAssignedToTemporaryUsers}
                  />
                )}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default RoleInformation;
