import {
  IDeviceDetailsProviderHooks,
  useDeviceDetailsProviderHooks,
} from "features/device/device-details/providers/device-details-provider/hooks";
import React, { ReactElement, ReactNode } from "react";
import { ViewingMode } from "utils/viewing-utils";

const DeviceDetailsContext = React.createContext<IDeviceDetailsProviderHooks>(
  {} as never,
);

interface IProps {
  children: ReactNode;
  deviceId: string | undefined;
  initialViewingMode: ViewingMode;
}

export const DeviceDetailsProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <DeviceDetailsContext.Provider
      value={useDeviceDetailsProviderHooks(
        props.deviceId,
        props.initialViewingMode,
      )}
    >
      {props.children}
    </DeviceDetailsContext.Provider>
  );
};

export const useDeviceDetailsContextProvider =
  (): IDeviceDetailsProviderHooks =>
    React.useContext<IDeviceDetailsProviderHooks>(DeviceDetailsContext);
