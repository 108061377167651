import { Divider as MuiDivider, Grid, Typography } from "@mui/material";
import "./divider.component.scss";
import { ReactElement } from "react";

interface IProps {
  startText?: string;
  endElement?: ReactElement;
  className?: string;
}

function Divider(props: Readonly<IProps>): ReactElement {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap={"16px"}
      className={props.className ? props.className : "divider"}
    >
      {props.startText && (
        <Grid item>
          <Typography variant="h5">{props.startText}</Typography>
        </Grid>
      )}
      <Grid item xs>
        <MuiDivider variant="fullWidth" />
      </Grid>
      {props.endElement && <Grid item>{props.endElement}</Grid>}
    </Grid>
  );
}

export default Divider;
