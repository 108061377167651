import { ReactElement } from "react";
import { ReactComponent as LogoCenter } from "./logo-center.svg";
import { ReactComponent as LogoInnerRing } from "./logo-inner-ring.svg";
import { ReactComponent as LogoOuterRing } from "./logo-outer-ring.svg";

import "./sonevo-loader.scss";

export enum LoadingIndicatorStyle {
  colored,
  white,
}

interface LoadingIndicatorProps {
  readonly size?: number;
  readonly style?: LoadingIndicatorStyle;
}

export default function SonevoLoadingIndicator(
  props: LoadingIndicatorProps,
): ReactElement {
  return (
    <>
      <LogoCenter
        className={
          props.style === LoadingIndicatorStyle.white
            ? "sonevo-loader white"
            : undefined
        }
        height={props.size}
        width={props.size}
        fill={props.style === LoadingIndicatorStyle.white ? "white" : undefined}
        style={{ position: "absolute" }}
      />
      <LogoInnerRing
        className={
          props.style === LoadingIndicatorStyle.white
            ? "sonevo-loader white"
            : undefined
        }
        height={props.size}
        width={props.size}
        style={{
          position: "absolute",
          animation: "spin 1s linear infinite",
        }}
      />
      <LogoOuterRing
        className={
          props.style === LoadingIndicatorStyle.white
            ? "sonevo-loader white"
            : undefined
        }
        height={props.size}
        width={props.size}
        style={{
          position: "absolute",
          animation: "spin 1s linear infinite reverse",
        }}
      />
    </>
  );
}
