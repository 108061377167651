import "./role-details-popup-header.scss";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CheckRounded from "@mui/icons-material/CheckRounded";
import { useTranslation } from "react-i18next";
import { ViewingMode } from "utils/viewing-utils";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import { LoadingButton } from "@mui/lab";
import { ReactElement } from "react";

interface IProps {
  onClose: () => void;
  onCancel: () => void;
  onEdit: () => void;
  onDelete: () => void;
  viewingMode: ViewingMode;
  isLoading: boolean;
}

function RoleDetailsPopupHeader(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("role");

  return (
    <div className="role-details-header">
      {(props.viewingMode === "creation" ||
        props.viewingMode === "editing") && (
        <>
          <Button
            data-testid="roleDetailCancelButton"
            variant="text"
            onClick={props.onCancel}
            disabled={props.isLoading}
          >
            {t("details.cancelFormButton")}
          </Button>
          <LoadingButton
            data-testid="roleDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={props.isLoading}
            loadingPosition="start"
          >
            {t("details.saveFormButton")}
          </LoadingButton>
        </>
      )}
      {props.viewingMode === "viewing" && (
        <>
          <AutorisationWrapper atLeastOnePermissionOf={[Permission.DeleteRole]}>
            <Button
              data-testid="roleDetailRemoveButton"
              startIcon={<DeleteForeverOutlinedIcon className="icon-on-text" />}
              variant="text"
              onClick={props.onDelete}
              disabled={props.isLoading}
            >
              {t("details.removeFormButton")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper atLeastOnePermissionOf={[Permission.UpdateRole]}>
            <Button
              data-testid="roleDetailEditButton"
              startIcon={
                <ModeEditOutlineOutlinedIcon className="icon-on-text" />
              }
              variant="text"
              onClick={props.onEdit}
              disabled={props.isLoading}
            >
              {t("details.editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className="close-button"
        onClick={props.onClose}
        data-testid="roleDetailsPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
}

export default RoleDetailsPopupHeader;
