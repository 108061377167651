import { useTranslation } from "react-i18next";
import { RegexExpressions } from "constants/regex/regex-expressions";
import { MalfunctionNotificationRecipient } from "../domain/models/malfunction-notification";

export interface IRecipientValidationHook {
  isValidEmail: (value: string) => boolean | string;
  isUniqueEmail: (
    value: string,
    fields: MalfunctionNotificationRecipient[],
  ) => boolean | string;
}

const useRecipientValidation = (): IRecipientValidationHook => {
  const regex = new RegExp(RegexExpressions.EMAIL);
  const { t } = useTranslation("malfunctionNotifications");

  const isValidEmail = (value: string) => {
    return regex.test(value?.trim()) || t("notifications.email.invalid");
  };

  const isUniqueEmail = (
    value: string,
    fields: MalfunctionNotificationRecipient[],
  ) => {
    const index = fields.findIndex((field: any) => field.email === value);
    const isUniqueEmail = fields.every(
      (field: any, i: number) => i === index || field.email !== value,
    );

    return isUniqueEmail || t("notifications.email.isUnique");
  };

  return {
    isValidEmail,
    isUniqueEmail,
  };
};

export default useRecipientValidation;
