import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer, {
  authenticationApi,
} from "features/authentication/domain/reducers/authentication.reducer";
import { permissionApi } from "features/autorisation/domain/reducers/permission.reducer";
import { configurationApi } from "features/configuration/domain/reducers/configuration.reducer";
import confirmationPopupReducer from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { ConnectionTypeApi } from "features/connectionType/domain/reducers/connectionType.reducer";
import { connectorsApi } from "features/connectors/domain/reducers/connectors.reducer";
import { dashboardApi } from "features/dashboard/domain/reducers/dashboard.reducer";
import { deviceApi } from "features/device/domain/reducers/device.reducer";
import { linkableDeviceApi } from "features/device/linkable-device/domain/reducers/linkable-device.reducer";
import { sendTestEventApi } from "features/device/send-test-event/domain/reducers/send-test-event.reducer";
import { emergencyAlarmSettingsApi } from "features/emergency-alarm-settings/domain/reducers/emergency-alarm-setting.reducer";
import errorMessageReducer from "features/error-handling/domain/reducers/error-handling.reducer";
import { explanationLinksApi } from "features/explanation-links/domain/reducers/explanation-links.reducer";
import { gatewayApi } from "features/gateway/domain/reducers/gateway.reducer";
import { historyApi } from "features/history/domain/reducers/history.reducer";
import { malfunctionNotificationApi } from "features/malfunction-notification-settings/domain/reducers/malfunction-notifications.reducer";
import { organisationUnitApi } from "features/organisation/domain/reducers/organisation-unit.reducer";
import { residentApi } from "features/residents/residents-settings/domain/reducers/resident.reducer";
import { roleApi } from "features/role/domain/reducers/role.reducer";
import { scenariosApi } from "features/scenario/domain/reducers/scenarios.reducer";
import { subscriptionApi } from "features/subscription-info/domain/reducers/subscription.reducer";
import { systemsApi } from "features/systems/domain/reducers/systems.reducer";
import { temporaryUsersApi } from "features/temporary-access/domain/reducers/temporary-user.reducer";
import { tenantApi } from "features/tenants/domain/reducers/tenant.reducer";
import { useDispatch } from "react-redux";
import errorHandlingMiddleware from "./error-handling-middleware";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    authenticationApi: authenticationApi.reducer,
    errorHandling: errorMessageReducer,
    confirmationPopup: confirmationPopupReducer,
    organisationUnit: organisationUnitApi.reducer,
    role: roleApi.reducer,
    permission: permissionApi.reducer,
    gateway: gatewayApi.reducer,
    linkableDevice: linkableDeviceApi.reducer,
    device: deviceApi.reducer,
    scenarios: scenariosApi.reducer,
    resident: residentApi.reducer,
    history: historyApi.reducer,
    dashboard: dashboardApi.reducer,
    tenant: tenantApi.reducer,
    configuration: configurationApi.reducer,
    temporaryUsers: temporaryUsersApi.reducer,
    explanationLinks: explanationLinksApi.reducer,
    connectionType: ConnectionTypeApi.reducer,
    emergencyAlarmSettings: emergencyAlarmSettingsApi.reducer,
    malfunctionNotifications: malfunctionNotificationApi.reducer,
    sendTestEvent: sendTestEventApi.reducer,
    subscriptionInfo: subscriptionApi.reducer,
    systems: systemsApi.reducer,
    connectors: connectorsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      errorHandlingMiddleware,
      organisationUnitApi.middleware,
      roleApi.middleware,
      permissionApi.middleware,
      gatewayApi.middleware,
      linkableDeviceApi.middleware,
      deviceApi.middleware,
      authenticationApi.middleware,
      scenariosApi.middleware,
      residentApi.middleware,
      historyApi.middleware,
      dashboardApi.middleware,
      tenantApi.middleware,
      configurationApi.middleware,
      temporaryUsersApi.middleware,
      explanationLinksApi.middleware,
      ConnectionTypeApi.middleware,
      emergencyAlarmSettingsApi.middleware,
      malfunctionNotificationApi.middleware,
      sendTestEventApi.middleware,
      subscriptionApi.middleware,
      systemsApi.middleware,
      connectorsApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export function resetState(): void {
  store.dispatch(organisationUnitApi.util.resetApiState());
  store.dispatch(roleApi.util.resetApiState());
  store.dispatch(permissionApi.util.resetApiState());
  store.dispatch(gatewayApi.util.resetApiState());
  store.dispatch(linkableDeviceApi.util.resetApiState());
  store.dispatch(deviceApi.util.resetApiState());
  store.dispatch(scenariosApi.util.resetApiState());
  store.dispatch(residentApi.util.resetApiState());
  store.dispatch(historyApi.util.resetApiState());
  store.dispatch(dashboardApi.util.resetApiState());
  store.dispatch(tenantApi.util.resetApiState());
  store.dispatch(configurationApi.util.resetApiState());
  store.dispatch(temporaryUsersApi.util.resetApiState());
  store.dispatch(explanationLinksApi.util.resetApiState());
  store.dispatch(ConnectionTypeApi.util.resetApiState());
  store.dispatch(emergencyAlarmSettingsApi.util.resetApiState());
  store.dispatch(malfunctionNotificationApi.util.resetApiState());
  store.dispatch(sendTestEventApi.util.resetApiState());
  store.dispatch(subscriptionApi.util.resetApiState());
  store.dispatch(systemsApi.util.resetApiState());
  store.dispatch(connectorsApi.util.resetApiState());
}
