import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import "./page-tab.scss";
import Permission from "features/autorisation/domain/models/permission";
import { useLocation } from "react-router-dom";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { ReactElement } from "react";

export interface PageTabModel {
  title: string;
  link: string;
  atLeastOnePermissionOf?: Permission[];
}

interface IProps {
  onPageChanged: (link: string) => void;
  pageTabs: PageTabModel[];
}

export default function PageTab(props: Readonly<IProps>): ReactElement {
  const location = useLocation();
  const { hasAtLeastOnePermissionOf } = useAuth();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    props.onPageChanged(newValue);
  };

  const permissableTabs = props.pageTabs.filter(
    (pageTab) =>
      !pageTab.atLeastOnePermissionOf ||
      hasAtLeastOnePermissionOf(pageTab.atLeastOnePermissionOf),
  );

  return (
    <>
      {permissableTabs.length > 1 && (
        <div className="page-tab-container">
          <ToggleButtonGroup
            color="primary"
            value={location.pathname}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            {permissableTabs.map((pageTab) => {
              return (
                <ToggleButton
                  data-testid={"page-tab-" + pageTab.title}
                  key={pageTab.link}
                  value={pageTab.link}
                  disabled={pageTab.link === location.pathname}
                >
                  <div className="button-container">
                    {pageTab.link === location.pathname && (
                      <CheckRoundedIcon className="icon" fontSize="small" />
                    )}
                    {pageTab.title}
                  </div>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
    </>
  );
}
