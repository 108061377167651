import moment from "moment";

export function toDate(value?: string | number | Date): Date | undefined {
  if (!value) {
    return undefined;
  }

  const date = new Date(value);
  return date;
}

export function calculateDurationInMinutes(
  current: string,
  previous: string,
  ignoreSeconds: boolean = false,
): number {
  let currentMoment = moment(current);
  let previousMoment = moment(previous);

  if (ignoreSeconds) {
    currentMoment = moment(currentMoment.format("YYYY-MM-DD HH:mm"));
    previousMoment = moment(previousMoment.format("YYYY-MM-DD HH:mm"));
  }

  return currentMoment.diff(previousMoment, "minutes");
}

export function getAlarmTimeForAlarmCard(
  statusLastChangedAt: string | undefined,
  dateDisplayYesterday: string,
): string {
  const now = moment();
  const timeOfAlarm = moment(statusLastChangedAt);

  if (timeOfAlarm.isSame(now, "day")) {
    return timeOfAlarm.format("HH:mm");
  } else if (timeOfAlarm.isSame(now.add(-1, "day"), "day")) {
    return `${dateDisplayYesterday} ${timeOfAlarm.format("HH:mm")}`;
  } else {
    return `${moment(statusLastChangedAt).format("DD-MM")} ${timeOfAlarm.format(
      "HH:mm",
    )}`;
  }
}

export namespace dateFormatOptions {
  export const date: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  export const time: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };
  export const dateTime: Intl.DateTimeFormatOptions = { ...date, ...time };
}
