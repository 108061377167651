import MonitoringAlert from "components/monitoring/monitoring-alert.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface IProps {
  message?: string;
  lastChangedOn?: Date;
}

function Offline(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      {...props}
      text={t("messages.offline")}
      type={MonitoringType.Offline}
      color={Constants.Colors.error}
    />
  );
}

function BatteryLow(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      {...props}
      text={t("messages.batteryLow")}
      type={MonitoringType.BatteryLow}
      color={Constants.Colors.brightOrange}
    />
  );
}

function BatteryCritical(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      {...props}
      text={t("messages.batteryCritical")}
      type={MonitoringType.BatteryCritical}
      color={Constants.Colors.error}
    />
  );
}

function Outdated(): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringAlert
      text={t("messages.outDated")}
      type={MonitoringType.OutDated}
      color={Constants.Colors.error}
    />
  );
}

const DeviceMonitoringAlert = {
  MonitoringAlert: MonitoringAlert,
  Offline: Offline,
  BatteryLow: BatteryLow,
  BatteryCritical: BatteryCritical,
  Outdated: Outdated,
};

export default DeviceMonitoringAlert;
