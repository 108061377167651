import { MenuItem, Typography } from "@mui/material";
import SelectField from "components/formFields/select-field.component";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const GatewaySettingsType = (): JSX.Element => {
  const { t } = useTranslation("gateway");

  const {
    formState: { errors },
    control,
  } = useFormContext();
  const registerOptions = {
    type: {
      required: t("details.information.requiredHelperText"),
    },
  };

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const isCreating = gatewayDetailsHook.viewingMode === "creation";
  const sortedOptions = Object.values(GatewayType)
    .filter((gatewayType) => gatewayType !== GatewayType.None)
    .sort((a, b) => a.localeCompare(b));

  if (isCreating) {
    return (
      <Controller
        name="type"
        control={control}
        rules={registerOptions.type}
        render={({ field }) => {
          return (
            <SelectField
              {...field}
              inputProps={{ "data-testid": "typeInput" }}
              id="typeInput"
              error={!!errors.type}
              helperText={errors.type?.message?.toString()}
              variant="outlined"
              label={t("details.settings.typeLabel")}
            >
              {sortedOptions.map((gatewayType) => (
                <MenuItem
                  data-testid={`gateway-type-${gatewayType}`}
                  key={`gatewayType-${gatewayType}`}
                  value={gatewayType}
                >
                  {t(`details.settings.type.${gatewayType}`)}
                </MenuItem>
              ))}
            </SelectField>
          );
        }}
      />
    );
  }

  return (
    <>
      <Typography variant="h5">{t("details.settings.typeLabel")}</Typography>
      <Typography variant="subtitle1" data-testid="gatewaySettingsType">
        {t(
          `details.settings.type.${gatewayDetailsHook.currentSelectedGateway?.type}`,
        )}
      </Typography>
    </>
  );
};

export default GatewaySettingsType;
