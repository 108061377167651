import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function EmergencyHomeRounded(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M13.4251 21.45C13.2251 21.65 13.0041 21.7956 12.7621 21.887C12.5208 21.979 12.2668 22.025 12.0001 22.025C11.7334 22.025 11.4794 21.979 11.2381 21.887C10.9961 21.7956 10.7751 21.65 10.5751 21.45L2.5501 13.425C2.3501 13.225 2.2041 13.004 2.1121 12.762C2.02076 12.5206 1.9751 12.2666 1.9751 12C1.9751 11.7333 2.02076 11.4793 2.1121 11.238C2.2041 10.996 2.3501 10.775 2.5501 10.575L10.5751 2.54998C10.7751 2.34998 10.9961 2.20398 11.2381 2.11198C11.4794 2.02064 11.7334 1.97498 12.0001 1.97498C12.2668 1.97498 12.5208 2.02064 12.7621 2.11198C13.0041 2.20398 13.2251 2.34998 13.4251 2.54998L21.4501 10.575C21.6501 10.775 21.7961 10.996 21.8881 11.238C21.9794 11.4793 22.0251 11.7333 22.0251 12C22.0251 12.2666 21.9794 12.5206 21.8881 12.762C21.7961 13.004 21.6501 13.225 21.4501 13.425L13.4251 21.45ZM12.0001 20.025L20.0251 12L12.0001 3.97498L3.9751 12L12.0001 20.025ZM12.0001 13C12.2834 13 12.5211 12.904 12.7131 12.712C12.9044 12.5206 13.0001 12.2833 13.0001 12V7.99998C13.0001 7.71664 12.9044 7.47898 12.7131 7.28698C12.5211 7.09564 12.2834 6.99998 12.0001 6.99998C11.7168 6.99998 11.4794 7.09564 11.2881 7.28698C11.0961 7.47898 11.0001 7.71664 11.0001 7.99998V12C11.0001 12.2833 11.0961 12.5206 11.2881 12.712C11.4794 12.904 11.7168 13 12.0001 13ZM12.0001 16C12.2834 16 12.5211 15.904 12.7131 15.712C12.9044 15.5206 13.0001 15.2833 13.0001 15C13.0001 14.7166 12.9044 14.479 12.7131 14.287C12.5211 14.0956 12.2834 14 12.0001 14C11.7168 14 11.4794 14.0956 11.2881 14.287C11.0961 14.479 11.0001 14.7166 11.0001 15C11.0001 15.2833 11.0961 15.5206 11.2881 15.712C11.4794 15.904 11.7168 16 12.0001 16Z" />
    </SvgIcon>
  );
}
