import "./timeline.component.scss";
import { ReactElement } from "react";
import Constants from "style/constants";
import { useTranslation } from "react-i18next";
import { calculateDurationInMinutes } from "utils/date-utils";
import { TimelineItem } from "./timeline-item";
import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

interface IProps {
  prefix?: string;
  firstItem: TimelineItem;
  secondItem: TimelineItem;
  typographyVariant: Variant;
  durationInMinutes: number;
  usePreCalculatedTime: boolean;
}

function Duration(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("timeline");

  function getDurationTranslation(): string {
    const durationInMinutes = props.usePreCalculatedTime
      ? props.durationInMinutes
      : calculateDurationInMinutes(
          props.secondItem.createdOn,
          props.firstItem.createdOn,
          true,
        );

    if (durationInMinutes >= 60) {
      let durationInHours = durationInMinutes / 60;

      if (durationInHours >= 24) {
        durationInHours = Math.ceil(durationInHours);

        const durationInDays = durationInHours / 24;
        return `${t("duration_days", {
          count: Math.floor(durationInDays),
        })} ${t("and")} ${t("duration_hours", {
          count: durationInHours % 24,
        })}`;
      }

      return `${t("duration_hours", {
        count: Math.floor(durationInHours),
      })} ${t("and")} ${t("duration_minutes", {
        count: Math.ceil(durationInMinutes % 60),
      })}`;
    }

    return t("duration_minutes", {
      count: Math.ceil(durationInMinutes),
    });
  }

  return (
    <Typography
      variant={props.typographyVariant}
      sx={{ color: Constants.Colors.onSurface }}
    >
      {props.prefix && `${props.prefix}`} {getDurationTranslation()}
    </Typography>
  );
}

export default Duration;
