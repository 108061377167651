import { useState } from "react";
import OrganisationTreeNode from "../domain/models/organisation-tree-node";
import OrganisationUnit from "../domain/models/organisation-unit";
import OrganisationUnitDevice from "../domain/models/organisation-unit-device";
import { useLazyGetOrganisationUnitQuery } from "../domain/reducers/organisation-unit.reducer";
import SubscriptionChannelValidator from "../domain/validators/subscription-channel-validator";
import Scenario from "../models/scenario";

export interface IOrganisationUnitDetailsHook {
  selectedOrganisationUnit: OrganisationUnit | undefined;
  isLoadingSelectedOrganisationUnit: boolean;
  loadOrganisationUnit: (organisationUnitId: string) => void;
  refresh: () => void;

  getDiversionTimeoutSelectValueOrDefault: (
    value: number | undefined,
  ) => number;
  organisationUnitTreeBranchContainsLocation: (
    selectedNode: OrganisationTreeNode | undefined,
    parentNode: OrganisationTreeNode | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ) => boolean;
  isSelectedScenario: (scenario: Scenario, scenarios: Scenario[]) => boolean;
  isSelectedDevice: (
    scenario: OrganisationUnitDevice,
    scenarios: OrganisationUnitDevice[],
  ) => boolean;
  isScrolling: boolean;
  handleScroll: (event: React.UIEvent<HTMLElement>) => void;
}

const useOrganisationUnitDetails = (): IOrganisationUnitDetailsHook => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const [
    triggerGetOrganisationUnit,
    {
      data: getOrganisationUnitData,
      isFetching: getOrganisationUnitIsFetching,
      isLoading: getOrganisationUnitIsLoading,
    },
  ] = useLazyGetOrganisationUnitQuery();

  const refresh = () => {
    if (getOrganisationUnitData) {
      triggerGetOrganisationUnit(getOrganisationUnitData.id);
    }
  };

  const loadOrganisationUnit = (organisationUnitId: string) => {
    triggerGetOrganisationUnit(organisationUnitId);
  };

  function getDiversionTimeoutSelectValueOrDefault(
    value: number | undefined,
  ): number {
    const possibleValues = [900, 1800, 2700];
    if (value && possibleValues.find((x) => x === value)) {
      return value;
    }

    return possibleValues[0];
  }

  function organisationUnitTreeBranchContainsLocation(
    selectedNode: OrganisationTreeNode | undefined,
    parentNode: OrganisationTreeNode | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): boolean {
    if (selectedNode?.isLocation) {
      return false;
    }

    if (
      SubscriptionChannelValidator.hasLocationInParents(
        parentNode?.id,
        upstreamParentBranch,
      ) ||
      SubscriptionChannelValidator.hasLocationInChildren(
        selectedNode?.childOrganisationUnits,
      )
    ) {
      return true;
    }
    return false;
  }

  function isSelectedScenario(
    scenario: Scenario,
    selectedScenarios: Scenario[],
  ): boolean {
    return (
      selectedScenarios.findIndex(
        (selectedScenario) => selectedScenario.id === scenario.id,
      ) === -1
    );
  }

  function isSelectedDevice(
    device: OrganisationUnitDevice,
    selectedDevices: OrganisationUnitDevice[],
  ): boolean {
    return (
      selectedDevices.findIndex(
        (selectedDevice) => selectedDevice.id === device.id,
      ) === -1
    );
  }

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    if (isScrolling && event.currentTarget.scrollTop === 0) {
      setIsScrolling(false);
    } else if (!isScrolling && event.currentTarget.scrollTop > 0) {
      setIsScrolling(true);
    }
  };

  return {
    selectedOrganisationUnit: getOrganisationUnitData,
    isLoadingSelectedOrganisationUnit:
      getOrganisationUnitIsFetching || getOrganisationUnitIsLoading,
    loadOrganisationUnit,
    refresh,

    getDiversionTimeoutSelectValueOrDefault,
    organisationUnitTreeBranchContainsLocation,
    isSelectedScenario,
    isSelectedDevice,
    isScrolling,
    handleScroll,
  };
};

export default useOrganisationUnitDetails;
