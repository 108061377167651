import { useEffect, useState } from "react";
import OrganisationTreeNode, {
  findUpstreamParentBranch,
} from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
} from "react-hook-form";
import { useGatewaysContextProvider } from "../context/gateway-provider";

export interface IGatewayOrganisationHook {
  isOrganisationPickerOpen: boolean;
  upstreamParentBranch: OrganisationTreeNode[];
  isLinkErrorPopupOpen: boolean;
  organisationUnitField: ControllerRenderProps<
    FieldValues,
    "organisationUnitId"
  >;

  setOrganisationUnit: (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null,
    upstreamBranch: OrganisationTreeNode[] | null,
  ) => void;

  linkOrganisationUnit: () => void;
  unlinkOrganisationUnit: () => void;

  closeLinkErrorPopup: () => void;
  closeOrganisationPicker: () => void;
}

const useGatewayOrganisation = (): IGatewayOrganisationHook => {
  const [isOrganisationPickerOpen, setIsOrganisationPickerOpen] =
    useState(false);
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);
  const [isLinkErrorPopupOpen, setIsLinkErrorPopupOpen] =
    useState<boolean>(false);

  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const { field } = useController({ name: "organisationUnitId" });

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  useEffect(() => {
    if (
      organisationUnitTree &&
      gatewayDetailsHook.currentSelectedGateway?.organisationUnitId
    ) {
      const upstreamBranch = findUpstreamParentBranch(
        organisationUnitTree,
        gatewayDetailsHook.currentSelectedGateway.organisationUnitId,
        [],
      );
      setUpstreamParentBranch(upstreamBranch ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organisationUnitTree,
    gatewayDetailsHook.currentSelectedGateway,
    gatewayDetailsHook.viewingMode,
  ]);

  const setOrganisationUnit = (
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null = null,
    upstreamBranch: OrganisationTreeNode[] | null = null,
  ) => {
    field.onChange(selectedNode?.id ?? null);
    setUpstreamParentBranch(upstreamBranch ?? []);
    setIsOrganisationPickerOpen(false);
  };

  const canLinkOrUnlinkOrganisationUnit = () =>
    !gatewayDetailsHook.currentSelectedGateway?.devices?.some(
      (device) => !!device.organisationUnitId,
    );

  const linkOrganisationUnit = () => {
    if (canLinkOrUnlinkOrganisationUnit()) {
      setIsOrganisationPickerOpen(true);
    } else {
      setIsLinkErrorPopupOpen(true);
    }
  };

  const unlinkOrganisationUnit = () => {
    if (canLinkOrUnlinkOrganisationUnit()) {
      setOrganisationUnit(field);
    } else {
      setIsLinkErrorPopupOpen(true);
    }
  };

  const closeLinkErrorPopup = () => setIsLinkErrorPopupOpen(false);

  const closeOrganisationPicker = () => setIsOrganisationPickerOpen(false);

  return {
    isOrganisationPickerOpen,
    upstreamParentBranch,
    isLinkErrorPopupOpen,
    organisationUnitField: field,

    setOrganisationUnit,
    linkOrganisationUnit,

    unlinkOrganisationUnit,

    closeLinkErrorPopup,
    closeOrganisationPicker,
  };
};

export default useGatewayOrganisation;
