import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import alarmCard_nl from "components/alarm-card/translations/alarm-card_nl.json";
import copyButton_nl from "components/copy-button/translations/copy-button_nl.json";
import dataGrid_nl from "components/data-grid/translations/data-grid_nl.json";
import dateRangePicker_nl from "components/date-range-picker/translations/date-range-picker_nl.json";
import filterChip_nl from "components/filter-chip/translations/filter-chip_nl.json";
import monintoring_nl from "components/monitoring/translations/monitoring_nl.json";
import multiValueFilterChip_nl from "components/multi-value-filter-chip/translations/multi-value-filter-chip_nl.json";
import searchbar_nl from "components/search/translations/searchbar_nl.json";
import statusCard_nl from "components/status-card/translations/status-card_nl.json";
import table_nl from "components/table/translations/table_nl.json";
import timeline_nl from "components/timeline/translations/timeline_nl.json";
import appInformation_nl from "features/app-information/translations/app-information_nl.json";
import authentication_nl from "features/authentication/translations/authentication_nl.json";
import confirmationPopup_nl from "features/confirmation-popup/translations/confirmation-popup_nl.json";
import connectionType_nl from "features/connectionType/translations/connectionType_nl.json";
import connectors_nl from "features/connectors/translations/nl/connectors.json";
import dashboard_nl from "features/dashboard/translations/dashboard_nl.json";
import device_details_nl from "features/device/device-details/translations/device-details_nl.json";
import devices_nl from "features/device/devices/translations/devices_nl.json";
import linkableDevice_nl from "features/device/linkable-device/translations/linkable-device_nl.json";
import sendTestEvent_nl from "features/device/send-test-event/translations/send-test-event_nl.json";
import emergencyAlarm_nl from "features/emergency-alarm-settings/translations/emergency-alarm_nl.json";
import errorHandling_nl from "features/error-handling/translations/error-handling_nl.json";
import explanationLinks_nl from "features/explanation-links/translations/explanation-links_nl.json";
import gateway_nl from "features/gateway/translations/gateway_nl.json";
import history_nl from "features/history/translations/history_nl.json";
import integrations_nl from "features/integrations/translations/integrations_nl.json";
import malfunctionNotifications_nl from "features/malfunction-notification-settings/translations/malfunction-notifications_nl.json";
import menu_nl from "features/menu/translations/menu_nl.json";
import organisation_nl from "features/organisation/translations/organisation_nl.json";
import residentsSettings_nl from "features/residents/residents-settings/translations/residents-settings_nl.json";
import permissions_nl from "features/role/translations/permissions_nl.json";
import role_nl from "features/role/translations/role_nl.json";
import noPermissions_nl from "features/routing/translations/no-permissions_nl.json";
import linkableScenarios_nl from "features/scenario/linkable-scenario/translations/linkable-scenarios_nl.json";
import scenario_nl from "features/scenario/translations/scenario_nl.json";
import settings_nl from "features/settings/translations/settings_nl.json";
import systems_nl from "features/systems/translations/nl/systems.json";
import temporaryAccess_nl from "features/temporary-access/translations/temporary-access_nl.json";
import tenants_nl from "features/tenants/translations/tenants_nl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      nl: {
        menu: menu_nl,
        authentication: authentication_nl,
        organisation: organisation_nl,
        role: role_nl,
        permissions: permissions_nl,
        gateway: gateway_nl,
        errorHandling: errorHandling_nl,
        confirmationPopup: confirmationPopup_nl,
        linkableDevice: linkableDevice_nl,
        systems: systems_nl,
        connectors: connectors_nl,
        devices: devices_nl,
        deviceDetails: device_details_nl,
        integrations: integrations_nl,
        scenario: scenario_nl,
        table: table_nl,
        linkableScenarios: linkableScenarios_nl,
        residentsSettings: residentsSettings_nl,
        copyButton: copyButton_nl,
        noPermissions: noPermissions_nl,
        history: history_nl,
        filterChip: filterChip_nl,
        search: searchbar_nl,
        dashboard: dashboard_nl,
        tenants: tenants_nl,
        appInformation: appInformation_nl,
        alarmCard: alarmCard_nl,
        statusCard: statusCard_nl,
        timeline: timeline_nl,
        temporaryAccess: temporaryAccess_nl,
        dateRangePicker: dateRangePicker_nl,
        monitoring: monintoring_nl,
        settings: settings_nl,
        dataGrid: dataGrid_nl,
        connectionType: connectionType_nl,
        emergencyAlarm: emergencyAlarm_nl,
        malfunctionNotifications: malfunctionNotifications_nl,
        explanationLinks: explanationLinks_nl,
        sendTestEvent: sendTestEvent_nl,
        multiValueFilterChip: multiValueFilterChip_nl,
      },
    },
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
