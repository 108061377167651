import { Divider } from "@mui/material";
import { ViewingMode } from "utils/viewing-utils";
import {
  DefaultPermissionGroupSettingsApp,
  DefaultPermissionGroupSettingsCentralSupportPortal,
  DefaultPermissionGroupSettingsPortal,
  PermissionGroupSetting,
} from "./permission-group-settings";
import RolePermissionGroupComponent from "./role-permission-group-component";
import "./role-permissions-component.scss";
import { ReactElement, useState } from "react";
import Permission from "features/autorisation/domain/models/permission";
import { useTranslation } from "react-i18next";
import Tabs from "components/tabs/tabs.component";

interface IProps {
  viewingMode: ViewingMode;
}

function RolePermissionsComponent(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("permissions");

  const [permissionGroupSettings, setPermissionGroupSettings] = useState<
    Array<PermissionGroupSetting>
  >(DefaultPermissionGroupSettingsApp);

  const tabs = [
    {
      value: 0,
      title: t("tabs.App"),
      permission: Permission.ReadRole,
    },
    {
      value: 1,
      title: t("tabs.Portal"),
      permission: Permission.ReadRole,
    },
    {
      value: 2,
      title: t("tabs.CentralSupportPortal"),
      permission: Permission.ReadRole,
    },
  ];

  function tabChanged(tabValue: number): void {
    switch (tabValue) {
      case 0:
        setPermissionGroupSettings(DefaultPermissionGroupSettingsApp);
        break;
      case 1:
        setPermissionGroupSettings(DefaultPermissionGroupSettingsPortal);
        break;
      case 2:
        setPermissionGroupSettings(
          DefaultPermissionGroupSettingsCentralSupportPortal,
        );
        break;
    }
  }

  return (
    <>
      <div className="role-permissions-tabs">
        <Tabs onTabChanged={tabChanged} tabs={tabs} />
      </div>
      <div data-testid="rolePermissionsComponent" className="role-permissions">
        {permissionGroupSettings.map((setting, index) => (
          <div key={`role-permission-group-${setting.permissionGroup}`}>
            <RolePermissionGroupComponent
              permissionGroup={setting.permissionGroup}
              permissions={setting.permissions}
              icon={setting.icon || <></>}
              viewingMode={props.viewingMode}
            />
            {index !== permissionGroupSettings.length - 1 && (
              <Divider className="role-permission-group-divider" />
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default RolePermissionsComponent;
