import { Button } from "@mui/material";
import { ReactElement } from "react";
import "./outline-toggle-button.scss";

interface IProps {
  icon?: ReactElement;
  label: string;
  isSelected?: boolean;
  onClick?: () => void;
}

const OutlineToggleButton = ({
  icon,
  label,
  isSelected = false,
  onClick,
}: IProps): ReactElement => {
  return (
    <Button
      className={`outlined-toggle-button ${isSelected && "selected"}`}
      variant="outlined"
      startIcon={icon}
      onClick={onClick}
      aria-label={label}
    >
      {label}
    </Button>
  );
};

export default OutlineToggleButton;
