import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function SubscriptionIcon(): ReactElement {
  return (
    <SvgIcon viewBox="-2 -3 24 24">
      <path d="M20 3.99997C20 3.44997 19.55 2.99997 19 2.99997H12C11.45 2.99997 11 3.44997 11 3.99997C11 4.54997 11.45 4.99997 12 4.99997H19C19.55 4.99997 20 4.54997 20 3.99997ZM11 12C11 12.55 11.45 13 12 13H19C19.55 13 20 12.55 20 12C20 11.45 19.55 11 19 11H12C11.45 11 11 11.45 11 12ZM8.46998 0.629971C8.85998 1.01997 8.85998 1.64997 8.46998 2.03997L4.23998 6.28997C3.84998 6.67997 3.21998 6.67997 2.81998 6.28997L0.69998 4.15997C0.513002 3.97299 0.407959 3.7194 0.407959 3.45497C0.407959 3.32404 0.433748 3.19439 0.483853 3.07343C0.533958 2.95246 0.607398 2.84255 0.69998 2.74997C0.792561 2.65739 0.902472 2.58395 1.02344 2.53384C1.1444 2.48374 1.27405 2.45795 1.40498 2.45795C1.66941 2.45795 1.923 2.56299 2.10998 2.74997L3.52998 4.16997L7.06998 0.629971C7.44998 0.249971 8.08998 0.249971 8.46998 0.629971ZM8.47998 8.63997C8.86998 9.02997 8.86998 9.65997 8.47998 10.05L4.24998 14.3C3.85998 14.69 3.22998 14.69 2.82998 14.3L0.69998 12.16C0.607398 12.0674 0.533958 11.9575 0.483853 11.8365C0.433748 11.7155 0.407959 11.5859 0.407959 11.455C0.407959 11.324 0.433748 11.1944 0.483853 11.0734C0.533958 10.9525 0.607398 10.8426 0.69998 10.75C0.792561 10.6574 0.902472 10.5839 1.02344 10.5338C1.1444 10.4837 1.27405 10.4579 1.40498 10.4579C1.53591 10.4579 1.66556 10.4837 1.78652 10.5338C1.90749 10.5839 2.0174 10.6574 2.10998 10.75L3.52998 12.17L7.06998 8.62997C7.44998 8.24997 8.08998 8.24997 8.47998 8.63997Z" />
    </SvgIcon>
  );
}
