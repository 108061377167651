import { useState } from "react";

export interface IDevicesState {
  isPopupOpen: boolean;
  allowUnlinking: boolean;
  openPopup: (allowUnlinking: boolean) => void;
  closePopup: () => void;
  isDeleteConfirmationPopupOpen: boolean;
  setIsDeleteConfirmationPopupOpen: (
    isDeleteConfirmationPopupOpen: boolean,
  ) => void;
  isDeleteErrorPopupOpen: boolean;
  setIsDeleteErrorPopupOpen: (isDeleteErrorPopupOpen: boolean) => void;
  deleteConfirmationAction: Function;
  setDeleteConfirmationAction: (deleteConfirmationAction: () => void) => void;
}

export const useDevicesState = (): IDevicesState => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [allowUnlinking, setAllowUnlinking] = useState<boolean>(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );

  const openPopup = (allowUnlinking: boolean = false) => {
    setAllowUnlinking(allowUnlinking);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setAllowUnlinking(false);
  };

  return {
    isPopupOpen,
    allowUnlinking,
    openPopup,
    closePopup,
    isDeleteConfirmationPopupOpen,
    setIsDeleteConfirmationPopupOpen,
    isDeleteErrorPopupOpen,
    setIsDeleteErrorPopupOpen,
    deleteConfirmationAction,
    setDeleteConfirmationAction,
  };
};
