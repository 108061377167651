import { useTranslation } from "react-i18next";
import { ReactElement, useEffect, useState } from "react";
import "./devices-list.scss";
import { LinkOutlined } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import { Controller } from "react-hook-form";
import ResidentDetailsLinkableDevicesModal from "features/residents/residents-settings/views/resident-details/devices-list/resident-details-linkable-devices-modal.component";
import { useResidentSettingsContextProvider } from "features/residents/residents-settings/context/resident-settings-provider";
import Permission from "features/autorisation/domain/models/permission";
import { useAuth } from "features/authentication/providers/authentication.provider";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { toMonitoringType } from "models/fault-type";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { ResidentDevice } from "features/residents/residents-settings/domain/models/resident-device";

interface IProps {
  organisationUnitId: string;
}

export function DevicesList(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const { hasPermission } = useAuth();

  const [linkableDevicesModalIsOpen, setLinkableDevicesModalIsOpen] =
    useState<boolean>(false);

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const [unlinkedDevices, setUnlinkedDevices] = useState<ResidentDevice[]>([]);

  function handleOnDevicesLinkButtonClicked(
    selectedDevices: ResidentDevice[],
  ): void {
    setUnlinkedDevices((current) =>
      current.filter((unlinkedDevice) =>
        isSelectedDevice(unlinkedDevice, selectedDevices),
      ),
    );

    residentDetailsHook.form.setValue(
      "devices",
      [...residentDetailsHook.form.getValues("devices")].concat(
        selectedDevices,
      ),
      {
        shouldDirty: true,
      },
    );

    setLinkableDevicesModalIsOpen(false);
  }

  function isSelectedDevice(
    device: ResidentDevice,
    selectedDevices: ResidentDevice[],
  ): boolean {
    return (
      selectedDevices.findIndex(
        (selectedDevice) => selectedDevice.id === device.id,
      ) === -1
    );
  }

  useEffect(() => {
    if (residentDetailsHook.viewingMode === "viewing") {
      setUnlinkedDevices([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentDetailsHook.viewingMode]);

  function handleOnUnlinkDeviceButtonClicked(
    selectedDevice: ResidentDevice,
  ): void {
    setUnlinkedDevices([...unlinkedDevices, selectedDevice]);
    residentDetailsHook.form.setValue(
      "devices",
      [...residentDetailsHook.form.getValues("devices")].filter(
        (device) => device.id !== selectedDevice.id,
      ),
      { shouldDirty: true },
    );
  }

  function handleOnDeviceConnectionButtonClicked(): void {
    setLinkableDevicesModalIsOpen(true);
  }

  const renderIcon = (device: ResidentDevice): ReactElement => {
    const monitoringType: MonitoringType | undefined = toMonitoringType(
      device.fault,
    );

    return monitoringType ? (
      <MonitoringIcon type={monitoringType} withTooltip visible size={16} />
    ) : (
      <></>
    );
  };

  const renderDevices = (linkedDevices: ResidentDevice[]) => {
    return (linkedDevices && linkedDevices.length > 0) ||
      (unlinkedDevices && unlinkedDevices?.length > 0) ? (
      <div className="resident-details-devices-container">
        {linkedDevices?.map((linkedDevice) => {
          return (
            <div key={linkedDevice.id} className="device-container">
              <Typography
                className="device-name"
                variant="subtitle1"
                data-testid={`deviceNameTextFor${linkedDevice.name}`}
              >
                {linkedDevice.name}
              </Typography>
              {renderIcon(linkedDevice)}
              {residentDetailsHook.viewingMode !== "viewing" &&
                hasPermission(Permission.LinkDevice) && (
                  <Button
                    startIcon={<LinkOffOutlinedIcon />}
                    onClick={() =>
                      handleOnUnlinkDeviceButtonClicked(linkedDevice)
                    }
                    variant="text"
                    data-testid={`unlinkDeviceButtonFor${linkedDevice.name}`}
                  >
                    {t("devicesList.unlinkButtonText")}
                  </Button>
                )}
            </div>
          );
        })}
        {unlinkedDevices?.map((unlinkedDevice) => {
          return (
            <div key={unlinkedDevice.id} className="device-container">
              <Typography
                variant="subtitle1"
                data-testid={`deviceNameTextFor${unlinkedDevice.name}`}
                className="unlinked-device device-name"
              >
                {unlinkedDevice.name}
              </Typography>
              {renderIcon(unlinkedDevice)}
              {residentDetailsHook.viewingMode !== "viewing" &&
                hasPermission(Permission.LinkDevice) && (
                  <AutorisationWrapper>
                    <Button
                      startIcon={<LinkOutlined />}
                      onClick={() =>
                        handleOnDevicesLinkButtonClicked([unlinkedDevice])
                      }
                      variant="text"
                      data-testid={`relinkDeviceButtonFor${unlinkedDevice.name}`}
                    >
                      {t("devicesList.relinkButtonText")}
                    </Button>
                  </AutorisationWrapper>
                )}
            </div>
          );
        })}
      </div>
    ) : (
      <Typography variant="subtitle1">
        {t("details.noDevicesLinked")}
      </Typography>
    );
  };

  return (
    <div className={"resident-devices-list-container"}>
      <Controller
        name="devices"
        control={residentDetailsHook.form.control}
        render={({ field }) => (
          <>
            {renderDevices(field.value)}
            <ResidentDetailsLinkableDevicesModal
              isOpen={linkableDevicesModalIsOpen}
              onClose={() => setLinkableDevicesModalIsOpen(false)}
              onLinkButtonClicked={handleOnDevicesLinkButtonClicked}
              organisationUnitId={props.organisationUnitId}
              devicesToHide={field.value}
            />
          </>
        )}
      />
      {(residentDetailsHook.viewingMode === "creation" ||
        residentDetailsHook.viewingMode === "editing") &&
      hasPermission(Permission.LinkDevice) ? (
        <Button
          className="link-devices-button"
          startIcon={<LinkOutlined />}
          variant="outlined"
          onClick={handleOnDeviceConnectionButtonClicked}
          data-testid="linkDeviceButton"
        >
          {t("details.linkDeviceButtonText")}
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default DevicesList;
