import { MonitoringType } from "components/monitoring/monitoring-type";

export enum FaultType {
  Offline = "Offline",
  BatteryCritical = "BatteryCritical",
  BatteryLow = "BatteryLow",
  OutDated = "OutDated",
  GatewayDown = "GatewayDown",
}

export function toMonitoringType(
  fault: FaultType | undefined,
): MonitoringType | undefined {
  switch (fault) {
    case FaultType.Offline:
      return MonitoringType.Offline;
    case FaultType.BatteryCritical:
      return MonitoringType.BatteryCritical;
    case FaultType.BatteryLow:
      return MonitoringType.BatteryLow;
    case FaultType.OutDated:
      return MonitoringType.OutDated;
    case FaultType.GatewayDown:
      return MonitoringType.GatewayOffline;
    default:
      return undefined;
  }
}
