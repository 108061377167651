import PageHeader from "components/page-header/page-header";
import SettingsNavigation from "features/settings/views/settings-navigation";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FaultTypes,
  MalfunctionNotificationRecipient,
} from "../domain/models/malfunction-notification";
import "./malfunction-notification-settings.scss";
import useMalfunctionNotificationSettings from "../context/malfunction-notification-settings-hook";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckRounded from "@mui/icons-material/CheckRounded";
import { Divider, IconButton, TextField, Typography } from "@mui/material";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import KeyValuePair from "models/key-value-pair";
import MultiValueSelect from "components/multi-value-select/multi-select-field.component";
import { Add, DeleteForeverOutlined } from "@mui/icons-material";
import MalfunctionNotificationCommand from "../domain/models/malfunction-notification-command";
import { setShouldShowConfirmation } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useDispatch } from "react-redux";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import useRecipientValidation from "../hooks/recipient-validation-hook";

function MalfunctionNotificationSettings(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation("malfunctionNotifications");
  const {
    malfunctionNotifications,
    malfunctionNotificationIsLoading,
    handleSave,
  } = useMalfunctionNotificationSettings();
  const [isRecipientsEmpty, setIsRecipientsEmpty] = useState(true);

  const getFaultTypes = (): KeyValuePair[] =>
    Object.entries(FaultTypes).map(([key, value]) => {
      return {
        key,
        value: t(`notifications.faultTypes.${value}`),
      };
    });

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<{
    recipients: MalfunctionNotificationRecipient[];
  }>({
    mode: "onBlur",
  });

  const watchFields = watch("recipients");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const { isValidEmail, isUniqueEmail } = useRecipientValidation();
  const noRecipients =
    isRecipientsEmpty && fields.length < 1 && !malfunctionNotificationIsLoading;

  const registerOptions = {
    email: {
      required: t("notifications.email.required"),
      validate: {
        isValidEmail,
        isUniqueEmail: (value: string) => isUniqueEmail(value, watchFields),
      },
    },
    FaultTypes: {
      required: t("notifications.faultTypes.required"),
    },
  };

  const onSubmit: SubmitHandler<{
    recipients: MalfunctionNotificationRecipient[];
  }> = (data) => {
    const notification: MalfunctionNotificationCommand = {
      isActivelyMailing: true,
      recipients: data.recipients,
    };

    handleSave(notification);
  };

  useEffect(() => {
    if (malfunctionNotifications) {
      const allRecipients = malfunctionNotifications.flatMap((notification) =>
        notification.recipients.map((recipient) => ({
          id: recipient.id,
          templateId: recipient.templateId,
          email: recipient.email,
          faultTypes: recipient.faultTypes,
        })),
      );

      setValue("recipients", [...allRecipients]);
      reset({ recipients: [...allRecipients] });
      setIsRecipientsEmpty(allRecipients.length === 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [malfunctionNotifications, setValue, getValues]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <>
      <PageHeader
        title={t("pageHeaderTitle")}
        navigationComponent={<SettingsNavigation />}
      />
      <div className="malfunction-notifications-content">
        <div className="save-settings">
          <Typography variant="h5">{t("notifications.title")}</Typography>
          <LoadingButton
            disabled={!isDirty}
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            {t("notifications.save")}
          </LoadingButton>
        </div>
        <div className="settings">
          <Typography variant="body1">
            {t("notifications.subTitle.email")}
          </Typography>
          <Typography variant="body1">
            {t("notifications.subTitle.faultTypes")}
          </Typography>
          <form>
            {fields.length === 0 && <Divider />}
            {!malfunctionNotificationIsLoading && noRecipients && (
              <div className="no-recipients">
                <Typography variant="body1">
                  {t("notifications.empty")}
                </Typography>
              </div>
            )}
            {malfunctionNotificationIsLoading && <LoadingIndicator />}
            {fields.map((field, index) => {
              const error = errors.recipients?.[index];
              return (
                <React.Fragment key={field.id}>
                  <Divider />
                  <div className="row">
                    <Controller
                      name={`recipients.${index}.email`}
                      control={control}
                      rules={registerOptions.email}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t("notifications.email.label")}
                          variant="outlined"
                          error={!!error?.email}
                          helperText={error?.email?.message}
                        />
                      )}
                    />
                    <Controller
                      name={`recipients.${index}.faultTypes`}
                      control={control}
                      rules={registerOptions.FaultTypes}
                      render={({ field }) => (
                        <MultiValueSelect
                          field={{ ...field }}
                          label={t("notifications.faultTypes.label")}
                          options={getFaultTypes()}
                          error={!!error?.faultTypes}
                          helperText={error?.faultTypes?.message}
                        />
                      )}
                    />
                    <IconButton
                      className="remove-button"
                      onClick={() => remove(index)}
                    >
                      <DeleteForeverOutlined />
                    </IconButton>
                  </div>
                </React.Fragment>
              );
            })}
            <Button
              variant="text"
              startIcon={<Add />}
              onClick={() =>
                append({
                  email: "",
                  faultTypes: [],
                })
              }
            >
              {t("notifications.add")}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default MalfunctionNotificationSettings;
