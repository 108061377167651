import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { ReadSystemsQuery } from "features/systems/domain/read-systems-query";
import { System } from "features/systems/domain/system";
import SystemFilter, {
  SystemFilterValueType,
} from "features/systems/domain/system-filter";
import useTableHook, { ITableHook } from "hooks/table-hook";
import { useEffect, useState } from "react";
import { useSessionStorage } from "usehooks-ts";

export interface SystemFiltersHook {
  activeFilters: SystemFilter[];
  searchBarValue: string;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  submitSearch: (query: string) => void;
  selectOption: (
    filterValueType: SystemFilterValueType,
    keys: string[],
  ) => void;
  clearFilters: () => void;

  readSystemsQuery: ReadSystemsQuery;

  table: ITableHook<System>;
}

const useSystemFilters = (): SystemFiltersHook => {
  const [activeFilters, setActiveFilters] = useState<SystemFilter[]>([]);
  const [readSystemsQuery, setReadSystemsQuery] = useState<ReadSystemsQuery>({
    filters: Array<SystemFilter>(),
  } as ReadSystemsQuery);

  const table = useTableHook<System>({
    defaultSort: {
      isAscending: true,
      property: "name",
    },
    sessionStorageKey: "systems-sort",
  });

  const [systemFiltersFromSessionStorage, setSystemFiltersFromSessionStorage] =
    useSessionStorage<SystemFilter[]>("system-filters", []);
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-system-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-system-filters", "");

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    table.sortFromSessionStorage,
    systemFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    table.resetPaging();
  };

  const selectOption = (
    filterValueType: SystemFilterValueType,
    keys: string[],
  ) => {
    let updatedFiltersList = [...readSystemsQuery.filters];
    const filterIndex = updatedFiltersList.findIndex(
      (x) => x.filterValueType === filterValueType,
    );

    if (filterIndex !== -1) {
      updatedFiltersList[filterIndex] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setSystemFiltersFromSessionStorage(updatedFiltersList);
    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) =>
    setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);

  const clearFilters = () => {
    setActiveFilters([]);
    setSystemFiltersFromSessionStorage([]);
    setSelectedOrganisationUnitsFromSessionStorage([]);
    setReadSystemsQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    table.resetPaging();
  };

  const buildAndSaveQuery = () => {
    setActiveFilters(systemFiltersFromSessionStorage);
    setReadSystemsQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      sort: table.sortFromSessionStorage ?? prevState.sort,
      filters: systemFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  };

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readSystemsQuery,

    selectOption,
    submitSearch,
    clearFilters,

    table,
  };
};

export default useSystemFilters;
