import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import { forwardRef } from "react";

type IProps = SelectProps & {
  helperText?: string;
};

const SelectField = forwardRef<HTMLSelectElement, IProps>(
  (props: Readonly<IProps>, ref) => {
    const { helperText, ...forwardProps } = props;

    return (
      <FormControl fullWidth>
        {forwardProps.label && (
          <InputLabel id="test-select-label">{forwardProps.label}</InputLabel>
        )}
        <Select {...forwardProps} ref={ref} />
        {props.helperText && (
          <FormHelperText
            error={forwardProps.error}
            className="select-field-helper-text"
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default SelectField;
