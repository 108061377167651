import ObjectSort from "models/object-sort";
import Pagination from "models/pagination";
import { Dispatch, SetStateAction, useState } from "react";
import { useSessionStorage } from "usehooks-ts";
import { NestedKeyof } from "utils/nested-keyof-utils";

export interface ITableSortInitializer<T extends object> {
  sessionStorageKey: string;
  defaultSort: ObjectSort<T>;
}

export interface ITableHook<T extends object> {
  currentPagination: [Pagination, Dispatch<SetStateAction<Pagination>>];
  currentSearchQuery: [string, Dispatch<SetStateAction<string>>];
  currentPage: number;
  currentRowsPerPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setCurrentRowsPerPage: Dispatch<SetStateAction<number>>;
  handleOnRowsPerPageChanged: (rowsPerPage: number) => void;
  handleOnPageChanged: (page: number) => void;
  handleOnSearchChanged: (value: string) => void;
  handleOnSortChanged: (property: NestedKeyof<T>, isAscending: boolean) => void;
  resetPaging: () => void;
  sortFromSessionStorage: ObjectSort<T> | undefined;
}

const useTableHook = <T extends object>(
  tableSortInitializer: ITableSortInitializer<T> | undefined = undefined,
): ITableHook<T> => {
  const [currentPagination, setCurrentPagination] = useState<Pagination>({
    skip: 0,
    take: 50,
  });
  const [currentSearchQuery, setCurrentSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(50);
  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<T> | undefined
  >(
    tableSortInitializer?.sessionStorageKey ?? "",
    tableSortInitializer?.defaultSort ?? undefined,
  );

  function handleOnRowsPerPageChanged(rowsPerPage: number) {
    setCurrentRowsPerPage(rowsPerPage);
    setCurrentPage(0);
    setCurrentPagination({
      skip: 0,
      take: rowsPerPage,
    });
  }

  function handleOnPageChanged(page: number) {
    setCurrentPage(page);
    setCurrentPagination({
      skip: page * currentRowsPerPage,
      take: currentRowsPerPage,
    });
  }

  function handleOnSearchChanged(value: string) {
    setCurrentSearchQuery(value);
    setCurrentPage(0);
    handleOnPageChanged(0);
  }

  function resetPaging() {
    setCurrentPage(0);
    handleOnPageChanged(0);
  }

  function handleOnSortChanged(property: NestedKeyof<T>, isAscending: boolean) {
    setSortFromSessionStorage({
      isAscending: isAscending,
      property: property,
    });

    setCurrentPage(0);
    setCurrentPagination({
      skip: 0,
      take: currentRowsPerPage,
    });
  }

  return {
    currentPagination: [currentPagination, setCurrentPagination],
    currentSearchQuery: [currentSearchQuery, setCurrentSearchQuery],
    currentPage,
    currentRowsPerPage,
    setCurrentPage,
    setCurrentRowsPerPage,
    handleOnRowsPerPageChanged: handleOnRowsPerPageChanged,
    handleOnPageChanged: handleOnPageChanged,
    handleOnSearchChanged: handleOnSearchChanged,
    handleOnSortChanged,
    resetPaging,
    sortFromSessionStorage,
  };
};

export default useTableHook;
