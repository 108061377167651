import { useEffect } from "react";
import { useLazyReadResidentsQuery } from "../domain/reducers/resident.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useAppDispatch } from "redux-base/store";
import ReadResidentsResponse from "../domain/models/read-residents-response";
import useResidentSettingsFilters, {
  IResidentSettingsFiltersHook,
} from "../hooks/resident-settings-filters-hook";
import useResidentDetails, {
  IResidentDetailsHook,
} from "../hooks/resident-details-hook";
import Resident from "../domain/models/resident";
import useResidentsBulkEdit, {
  IResidentsBulkEditHook,
} from "../hooks/residents-bulk-edit-hook";

export interface IResidentSettingsHook {
  readResidentsResponse: ReadResidentsResponse | undefined;
  readResidentsIsLoading: boolean;
  readResidentsIsSuccess: boolean;

  residentSettingsFiltersHook: IResidentSettingsFiltersHook;
  residentDetailsHook: IResidentDetailsHook;
  residentsBulkEditHook: IResidentsBulkEditHook;

  openDetailsAndRefresh: (resident: Resident) => void;
  setSelectedResidents: (residents: Resident[]) => void;
}

const useResidentSettingsProvider = (): IResidentSettingsHook => {
  const dispatch = useAppDispatch();

  const residentSettingsFiltersHook = useResidentSettingsFilters();
  const residentDetailsHook = useResidentDetails();
  const residentsBulkEditHook = useResidentsBulkEdit();

  const [
    triggerReadResidentsQuery,
    {
      data: readResidentsResponse,
      isSuccess: readResidentsIsSuccess,
      isLoading: readResidentsIsLoading,
      error: readResidentsError,
    },
  ] = useLazyReadResidentsQuery();

  useEffect(() => {
    residentDetailsHook.closeDetails();
    setSelectedResidents([]);
    triggerReadResidentsQuery(
      residentSettingsFiltersHook.readResidentSettingsQuery,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [residentSettingsFiltersHook.readResidentSettingsQuery]);

  useEffect(() => {
    if (residentDetailsHook.currentResident) {
      const updatedCurrentResident = readResidentsResponse?.residents.find(
        (resident) =>
          resident.organisationUnitId ===
          residentDetailsHook.currentResident!.organisationUnitId,
      );

      if (updatedCurrentResident) {
        residentDetailsHook.changeCurrentResident(updatedCurrentResident);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readResidentsResponse]);

  useEffect(() => {
    if (readResidentsError) {
      dispatch(
        setErrorMessage({
          error: readResidentsError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readResidentsError]);

  function openDetailsAndRefresh(resident: Resident) {
    if (residentsBulkEditHook.selectedResidents.length > 0) {
      return;
    }
    triggerReadResidentsQuery(
      residentSettingsFiltersHook.readResidentSettingsQuery,
    );
    residentDetailsHook.openDetails(resident);
  }

  function setSelectedResidents(residents: Resident[]) {
    residentDetailsHook.closeDetails();
    residentsBulkEditHook.selectResidents(
      residents,
      readResidentsResponse?.residents ?? [],
    );
  }

  return {
    readResidentsResponse,
    readResidentsIsSuccess,
    readResidentsIsLoading,

    residentSettingsFiltersHook,
    residentDetailsHook,
    residentsBulkEditHook,
    openDetailsAndRefresh,
    setSelectedResidents,
  };
};

export default useResidentSettingsProvider;
