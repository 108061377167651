import React, { ReactElement, ReactNode } from "react";
import useResidentSettings, {
  IResidentSettingsHook,
} from "./resident-settings-hook";

const ResidentSettingsContext = React.createContext<IResidentSettingsHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const ResidentSettingsProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <ResidentSettingsContext.Provider value={useResidentSettings()}>
      {props.children}
    </ResidentSettingsContext.Provider>
  );
};

export const useResidentSettingsContextProvider = (): IResidentSettingsHook =>
  React.useContext<IResidentSettingsHook>(ResidentSettingsContext);
