import { ReactElement, ReactNode, createContext, useContext } from "react";
import useHistoryProvider, {
  IHistoryProviderHook,
} from "../hooks/history-hook";

const HistoryContext = createContext<IHistoryProviderHook>(
  {} as IHistoryProviderHook,
);

export type HistoryProviderProps = {
  children: ReactNode;
};

const HistoryProvider = ({ children }: HistoryProviderProps): ReactElement => (
  <HistoryContext.Provider value={useHistoryProvider()}>
    {children}
  </HistoryContext.Provider>
);

const useHistoryContextProvider = (): IHistoryProviderHook =>
  useContext<IHistoryProviderHook>(HistoryContext);

export { HistoryProvider, useHistoryContextProvider };
