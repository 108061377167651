import { Box, Button, Modal, Typography } from "@mui/material";
import "./pop-up.component.scss";
import { ReactElement } from "react";
import { LoadingButton } from "@mui/lab";

interface IProps {
  isOpen: boolean;
  handleOnClose: React.ReactEventHandler<{}>;
  title: string;
  body: string | ReactElement;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryButtonAction: React.ReactEventHandler<{}>;
  secondaryButtonAction?: React.ReactEventHandler<{}>;
  isLoadingPrimaryAction?: boolean;
}

function PopUp(props: Readonly<IProps>): ReactElement {
  return (
    <Modal open={props.isOpen} onClose={props.handleOnClose}>
      <Box className="modal-container">
        <Typography variant="h4">{props.title}</Typography>
        <Typography
          className="content"
          margin="16px 0px 24px 0px"
          variant="subtitle1"
        >
          {props.body}
        </Typography>
        <div className="buttons">
          {props.secondaryButtonText && props.secondaryButtonAction && (
            <Button
              data-testid={"popup-button-" + props.secondaryButtonText}
              className="button"
              variant="text"
              onClick={props.secondaryButtonAction}
            >
              {props.secondaryButtonText}
            </Button>
          )}
          <LoadingButton
            data-testid={
              "popup-primary-action-button-" + props.primaryButtonText
            }
            className="button"
            variant="contained"
            onClick={props.primaryButtonAction}
            loading={props.isLoadingPrimaryAction}
            loadingPosition="center"
          >
            {props.primaryButtonText}
          </LoadingButton>
        </div>
      </Box>
    </Modal>
  );
}

export default PopUp;
