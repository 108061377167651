import { createContext, useContext, useEffect, useMemo } from "react";
import { useLazyGetMinimumLogLevelQuery } from "../reducers/configuration.reducer";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsights } from "AppInsights";

interface ILoggingContext {
  log: (message: string, severityLevel: SeverityLevel) => void;
}

const LoggingContext = createContext<ILoggingContext>({} as ILoggingContext);

const useLogging = () => useContext(LoggingContext);

function LoggingProvider({
  children,
}: Readonly<{ children: React.ReactNode }>): JSX.Element {
  const { tenant } = useAuth();

  const [triggerGetMinimumLogLevelQuery, { data }] =
    useLazyGetMinimumLogLevelQuery();

  useEffect(() => {
    if (tenant) {
      triggerGetMinimumLogLevelQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const value: ILoggingContext = useMemo(() => {
    const log = (message: string, severityLevel: SeverityLevel) => {
      let minimumLogLevel = SeverityLevel.Information;

      switch (data?.minimumLogLevel?.toLowerCase()) {
        case "warning":
          minimumLogLevel = SeverityLevel.Warning;
          break;
        case "critical":
          minimumLogLevel = SeverityLevel.Critical;
          break;
        case "error":
          minimumLogLevel = SeverityLevel.Error;
          break;
        case "trace":
          minimumLogLevel = SeverityLevel.Verbose;
          break;
      }

      if (
        severityLevel >= minimumLogLevel ||
        minimumLogLevel === SeverityLevel.Verbose
      ) {
        appInsights.trackTrace(
          { message: message, severityLevel: severityLevel },
          { ApplicationName: "Sonevo Cloud Portal" },
        );
      }
    };

    return {
      log,
    };
  }, [data]);

  return (
    <LoggingContext.Provider value={value}>{children}</LoggingContext.Provider>
  );
}

export { LoggingProvider, useLogging };
