import { ReactElement } from "react";
import { ViewingMode } from "utils/viewing-utils";
import RolePermissionsComponent from "./permissions/role-permissions-component";
import "./role-details-popup.component.scss";
import { RoleType } from "features/role/domain/models/role-type";
import RoleOrganisationPickerComponent from "./role-organisation-picker.component";

interface IProps {
  viewingMode: ViewingMode;
  roleType: RoleType;
  initialSelectedOrganisationUnit?: string;
}

function RoleDetailsPopupRightContent(props: Readonly<IProps>): ReactElement {
  return props.roleType === RoleType.Permissions ? (
    <RolePermissionsComponent viewingMode={props.viewingMode} />
  ) : (
    <RoleOrganisationPickerComponent
      key={"AccessRightOrganisationPicker"}
      viewingMode={props.viewingMode}
      initialSelectedOrganisationUnit={props.initialSelectedOrganisationUnit}
    />
  );
}

export default RoleDetailsPopupRightContent;
