import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGenerateSecretsMutation } from "features/gateway/domain/reducers/gateway.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Gateway from "features/gateway/domain/models/gateway";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

export interface IGatewaySettingsIoTEdgeClusterClientHook {
  deviceId: string | undefined;
  ioTEdgeConnectionString: string;
  generateIoTEdgeConnectionStringIsLoading: boolean;
  isGenerateConfirmationOpen: boolean;
  defaultIoTEdgeConnectionString: string;

  generateIoTEdgeClusterDeviceConnectionString: () => void;
  submitGenerateIoTEdgeClusterDeviceConnectionString: () => void;
  closeGenerateConfirmationPopup: () => void;
}

const useGatewaySettingsIoTEdgeClusterClient = (
  childGateway: Gateway | undefined,
): IGatewaySettingsIoTEdgeClusterClientHook => {
  const dispatch = useDispatch();

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const defaultIoTEdgeConnectionString = "********";

  const [isGenerateConfirmationOpen, setIsGenerateConfirmationOpen] =
    useState(false);
  const [deviceId, setDeviceId] = useState(childGateway?.deviceId);
  const [ioTEdgeConnectionString, setIoTEdgeConnectionString] = useState(
    defaultIoTEdgeConnectionString,
  );

  const [
    generateSecrets,
    {
      isLoading: generateSecretIsLoading,
      isError: generateSecretIsError,
      error: generateSecretError,
      isSuccess: generateSecretIsSuccess,
      data: generateSecretData,
    },
  ] = useGenerateSecretsMutation();

  useEffect(() => {
    if (generateSecretIsError && generateSecretError) {
      dispatch(
        setErrorMessage({
          error: generateSecretError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSecretIsError]);

  useEffect(() => {
    if (generateSecretData) {
      setDeviceId(generateSecretData.deviceId);
      setIoTEdgeConnectionString(
        generateSecretData.ioTEdgeConnectionString ??
          defaultIoTEdgeConnectionString,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSecretIsSuccess]);

  useEffect(() => {
    setDeviceId(childGateway?.deviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gatewayDetailsHook.currentSelectedGateway]);

  const generateIoTEdgeConnectionString = () => {
    if (deviceId && deviceId.length > 0) {
      setIsGenerateConfirmationOpen(true);
    } else {
      generateSecrets(childGateway!.id!);
      setIsGenerateConfirmationOpen(false);
    }
  };

  const closeGenerateConfirmationPopup = () =>
    setIsGenerateConfirmationOpen(false);

  const submitGenerateIoTEdgeConnectionString = () => {
    generateSecrets(childGateway!.id!);
    setIsGenerateConfirmationOpen(false);
  };

  return {
    deviceId,
    ioTEdgeConnectionString,
    generateIoTEdgeConnectionStringIsLoading: generateSecretIsLoading,
    isGenerateConfirmationOpen,
    defaultIoTEdgeConnectionString,

    generateIoTEdgeClusterDeviceConnectionString:
      generateIoTEdgeConnectionString,
    submitGenerateIoTEdgeClusterDeviceConnectionString:
      submitGenerateIoTEdgeConnectionString,
    closeGenerateConfirmationPopup,
  };
};

export default useGatewaySettingsIoTEdgeClusterClient;
