import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import { ConnectionTypeResponse } from "../models/connection-type";

const baseUrl = "/api/v1/settings";

export const ConnectionTypeApi = createApi({
  reducerPath: "connectionType",
  baseQuery,
  tagTypes: ["ConnectionType"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readConnectionType: build.query<ConnectionTypeResponse, void>({
      query: () => ({
        url: `${baseUrl}/ReadConnectionType`,
        method: "GET",
      }),
      providesTags: ["ConnectionType"],
    }),
    updateConnectionType: build.mutation<void, ConnectionTypeResponse>({
      query: (body) => ({
        url: `${baseUrl}/UpdateConnectionType`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ConnectionType"],
    }),
  }),
});

export const { useUpdateConnectionTypeMutation, useReadConnectionTypeQuery } =
  ConnectionTypeApi;
