import { Dialog } from "@mui/material";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import DeviceDetailsPopupHeader from "features/device/device-details/views/device-details-popup-header.component";
import DeviceDetailsPopupInformation from "features/device/device-details/views/device-details-popup-information.component";
import "features/device/device-details/views/device-details-popup.component.scss";
import DeviceDetailsPopupSettings from "features/device/device-details/views/device-details-settings/device-details-popup-settings.component";
import { ReactElement } from "react";
import { FormProvider } from "react-hook-form";

function DeviceDetailsPopup(): ReactElement {
  const { submitDevice, handleClosePopup, form, isPopupOpen, isLoading } =
    useDeviceDetailsHook();
  return (
    <Dialog
      open={isPopupOpen}
      onClose={() => handleClosePopup()}
      className="device-details-popup"
      PaperProps={{ classes: { root: "device-details-popup-paper" } }}
    >
      <FormProvider {...form}>
        <DetailsPopUpContent
          onSubmit={form.handleSubmit(submitDevice)}
          leftContent={isLoading ? <></> : <DeviceDetailsPopupInformation />}
          rightContent={
            isLoading ? <LoadingIndicator /> : <DeviceDetailsPopupSettings />
          }
          header={<DeviceDetailsPopupHeader />}
        />
      </FormProvider>
    </Dialog>
  );
}

export default DeviceDetailsPopup;
