import MonitoringFilter from "components/monitoring/monitoring-filter.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

interface IProps {
  count: number;
  countColorOverride?: string;
  isSelected: boolean;
  onSelected: (selected: boolean) => void;
}

function BatteryCritical(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.batteryCritical")}
      type={MonitoringType.BatteryCritical}
      countColor={Constants.Colors.error}
    />
  );
}

function BatteryLow(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.batteryLow")}
      type={MonitoringType.BatteryLow}
      countColor={Constants.Colors.brightOrange}
    />
  );
}

function Offline(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.offline")}
      type={MonitoringType.Offline}
      countColor={Constants.Colors.error}
    />
  );
}

function Online(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.online")}
      type={MonitoringType.Online}
      countColor={Constants.Colors.success}
    />
  );
}

function Outdated(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.outDated")}
      type={MonitoringType.OutDated}
      countColor={Constants.Colors.error}
    />
  );
}

function NotMonitored(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("monitoring");

  return (
    <MonitoringFilter
      {...props}
      text={t("filter.notMonitored")}
      type={MonitoringType.NotMonitored}
      countColor={Constants.Colors.onSurface}
    />
  );
}

const DeviceMonitoringFilter = {
  MonitoringFilter: MonitoringFilter,
  Offline: Offline,
  Online: Online,
  BatteryCritical: BatteryCritical,
  BatteryLow: BatteryLow,
  Outdated: Outdated,
  NotMonitored: NotMonitored,
};

export default DeviceMonitoringFilter;
