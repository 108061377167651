function objectToLowerCase(obj: any) {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof key === "string") {
      delete obj[key];
      obj[key.charAt(0).toLowerCase() + key.slice(1)] = value;
    }

    if (value instanceof Array) {
      value.forEach((v) => objectToLowerCase(v));
    } else if (value instanceof Object) {
      objectToLowerCase(value);
    }
  }
}

export function jsonParseWithLowerCaseKeys(
  jsonString: string | undefined,
): object {
  if (!jsonString) {
    return {};
  }

  let obj = JSON.parse(jsonString);
  objectToLowerCase(obj);

  return obj;
}

function objectToUpperCase(obj: any) {
  for (const [key, value] of Object.entries(obj)) {
    if (typeof key === "string") {
      delete obj[key];
      obj[key.charAt(0).toUpperCase() + key.slice(1)] = value;
    }

    if (value instanceof Array) {
      value.forEach((v) => objectToUpperCase(v));
    } else if (value instanceof Object) {
      objectToUpperCase(value);
    }
  }
}

export function jsonParseWithUpperCaseKeys(obj: object): string {
  objectToUpperCase(obj);

  return JSON.stringify(obj);
}

export function splitFullNameToInitials(fullName: string | undefined): string {
  return `${fullName?.split(" ").shift()?.charAt(0)}${fullName
    ?.split(" ")
    .pop()
    ?.charAt(0)}`;
}
