import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import { SubscriptionInfo } from "../models/subscription-info";

const baseUrl = "/api/v3/Subscription";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionInfo",
  baseQuery,
  tagTypes: ["GetSubscriptionInfo"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getSubscriptionInfo: build.query<SubscriptionInfo, void>({
      query: () => ({
        url: `${baseUrl}/GetSubscriptionInfo/Portal/All`,
        method: "GET",
      }),
      providesTags: ["GetSubscriptionInfo"],
    }),
  }),
});

export const { useLazyGetSubscriptionInfoQuery } = subscriptionApi;
