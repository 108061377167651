import { useState, useEffect } from "react";
import IMenuItem from "features/menu/views/menu-item";
import routes from "features/routing/routes";

function useSecondaryMenuItems() {
  const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);

  useEffect(() => {
    const defaultMenuItems: IMenuItem[] = [
      {
        titleTranslationKey: "appInformation",
        atLeastOnePermissionOf: routes.appInformation.atLeastOnePermissionOf,
        route: routes.appInformation.path,
      },
    ];

    setMenuItems(defaultMenuItems);
  }, [setMenuItems]);

  return menuItems;
}

export default useSecondaryMenuItems;
