import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import "./app-information.component.scss";
import { useAuth } from "features/authentication/providers/authentication.provider";
import ExternalLink from "./external-link.component";

function AppInformation(): ReactElement {
  const { t } = useTranslation("appInformation");

  const { profile } = useAuth();

  return (
    <div className="app-information-container">
      <Typography variant="h3">{t("title")}</Typography>
      <div className="company-container">
        <Typography variant="h5">{t("company")}</Typography>
        <Typography variant="h4">{profile?.customerRealmName}</Typography>
      </div>

      <div className="privacy-and-terms-container">
        <Typography variant="h5">{t("privacyAndTerms")}</Typography>
        <ExternalLink
          link="https://www.sonevo.nl/privacystatement"
          linkTranslationKey="privacyPolicy"
          linkCompanyTranslationKey="sonevocloud"
        />
      </div>
    </div>
  );
}

export default AppInformation;
