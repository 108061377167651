import { FieldValues } from "react-hook-form";
import { Checkbox, ListItemText, MenuItem } from "@mui/material";
import SelectField from "components/formFields/select-field.component";

interface MultiSelectFieldProps {
  label: string;
  options: { key: string; value: string }[];
  field: FieldValues;
  error: boolean;
  helperText: string | undefined;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  label,
  options,
  field,
  error,
  helperText,
}) => {
  const renderSelectedOptions = (selected: string[]) =>
    selected
      .map((value) => options.find((o) => o.key === value)?.value)
      .join(", ");

  return (
    <SelectField
      multiple
      {...field}
      label={label}
      variant="outlined"
      renderValue={(selected) => renderSelectedOptions(selected as string[])}
      error={error}
      helperText={helperText}
    >
      {options.map((option) => (
        <MenuItem key={`option-${option.key}`} value={option.key}>
          <Checkbox
            sx={{ padding: "0 9px" }}
            checked={field.value.includes(option.key)}
          />
          <ListItemText primary={option.value} />
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default MultiSelectField;
