import axios, { AxiosInstance } from "axios";
import { useAuth } from "features/authentication/providers/authentication.provider";
import authenticationService from "features/authentication/services/authentication.service";
import { useEffect, useRef } from "react";

export interface IAxiosClientsHook {
  authenticatedInstance: AxiosInstance;
  unauthenticatedInstance: AxiosInstance;
  notificationProcessorInstance: AxiosInstance;
}

const baseURL = process.env.REACT_APP_API_BASE_URL ?? "";
const notificationProcessorApiBaseURL =
  process.env.REACT_APP_NOTIFICATION_PROCESSOR_API_BASE_URL ?? "";

const useAxiosClients = (): IAxiosClientsHook => {
  const auth = useAuth();
  const authenticatedInstance = useRef(
    axios.create({
      baseURL,
    }),
  );
  const unauthenticatedInstance = useRef(
    axios.create({
      baseURL,
    }),
  );
  const notificationProcessorInstance = useRef(
    axios.create({
      baseURL: notificationProcessorApiBaseURL,
    }),
  );

  const updateAuthHeader = (
    accessToken: string | undefined,
    instance: AxiosInstance,
  ): void => {
    if (accessToken) {
      instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }
  };

  useEffect(() => {
    updateAuthHeader(auth.user?.access_token, authenticatedInstance.current);
    updateAuthHeader(
      auth.user?.access_token,
      notificationProcessorInstance.current,
    );
  }, [auth.user?.access_token]);

  authenticatedInstance.current.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        authenticationService.logout();
      }
      throw error;
    },
  );

  notificationProcessorInstance.current.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        authenticationService.logout();
      }
      throw error;
    },
  );

  authenticatedInstance.current.defaults.headers.post["Content-Type"] =
    "application/json";
  unauthenticatedInstance.current.defaults.headers.post["Content-Type"] =
    "application/json";
  notificationProcessorInstance.current.defaults.headers.post["Content-Type"] =
    "application/json";
  authenticatedInstance.current.defaults.headers.put["Content-Type"] =
    "application/json";
  unauthenticatedInstance.current.defaults.headers.put["Content-Type"] =
    "application/json";
  notificationProcessorInstance.current.defaults.headers.put["Content-Type"] =
    "application/json";
  updateAuthHeader(auth.user?.access_token, authenticatedInstance.current);
  updateAuthHeader(
    auth.user?.access_token,
    notificationProcessorInstance.current,
  );

  return {
    authenticatedInstance: authenticatedInstance.current,
    unauthenticatedInstance: unauthenticatedInstance.current,
    notificationProcessorInstance: notificationProcessorInstance.current,
  };
};

export default useAxiosClients;
