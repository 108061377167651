import { ScenarioType } from "models/scenario-type";
import {
  jsonParseWithLowerCaseKeys,
  jsonParseWithUpperCaseKeys,
} from "utils/string-utils";

export default interface OrganisationUnitScenario {
  id: string;
  isActive: boolean;
  scenarioType: ScenarioType;
  configuration: string | undefined;
}

export function serialize(
  organisationUnitScenario: OrganisationUnitScenario,
): OrganisationUnitScenario {
  if (organisationUnitScenario.configuration) {
    const configuration = jsonParseWithLowerCaseKeys(
      organisationUnitScenario.configuration,
    );

    organisationUnitScenario.configuration =
      jsonParseWithUpperCaseKeys(configuration);
  }

  return organisationUnitScenario;
}
