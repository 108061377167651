import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import EmergencyAlarmSetting from "../models/emergency-alarm-setting";
import ReadEmergencyAlarmSettingsResponse from "../models/read-emergency-alarm-settings-response";

const baseUrl = "/api/v1/Settings";

export const emergencyAlarmSettingsApi = createApi({
  reducerPath: "emergencyAlarmSettings",
  baseQuery,
  tagTypes: ["EmergencyAlarmSettings"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readEmergencyAlarmSettings: build.query<
      ReadEmergencyAlarmSettingsResponse,
      void
    >({
      query: () => ({
        url: `${baseUrl}/ReadEmergencyAlarmSettings`,
        method: "GET",
      }),
      providesTags: ["EmergencyAlarmSettings"],
    }),
    upsertEmergencyAlarmSetting: build.mutation<
      EmergencyAlarmSetting,
      EmergencyAlarmSetting
    >({
      query: (body) => ({
        url: `${baseUrl}/UpsertEmergencyAlarmSetting`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["EmergencyAlarmSettings"],
    }),
    deleteEmergencyAlarmSetting: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteDivergentEmergencyAlarmSetting/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["EmergencyAlarmSettings"],
    }),
  }),
});

export const {
  useReadEmergencyAlarmSettingsQuery,
  useUpsertEmergencyAlarmSettingMutation,
  useDeleteEmergencyAlarmSettingMutation,
} = emergencyAlarmSettingsApi;
