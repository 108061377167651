import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGenerateSecretsMutation } from "features/gateway/domain/reducers/gateway.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

export interface IGatewaySettingsIoTEdgeClientHook {
  deviceId: string | undefined;
  ioTEdgeConnectionString: string;
  generateIoTEdgeConnectionStringIsLoading: boolean;
  isGenerateConfirmationOpen: boolean;
  defaultIoTEdgeConnectionString: string;

  generateIoTEdgeConnectionString: () => void;
  submitGenerateIoTEdgeConnectionString: () => void;
  closeGenerateConfirmationPopup: () => void;
}

const useGatewaySettingsIoTEdgeClient =
  (): IGatewaySettingsIoTEdgeClientHook => {
    const dispatch = useDispatch();

    const { gatewayDetailsHook } = useGatewaysContextProvider();

    const defaultIoTEdgeConnectionString = "********";

    const [isGenerateConfirmationOpen, setIsGenerateConfirmationOpen] =
      useState(false);
    const [deviceId, setDeviceId] = useState(
      gatewayDetailsHook.currentSelectedGateway?.deviceId,
    );
    const [ioTEdgeConnectionString, setIoTEdgeConnectionString] = useState(
      defaultIoTEdgeConnectionString,
    );

    const [
      generateSecrets,
      {
        isLoading: generateSecretIsLoading,
        isError: generateSecretIsError,
        error: generateSecretError,
        isSuccess: generateSecretIsSuccess,
        data: generateSecretData,
      },
    ] = useGenerateSecretsMutation();

    useEffect(() => {
      if (generateSecretIsError && generateSecretError) {
        dispatch(
          setErrorMessage({
            error: generateSecretError,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateSecretIsError]);

    useEffect(() => {
      if (generateSecretData) {
        setDeviceId(generateSecretData.deviceId);
        setIoTEdgeConnectionString(
          generateSecretData.ioTEdgeConnectionString ??
            defaultIoTEdgeConnectionString,
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateSecretIsSuccess]);

    useEffect(() => {
      setDeviceId(gatewayDetailsHook.currentSelectedGateway?.deviceId);
    }, [gatewayDetailsHook.currentSelectedGateway]);

    const generateIoTEdgeConnectionString = () => {
      if (deviceId && deviceId.length > 0) {
        setIsGenerateConfirmationOpen(true);
      } else {
        generateSecrets(gatewayDetailsHook.currentSelectedGateway!.id!);
        setIsGenerateConfirmationOpen(false);
      }
    };

    const closeGenerateConfirmationPopup = () =>
      setIsGenerateConfirmationOpen(false);

    const submitGenerateIoTEdgeConnectionString = () => {
      generateSecrets(gatewayDetailsHook.currentSelectedGateway!.id!);
      setIsGenerateConfirmationOpen(false);
    };

    return {
      deviceId,
      ioTEdgeConnectionString,
      generateIoTEdgeConnectionStringIsLoading: generateSecretIsLoading,
      isGenerateConfirmationOpen,
      defaultIoTEdgeConnectionString,

      generateIoTEdgeConnectionString,
      submitGenerateIoTEdgeConnectionString,
      closeGenerateConfirmationPopup,
    };
  };

export default useGatewaySettingsIoTEdgeClient;
