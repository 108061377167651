import { ReactElement } from "react";
import "./acoustic-scenario-slider.scss";
import { InputLabel, Slider } from "@mui/material";
import { AccessTimeRounded, MicNoneOutlined } from "@mui/icons-material";

export enum AcousticScenarioSliderType {
  soundThreshold,
  duration,
}

interface IProps {
  sliderType: AcousticScenarioSliderType;
  onChange?: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void;
  value?: number;
  label: string;
  disabled: boolean;
}

const sliderMarks = [
  { value: 1, label: "1" },
  { value: 2 },
  { value: 3 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9, label: "9" },
];
export function AcousticScenarioSlider(props: Readonly<IProps>): ReactElement {
  return (
    <div className="slider-column">
      <InputLabel>{props.label}</InputLabel>
      <div className="slider-row">
        {props.sliderType === AcousticScenarioSliderType.duration && (
          <AccessTimeRounded
            className="slider-icon-small"
            color={props.disabled ? "disabled" : "primary"}
          />
        )}
        {props.sliderType === AcousticScenarioSliderType.soundThreshold && (
          <MicNoneOutlined
            className="slider-icon-small"
            color={props.disabled ? "disabled" : "primary"}
          />
        )}
        <Slider
          value={props.value}
          valueLabelDisplay="auto"
          shiftStep={1}
          step={1}
          marks={sliderMarks}
          min={1}
          max={9}
          disabled={props.disabled}
          onChange={props.onChange}
        />
        {props.sliderType === AcousticScenarioSliderType.duration && (
          <AccessTimeRounded
            className="slider-icon"
            color={props.disabled ? "disabled" : "primary"}
          />
        )}
        {props.sliderType === AcousticScenarioSliderType.soundThreshold && (
          <MicNoneOutlined
            className="slider-icon"
            color={props.disabled ? "disabled" : "primary"}
          />
        )}
      </div>
    </div>
  );
}
export default AcousticScenarioSlider;
