import { Typography } from "@mui/material";
import "./alarm-card.component.scss";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AlarmPriority } from "features/history/domain/models/alarm-priority";
import { AlarmReporterIdentityType } from "features/history/domain/models/alarm-reporter-identity-type";
import AlarmIcon from "components/alarm-icon/alarm-icon.component";
import { AlarmStatus } from "features/history/domain/models/alarm-status";
import Constants from "style/constants";
import { AlarmType } from "features/history/domain/models/alarm-type";
import { getAlarmTimeForAlarmCard } from "utils/date-utils";

interface IProps {
  isDiverted?: boolean;
  priority: AlarmPriority;
  status: AlarmStatus;
  type: string;
  translatedType: string;
  nearbyText?: string;
  message?: string;
  statusLastChangedAt?: string;
  statusLastChangedByFullName: string;
  statusLastChangedByEmail: string;
  isResidentialLocation: boolean;
  isAssistanceType: boolean;
  reporterIdentityType: string;
  reportedByFullName: string;
  organisationUnitName: string;
  descendantOrganisationUnitNames: string[];
}

function AlarmCard(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("alarmCard");

  const isGenericOrEmergency =
    props.type === AlarmType.GenericCall || props.type === AlarmType.Emergency;

  function getPriorityColorClassName(): string {
    switch (props.priority) {
      case AlarmPriority.Highest:
        return "highestPriority";
      case AlarmPriority.High:
        return "highPriority";
      case AlarmPriority.Medium:
        return "mediumPriority";
      default:
        return "defaultPriority";
    }
  }

  function getOrganisationUnitPrefix(): string {
    if (props.isAssistanceType) {
      return props.reporterIdentityType === AlarmReporterIdentityType.User &&
        props.reportedByFullName !== ""
        ? props.reportedByFullName
        : t("colleaguePrefix");
    }

    return t("residentPrefix");
  }

  function getOrganisationUnitName(): string {
    if (props.descendantOrganisationUnitNames.length > 0) {
      return `${
        props.organisationUnitName
      }, ${props.descendantOrganisationUnitNames.join(", ")}`;
    }

    return props.organisationUnitName;
  }

  return (
    <div
      className={`alarm-card-container ${getPriorityColorClassName()} status-${
        props.status
      }`}
    >
      {!!props.isDiverted && (
        <div className="diverted-tag">
          <Typography variant="overline">{t("divertedAlarm")}</Typography>
        </div>
      )}
      <div className="alarm-card-content">
        <div className={`content-names-row status-${props.status}`}>
          <div className="notification-icon-background">
            <AlarmIcon type={props.type} />
          </div>
          <div className="content-names">
            {(props.isResidentialLocation || props.isAssistanceType) && (
              <Typography variant="body1">
                {getOrganisationUnitPrefix()}
              </Typography>
            )}
            <Typography variant="subtitle2">
              {getOrganisationUnitName()}
            </Typography>
          </div>
        </div>
        <div className={`content-information-row status-${props.status}`}>
          <Typography variant="subtitle1">
            {isGenericOrEmergency ? props.message : props.translatedType}
          </Typography>
          <Typography variant="subtitle1">
            {getAlarmTimeForAlarmCard(
              props.statusLastChangedAt,
              t("dateDisplayYesterday"),
            )}
          </Typography>
        </div>

        {props.message && !isGenericOrEmergency && (
          <div className={`content-information-row status-${props.status}`}>
            <Typography variant="subtitle1">{props.message}</Typography>
          </div>
        )}

        {props.nearbyText && (
          <div
            className={`content-nearby-information-row status-${props.status}`}
          >
            <Typography variant="subtitle1">{t("nearby")}</Typography>
            <Typography variant="body1">{props.nearbyText}</Typography>
          </div>
        )}

        {props.status !== AlarmStatus.Reported && (
          <div className="status-container">
            {props.statusLastChangedByFullName &&
              props.statusLastChangedByEmail && (
                <div className="avatar-container">
                  <Typography
                    variant="overline"
                    sx={{ color: Constants.Colors.onPrimaryContainer }}
                    className="name"
                  >
                    {`${props.statusLastChangedByFullName
                      ?.split(" ")
                      .shift()
                      ?.charAt(0)}${props.statusLastChangedByFullName
                      ?.split(" ")
                      .pop()
                      ?.charAt(0)}`}
                  </Typography>
                </div>
              )}
            <Typography
              variant="overline"
              sx={{ color: Constants.Colors.onSurfaceVariant }}
              className="name"
            >
              {t(props.status)}{" "}
              {props.statusLastChangedByFullName &&
              props.statusLastChangedByEmail
                ? `${t("by")} ${props.statusLastChangedByFullName}`
                : ""}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
export default AlarmCard;
