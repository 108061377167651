import { CommentOutlined } from "@mui/icons-material";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import PageHeader from "components/page-header/page-header";
import PopUp from "components/pop-up/pop-up.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import Gateway from "features/gateway/domain/models/gateway";
import { GatewayStatus } from "features/gateway/domain/models/gateway-status";
import GatewayDetailsPopup from "features/gateway/views/gateway-details/gateway-details-popup.component";
import GatewayFilters from "features/gateway/views/gateway-filters";
import styles from "features/gateway/views/gateways.module.scss";
import IntegrationsNavigation from "features/integrations/views/integrations-navigation";
import { findUpstreamParentBranch } from "features/organisation/domain/models/organisation-tree-node";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";
import { useGatewaysContextProvider } from "../context/gateway-provider";

const Gateways = (): JSX.Element => {
  const { t } = useTranslation("gateway");
  const { hasPermission } = useAuth();
  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const {
    gatewaysResponse,
    gatewaysResponseIsLoading,
    gatewaysResponseIsSuccess,
    gatewayDetailsHook,
    gatewayFilterHook,
    openDetailsInCreationModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
    openDetailsInViewingModeAndRefresh,
  } = useGatewaysContextProvider();

  const getOrganisationBreadCrumb = (gateway: Gateway): JSX.Element => {
    if (gateway.organisationUnitId && organisationUnitTree) {
      const upstreamParentBranch = findUpstreamParentBranch(
        organisationUnitTree,
        gateway.organisationUnitId,
        [],
      );
      if (upstreamParentBranch) {
        return (
          <OrganisationBreadCrumb
            rootNodeId={gateway.organisationUnitId}
            upstreamParentBranch={upstreamParentBranch}
            data-testId="organisationBreadCrumb"
          />
        );
      }
    }
    return <></>;
  };

  const columns: ColumnDefinition<Gateway, keyof Gateway>[] = [
    { key: "name", label: t("nameHeaderLabel") },
    {
      key: "type",
      label: t("typeHeaderLabel"),
      renderCustomContentProvider: (gateway) => (
        <>{t(`details.settings.type.${gateway.type}`)}</>
      ),
    },
    {
      key: "organisationUnitId",
      label: t("organisationUnitLabel"),
      renderCustomContentProvider: getOrganisationBreadCrumb,
      disableSort: true,
      tableCellProps: {
        classes: { root: styles.listOrganisationBreadCrumb },
      },
    },
    {
      key: "note",
      label: "",
      renderCustomContentProvider: (gateway) => (
        <div className={styles.iconRow}>{getIcons(gateway)}</div>
      ),
      disableSort: true,
      tableCellProps: {
        align: "right",
        width: "24px",
      },
    },
  ];

  const getIcons = (gateway: Gateway): JSX.Element[] => {
    const result: JSX.Element[] = [];

    result.push(
      <CommentOutlined
        data-testid="noteIcon"
        key={"note-icon"}
        sx={{
          width: 16,
          height: 16,
          color: Constants.Colors.onSurfaceVariant,
          visibility: gateway.note ? "visible" : "hidden",
        }}
      />,
    );

    if (gateway.status === GatewayStatus.offline) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.GatewayOffline}`}
          type={MonitoringType.GatewayOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (gateway.status === GatewayStatus.serviceOfflineOnly) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.GatewayServiceOffline}`}
          type={MonitoringType.GatewayServiceOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (gateway.status === GatewayStatus.passiveOfflineOnly) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.PassiveGatewayOffline}`}
          type={MonitoringType.PassiveGatewayOffline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else if (gateway.status === GatewayStatus.online) {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.GatewayOnline}`}
          type={MonitoringType.GatewayOnline}
          withTooltip={true}
          visible={true}
          size={16}
        />,
      );
    } else {
      result.push(
        <MonitoringIcon
          key={`monitoring-icon-${MonitoringType.NotMonitored}`}
          type={MonitoringType.NotMonitored}
          withTooltip={false}
          visible={true}
          size={16}
        />,
      );
    }

    return result;
  };

  const getOverflowMenuItems = ():
    | Array<TableOverflowMenuItem<Gateway>>
    | undefined => {
    let menuItems: Array<TableOverflowMenuItem<Gateway>> = [
      {
        label: t("overflowMenu.view"),
        action: (gateway) => openDetailsInViewingModeAndRefresh(gateway),
      },
    ];

    if (
      hasPermission(
        Permission.UpdateSystemsGatewaysConnectors ||
          Permission.UpdateExternalSystem,
      )
    ) {
      menuItems.push({
        label: t("overflowMenu.edit"),
        action: (gateway) => openDetailsInEditingModeAndRefresh(gateway),
      });
    }
    if (
      hasPermission(
        Permission.DeleteSystemsGatewaysConnectors ||
          Permission.DeleteExternalSystem,
      )
    ) {
      menuItems.push("divider");
      menuItems.push({
        label: t("overflowMenu.delete"),
        action: (gateway) => gatewayDetailsHook.deleteGateway(gateway),
      });
    }

    return menuItems;
  };

  return (
    <div className={styles.container}>
      <PageHeader
        title={t("pageTitle")}
        addActionPermission={
          Permission.CreateSystemsGatewaysConnectors ||
          Permission.CreateExternalSystem
        }
        isAddActionVisible={gatewaysResponseIsSuccess}
        onAdd={openDetailsInCreationModeAndRefresh}
        navigationComponent={<IntegrationsNavigation />}
        filterComponent={
          gatewaysResponseIsSuccess ? (
            <div className={styles.filterComponentContainer}>
              <GatewayFilters />
            </div>
          ) : (
            <></>
          )
        }
      />
      {gatewaysResponseIsLoading && <LoadingIndicator />}
      {gatewaysResponseIsSuccess &&
        gatewaysResponse &&
        !gatewaysResponseIsLoading && (
          <Table
            data={gatewaysResponse.gateways.map<IRowItem<Gateway>>(
              (gateway) => ({ data: gateway }),
            )}
            columns={columns}
            onItemClick={(gateway: Gateway) =>
              openDetailsInViewingModeAndRefresh(gateway)
            }
            overflowMenuOptions={getOverflowMenuItems()}
            rowIdentifier={(gateway: Gateway) => gateway.id!}
            initialOrderBy={
              gatewayFilterHook.table.sortFromSessionStorage!.property
            }
            initialOrderDirection={
              gatewayFilterHook.table.sortFromSessionStorage!.isAscending
                ? "asc"
                : "desc"
            }
            useBuiltInSorter={true}
            enablePagination={true}
            count={gatewaysResponse.total}
            onPageChanged={gatewayFilterHook.table.handleOnPageChanged}
            rowsPerPage={gatewayFilterHook.table.currentRowsPerPage}
            page={gatewayFilterHook.table.currentPage}
            onRowsPerPageChanged={
              gatewayFilterHook.table.handleOnRowsPerPageChanged
            }
            onSortChanged={gatewayFilterHook.table.handleOnSortChanged}
          />
        )}
      {gatewayDetailsHook.isDetailsOpen && <GatewayDetailsPopup />}
      <PopUp
        isOpen={gatewayDetailsHook.isDeleteConfirmationPopupOpen}
        title={t("deleteGatewayConfirmation.title", {
          gatewayName: gatewayDetailsHook.currentSelectedGateway?.name,
        })}
        body={t("deleteGatewayConfirmation.bodyText")}
        primaryButtonText={t("deleteGatewayConfirmation.confirmButton")}
        secondaryButtonText={t("deleteGatewayConfirmation.cancelButton")}
        handleOnClose={gatewayDetailsHook.closeDeleteConfirmationPopup}
        secondaryButtonAction={gatewayDetailsHook.closeDeleteConfirmationPopup}
        primaryButtonAction={() =>
          gatewayDetailsHook.deleteConfirmationAction?.()
        }
        isLoadingPrimaryAction={gatewayDetailsHook.mutationIsLoading}
      />
      <PopUp
        isOpen={gatewayDetailsHook.isDeleteErrorPopupOpen}
        title={t("deleteGatewayError.title", {
          gatewayName: gatewayDetailsHook.currentSelectedGateway?.name,
        })}
        body={t(
          `deleteGatewayError.${gatewayDetailsHook.deleteErrorPopupTextKey}`,
          {
            gatewayName: gatewayDetailsHook.currentSelectedGateway?.name,
          },
        )}
        primaryButtonText={t("deleteGatewayError.confirmButton")}
        handleOnClose={gatewayDetailsHook.closeDeleteErrorPopup}
        primaryButtonAction={gatewayDetailsHook.closeDeleteErrorPopup}
      />
    </div>
  );
};

export default Gateways;
