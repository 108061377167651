import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./setting-scenario-configuration.component.scss";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import BaseSettingScenarioConfiguration from "../../domain/models/configurations/base-setting-scenario-configuration";
import TimeFrame from "../../domain/models/configurations/time-frame";
import { useResidentSettingsContextProvider } from "../../context/resident-settings-provider";
import { jsonParseWithLowerCaseKeys } from "utils/string-utils";
import OrganisationUnitScenario from "../../domain/models/organisation-unit-scenario";
import TimeFrames from "./time-frames.component";

enum ActiveOption {
  WhenActiveAlarm = 0,
  EverySituation = 1,
}

interface IProps {
  organisationUnitScenario: OrganisationUnitScenario;
  scenarioTitle: string;
  index: number;
}

export function SettingScenarioConfiguration(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("residentsSettings");

  const [configuration, setConfiguration] = useState(
    jsonParseWithLowerCaseKeys(
      props.organisationUnitScenario.configuration,
    ) as BaseSettingScenarioConfiguration,
  );

  const { residentDetailsHook } = useResidentSettingsContextProvider();

  const activeOptions = [
    <MenuItem
      data-testid={`when-active-alarm`}
      key={`when-active-alarm`}
      value={ActiveOption.WhenActiveAlarm}
    >
      {t("scenarioConfiguration.whenActiveAlarm")}
    </MenuItem>,
    <MenuItem
      data-testid={`every-situation`}
      key={`every-situation`}
      value={ActiveOption.EverySituation}
    >
      {t("scenarioConfiguration.everySituation")}
    </MenuItem>,
  ];

  function updateActiveOption(selectedOption: string | number): void {
    let newConfiguration;
    switch (selectedOption) {
      case ActiveOption.WhenActiveAlarm:
        newConfiguration = {
          ...configuration,
          whenActiveAlarm: true,
        };
        residentDetailsHook.scenarioConfigurationUpdated(
          newConfiguration,
          props.index,
        );
        setConfiguration(newConfiguration);
        break;
      case ActiveOption.EverySituation:
        newConfiguration = {
          ...configuration,
          whenActiveAlarm: false,
        };
        residentDetailsHook.scenarioConfigurationUpdated(
          newConfiguration,
          props.index,
        );
        setConfiguration(newConfiguration);
        break;
    }
  }

  useEffect(() => {
    residentDetailsHook.clearValidationErrorForScenario(props.index);
    setConfiguration(
      jsonParseWithLowerCaseKeys(
        props.organisationUnitScenario.configuration,
      ) as BaseSettingScenarioConfiguration,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.organisationUnitScenario, residentDetailsHook.viewingMode]);

  function determineSelectedValue(): ActiveOption {
    return configuration.whenActiveAlarm
      ? ActiveOption.WhenActiveAlarm
      : ActiveOption.EverySituation;
  }

  function timeFrameValuesChanged(
    newTimeFrames: TimeFrame[] | null,
    newActiveAllDay: boolean,
  ): void {
    const newConfiguration = {
      ...configuration,
      timeFrames: newTimeFrames,
      activeAllDay: newActiveAllDay,
    };
    residentDetailsHook.scenarioConfigurationUpdated(
      newConfiguration,
      props.index,
    );
    setConfiguration(newConfiguration);
  }

  return (
    <div className="setting-scenario-configuration-container">
      <div className="restore-row">
        <Typography variant="subtitle1">
          {`${props.scenarioTitle} ${t(
            `scenarioConfiguration.scenarioDescription`,
          )}`}
        </Typography>
      </div>
      <div className="scenario-active-row">
        <FormControl>
          <InputLabel id="scenario-active-label">
            {t("scenarioConfiguration.scenarioActiveLabel")}
          </InputLabel>
          <Select
            inputProps={{ "data-testid": "ScenarioActiveInput" }}
            id="scenarioActiveInput"
            variant="outlined"
            className="scenario-active-select"
            labelId="scenario-active-label"
            label={t("scenarioConfiguration.scenarioActiveLabel")}
            value={determineSelectedValue()}
            defaultValue={0}
            disabled={residentDetailsHook.viewingMode === "viewing"}
            onChange={(event, _) => {
              updateActiveOption(event.target.value);
            }}
          >
            {activeOptions.map((menuItem) => menuItem)}
          </Select>
        </FormControl>
      </div>

      <TimeFrames
        activeAllDay={configuration.activeAllDay}
        index={props.index}
        timeFrames={configuration.timeFrames}
        valuesChanged={timeFrameValuesChanged}
        timeFramesDescription={t(
          "scenarioConfiguration.timeFramesDescriptionScenario",
        )}
      />
    </div>
  );
}

export default SettingScenarioConfiguration;
