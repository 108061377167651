import { FormControlLabel, Switch } from "@mui/material";
import Permission from "features/autorisation/domain/models/permission";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ViewingMode } from "utils/viewing-utils";
import "./permission-group-permissions-component.scss";
import { ReactElement } from "react";

interface IProps {
  permissions: Array<Permission>;
  viewingMode: ViewingMode;
}

function PermissionGroupPermissionsComponent(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("permissions");
  const { control } = useFormContext();

  function translatePermission(permission: Permission) {
    return t(`permissions.${permission}`);
  }

  const sortedPermission = [...props.permissions].sort((x, y) =>
    translatePermission(x).localeCompare(translatePermission(y)),
  );

  return (
    <div className="permission-group-detail">
      {sortedPermission.map((permission) => (
        <div
          className="permission-container"
          key={`permission-container-${permission}`}
        >
          <Controller
            name={`permissions.${permission}`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    type="checkbox"
                    value={field.value}
                    checked={field.value}
                    disabled={props.viewingMode === "viewing"}
                    // prettier-ignore
                    // @ts-ignore
                    inputProps={{ "data-testid": `permissionSwitch${permission}` }}
                  />
                }
                label={translatePermission(permission)}
              />
            )}
          />
        </div>
      ))}
    </div>
  );
}

export default PermissionGroupPermissionsComponent;
