import {
  IDevicesState,
  useDevicesState,
} from "features/device/devices/providers/devices-provider/hooks/states/devices-state";

export interface IDevicesProviderHooks {
  devicesState: IDevicesState;
}

export const useDevicesProviderHooks = (): IDevicesProviderHooks => {
  return {
    devicesState: useDevicesState(),
  };
};
