import PageTab from "components/page-tab/views/page-tab";
import routes from "features/routing/routes";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function DashboardNavigation(): ReactElement {
  const { t } = useTranslation("dashboard");

  const navigate = useNavigate();

  return (
    <PageTab
      onPageChanged={(link: string) => navigate(link)}
      pageTabs={[
        {
          link: routes.dashboardAlarms.path,
          title: t("pageTab.alarmsLabel"),
          atLeastOnePermissionOf: routes.dashboardAlarms.atLeastOnePermissionOf,
        },
        {
          link: routes.dashboardResidents.path,
          title: t("pageTab.residentsLabel"),
          atLeastOnePermissionOf:
            routes.dashboardResidents.atLeastOnePermissionOf,
        },
      ]}
    />
  );
}

export default DashboardNavigation;
