import { ReactElement, ReactNode } from "react";
import "./tenant-monitoring-item.scss";

interface IProps {
  icon: ReactElement;
  label: string;
  count?: ReactNode;
}

const TenantMonitoringItem = ({ icon, label, count }: IProps): ReactElement => (
  <div className="monitoring-item">
    <div className="title">
      {icon}
      {label}
    </div>
    {count}
  </div>
);

export default TenantMonitoringItem;
