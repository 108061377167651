import { useEffect, useState } from "react";
import EmergencyAlarmSetting from "../domain/models/emergency-alarm-setting";
import useEmergencyAlarmSettingsDetails, {
  EmergencyAlarmSettingsDetailsHook,
} from "../hooks/emergency-alarm-settings-details-hook";
import { useReadEmergencyAlarmSettingsQuery } from "../domain/reducers/emergency-alarm-setting.reducer";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";

export interface IEmergencyAlarmSettingsHook {
  organisationEmergencyAlarmSettings: EmergencyAlarmSetting[] | undefined;
  defaultTemplateSetting: EmergencyAlarmSetting | undefined;
  emergencyAlarmSettingsDetailsHook: EmergencyAlarmSettingsDetailsHook;
  emergencyAlarmSettingsIsLoading: boolean;
}

const useEmergencyAlarmSettings = (): IEmergencyAlarmSettingsHook => {
  const dispatch = useDispatch();
  const [
    organisationEmergencyAlarmSettings,
    setOrganisationEmergencyAlarmSettings,
  ] = useState<EmergencyAlarmSetting[]>();

  const [defaultTemplateSetting, setDefaultTemplateSetting] =
    useState<EmergencyAlarmSetting>();

  const emergencyAlarmSettingsDetailsHook = useEmergencyAlarmSettingsDetails();

  const {
    data: emergencyAlarmSettingsResponse,
    isLoading: emergencyAlarmSettingsIsLoading,
    isError: emergencyAlarmSettingsIsError,
    error: emergencyAlarmSettingsError,
  } = useReadEmergencyAlarmSettingsQuery();

  useEffect(() => {
    if (emergencyAlarmSettingsResponse?.emergencyAlarmSettings) {
      setOrganisationEmergencyAlarmSettings(
        emergencyAlarmSettingsResponse.emergencyAlarmSettings,
      );
      setDefaultTemplateSetting(
        emergencyAlarmSettingsResponse.defaultTemplateSetting,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyAlarmSettingsResponse]);

  useEffect(() => {
    if (emergencyAlarmSettingsIsError) {
      const error = emergencyAlarmSettingsError;
      dispatch(setErrorMessage({ error }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emergencyAlarmSettingsIsError]);

  return {
    defaultTemplateSetting,
    organisationEmergencyAlarmSettings,
    emergencyAlarmSettingsDetailsHook,
    emergencyAlarmSettingsIsLoading,
  };
};
export default useEmergencyAlarmSettings;
