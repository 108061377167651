import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export default function LogoutCallbackView() {
  const auth = useAuth();
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    auth
      .signoutRedirectCallback()
      .catch((error: any) =>
        appInsights?.trackException({
          error: new Error(`An error occured while logging out`, error),
          severityLevel: SeverityLevel.Error,
        }),
      )
      .finally(() => navigate("/login"));
  }, [auth, navigate, appInsights]);

  return <></>;
}
