import {
  IDevicesProviderHooks,
  useDevicesProviderHooks,
} from "features/device/devices/providers/devices-provider/hooks";
import React, { ReactElement, ReactNode } from "react";

const DevicesContext = React.createContext<IDevicesProviderHooks>({} as never);

interface IProps {
  children: ReactNode;
}

export const DevicesProvider = (props: Readonly<IProps>): ReactElement => {
  return (
    <DevicesContext.Provider value={useDevicesProviderHooks()}>
      {props.children}
    </DevicesContext.Provider>
  );
};

export const useDevicesContextProvider = (): IDevicesProviderHooks =>
  React.useContext<IDevicesProviderHooks>(DevicesContext);
