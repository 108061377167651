import Permission from "features/autorisation/domain/models/permission";
import IMenuItem from "features/menu/views/menu-item";

interface IMenuItemGroup {
  key: string;
  menuItems: IMenuItem[];
}

export function GetMenuItemGroupPermissions(
  menuItemGroup: IMenuItemGroup,
): Permission[] {
  return menuItemGroup.menuItems
    .flatMap((menuItem) => menuItem.atLeastOnePermissionOf)
    .filter((permission) => permission !== undefined) as Permission[];
}

export default IMenuItemGroup;
