import { Typography } from "@mui/material";
import styles from "components/alert/alert.component.module.scss";
import { ReactElement } from "react";

interface IProps {
  title?: string;
  body: string;
  severity?: "success" | "info" | "warning" | "error" | "none";
}

function Alert({ title, body, severity }: Readonly<IProps>): ReactElement {
  let containerClassName;

  switch (severity) {
    case "success":
      containerClassName = styles.succes;
      break;
    case "warning":
      containerClassName = styles.warning;
      break;
    case "error":
      containerClassName = styles.error;
      break;
    case "none":
      containerClassName = styles.none;
      break;
    default:
      containerClassName = styles.info;
      break;
  }

  return (
    <div className={`${styles.alert} ${containerClassName}`}>
      {title && <Typography variant="h5">{title}</Typography>}
      <div className={styles.body}>
        <Typography
          variant="subtitle1"
          className={styles.text}
          sx={{ whiteSpace: "pre-line" }}
        >
          {body}
        </Typography>
      </div>
    </div>
  );
}
export default Alert;
