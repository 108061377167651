import { ReactElement, useState } from "react";
import "./history-details-right-content.component.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Timeline from "components/timeline/timeline.component";
import { useHistoryContextProvider } from "features/history/providers/history-provider";
import { AlarmStatus } from "features/history/domain/models/alarm-status";
import { HistoryItemType } from "features/history/domain/models/history-item-type";
import { TimelineItem } from "components/timeline/timeline-item";
import { TimelineItemType } from "models/timeline-item-type";
import Duration from "components/timeline/duration.component";
import moment from "moment";
import { AlarmType } from "features/history/domain/models/alarm-type";

function HistoryDetailsRightContent(): ReactElement {
  const { t } = useTranslation("history");

  const { currentSelectedItem, readAlarmData, readEmergencyAlarmData } =
    useHistoryContextProvider();
  const data =
    currentSelectedItem?.type === AlarmType.EmergencyAlarm
      ? readEmergencyAlarmData
      : readAlarmData;

  const [boxShadowClassName, setBoxShadowClassName] = useState<string>("");

  function trackScrolling(element: any): void {
    const top = element.target.scrollTop === 0;
    const bottom =
      element.target.scrollHeight - Math.round(element.target.scrollTop) ===
      element.target.clientHeight;

    if (bottom && boxShadowClassName !== "top-box-shadow") {
      setBoxShadowClassName("top-box-shadow");
    } else if (top && boxShadowClassName !== "bottom-box-shadow") {
      setBoxShadowClassName("bottom-box-shadow");
    } else if (
      !bottom &&
      !top &&
      boxShadowClassName !== "top-and-bottom-box-shadow"
    ) {
      setBoxShadowClassName("top-and-bottom-box-shadow");
    }
  }

  function getDurationInMinutes(): number {
    const duration = moment.duration(data!.duration);
    return duration.asMinutes();
  }

  function getTimelineItems(): TimelineItem[] {
    if (currentSelectedItem?.historyType === HistoryItemType.Alarm) {
      const alarmTimelineItems = data!.timelineItems.map((x) => {
        return {
          id: x.id,
          title: getTypeTranslation(x.type, x.alarmStatus),
          users: x.users,
          createdOn: x.createdOn,
          isHighlighted:
            x.type === TimelineItemType.StatusChanged &&
            x.alarmStatus === AlarmStatus.Handled,
          timeLineType: x.type,
        };
      });

      return alarmTimelineItems;
    }

    return [];
  }

  function hasTimelineItems(): boolean {
    return currentSelectedItem?.historyType === HistoryItemType.Alarm
      ? data!.timelineItems.length > 0
      : false;
  }

  function getTypeTranslation(
    readAlarmTimelineItemType: TimelineItemType,
    alarmStatus: AlarmStatus,
  ): string {
    return readAlarmTimelineItemType === TimelineItemType.StatusChanged
      ? t(
          `details.readAlarmTimelineItemType.${readAlarmTimelineItemType}.${alarmStatus}`,
        )
      : t(`details.readAlarmTimelineItemType.${readAlarmTimelineItemType}`);
  }

  return (
    <div className="history-details-right-content-container">
      {currentSelectedItem && hasTimelineItems() && (
        <>
          <div className="title">
            <Typography variant="h5">{t("details.timeline")}</Typography>
          </div>

          <div
            className={`timeline-container ${boxShadowClassName}`}
            onScroll={trackScrolling}
          >
            <Timeline
              historyItemType={currentSelectedItem.historyType}
              items={getTimelineItems()}
              showOpenChip={
                currentSelectedItem.historyType === HistoryItemType.Alarm &&
                data !== undefined &&
                data.status !== AlarmStatus.Handled &&
                data.status !== AlarmStatus.Cancelled
              }
            />
          </div>
          {currentSelectedItem.historyType === HistoryItemType.Alarm && (
            <div className="total-duration">
              <Typography variant="subtitle1">
                {t("details.totalDuration")}
              </Typography>
              <Duration
                typographyVariant="subtitle1"
                firstItem={getTimelineItems()[0]}
                secondItem={getTimelineItems()[getTimelineItems().length - 1]}
                durationInMinutes={getDurationInMinutes()}
                usePreCalculatedTime={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default HistoryDetailsRightContent;
