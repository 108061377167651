import MonitoringFilter from "components/monitoring/monitoring-filter.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Constants from "style/constants";

function Offline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { gatewayFilterHook } = useGatewaysContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.gatewayOffline")}
      type={MonitoringType.GatewayOffline}
      countColor={Constants.Colors.error}
      count={
        gatewayFilterHook.getMonitorFilter(MonitoringType.GatewayOffline).count
      }
      isSelected={gatewayFilterHook.isActiveMonitorFilter(
        MonitoringType.GatewayOffline,
      )}
      onSelected={(selected) => {
        gatewayFilterHook.selectMonitorFilter(
          MonitoringType.GatewayOffline,
          selected,
        );
      }}
    />
  );
}

function PassiveOffline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { gatewayFilterHook } = useGatewaysContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.passiveGatewayOffline")}
      type={MonitoringType.PassiveGatewayOffline}
      countColor={Constants.Colors.brightOrange}
      count={
        gatewayFilterHook.getMonitorFilter(MonitoringType.PassiveGatewayOffline)
          .count
      }
      isSelected={gatewayFilterHook.isActiveMonitorFilter(
        MonitoringType.PassiveGatewayOffline,
      )}
      onSelected={(selected) => {
        gatewayFilterHook.selectMonitorFilter(
          MonitoringType.PassiveGatewayOffline,
          selected,
        );
      }}
    />
  );
}

function Online(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { gatewayFilterHook } = useGatewaysContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.gatewayOnline")}
      type={MonitoringType.GatewayOnline}
      countColor={Constants.Colors.success}
      count={
        gatewayFilterHook.getMonitorFilter(MonitoringType.GatewayOnline).count
      }
      isSelected={gatewayFilterHook.isActiveMonitorFilter(
        MonitoringType.GatewayOnline,
      )}
      onSelected={(selected) => {
        gatewayFilterHook.selectMonitorFilter(
          MonitoringType.GatewayOnline,
          selected,
        );
      }}
    />
  );
}

function ServiceOffline(): ReactElement {
  const { t } = useTranslation("monitoring");
  const { gatewayFilterHook } = useGatewaysContextProvider();

  return (
    <MonitoringFilter
      text={t("filter.gatewayServiceOffline")}
      type={MonitoringType.GatewayServiceOffline}
      countColor={Constants.Colors.brightOrange}
      count={
        gatewayFilterHook.getMonitorFilter(MonitoringType.GatewayServiceOffline)
          .count
      }
      isSelected={gatewayFilterHook.isActiveMonitorFilter(
        MonitoringType.GatewayServiceOffline,
      )}
      onSelected={(selected) => {
        gatewayFilterHook.selectMonitorFilter(
          MonitoringType.GatewayServiceOffline,
          selected,
        );
      }}
    />
  );
}

const GatewayMonitoringFilter = {
  MonitoringFilter: MonitoringFilter,
  Offline: Offline,
  PassiveOffline: PassiveOffline,
  Online: Online,
  ServiceOffline: ServiceOffline,
};

export default GatewayMonitoringFilter;
