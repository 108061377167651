import { Grid, Typography } from "@mui/material";
import "./settings-row.component.scss";
import { ReactElement } from "react";

interface IProps {
  icon?: ReactElement;
  text: string;
  hasError?: boolean;
  helperText?: string;
  formField: ReactElement;
}

function SettingsRow(props: Readonly<IProps>): ReactElement {
  return (
    <Grid container direction="column" className="settings-switch">
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
      >
        {props.icon}
        <Grid item xs>
          <Typography variant="subtitle1">{props.text}</Typography>
        </Grid>
        <Grid item>{props.formField}</Grid>
      </Grid>
      {props.hasError && (
        <Grid item>
          <Typography className="helper-text" variant="caption">
            {props.helperText}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default SettingsRow;
