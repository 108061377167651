import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { AlarmPriority } from "../domain/models/alarm-priority";
import "./category.component.scss";
import { HistoryItemType } from "../domain/models/history-item-type";

interface IProps {
  alarmPriority: AlarmPriority;
  historyType: HistoryItemType;
}

function Category(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("history");

  function getBackgroundColorClassName(priority: AlarmPriority): string {
    if (props.historyType === HistoryItemType.Status) {
      return "status";
    }

    switch (priority) {
      case AlarmPriority.Highest:
        return "highestPriority";
      case AlarmPriority.High:
        return "highPriority";
      case AlarmPriority.Medium:
        return "mediumPriority";
      default:
        return "defaultPriority";
    }
  }

  return (
    <div
      className={`category ${getBackgroundColorClassName(props.alarmPriority)}`}
    >
      <Typography className="categoryText" variant="overline">
        {t(`category.${props.historyType}`)}
      </Typography>
    </div>
  );
}

export default Category;
