import { useTranslation } from "react-i18next";
import "./resident-row.scss";
import { PersonPinOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import { ReactElement } from "react";

interface IProps {
  residentName: string;
  breadCrumbs: string[];
  isSelected: boolean;
}

function ResidentRow(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("residentsSettings");

  return (
    <div className="resident-row-container">
      <div className="resident-status">
        <PersonPinOutlined />
      </div>
      <div className="resident-information">
        <Typography variant="body2" className="resident-of">
          {t("residentOf")}
        </Typography>
        <Typography
          variant="subtitle2"
          className={`resident-name ${props.isSelected ? "selected" : ""}`}
        >
          {props.residentName}
        </Typography>
        <BreadCrumb
          showEllipsis={props.breadCrumbs.length > 2}
          allElementNames={props.breadCrumbs}
          data-testid={`resident-breadcrumb-child-${props.residentName}`}
        >
          {props.breadCrumbs.map((parentTitle: string, index: number) => {
            return (
              <Typography
                data-testid={`resident-breadcrumb-child-${index}`}
                key={`Resident_Breadcrumb_Child_${parentTitle}`}
                variant="caption"
                className="bread-crumb-item"
              >
                {parentTitle}
              </Typography>
            );
          })}
        </BreadCrumb>
      </div>
    </div>
  );
}

export default ResidentRow;
