import { Dialog, Typography } from "@mui/material";
import { ReactElement, useEffect } from "react";
import "./history-details.component.scss";
import { useHistoryContextProvider } from "features/history/providers/history-provider";
import HistoryDetailsLeftContent from "./history-details-left-content.component";
import HistoryDetailsRightContent from "./history-details-right-content.component";
import HistoryDetailsHeader from "./history-details-header.component";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import SonevoLoadingIndicator from "components/loading-indicator/sonevo-loader";
import { HistoryItemType } from "features/history/domain/models/history-item-type";
import theme from "style/theme";
import { useTranslation } from "react-i18next";

function HistoryDetails(): ReactElement {
  const {
    currentSelectedItem,
    setCurrentSelectedItem,
    isAlarmDataLoading,
    readAlarmError,
    readEmergencyAlarmError,
    refresh,
  } = useHistoryContextProvider();

  const { t } = useTranslation("history");

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutorisationWrapper
      atLeastOnePermissionOf={[Permission.ReadHistoryDetails]}
    >
      <Dialog
        open={currentSelectedItem !== undefined}
        onClose={() => setCurrentSelectedItem(undefined)}
        className="history-details"
        PaperProps={{
          classes: { root: "history-details-paper" },
        }}
      >
        <HistoryDetailsHeader />
        <div className="history-details-content">
          {readAlarmError || readEmergencyAlarmError ? (
            <Typography
              variant="caption"
              sx={{ color: theme.palette.error.main }}
            >
              {t("error")}
            </Typography>
          ) : (
            <>
              {!currentSelectedItem ||
              (currentSelectedItem.historyType === HistoryItemType.Alarm &&
                isAlarmDataLoading()) ? (
                <div className="loading-indicator">
                  <SonevoLoadingIndicator size={50} />
                </div>
              ) : (
                <>
                  <div className="history-details-left-content">
                    <HistoryDetailsLeftContent />
                  </div>
                  <div className="history-details-right-content">
                    <HistoryDetailsRightContent />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Dialog>
    </AutorisationWrapper>
  );
}

export default HistoryDetails;
