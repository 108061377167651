import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { ViewingMode } from "utils/viewing-utils";

export default class NameValidator {
  static isValid(
    name: string,
    viewingMode: ViewingMode,
    parentNode: OrganisationTreeNode | undefined,
    siblings: OrganisationTreeNode[],
  ): boolean {
    let isInvalidInCreationMode =
      viewingMode === "creation" &&
      parentNode?.childOrganisationUnits?.some(
        (child) => child.name === name.trim(),
      );
    let isInvalidInEdittingMode =
      viewingMode === "editing" &&
      siblings.some((sibling) => sibling.name === name.trim());

    if (!isInvalidInCreationMode && !isInvalidInEdittingMode) {
      return true;
    }

    return false;
  }
}
