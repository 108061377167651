import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function ConversionPathIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M19 21C18.35 21 17.7667 20.8127 17.25 20.438C16.7333 20.0627 16.375 19.5833 16.175 19H11C9.9 19 8.95833 18.6083 8.175 17.825C7.39167 17.0417 7 16.1 7 15C7 13.9 7.39167 12.9583 8.175 12.175C8.95833 11.3917 9.9 11 11 11H13C13.55 11 14.021 10.804 14.413 10.412C14.8043 10.0207 15 9.55 15 9C15 8.45 14.8043 7.979 14.413 7.587C14.021 7.19567 13.55 7 13 7H7.825C7.60833 7.58333 7.246 8.06267 6.738 8.438C6.22933 8.81267 5.65 9 5 9C4.16667 9 3.45833 8.70833 2.875 8.125C2.29167 7.54167 2 6.83333 2 6C2 5.16667 2.29167 4.45833 2.875 3.875C3.45833 3.29167 4.16667 3 5 3C5.65 3 6.22933 3.18733 6.738 3.562C7.246 3.93733 7.60833 4.41667 7.825 5H13C14.1 5 15.0417 5.39167 15.825 6.175C16.6083 6.95833 17 7.9 17 9C17 10.1 16.6083 11.0417 15.825 11.825C15.0417 12.6083 14.1 13 13 13H11C10.45 13 9.97933 13.1957 9.588 13.587C9.196 13.979 9 14.45 9 15C9 15.55 9.196 16.021 9.588 16.413C9.97933 16.8043 10.45 17 11 17H16.175C16.3917 16.4167 16.7543 15.9373 17.263 15.562C17.771 15.1873 18.35 15 19 15C19.8333 15 20.5417 15.2917 21.125 15.875C21.7083 16.4583 22 17.1667 22 18C22 18.8333 21.7083 19.5417 21.125 20.125C20.5417 20.7083 19.8333 21 19 21ZM5 7C5.28333 7 5.521 6.90433 5.713 6.713C5.90433 6.521 6 6.28333 6 6C6 5.71667 5.90433 5.479 5.713 5.287C5.521 5.09567 5.28333 5 5 5C4.71667 5 4.479 5.09567 4.287 5.287C4.09567 5.479 4 5.71667 4 6C4 6.28333 4.09567 6.521 4.287 6.713C4.479 6.90433 4.71667 7 5 7Z" />
    </SvgIcon>
  );
}
