import React, { ReactElement, ReactNode } from "react";
import useTemporaryAccess, { ITemporaryAccessProviderHook } from "../hooks";

const TemporaryAccessContext =
  React.createContext<ITemporaryAccessProviderHook>({} as never);

interface IProps {
  children: ReactNode;
}

export const TemporaryAccessProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <TemporaryAccessContext.Provider value={useTemporaryAccess()}>
      {props.children}
    </TemporaryAccessContext.Provider>
  );
};

export const useTemporaryAccessContextProvider =
  (): ITemporaryAccessProviderHook =>
    React.useContext<ITemporaryAccessProviderHook>(TemporaryAccessContext);
