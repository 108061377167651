import { RegexExpressions } from "constants/regex/regex-expressions";
import { useTranslation } from "react-i18next";
import { ApiResponse, isOkResponse } from "redux-base/create-api-utils";
import {
  useValidateGatewayIpAddressMutation,
  useValidateGatewayNameMutation,
} from "features/gateway/domain/reducers/gateway.reducer";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";

interface GatewayValidationHook {
  validateName: () => Promise<boolean | string>;
  validateIpAddress: (ipAddress: string) => boolean | string;
  validateVirtualIpAddress: () => Promise<boolean | string>;
  validatePassword: (password: string) => boolean | string;
}

const useGatewayValidation = (): GatewayValidationHook => {
  const { t } = useTranslation("gateway");

  const [validateGatewayName] = useValidateGatewayNameMutation();
  const [validateGatewayIpAddress] = useValidateGatewayIpAddressMutation();

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const validateName = async (): Promise<boolean | string> => {
    const name = gatewayDetailsHook.form.getValues("name")?.trim();
    if (!name) return true;

    const id = gatewayDetailsHook.currentSelectedGateway?.id;
    const result = (await validateGatewayName({
      id,
      name,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("details.information.uniqueNameHelperText");
  };

  const validateVirtualIpAddress = async (): Promise<boolean | string> => {
    const ipAddress = gatewayDetailsHook.form.getValues("ipAddress")?.trim();
    if (!ipAddress) return true;

    const regex = new RegExp(RegexExpressions.IP_ADDRESS);
    if (!regex.test(ipAddress)) {
      return t("details.information.invalidVirtualIpAddressHelperText");
    }

    const id = gatewayDetailsHook.currentSelectedGateway?.id;
    const result = (await validateGatewayIpAddress({
      id,
      ipAddress,
    })) as ApiResponse<void>;

    if (isOkResponse(result)) return true;

    return t("details.information.duplicateIpAddressHelperText");
  };

  const validateIpAddress = (ipAddress: string | undefined) => {
    ipAddress = ipAddress?.trim();
    if (!ipAddress) return true;

    const regex = new RegExp(RegexExpressions.IP_ADDRESS);
    if (!regex.test(ipAddress)) {
      return t("details.information.invalidIpAddressHelperText");
    }

    return true;
  };

  const validatePassword = (password: string) => {
    if (!password) return t("details.information.requiredHelperText");

    const regex = new RegExp(RegexExpressions.PASSWORD);
    if (!regex.test(password)) {
      return t(
        "details.settings.offlinePortalCredentials.invalidPasswordHelperText",
      );
    }

    return true;
  };

  return {
    validateName,
    validateIpAddress,
    validateVirtualIpAddress,
    validatePassword,
  };
};

export default useGatewayValidation;
