import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function ConnectionIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M21.3999 7.50005C22.1999 8.30005 22.1999 9.60005 21.3999 10.3L18.5999 13.1L10.7999 5.30005L13.5999 2.50005C14.3999 1.70005 15.6999 1.70005 16.3999 2.50005L18.1999 4.30005L21.1999 1.30005L22.5999 2.70005L19.5999 5.70005L21.3999 7.50005ZM15.5999 13.3L14.1999 11.9L11.3999 14.7L9.2999 12.6L12.0999 9.80005L10.6999 8.40005L7.8999 11.2L6.3999 9.80005L3.5999 12.6C2.7999 13.4 2.7999 14.7 3.5999 15.4L5.3999 17.2L1.3999 21.2001L2.7999 22.6L6.7999 18.6L8.5999 20.4C9.3999 21.2 10.6999 21.2 11.3999 20.4L14.1999 17.6L12.7999 16.2L15.5999 13.3Z" />
    </SvgIcon>
  );
}
