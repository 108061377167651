import { Typography } from "@mui/material";
import OrganisationTreeNode, {
  findUpstreamParentBranch,
} from "features/organisation/domain/models/organisation-tree-node";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import { useSendTestEventContextProvider } from "../context/send-test-event-provider";

function SendTestEventOrganisation(): ReactElement {
  const { t } = useTranslation("sendTestEvent");
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);

  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const { selectedDevice } = useSendTestEventContextProvider();

  useEffect(() => {
    if (organisationUnitTree && selectedDevice?.organisationUnitId) {
      const upstreamBranch = findUpstreamParentBranch(
        organisationUnitTree,
        selectedDevice.organisationUnitId,
        [],
      );
      setUpstreamParentBranch(upstreamBranch ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree, selectedDevice]);

  return (
    <>
      {selectedDevice?.organisationUnitId ? (
        <OrganisationBreadCrumb
          rootNodeId={selectedDevice.organisationUnitId}
          upstreamParentBranch={upstreamParentBranch}
        />
      ) : (
        <Typography variant="subtitle1">
          {t("noOrganisationUnitLinkedLabel")}
        </Typography>
      )}
    </>
  );
}

export default SendTestEventOrganisation;
