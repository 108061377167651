import { ExpandMore, LinkOutlined } from "@mui/icons-material";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Switch,
  Typography,
} from "@mui/material";
import IntentionOutOfBedIcon from "components/custom-icons/intention-out-of-bed-icon";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Scenario from "features/organisation/models/scenario";
import styles from "features/organisation/views/organisation-details/organisation-details-scenarios-list.module.scss";
import SettingsRow from "features/organisation/views/organisation-details/settings-row.component";
import OrganisationUnitScenarioIcon from "features/residents/residents-settings/views/resident-alarm-scenarios/organisation-unit-scenario-icon";
import ScenarioName from "features/scenario/views/scenario-name.component";
import { ScenarioType } from "models/scenario-type";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ViewingMode } from "utils/viewing-utils";

interface Props {
  viewingMode: ViewingMode;
  linkedScenarios: Scenario[] | undefined;
  unlinkedScenarios: Scenario[] | undefined;
  onUnlinkButtonClicked: (selectedScenario: Scenario) => void;
  onRelinkButtonClicked: (selectedScenario: Scenario) => void;
}

const OrganisationDetailsScenariosList = ({
  viewingMode,
  linkedScenarios,
  unlinkedScenarios,
  onUnlinkButtonClicked,
  onRelinkButtonClicked,
}: Readonly<Props>): JSX.Element => {
  const { t } = useTranslation("organisation");

  const { hasPermission } = useAuth();
  const { control } = useFormContext();

  const isViewing = viewingMode === "viewing";
  const hasAnyScenarios =
    (linkedScenarios && linkedScenarios.length > 0) ||
    (unlinkedScenarios && unlinkedScenarios?.length > 0);
  const isDisableLinkScenario =
    isViewing || !hasPermission(Permission.LinkScenario);

  const linkedScenarioRow = (linkedScenario: Scenario): JSX.Element => (
    <div key={linkedScenario.id} className={styles.scenario}>
      <div className={styles.row}>
        <OrganisationUnitScenarioIcon
          scenarioType={linkedScenario.scenarioType}
        />
        <Typography
          variant="subtitle1"
          data-testid={`scenarioTypeTextFor${linkedScenario.scenarioType}`}
        >
          <ScenarioName scenarioType={linkedScenario.scenarioType} />
        </Typography>
      </div>
      {!isViewing && (
        <AutorisationWrapper atLeastOnePermissionOf={[Permission.LinkScenario]}>
          <Button
            startIcon={<LinkOffOutlinedIcon />}
            onClick={() => onUnlinkButtonClicked(linkedScenario)}
            variant="text"
          >
            {t("scenariosList.unlinkButtonText")}
          </Button>
        </AutorisationWrapper>
      )}
    </div>
  );

  if (!hasAnyScenarios) {
    return (
      <Typography variant="subtitle1">
        {t("scenariosList.noScenariosLinked")}
      </Typography>
    );
  }

  return (
    <div className={styles.container}>
      {linkedScenarios?.map((linkedScenario) => {
        if (linkedScenario.scenarioType === ScenarioType.Bed) {
          return (
            <Accordion key={linkedScenario.id} disableGutters>
              <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
                {linkedScenarioRow(linkedScenario)}
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Controller
                  name="intentionOutOfBedEnabled"
                  control={control}
                  render={({ field }) => (
                    <SettingsRow
                      icon={<IntentionOutOfBedIcon />}
                      text={t("scenariosList.intentionOutOfBed")}
                      formField={
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          // prettier-ignore
                          // @ts-ignore
                          inputProps={{ "data-testid": "intentionOutOfBedEnabledSwitch" }}
                          disabled={isDisableLinkScenario}
                        />
                      }
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          );
        }
        return linkedScenarioRow(linkedScenario);
      })}

      {unlinkedScenarios?.map((unlinkedScenario) => {
        const { id, scenarioType } = unlinkedScenario;

        return (
          <div key={id} className={styles.scenario}>
            <div className={styles.row}>
              <OrganisationUnitScenarioIcon scenarioType={scenarioType} />
              <Typography
                variant="subtitle1"
                className={styles.unlinked}
                data-testid={`scenarioTypeTextFor${scenarioType}`}
              >
                <ScenarioName scenarioType={scenarioType} />
              </Typography>
            </div>

            {!isViewing && (
              <AutorisationWrapper
                atLeastOnePermissionOf={[Permission.LinkScenario]}
              >
                <Button
                  startIcon={<LinkOutlined />}
                  onClick={() => onRelinkButtonClicked(unlinkedScenario)}
                  variant="text"
                >
                  {t("scenariosList.relinkButtonText")}
                </Button>
              </AutorisationWrapper>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OrganisationDetailsScenariosList;
