import TimeFrame from "features/residents/residents-settings/domain/models/configurations/time-frame";
import moment, { Moment } from "moment";

export enum TimeFormat {
  Utc = 0,
  Local = 1,
}

export interface FormattedTimeFrame {
  formattedTimeFrameText: string;
  isInTimeFrame: boolean;
}

export function getClosestTimeFrame(
  timeFrames: TimeFrame[],
  timeToBeClosestTo?: Moment,
): FormattedTimeFrame {
  const sortedTimeFrames = [...timeFrames]
    .filter((x) => x.from != null && x.to != null)
    .sort((x, y) => {
      const xTotalSeconds = moment(x.from, "HH:mm").diff(
        moment().startOf("day"),
        "seconds",
      );
      const yTotalSeconds = moment(y.from, "HH:mm").diff(
        moment().startOf("day"),
        "seconds",
      );

      return xTotalSeconds < yTotalSeconds ? -1 : 0;
    });

  if (sortedTimeFrames.length === 0) {
    return { formattedTimeFrameText: "", isInTimeFrame: false };
  }

  const now = timeToBeClosestTo ?? moment();
  const nowTotalSeconds = now.diff(moment().startOf("day"), "seconds");

  for (let i = 0; i < sortedTimeFrames.length; i++) {
    const currentTimeFrame = sortedTimeFrames[i];

    const from = moment(currentTimeFrame.from, "HH:mm");
    const to = moment(currentTimeFrame.to, "HH:mm");
    const fromTotalSeconds = from.diff(moment().startOf("day"), "seconds");
    const toTotalSeconds = to.diff(moment().startOf("day"), "seconds");

    if (isInTimeFrame(fromTotalSeconds, toTotalSeconds, nowTotalSeconds)) {
      return {
        formattedTimeFrameText: to.format("HH:mm"),
        isInTimeFrame: true,
      };
    }

    const nextTimeFrame =
      i < sortedTimeFrames.length - 1 ? sortedTimeFrames[i + 1] : null;

    if (nextTimeFrame == null) {
      continue;
    }

    const nextTimeFrameFrom = moment(nextTimeFrame.from, "HH:mm");
    const nextTimeFrameFromTotalSeconds = nextTimeFrameFrom.diff(
      moment().startOf("day"),
      "seconds",
    );

    if (
      isInTimeFrame(
        toTotalSeconds,
        nextTimeFrameFromTotalSeconds,
        nowTotalSeconds,
      )
    ) {
      return {
        formattedTimeFrameText: nextTimeFrameFrom.format("HH:mm"),
        isInTimeFrame: false,
      };
    }
  }

  const firstTimeFrameFrom = moment(sortedTimeFrames[0].from, "HH:mm");
  return {
    formattedTimeFrameText: firstTimeFrameFrom.format("HH:mm"),
    isInTimeFrame: false,
  };
}

function isInTimeFrame(
  fromTotalSeconds: number,
  toTotalSeconds: number,
  nowTotalSeconds: number,
): boolean {
  if (toTotalSeconds > fromTotalSeconds) {
    return (
      nowTotalSeconds > fromTotalSeconds && nowTotalSeconds < toTotalSeconds
    );
  }

  return nowTotalSeconds > fromTotalSeconds || nowTotalSeconds < toTotalSeconds;
}

export function convertTimeFrames(
  timeFrames: TimeFrame[],
  timeFormat: TimeFormat,
): TimeFrame[] {
  const now =
    timeFormat === TimeFormat.Local ? moment().utc() : moment().local();

  return timeFrames.map((timeFrame) => {
    const timeFrom = moment(timeFrame.from, "HH:mm");
    const timeTo = moment(timeFrame.to, "HH:mm");

    now.set({
      hour: timeFrom.get("hour"),
      minute: timeFrom.get("minute"),
      second: timeFrom.get("second"),
    });

    const from =
      timeFormat === TimeFormat.Local
        ? now.clone().local().format("HH:mm")
        : now.clone().utc().format("HH:mm");

    now.set({
      hour: timeTo.get("hour"),
      minute: timeTo.get("minute"),
      second: timeTo.get("second"),
    });

    const to =
      timeFormat === TimeFormat.Local
        ? now.clone().local().format("HH:mm")
        : now.clone().utc().format("HH:mm");

    return { from: from, to: to } as TimeFrame;
  });
}

export function validateTimeFrames(timeFrames: TimeFrame[]): boolean[] {
  const newValidationErrors: boolean[] = [];

  timeFrames.forEach((timeFrame: TimeFrame, index: number) => {
    let timeFrameFrom = moment(timeFrame.from, "HH:mm");
    let timeFrameTo = moment(timeFrame.to, "HH:mm");

    newValidationErrors[index] =
      timeFrame.from === timeFrame.to ||
      IsInvalidOrEmptyDate(timeFrame.from) ||
      IsInvalidOrEmptyDate(timeFrame.to) ||
      timeFrames.some(
        (compareTimeFrame) =>
          compareTimeFrame !== timeFrame &&
          (timeFrameFrom.isSame(moment(compareTimeFrame.from, "HH:mm")) ||
            timeFrameFrom.isSame(moment(compareTimeFrame.to, "HH:mm")) ||
            timeFrameTo.isSame(moment(compareTimeFrame.from, "HH:mm")) ||
            timeFrameTo.isSame(moment(compareTimeFrame.to, "HH:mm")) ||
            getClosestTimeFrame([compareTimeFrame], timeFrameFrom)
              .isInTimeFrame ||
            getClosestTimeFrame([compareTimeFrame], timeFrameTo).isInTimeFrame),
      );
  });

  return newValidationErrors;
}

export function IsInvalidOrEmptyDate(date: string | null): boolean {
  return !date || date === "Invalid date" || date === "";
}
