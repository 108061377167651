import moment, { Moment } from "moment";
import { useDateRangePickerState } from "./states/date-range-picker-state";

export interface IDateRangePickerProviderHook {
  dateRangePickerIsOpen: boolean;
  startDate: Moment | undefined;
  endDate: Moment | undefined;
  temporaryStartDate: Moment | undefined;
  temporaryEndDate: Moment | undefined;
  maxEndDate: Moment | undefined;
  openDateRangePicker: () => void;
  closeDateRangePicker: () => void;
  applyStartDate: (startDate: Moment | undefined) => void;
  applyEndDate: (endDate: Moment | undefined) => void;
  applyTemporaryStartDate: (temporaryStartDate: Moment) => void;
  applyTemporaryEndDate: (temporaryEndDate: Moment) => void;
}

const useDateRangePicker = (
  maxRangeInDays?: number,
): IDateRangePickerProviderHook => {
  const {
    dateRangePickerIsOpen,
    startDate,
    endDate,
    temporaryStartDate,
    temporaryEndDate,
    maxEndDate,
    setDateRangePickerIsOpen,
    setStartDate,
    setEndDate,
    setTemporaryStartDate,
    setTemporaryEndDate,
    setMaxEndDate,
  } = useDateRangePickerState();

  const openDateRangePicker = () => {
    setTemporaryStartDate(startDate ?? moment());
    setTemporaryEndDate(endDate ?? moment());

    const newPossibleMaxEndDate = temporaryStartDate
      ? moment(temporaryStartDate).add(maxRangeInDays, "days")
      : moment().add(maxRangeInDays, "days");

    setMaxEndDate(
      maxEndDate ?? maxRangeInDays ? newPossibleMaxEndDate : maxEndDate,
    );
    setDateRangePickerIsOpen(true);
  };

  const closeDateRangePicker = () => {
    setDateRangePickerIsOpen(false);
  };

  const applyStartDate = (startDate: Moment | undefined) =>
    setStartDate(startDate);

  const applyEndDate = (endDate: Moment | undefined) => setEndDate(endDate);

  const applyTemporaryStartDate = (temporaryStartDate: Moment) => {
    setTemporaryStartDate(temporaryStartDate);
    setMaxEndDate(moment(temporaryStartDate).add(maxRangeInDays, "days"));

    if (temporaryEndDate && temporaryEndDate < temporaryStartDate) {
      setTemporaryEndDate(temporaryStartDate);
      return;
    }

    if (
      maxRangeInDays &&
      temporaryEndDate &&
      temporaryStartDate &&
      temporaryEndDate?.diff(temporaryStartDate, "days") > 7
    ) {
      setTemporaryEndDate(
        moment(temporaryStartDate).add(maxRangeInDays, "days"),
      );
    }
  };

  const applyTemporaryEndDate = (temporaryEndDate: Moment) => {
    setTemporaryEndDate(temporaryEndDate);

    if (temporaryStartDate && temporaryStartDate > temporaryEndDate) {
      setTemporaryStartDate(temporaryEndDate);
      setMaxEndDate(moment(temporaryEndDate).add(maxRangeInDays, "days"));
    }
  };

  return {
    dateRangePickerIsOpen,
    startDate,
    endDate,
    temporaryStartDate,
    temporaryEndDate,
    maxEndDate,
    openDateRangePicker,
    closeDateRangePicker,
    applyStartDate,
    applyEndDate,
    applyTemporaryStartDate,
    applyTemporaryEndDate,
  };
};

export default useDateRangePicker;
