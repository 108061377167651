import { Typography } from "@mui/material";
import { useTenantsContextProvider } from "features/tenants/providers/tenants-provider";
import { ReactElement } from "react";
import "./tenant-monitoring-details.scss";
import {
  Battery1BarRounded,
  CloseRounded,
  CloudOffOutlined,
  CloudOutlined,
} from "@mui/icons-material";
import TenantMonitoringItem from "./tenant-monitoring-item";
import Constants from "style/constants";
import MonitoringBar from "features/tenants/views/monitoring-bar";
import { TenantMonitoringType } from "features/tenants/domain/models/tenant";
import { useTranslation } from "react-i18next";
import { AggregatedType } from "features/tenants/domain/models/aggregated-monitor-data";

const TenantMonitoringDetails = (): ReactElement => {
  const { t } = useTranslation("tenants");
  const { currentTenant } = useTenantsContextProvider();

  const gatewayMonitoring = currentTenant?.gatewayMonitorings ?? [];
  const devicesMonitoring = currentTenant?.devicesMonitorings ?? [];

  const generateMonitoringItem = (
    label: string,
    count: number,
    color: string,
    icon: ReactElement,
  ) => (
    <TenantMonitoringItem
      key={label}
      icon={icon}
      label={label}
      count={
        <Typography variant="h2" sx={{ color: color }}>
          {count}
        </Typography>
      }
    />
  );

  const offlineGateway = gatewayMonitoring.find(
    (gateway) => gateway.type === TenantMonitoringType.Offline,
  );
  const passiveOfflineGateway = gatewayMonitoring.find(
    (gateway) => gateway.type === TenantMonitoringType.PassiveGatewayOffline,
  );
  const onlineGateway = gatewayMonitoring.find(
    (gateway) => gateway.type === TenantMonitoringType.Online,
  );
  const devicesOffline = devicesMonitoring.find(
    (device) => device.type === TenantMonitoringType.Offline,
  );
  const devicesOnline = devicesMonitoring.find(
    (device) => device.type === TenantMonitoringType.Online,
  );
  const devicesNotMonitored = devicesMonitoring.find(
    (device) => device.type === TenantMonitoringType.NotMonitored,
  );
  const devicesBatteryCritical = devicesMonitoring.find(
    (device) => device.type === TenantMonitoringType.BatteryCritical,
  );
  const devicesBatteryLow = devicesMonitoring.find(
    (device) => device.type === TenantMonitoringType.BatteryLow,
  );

  return (
    <div>
      <div className="tenant-monitoring-container">
        <Typography variant="h5">
          {t("tenantDetails.gateways.title")}
        </Typography>
        <MonitoringBar
          monitoring={currentTenant?.gatewayMonitorings}
          aggregatedType={AggregatedType.Gateways}
        />
        <div className="tenant-monitoring-items">
          {offlineGateway &&
            generateMonitoringItem(
              t("tenantDetails.gateways.offline"),
              offlineGateway.count,
              Constants.Colors.error,
              <CloudOffOutlined sx={{ color: Constants.Colors.error }} />,
            )}
          {passiveOfflineGateway &&
            generateMonitoringItem(
              t("tenantDetails.gateways.warning"),
              passiveOfflineGateway.count,
              Constants.Colors.brightOrange,
              <CloudOffOutlined
                sx={{ color: Constants.Colors.brightOrange }}
              />,
            )}
          {onlineGateway &&
            generateMonitoringItem(
              t("tenantDetails.gateways.online"),
              onlineGateway.count,
              Constants.Colors.success,
              <CloudOutlined sx={{ color: Constants.Colors.success }} />,
            )}
        </div>
      </div>
      <div className="tenant-monitoring-container">
        <Typography variant="h5">{t("tenantDetails.devices.title")}</Typography>
        <MonitoringBar
          monitoring={currentTenant?.devicesMonitorings}
          aggregatedType={AggregatedType.Devices}
        />
        <div className="tenant-monitoring-items">
          {devicesOffline &&
            generateMonitoringItem(
              t("tenantDetails.devices.offline"),
              devicesOffline.count,
              Constants.Colors.error,
              <CloudOffOutlined sx={{ color: Constants.Colors.error }} />,
            )}
          {devicesOnline &&
            generateMonitoringItem(
              t("tenantDetails.devices.online"),
              devicesOnline.count,
              Constants.Colors.success,
              <CloudOutlined sx={{ color: Constants.Colors.success }} />,
            )}
          {devicesNotMonitored &&
            generateMonitoringItem(
              t("tenantDetails.devices.notMonitored"),
              devicesNotMonitored.count,
              Constants.Colors.onSurfaceVariant,
              <CloseRounded
                sx={{ color: Constants.Colors.onSurfaceVariant }}
              />,
            )}
          {devicesBatteryCritical &&
            generateMonitoringItem(
              t("tenantDetails.devices.batteryCritical"),
              devicesBatteryCritical.count,
              Constants.Colors.error,
              <Battery1BarRounded sx={{ color: Constants.Colors.error }} />,
            )}
          {devicesBatteryLow &&
            generateMonitoringItem(
              t("tenantDetails.devices.batteryLow"),
              devicesBatteryLow.count,
              Constants.Colors.brightOrange,
              <Battery1BarRounded
                sx={{ color: Constants.Colors.brightOrange }}
              />,
            )}
        </div>
      </div>
    </div>
  );
};

export default TenantMonitoringDetails;
