import { LinearProgress } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import "./loading-indicator.component.scss";

export default function LoadingIndicator(): ReactElement {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), 300);
    return () => clearTimeout(timer);
  });

  if (showLoader) {
    return <LinearProgress data-testid={"loading-indicator"} />;
  } else {
    return <></>;
  }
}
