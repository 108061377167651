export type NestedKeyof<ObjectType extends object> = {
  [Key in keyof ObjectType & string]: ObjectType[Key] extends object
    ?
        | `${Key}`
        | `${Key}.${NestedKeyof<ObjectType[Key]> extends infer U extends string
            ? U
            : never}`
    : `${Key}`;
}[keyof ObjectType & string];

export function ResolveByNestedKey<ObjectType extends object>(
  path: NestedKeyof<ObjectType>,
  obj: ObjectType,
) {
  return path.split(".").reduce((p: any, c: any) => p?.[c] || null, obj);
}
