export enum TimelineItemType {
  None = "None",
  Created = "Created",
  StatusChanged = "StatusChanged",
  Diverted = "Diverted",
  ReceivedCreatedNotifications = "ReceivedCreatedNotifications",
  ReceivedDivertedNotifications = "ReceivedDivertedNotifications",
  VideoStarted = "VideoStarted",
  VideoStopped = "VideoStopped",
  AudioStarted = "AudioStarted",
  AudioStopped = "AudioStopped",
  OpenDoor = "OpenDoor",
  Assistance = "Assistance",
  AudioRecordingStarted = "AudioRecordingStarted",
}
