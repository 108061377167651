import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import { Dispatch, SetStateAction, useState } from "react";
import { ApiError } from "redux-base/create-api-utils";

interface IOrganisationUnitTreeState {
  organisationUnitTree?: OrganisationTreeNode[];
  error?: ApiError | null;
  isLoading: boolean;
  isSuccess: boolean;
  refetch?: () => void;
}

export interface IOrganisationUnitTreeProviderHook
  extends IOrganisationUnitTreeState {
  setOrganisationUnitTreeState?: Dispatch<
    SetStateAction<IOrganisationUnitTreeState>
  >;
}

const useOrganisationUnitTreeProvider =
  (): IOrganisationUnitTreeProviderHook => {
    const [organisationUnitTreeState, setOrganisationUnitTreeState] =
      useState<IOrganisationUnitTreeState>({
        isLoading: false,
        isSuccess: false,
        refetch: () => {},
      });

    return {
      ...organisationUnitTreeState,
      setOrganisationUnitTreeState,
    };
  };

export default useOrganisationUnitTreeProvider;
