import { forwardRef, useEffect } from "react";
import "./date-range-picker.scss";
import {
  BaseTextFieldProps,
  Button,
  Dialog,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowForward, CalendarMonthOutlined } from "@mui/icons-material";
import { DateCalendar } from "@mui/x-date-pickers";
import { DateRange } from "./models/date-range";
import { useFormContext } from "react-hook-form";
import useDateRangePicker from "./hooks";
import moment, { Moment } from "moment";

interface IProps extends BaseTextFieldProps {
  name: string;
  maxRangeInDays?: number;
  disablePast?: boolean;
}

const DateRangePicker = forwardRef<HTMLInputElement, IProps>(
  (props: Readonly<IProps>, ref) => {
    const { name, maxRangeInDays, disablePast, ...forwardProps } = props;
    const { t } = useTranslation("dateRangePicker");

    const { watch, getValues, setValue } = useFormContext();

    const fieldWatch = watch(name);

    const {
      startDate,
      endDate,
      temporaryStartDate,
      temporaryEndDate,
      maxEndDate,
      dateRangePickerIsOpen,
      openDateRangePicker,
      closeDateRangePicker,
      applyStartDate,
      applyEndDate,
      applyTemporaryStartDate,
      applyTemporaryEndDate,
    } = useDateRangePicker(maxRangeInDays);

    const apply = () => {
      setValue(
        name,
        {
          startDate: temporaryStartDate?.toDate(),
          endDate: temporaryEndDate?.toDate(),
        } as DateRange,
        { shouldValidate: true },
      );
      closeDateRangePicker();
    };

    useEffect(() => {
      const currentValue = getValues(name) as DateRange;

      if (currentValue) {
        applyStartDate(moment(currentValue.startDate));
        applyEndDate(moment(currentValue.endDate));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldWatch]);

    return (
      <>
        <FormControl className="date-range-picker-input">
          <TextField
            {...forwardProps}
            ref={ref}
            id={props.name}
            variant="outlined"
            onClick={() => openDateRangePicker()}
            value={
              startDate && endDate
                ? `${startDate?.format("DD-MM-YYYY")} / ${endDate?.format(
                    "DD-MM-YYYY",
                  )}`
                : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthOutlined fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Dialog
          open={dateRangePickerIsOpen}
          onClose={() => closeDateRangePicker()}
          PaperProps={{ classes: { root: "date-range-picker-popup-paper" } }}
          maxWidth="xl"
        >
          <div className="date-range-picker-popup-container">
            <div className="date-range-picker-header-container">
              <Typography variant="body2">{t("startDate")}</Typography>
              <ArrowForward />
              <Typography variant="body2">{t("endDate")}</Typography>
            </div>
            <Divider />
            <div className="date-range-picker-container">
              <DateCalendar
                value={temporaryStartDate}
                onChange={(date: Moment | null) =>
                  applyTemporaryStartDate(date ?? moment())
                }
                defaultValue={moment()}
                disablePast={disablePast}
              />
              <DateCalendar
                value={temporaryEndDate}
                onChange={(date: Moment | null) =>
                  applyTemporaryEndDate(date ?? moment())
                }
                defaultValue={moment()}
                maxDate={maxEndDate}
                disablePast={disablePast}
              />
            </div>
            <div className="date-range-picker-actions-container">
              <Button
                className="button-center-text"
                variant="outlined"
                onClick={() => closeDateRangePicker()}
              >
                {t("actions.cancelText")}
              </Button>
              <Button variant="contained" onClick={() => apply()}>
                {t("actions.applyText")}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);

export default DateRangePicker;
