import { Backdrop } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import "./loading-indicator.component.scss";
import SonevoLoadingIndicator from "components/loading-indicator/sonevo-loader";

interface IProps {
  readonly isLoading: boolean;
  readonly useTimeout?: boolean;
}

export default function FullPageLoadingIndicator(props: IProps): ReactElement {
  const [showLoader, setShowLoader] = useState(
    props.isLoading && (props.useTimeout ?? false),
  );

  useEffect(() => {
    if (props.isLoading && props.useTimeout) {
      const timer = setTimeout(() => setShowLoader(true), 300);
      return () => clearTimeout(timer);
    } else {
      setShowLoader(props.isLoading);
    }
  }, [props.isLoading, props.useTimeout]);

  if (!showLoader) {
    return <></>;
  }

  return (
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <SonevoLoadingIndicator size={100} />
    </Backdrop>
  );
}
