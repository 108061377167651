import { ReactElement } from "react";
import "./details-pop-up-content.component.scss";

interface IProps {
  leftContent: ReactElement;
  rightContent: ReactElement;
  header: ReactElement;
  onSubmit?: () => void;
  isDisabled?: boolean;
}

function DetailsPopUpContent(props: Readonly<IProps>): ReactElement {
  return (
    <form
      onSubmit={props.onSubmit}
      className="details-form"
      data-testid="detailPopupForm"
    >
      <fieldset className="details-content" disabled={props.isDisabled}>
        <div className="left-content">{props.leftContent}</div>
        <div className="right-content">
          {props.header}
          <div className="right-content-container">{props.rightContent}</div>
        </div>
      </fieldset>
    </form>
  );
}

export default DetailsPopUpContent;
