import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function DoorOpenOutlineRoundedIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M11 13C11.2833 13 11.521 12.904 11.713 12.712C11.9043 12.5207 12 12.2833 12 12C12 11.7167 11.9043 11.479 11.713 11.287C11.521 11.0957 11.2833 11 11 11C10.7167 11 10.4793 11.0957 10.288 11.287C10.096 11.479 10 11.7167 10 12C10 12.2833 10.096 12.5207 10.288 12.712C10.4793 12.904 10.7167 13 11 13ZM7 21V19L13 18V6.875C13 6.625 12.925 6.4 12.775 6.2C12.625 6 12.4333 5.88333 12.2 5.85L7 5V3L12.5 3.9C13.2333 4.03333 13.8333 4.375 14.3 4.925C14.7667 5.475 15 6.11667 15 6.85V17.95C15 18.4333 14.8417 18.8627 14.525 19.238C14.2083 19.6127 13.8083 19.8417 13.325 19.925L7 21ZM7 19H17V5H7V19ZM4 21C3.71667 21 3.47933 20.904 3.288 20.712C3.096 20.5207 3 20.2833 3 20C3 19.7167 3.096 19.4793 3.288 19.288C3.47933 19.096 3.71667 19 4 19H5V5C5 4.43333 5.196 3.95833 5.588 3.575C5.97933 3.19167 6.45 3 7 3H17C17.5667 3 18.0417 3.19167 18.425 3.575C18.8083 3.95833 19 4.43333 19 5V19H20C20.2833 19 20.5207 19.096 20.712 19.288C20.904 19.4793 21 19.7167 21 20C21 20.2833 20.904 20.5207 20.712 20.712C20.5207 20.904 20.2833 21 20 21H4Z" />
    </SvgIcon>
  );
}
