import { FaultType } from "models/fault-type";

interface OrganisationTreeNode {
  id: string;

  depth: number;

  parentId?: string;
  name: string;
  isLocation: boolean;
  isResidentialLocation: boolean;
  isDiversionChannel: boolean;
  isSubscriptionChannel: boolean;
  isHidden: boolean;
  faults: FaultType[];
  parentOrganisationUnit?: OrganisationTreeNode;
  childOrganisationUnits: OrganisationTreeNode[];
}

export default OrganisationTreeNode;

export function findUpstreamParentBranch(
  rootNodes: OrganisationTreeNode | OrganisationTreeNode[],
  nodeId: string,
  upstream: OrganisationTreeNode[],
): OrganisationTreeNode[] | null {
  if (!Array.isArray(rootNodes)) {
    return findUpstreamParentBranchForSingleNode(rootNodes, nodeId, upstream);
  }

  for (const rootNode of rootNodes) {
    const result = findUpstreamParentBranchForSingleNode(
      rootNode,
      nodeId,
      upstream,
    );

    if (result) {
      return result;
    }
  }

  return null;
}

function findUpstreamParentBranchForSingleNode(
  rootNode: OrganisationTreeNode,
  nodeId: string,
  upstream: OrganisationTreeNode[],
): OrganisationTreeNode[] | null {
  const newUpstream = [...upstream].concat(rootNode);

  if (rootNode.id === nodeId) {
    return newUpstream;
  }

  for (const childNode of rootNode.childOrganisationUnits) {
    const result = findUpstreamParentBranch([childNode], nodeId, newUpstream);
    if (result) {
      return result;
    }
  }

  return null;
}
