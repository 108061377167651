import { SerializedError, createSlice } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { isFetchBaseQueryError } from "redux-base/create-api-utils";

export interface ErrorHandlingState {
  errorMessageTranslationKey: string | null;
}

const initialState = {
  errorMessageTranslationKey: null,
} as ErrorHandlingState;

const errorHandlingSlice = createSlice({
  name: "errorHandling",
  initialState: initialState,
  reducers: {
    setErrorMessage: (
      state,
      body: {
        payload: {
          error: SerializedError | FetchBaseQueryError | undefined;
        };
      },
    ) => {
      if (!body.payload.error) {
        state.errorMessageTranslationKey = null;
      } else if (
        isFetchBaseQueryError(body.payload.error) &&
        body.payload.error.status === 400
      ) {
        state.errorMessageTranslationKey = "badRequest";
      } else {
        state.errorMessageTranslationKey = "default";
      }
    },
  },
});

export const { setErrorMessage } = errorHandlingSlice.actions;
export default errorHandlingSlice.reducer;
