import { Dispatch, SetStateAction, useState } from "react";
import { HistoryItem } from "../domain/models/history-item";
import ReadAlarmResponse from "../domain/models/read-alarm-response";
import {
  useLazyReadAlarmQuery,
  useLazyReadEmergencyAlarmQuery,
} from "../domain/reducers/history.reducer";
import { HistoryItemType } from "../domain/models/history-item-type";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { AlarmType } from "../domain/models/alarm-type";
import ReadEmergencyAlarmResponse from "../domain/models/read-emergency-alarm-response";

export interface IHistoryProviderHook {
  currentSelectedItem?: HistoryItem;
  setCurrentSelectedItem: Dispatch<SetStateAction<HistoryItem | undefined>>;
  refresh: () => void;
  readAlarmData?: ReadAlarmResponse;
  readAlarmIsFetching: boolean;
  readAlarmError?: FetchBaseQueryError | SerializedError;
  isAlarmDataLoading: () => boolean;

  readEmergencyAlarmData?: ReadEmergencyAlarmResponse;
  readEmergencyAlarmIsFetching: boolean;
  readEmergencyAlarmError?: FetchBaseQueryError | SerializedError;
}

const useHistoryProvider = (): IHistoryProviderHook => {
  const [currentSelectedItem, setCurrentSelectedItem] = useState<
    HistoryItem | undefined
  >(undefined);

  const [
    triggerReadAlarm,
    {
      data: readAlarmData,
      isFetching: readAlarmIsFetching,
      error: readAlarmError,
    },
  ] = useLazyReadAlarmQuery();

  const [
    triggerReadEmergencyAlarm,
    {
      data: readEmergencyAlarmData,
      isFetching: readEmergencyAlarmIsFetching,
      error: readEmergencyAlarmError,
    },
  ] = useLazyReadEmergencyAlarmQuery();

  function refresh(): void {
    if (!currentSelectedItem) {
      return;
    }

    if (
      currentSelectedItem.historyType === HistoryItemType.Alarm &&
      currentSelectedItem.type === AlarmType.EmergencyAlarm
    ) {
      triggerReadEmergencyAlarm(currentSelectedItem.id);
    } else if (currentSelectedItem.historyType === HistoryItemType.Alarm) {
      triggerReadAlarm(currentSelectedItem.id);
    }
  }

  function isAlarmDataLoading(): boolean {
    if (currentSelectedItem?.type === AlarmType.EmergencyAlarm) {
      return (
        readEmergencyAlarmIsFetching ||
        !readEmergencyAlarmData ||
        readEmergencyAlarmData.id !== currentSelectedItem.id
      );
    }
    return (
      !currentSelectedItem ||
      readAlarmIsFetching ||
      !readAlarmData ||
      readAlarmData.id !== currentSelectedItem.id
    );
  }

  return {
    currentSelectedItem,
    setCurrentSelectedItem,
    refresh,
    readAlarmData,
    readAlarmIsFetching,
    readAlarmError,
    isAlarmDataLoading,
    readEmergencyAlarmData,
    readEmergencyAlarmIsFetching,
    readEmergencyAlarmError,
  };
};

export default useHistoryProvider;
