import { ReactElement } from "react";
import "./monitoring-alert.component.scss";
import { MonitoringType } from "./monitoring-type";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import Constants from "style/constants";
import MonitoringIcon from "./monitoring-icon.component";

interface IProps {
  type: MonitoringType;
  lastChangedOn?: Date;
  message?: string;
  color: string;
  text: string;
}

function MonitoringAlert(props: Readonly<IProps>): ReactElement {
  return (
    <div
      className="monitoring-alert-container"
      data-testid={`monitoring-alert-${props.type}`}
    >
      {props.lastChangedOn && (
        <Typography variant="body2" sx={{ color: Constants.Colors.onSurface }}>
          {moment(props.lastChangedOn).format("DD-MM-YYYY HH:mm").toString()}
        </Typography>
      )}
      <Box
        className={`status-container`}
        sx={{
          border: `3px solid ${props.color}`,
        }}
      >
        <div className="status-row">
          <MonitoringIcon
            type={props.type}
            withTooltip={false}
            visible={true}
            size={24}
          />
          <Typography
            variant="body1"
            sx={{ color: Constants.Colors.onSurface }}
          >
            {props.text}
          </Typography>
        </div>
        {props.message && (
          <Typography
            variant="subtitle1"
            className="text-padding"
            sx={{ color: Constants.Colors.onSurface }}
          >
            {props.message}
          </Typography>
        )}
      </Box>
    </div>
  );
}

export default MonitoringAlert;
