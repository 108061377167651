import { createApi } from "@reduxjs/toolkit/query/react";
import ClientSecret from "features/gateway/domain/models/client-secret";
import { baseQuery } from "redux-base/create-api-utils";
import { toDate } from "utils/date-utils";
import Gateway from "features/gateway/domain/models/gateway";
import { ReadGatewaysResponse } from "features/gateway/domain/models/read-gateways-response";
import { ReadGatewaysQuery } from "features/gateway/domain/models/read-gateways-query";
import { GatewayType } from "features/gateway/domain/models/gateway-type";
import GatewayMonitoringFilter from "features/gateway/domain/models/gateway-monitoring-filter";
import ValidateGatewayNameCommand from "features/gateway/domain/models/validate-gateway-name-command";
import ValidateGatewayIpAddressCommand from "features/gateway/domain/models/validate-gateway-ipaddress-command";

const baseUrl = "/api/v1/Gateway";

export const gatewayApi = createApi({
  reducerPath: "gateway",
  baseQuery,
  tagTypes: ["Gateway", "GatewayMonitoringFilters"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    createGateway: build.mutation<Gateway, Gateway>({
      query: (body) => ({
        url: `${baseUrl}/CreateGateway`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Gateway"],
    }),
    readGateways: build.query<ReadGatewaysResponse, ReadGatewaysQuery>({
      query: (body) => ({
        url: `${baseUrl}/ReadGateways`,
        method: "POST",
        body,
      }),
      providesTags: ["Gateway"],
      transformResponse: (response: ReadGatewaysResponse) => {
        response.gateways = response.gateways.map((gateway) => {
          let childGateways = gateway.childGateways;

          if (gateway.type === GatewayType.IoTEdgeCluster && childGateways) {
            childGateways.sort((a, b) => {
              if (a.isActiveInCluster && !b.isActiveInCluster) {
                return -1;
              } else if (!a.isActiveInCluster && b.isActiveInCluster) {
                return 1;
              } else {
                return 0;
              }
            });

            childGateways = childGateways.map((childGateway) => ({
              ...childGateway,
              lastCommunicationDate: toDate(childGateway.lastCommunicationDate),
            }));
          }

          return {
            ...gateway,
            childGateways,
            lastCommunicationDate: toDate(gateway.lastCommunicationDate),
          };
        });
        return response;
      },
    }),
    getMonitorFilters: build.query<GatewayMonitoringFilter[], void>({
      query: () => ({
        url: `${baseUrl}/GetMonitoringFilters`,
        method: "GET",
      }),
      providesTags: ["GatewayMonitoringFilters"],
    }),
    updateGateway: build.mutation<Gateway, Gateway>({
      query: (body) => ({
        url: `${baseUrl}/UpdateGateway`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Gateway"],
    }),
    deleteGateway: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteGateway/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Gateway"],
    }),
    generateSecrets: build.mutation<ClientSecret, string>({
      query: (gatewayId) => ({
        url: `${baseUrl}/GenerateSecrets`,
        method: "POST",
        body: { gatewayId },
      }),
      invalidatesTags: ["Gateway"],
    }),
    validateGatewayName: build.mutation<void, ValidateGatewayNameCommand>({
      query: (body) => ({
        url: `${baseUrl}/ValidateGatewayName`,
        method: "POST",
        body,
      }),
    }),
    validateGatewayIpAddress: build.mutation<
      void,
      ValidateGatewayIpAddressCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/ValidateGatewayIpAddress`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateGatewayMutation,
  useLazyReadGatewaysQuery,
  useReadGatewaysQuery,
  useLazyGetMonitorFiltersQuery,
  useUpdateGatewayMutation,
  useDeleteGatewayMutation,
  useGenerateSecretsMutation,
  useValidateGatewayNameMutation,
  useValidateGatewayIpAddressMutation,
} = gatewayApi;
