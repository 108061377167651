import React, { ReactElement, ReactNode } from "react";
import useConfirmationPopup, {
  IConfirmationPopupHook,
} from "./confirmation-popup-hook";
import ProvidedConfirmationPopup from "../views/provided-confirmation-popup";

const ConfirmationPopupContext = React.createContext<IConfirmationPopupHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const ConfirmationPopupProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <ConfirmationPopupContext.Provider value={useConfirmationPopup()}>
      <ProvidedConfirmationPopup></ProvidedConfirmationPopup>
      {props.children}
    </ConfirmationPopupContext.Provider>
  );
};

export const useConfirmationPopupContextProvider = (): IConfirmationPopupHook =>
  React.useContext<IConfirmationPopupHook>(ConfirmationPopupContext);
