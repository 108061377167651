import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import ReadTemporaryAccessResponse from "../domain/models/read-temporary-access-response";
import {
  useCreateTemporaryUserMutation,
  useLazyReadTemporaryUserQuery,
  useLazyReadTemporaryAccessQuery,
  useRevokeTemporaryChannelAccessMutation,
  useRevokeTemporaryUserMutation,
  useValidateTemporaryUserPeriodMutation,
} from "../domain/reducers/temporary-user.reducer";
import { useTemporaryUsersState } from "./states/temporary-access-state";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import { useLazyReadOrganisationUnitsForTemporaryAccessQuery } from "features/organisation/domain/reducers/organisation-unit.reducer";
import { useLazyReadRolesForTemporaryAccessQuery } from "features/role/domain/reducers/role.reducer";
import Role from "features/role/domain/models/role";
import { DateRange } from "components/date-range-picker/models/date-range";
import { CreateTemporaryUserCommand } from "../domain/models/create-temporary-user-command";
import { ValidateTemporaryUserPeriodCommand } from "../domain/models/validate-temporary-user-period-command";
import { ApiResponse, isOkResponse } from "redux-base/create-api-utils";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import TemporaryAccess from "../domain/models/temporary-access";
import { TemporaryAccessType } from "../domain/models/temporary-access-type";
import { ViewingMode } from "utils/viewing-utils";
import TemporaryUser from "../domain/models/temporary-user";
import useTemporaryAccessFilters, {
  ITemporaryAccessFilterHook,
} from "./temporary-access-filters-hook";

export interface ITemporaryAccessProviderHook {
  readTemporaryAccessData?: ReadTemporaryAccessResponse;
  readTemporaryAccessIsFetching: boolean;
  readTemporaryAccessIsSuccess: boolean;

  readTemporaryUserData?: TemporaryUser;
  readTemporaryUserIsFetching: boolean;
  readTemporaryUserIsSuccess: boolean;
  readTemporaryUserError?: FetchBaseQueryError | SerializedError;

  createTemporaryUser: (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    organisationUnitId: string,
    roleIdpId: string,
    period: DateRange,
  ) => Promise<boolean>;
  createTemporaryUserIsLoading: boolean;
  createTemporaryUserIsSuccess: boolean;
  createTemporaryUserIsError: boolean;

  revokeTemporaryAccess: (temporaryUser: TemporaryAccess) => void;
  revokeTemporaryAccessIsLoading: boolean;

  revokeTemporaryUserConfirmationPopupIsOpen: boolean;
  closeRevokeTemporaryUserConfirmationPopup: () => void;
  revokeTemporaryUserConfirmationAction: () => void;

  isTemporaryUserPeriodValid: (
    email: string,
    accessOrganisationUnitId: string,
    period: DateRange,
  ) => Promise<boolean>;

  readOrganisationUnitsForTemporaryAccessData?: Array<OrganisationUnit>;
  readOrganisationUnitsForTemporaryAccessIsSuccess: boolean;

  readRolesForTemporaryAccess: () => void;
  readRolesForTemporaryAccessData?: Array<Role>;
  readRolesForTemporaryAccessIsFetching: boolean;
  readRolesForTemporaryAccessIsSuccess: boolean;
  readRolesForTemporaryAccessError?: FetchBaseQueryError | SerializedError;

  temporaryUserDetailsPopupIsOpen: boolean;
  closeTemporaryUserDetailsPopup: () => void;
  openTemporaryUserDetailsPopup: (viewingMode: ViewingMode) => void;

  selectedTemporaryUserId: string | undefined;
  selectTemporaryUser: (
    temporaryUserId: string,
    viewingMode: ViewingMode,
  ) => void;

  detailsViewingMode: ViewingMode;

  refresh: () => void;
  temporaryAccessFilterHook: ITemporaryAccessFilterHook;
}

const useTemporaryAccess = (): ITemporaryAccessProviderHook => {
  const dispatch = useDispatch();

  const {
    setTemporaryUserDetailsPopupIsOpen,
    temporaryUserDetailsPopupIsOpen,
    setRevokeTemporaryUserConfirmationPopupIsOpen,
    revokeTemporaryUserConfirmationPopupIsOpen,
    revokeTemporaryUserConfirmationAction,
    setRevokeTemporaryUserConfirmationAction,
    selectedTemporaryUserId,
    setSelectedTemporaryUserId,
  } = useTemporaryUsersState();

  const [detailsViewingMode, setDetailsViewingMode] =
    useState<ViewingMode>("none");

  const [
    triggerReadTemporaryAccess,
    {
      data: readTemporaryAccessData,
      isSuccess: readTemporaryAccessIsSuccess,
      isFetching: readTemporaryAccessIsFetching,
      error: readTemporaryAccessError,
    },
  ] = useLazyReadTemporaryAccessQuery();

  const [
    triggerReadTemporaryUser,
    {
      data: readTemporaryUserData,
      isFetching: readTemporaryUserIsFetching,
      isSuccess: readTemporaryUserIsSuccess,
      error: readTemporaryUserError,
    },
  ] = useLazyReadTemporaryUserQuery();

  const [
    createTemporaryUserMutation,
    {
      isLoading: createTemporaryUserIsLoading,
      isSuccess: createTemporaryUserIsSuccess,
      isError: createTemporaryUserIsError,
      error: createTemporaryUserError,
    },
  ] = useCreateTemporaryUserMutation();

  const [
    revokeTemporaryUserMutation,
    {
      isLoading: revokeTemporaryUserIsLoading,
      isSuccess: revokeTemporaryUserIsSuccess,
      isError: revokeTemporaryUserIsError,
      error: revokeTemporaryUserError,
    },
  ] = useRevokeTemporaryUserMutation();

  const [
    revokeTemporaryChannelAccessMutation,
    {
      isLoading: revokeTemporaryChannelAccessIsLoading,
      isSuccess: revokeTemporaryChannelAccessIsSuccess,
      isError: revokeTemporaryChannelAccessIsError,
      error: revokeTemporaryChannelAccessError,
    },
  ] = useRevokeTemporaryChannelAccessMutation();

  const [validateTemporaryUserPeriod] =
    useValidateTemporaryUserPeriodMutation();

  const [
    triggerReadOrganisationUnitsForTemporaryAccess,
    {
      data: readOrganisationUnitsForTemporaryAccessData,
      isSuccess: readOrganisationUnitsForTemporaryAccessIsSuccess,
    },
  ] = useLazyReadOrganisationUnitsForTemporaryAccessQuery();

  const [
    triggerReadRolesForTemporaryAccess,
    {
      data: readRolesForTemporaryAccessData,
      isSuccess: readRolesForTemporaryAccessIsSuccess,
      isFetching: readRolesForTemporaryAccessIsFetching,
      error: readRolesForTemporaryAccessError,
    },
  ] = useLazyReadRolesForTemporaryAccessQuery();

  const temporaryAccessFilterHook = useTemporaryAccessFilters();

  const refresh = () => {
    triggerReadTemporaryAccess(
      temporaryAccessFilterHook.readTemporaryAccessQuery,
    );
  };

  useEffect(() => {
    readOrganisationUnitsForTemporaryAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readTemporaryAccessError) {
      dispatch(
        setErrorMessage({
          error: readTemporaryAccessError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readTemporaryAccessError]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temporaryAccessFilterHook.readTemporaryAccessQuery]);

  useEffect(() => {
    if (revokeTemporaryUserIsSuccess) {
      setRevokeTemporaryUserConfirmationPopupIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeTemporaryUserIsSuccess]);

  useEffect(() => {
    if (revokeTemporaryChannelAccessIsSuccess) {
      setRevokeTemporaryUserConfirmationPopupIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeTemporaryChannelAccessIsSuccess]);

  useEffect(() => {
    if (revokeTemporaryUserIsError && revokeTemporaryUserError) {
      dispatch(
        setErrorMessage({
          error: revokeTemporaryUserError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeTemporaryUserIsError, revokeTemporaryUserError]);

  useEffect(() => {
    if (
      revokeTemporaryChannelAccessIsError &&
      revokeTemporaryChannelAccessError
    ) {
      dispatch(
        setErrorMessage({
          error: revokeTemporaryUserError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revokeTemporaryChannelAccessIsError, revokeTemporaryChannelAccessError]);

  useEffect(() => {
    if (createTemporaryUserIsError && createTemporaryUserError) {
      dispatch(
        setErrorMessage({
          error: createTemporaryUserError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTemporaryUserIsError, createTemporaryUserError]);

  useEffect(() => {
    if (createTemporaryUserIsSuccess) {
      closeTemporaryUserDetailsPopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createTemporaryUserIsSuccess]);

  const createTemporaryUser = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    organisationUnitId: string,
    roleIdpId: string,
    period: DateRange,
  ): Promise<boolean> => {
    const createTemporaryUserCommand = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phoneNumber: phone.trim(),
      accessOrganisationUnitId: organisationUnitId,
      roleIdpId: roleIdpId,
      period: {
        startsOn: period.startDate,
        endsOn: period.endDate,
      },
    } as CreateTemporaryUserCommand;

    await createTemporaryUserMutation(createTemporaryUserCommand);

    return true;
  };

  const revokeTemporaryAccess = (temporaryAccess: TemporaryAccess) => {
    setRevokeTemporaryUserConfirmationPopupIsOpen(true);
    setRevokeTemporaryUserConfirmationAction(
      () => () => confirmRevokeTemporaryAccess(temporaryAccess),
    );
  };

  const confirmRevokeTemporaryAccess = (temporaryAccess: TemporaryAccess) => {
    temporaryAccess.type === TemporaryAccessType.User
      ? revokeTemporaryUserMutation({
          temporaryUserId: temporaryAccess.id,
        })
      : revokeTemporaryChannelAccessMutation({
          temporaryChannelAccessId: temporaryAccess.id,
        });

    setRevokeTemporaryUserConfirmationAction(() => () => {});
  };

  const closeRevokeTemporaryUserConfirmationPopup = () => {
    setRevokeTemporaryUserConfirmationPopupIsOpen(false);
  };

  const isTemporaryUserPeriodValid = async (
    email: string,
    accessOrganisationUnitId: string,
    period: DateRange,
  ): Promise<boolean> => {
    if (email && accessOrganisationUnitId && period) {
      let result = (await validateTemporaryUserPeriod({
        email: email,
        accessOrganisationUnitId: accessOrganisationUnitId,
        startsOn: period.startDate,
        endsOn: period.endDate,
      } as ValidateTemporaryUserPeriodCommand)) as ApiResponse<void>;

      if (!isOkResponse(result)) {
        return false;
      }
    }

    return true;
  };

  const readOrganisationUnitsForTemporaryAccess = () => {
    triggerReadOrganisationUnitsForTemporaryAccess();
  };

  const readRolesForTemporaryAccess = () => {
    triggerReadRolesForTemporaryAccess();
  };

  const openTemporaryUserDetailsPopup = (viewingMode: ViewingMode) => {
    setDetailsViewingMode(viewingMode);
    setTemporaryUserDetailsPopupIsOpen(true);
  };

  const closeTemporaryUserDetailsPopup = () => {
    setSelectedTemporaryUserId(undefined);
    setDetailsViewingMode("none");
    setTemporaryUserDetailsPopupIsOpen(false);
  };

  const selectTemporaryUser = (
    temporaryUserId: string,
    viewingMode: ViewingMode,
  ) => {
    setSelectedTemporaryUserId(temporaryUserId);
    triggerReadTemporaryUser(temporaryUserId);
    openTemporaryUserDetailsPopup(viewingMode);
  };

  return {
    readTemporaryAccessData,
    readTemporaryAccessIsFetching,
    readTemporaryAccessIsSuccess,

    readTemporaryUserData,
    readTemporaryUserIsFetching,
    readTemporaryUserIsSuccess,
    readTemporaryUserError,

    createTemporaryUser,
    createTemporaryUserIsLoading,
    createTemporaryUserIsSuccess,
    createTemporaryUserIsError,

    revokeTemporaryAccess,
    revokeTemporaryAccessIsLoading:
      revokeTemporaryUserIsLoading || revokeTemporaryChannelAccessIsLoading,

    closeRevokeTemporaryUserConfirmationPopup,
    revokeTemporaryUserConfirmationPopupIsOpen,
    revokeTemporaryUserConfirmationAction,

    isTemporaryUserPeriodValid,

    readOrganisationUnitsForTemporaryAccessData,
    readOrganisationUnitsForTemporaryAccessIsSuccess,

    readRolesForTemporaryAccess,
    readRolesForTemporaryAccessData,
    readRolesForTemporaryAccessIsFetching,
    readRolesForTemporaryAccessIsSuccess,
    readRolesForTemporaryAccessError,

    temporaryUserDetailsPopupIsOpen,
    closeTemporaryUserDetailsPopup,
    openTemporaryUserDetailsPopup,

    selectedTemporaryUserId,
    selectTemporaryUser,

    detailsViewingMode,

    refresh,
    temporaryAccessFilterHook,
  };
};

export default useTemporaryAccess;
