import { ReactElement, useRef } from "react";
import useOutsideAlerter from "./use-outside-alerter";

interface IProps {
  children: ReactElement | ReactElement[];
  functionToExecute: () => void;
}

export default function OutsideAlerter(props: Readonly<IProps>) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.functionToExecute);

  return <div ref={wrapperRef}>{props.children}</div>;
}
