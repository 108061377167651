import { Dialog } from "@mui/material";
import { ReactElement } from "react";
import { useTenantsContextProvider } from "features/tenants/providers/tenants-provider";
import { FormProvider } from "react-hook-form";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import TenantDetailsHeader from "./tenant-details-header";
import TenantDetailsInformation from "./tenant-details-information";
import "./tenant-details.scss";
import TenantMonitoringDetails from "./tenant-monitoring-details";

function TenantDetails(): ReactElement {
  const { closeDetails, form, submitTenant } = useTenantsContextProvider();

  return (
    <Dialog
      open={true}
      onClose={() => closeDetails()}
      className="tenant-details"
      PaperProps={{ classes: { root: "tenant-details-popup-paper" } }}
    >
      <FormProvider {...form}>
        <DetailsPopUpContent
          onSubmit={form.handleSubmit(submitTenant)}
          leftContent={<TenantDetailsInformation />}
          rightContent={<TenantMonitoringDetails />}
          header={<TenantDetailsHeader />}
        />
      </FormProvider>
    </Dialog>
  );
}

export default TenantDetails;
