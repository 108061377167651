import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import {
  useCreateExplanationLinkMutation,
  useDeleteExplanationLinkMutation,
  useReadExplanationLinksQuery,
  useUpdateExplanationLinkMutation,
} from "../domain/reducers/explanation-links.reducer";
import ExplanationLink from "../domain/models/explanationLink";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { ViewingMode } from "utils/viewing-utils";
import { GridRowModes, GridRowModesModel } from "@mui/x-data-grid";
import { useReadOrganisationUnitsForSettingsQuery } from "features/organisation/domain/reducers/organisation-unit.reducer";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import ExplanationLinkCommand from "../domain/models/explanationLink-command";
import { v4 as uuidv4 } from "uuid";

export interface IExplanationLinksHook {
  viewingMode: ViewingMode;
  mutationIsLoading: boolean;
  currentSelectedExplanationLink: ExplanationLink | undefined;

  explanationLinks: ExplanationLink[];
  explanationLinksResponseIsLoading: boolean;
  explanationLinksResponseIsSuccess: boolean;

  rowModesModel: GridRowModesModel;
  handleAddExplanationLink: () => void;
  handleEditClick: (explanationLink: ExplanationLink) => void;
  handleSaveClick: (explanationLinkCommand: ExplanationLinkCommand) => void;
  handleCancelEdit: (explanationLinkId: string) => void;

  handleDeleteExplanationLink: (ExplanationLink: ExplanationLink) => void;
  isDeleteConfirmationPopupOpen: boolean;
  closeDeleteConfirmationPopup: () => void;
  deleteConfirmationAction: () => void;

  readOrganisationUnitsForSettingsData?: Array<OrganisationUnit>;
  readOrganisationUnitsForSettingsIsSuccess: boolean;
  organisationUnits: string[];
}

const useExplanationLinks = (): IExplanationLinksHook => {
  const dispatch = useDispatch();
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [explanationLinks, setExplanationLinks] = useState<ExplanationLink[]>(
    [],
  );
  const [currentSelectedExplanationLink, setCurrentSelectedExplanationLink] =
    useState<ExplanationLink>();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [organisationUnits, setOrganisationUnits] = useState<string[]>([]);

  const {
    data: explanationLinksResponse,
    error: explanationLinksResponseError,
    isError: explanationLinksResponseIsError,
    isLoading: explanationLinksResponseIsLoading,
    isSuccess: explanationLinksResponseIsSuccess,
  } = useReadExplanationLinksQuery();

  const [
    deleteExplanationLink,
    {
      isLoading: deleteExplanationLinkIsLoading,
      isError: deleteExplanationLinkIsError,
      error: deleteExplanationLinkError,
    },
  ] = useDeleteExplanationLinkMutation();

  const [
    createExplanationLink,
    {
      isLoading: createExplanationLinkIsLoading,
      isSuccess: createExplanationLinkIsSuccess,
      isError: createExplanationLinkIsError,
      error: createExplanationLinkError,
      data: createExplanationLinkData,
    },
  ] = useCreateExplanationLinkMutation();

  const [
    updateExplanationLink,
    {
      isLoading: updateExplanationLinkIsLoading,
      isSuccess: updateExplanationLinkIsSuccess,
      isError: updateExplanationLinkIsError,
      error: updateExplanationLinkError,
      data: updateExplanationLinkData,
    },
  ] = useUpdateExplanationLinkMutation();

  const {
    data: readOrganisationUnitsForSettingsData,
    isSuccess: readOrganisationUnitsForSettingsIsSuccess,
  } = useReadOrganisationUnitsForSettingsQuery();

  useEffect(() => {
    if (createExplanationLinkIsSuccess && createExplanationLinkData) {
      setViewingMode("viewing");
      setRowModesModel({
        ...rowModesModel,
        [createExplanationLinkData.id]: { mode: GridRowModes.View },
      });
      setCurrentSelectedExplanationLink(undefined);
      setOrganisationUnits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExplanationLinkIsSuccess]);

  useEffect(() => {
    if (createExplanationLinkIsError && createExplanationLinkError) {
      if (currentSelectedExplanationLink) {
        setRowModesModel({
          ...rowModesModel,
          [currentSelectedExplanationLink.id]: { mode: GridRowModes.Edit },
        });
      }
      dispatch(
        setErrorMessage({
          error: createExplanationLinkError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExplanationLinkIsError]);

  useEffect(() => {
    if (updateExplanationLinkIsSuccess && updateExplanationLinkData) {
      setViewingMode("viewing");
      setRowModesModel({
        ...rowModesModel,
        [updateExplanationLinkData.id]: { mode: GridRowModes.View },
      });
      setCurrentSelectedExplanationLink(undefined);
      setOrganisationUnits([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExplanationLinkIsSuccess]);

  useEffect(() => {
    if (updateExplanationLinkIsError && updateExplanationLinkError) {
      if (currentSelectedExplanationLink) {
        setRowModesModel({
          ...rowModesModel,
          [currentSelectedExplanationLink.id]: { mode: GridRowModes.Edit },
        });
      }
      dispatch(
        setErrorMessage({
          error: updateExplanationLinkError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExplanationLinkIsError]);

  useEffect(() => {
    if (explanationLinksResponse?.explanationLinks) {
      setExplanationLinks([...explanationLinksResponse.explanationLinks]);
    }
  }, [explanationLinksResponse]);

  useEffect(() => {
    if (explanationLinksResponseIsError && explanationLinksResponseError) {
      dispatch(
        setErrorMessage({
          error: explanationLinksResponseError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [explanationLinksResponseIsError]);

  useEffect(() => {
    setMutationIsLoading(
      updateExplanationLinkIsLoading ||
        createExplanationLinkIsLoading ||
        deleteExplanationLinkIsLoading,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateExplanationLinkIsLoading,
    createExplanationLinkIsLoading,
    deleteExplanationLinkIsLoading,
  ]);

  useEffect(() => {
    if (deleteExplanationLinkIsError && deleteExplanationLinkError) {
      dispatch(
        setErrorMessage({
          error: deleteExplanationLinkError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteExplanationLinkIsError]);

  function handleDeleteExplanationLink(explanationLink: ExplanationLink): void {
    setIsDeleteConfirmationPopupOpen(true);
    setDeleteConfirmationAction(
      () => () => confirmDeleteExplanationLink(explanationLink),
    );
  }

  async function confirmDeleteExplanationLink(
    explanationLink: ExplanationLink,
  ): Promise<void> {
    const result = (await deleteExplanationLink(
      explanationLink.id,
    )) as ApiResponse<void>;

    if (isErrorResponse(result)) {
      dispatch(
        setErrorMessage({
          error: result.error,
        }),
      );
    }

    setDeleteConfirmationAction(() => () => {});
    setIsDeleteConfirmationPopupOpen(false);
  }

  const closeDeleteConfirmationPopup = () => {
    setIsDeleteConfirmationPopupOpen(false);
  };

  const handleAddExplanationLink = () => {
    setViewingMode("creation");
    const id = uuidv4();
    const newExplanationLink: ExplanationLink = {
      id: id,
      title: "",
      uri: "",
      organisationUnits: [],
    };
    setCurrentSelectedExplanationLink(newExplanationLink);
    setExplanationLinks([...explanationLinks, newExplanationLink]);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "title" },
    });
  };

  const handleEditClick = (explanationLink: ExplanationLink) => {
    setViewingMode("editing");
    setCurrentSelectedExplanationLink(explanationLink);
    let organisationUnitIds: string[] = [];
    explanationLink.organisationUnits.forEach((item) => {
      if (
        readOrganisationUnitsForSettingsData?.some(
          (organisationUnit) => organisationUnit.id === item.id,
        )
      ) {
        organisationUnitIds.push(item.id);
      }
    });
    setOrganisationUnits(organisationUnitIds);

    setRowModesModel({
      ...rowModesModel,
      [explanationLink.id]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (explanationLinkCommand: ExplanationLinkCommand) => {
    viewingMode === "creation"
      ? createExplanationLink(explanationLinkCommand)
      : updateExplanationLink(explanationLinkCommand);
  };

  const handleCancelEdit = (explanationLinkId: string) => {
    setRowModesModel({
      ...rowModesModel,
      [explanationLinkId]: {
        mode: GridRowModes.View,
        ignoreModifications: true,
      },
    });
    setCurrentSelectedExplanationLink(undefined);
    setOrganisationUnits([]);

    if (viewingMode === "creation") {
      setExplanationLinks(
        explanationLinks.filter((item) => item.id !== explanationLinkId),
      );
    }
    setViewingMode("viewing");
  };

  return {
    viewingMode,
    mutationIsLoading,
    currentSelectedExplanationLink,

    explanationLinks,
    explanationLinksResponseIsLoading,
    explanationLinksResponseIsSuccess,

    rowModesModel,
    handleAddExplanationLink,
    handleEditClick,
    handleSaveClick,
    handleCancelEdit,

    handleDeleteExplanationLink,
    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    readOrganisationUnitsForSettingsData,
    readOrganisationUnitsForSettingsIsSuccess,
    organisationUnits,
  };
};

export default useExplanationLinks;
