import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";

export default class SubscriptionChannelValidator {
  static hasLocationInChildren(
    childOrganisationUnits: OrganisationTreeNode[] | undefined,
  ): boolean {
    if (!childOrganisationUnits) {
      return false;
    }
    let anyChildIsLocation = false;
    for (const child of childOrganisationUnits) {
      if (child.isLocation) {
        anyChildIsLocation = true;
        break;
      }
      if (this.hasLocationInChildren(child.childOrganisationUnits)) {
        anyChildIsLocation = true;
        break;
      }
    }
    return anyChildIsLocation;
  }

  static hasLocationInParents(
    parentId: string | undefined,
    upstreamParentBranch: OrganisationTreeNode[],
  ): boolean {
    if (!parentId) {
      return false;
    }
    const parent = upstreamParentBranch.find((node) => node.id === parentId);
    if (parent?.isLocation) {
      return true;
    }

    return this.hasLocationInParents(parent?.parentId, upstreamParentBranch);
  }
}
