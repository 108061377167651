import { Button } from "@mui/material";
import { ReactElement } from "react";

interface IProps {
  icon?: ReactElement;
  label: string;
  onClick?: () => void;
}

const ElevatedButton = ({ icon, label, onClick }: IProps): ReactElement => {
  return (
    <Button
      sx={{
        "&.MuiButton-root": {
          boxShadow:
            "0px 2px 6px 0px rgba(0, 0, 0, 0.2), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        },
      }}
      startIcon={icon}
      onClick={onClick}
      aria-label={label}
    >
      {label}
    </Button>
  );
};

export default ElevatedButton;
