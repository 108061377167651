import useConnectors, {
  ConnectorsHook,
} from "features/connectors/context/connectors-hook";
import React, { ReactNode } from "react";

const ConnectorsContext = React.createContext<ConnectorsHook>({} as never);

interface Props {
  children: ReactNode;
}

export const ConnectorsProvider = ({
  children,
}: Readonly<Props>): JSX.Element => {
  return (
    <ConnectorsContext.Provider value={useConnectors()}>
      {children}
    </ConnectorsContext.Provider>
  );
};

export const useConnectorsContextProvider = (): ConnectorsHook =>
  React.useContext<ConnectorsHook>(ConnectorsContext);
