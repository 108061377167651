import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import EmbedInfo from "features/dashboard/domain/models/embedInfo";

const baseUrl = "/api/v1/dashboard";

export const dashboardApi = createApi({
  reducerPath: "dashboard",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["EmbedInfo"],
  endpoints: (build) => ({
    readEmbeddedReport: build.query<EmbedInfo, string>({
      query: (reportName) => ({
        url: `${baseUrl}/ReadEmbeddedReport/${reportName}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useReadEmbeddedReportQuery } = dashboardApi;
