import React, { ReactNode } from "react";
import useMalfunctionNotificationSettings, {
  IMalfunctionNotificationSettingsHook,
} from "./malfunction-notification-settings-hook";

const MalfunctionNotificationContext =
  React.createContext<IMalfunctionNotificationSettingsHook>({} as never);

interface IProps {
  children: ReactNode;
}

export const MalfunctionNotificationSettingsProvider = (
  props: Readonly<IProps>,
) => {
  return (
    <MalfunctionNotificationContext.Provider
      value={useMalfunctionNotificationSettings()}
    >
      {props.children}
    </MalfunctionNotificationContext.Provider>
  );
};

export const useMalfunctionNotificationContextProvider =
  (): IMalfunctionNotificationSettingsHook =>
    React.useContext<IMalfunctionNotificationSettingsHook>(
      MalfunctionNotificationContext,
    );
