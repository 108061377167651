import useOrganisationUnitDetails, {
  IOrganisationUnitDetailsHook,
} from "../hooks/organisation-unit-details-hook";

export interface IOrganisationUnitsHook {
  organisationUnitDetailsHook: IOrganisationUnitDetailsHook;
}

const useOrganisationUnits = (): IOrganisationUnitsHook => {
  const organisationUnitDetailsHook = useOrganisationUnitDetails();

  return {
    organisationUnitDetailsHook,
  };
};

export default useOrganisationUnits;
