import { Link as LinkIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import CopyButton from "components/copy-button/copy-button.component";
import PopUp from "components/pop-up/pop-up.component";
import { useAuth } from "features/authentication/providers/authentication.provider";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import { Trans, useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-clients/gateway-settings-client.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import useGatewaySettingsViewpointClient from "features/gateway/hooks/gateway-settings-viewpoint-client-hook";

const GatewaySettingsViewpointClient = (): JSX.Element => {
  const { t } = useTranslation("gateway");

  const { tenant } = useAuth();

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const {
    clientId,
    clientSecret,
    generateSecretIsLoading,
    isGenerateSecretConfirmationOpen,
    defaultSecret,
    generateSecret,
    submitGenerateSecret,
    closeGenerateSecretConfirmationPopup,
  } = useGatewaySettingsViewpointClient();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.infoItem}>
          <Typography variant="subtitle1">
            {t("details.settings.tenantLabel")}:
          </Typography>
          <Typography variant="subtitle1">
            {t("details.settings.clientIdLabel")}:
          </Typography>
          <Typography variant="subtitle1">
            {t("details.settings.clientSecretLabel")}:
          </Typography>
        </div>
        <div className={styles.infoItem}>
          <Typography variant="subtitle1">{tenant}</Typography>
          <div className={styles.secret}>
            <Typography variant="subtitle1" data-testid="gatewayClientId">
              {clientId && clientId.length > 0 ? clientId : "-"}
            </Typography>
            {clientId && <CopyButton contentToCopy={clientId} />}
          </div>
          <div className={styles.secret}>
            <Typography variant="subtitle1">{clientSecret}</Typography>
            {clientSecret !== defaultSecret && (
              <CopyButton contentToCopy={clientSecret} />
            )}
          </div>
        </div>
      </div>
      <AutorisationWrapper
        atLeastOnePermissionOf={[
          Permission.CreateSystemsGatewaysConnectors,
          Permission.CreateExternalSystem,
        ]}
      >
        <>
          {gatewayDetailsHook.viewingMode === "viewing" &&
            (clientSecret === defaultSecret ? (
              <LoadingButton
                variant="outlined"
                onClick={generateSecret}
                startIcon={<LinkIcon />}
                loading={generateSecretIsLoading}
                loadingPosition="start"
                className={styles.secretButton}
                data-testid="generate-secret-button"
              >
                {t("details.settings.clientSecret.generateButton")}
              </LoadingButton>
            ) : (
              <Typography variant="subtitle1">
                {t("details.settings.clientSecret.warningViewpoint")}
              </Typography>
            ))}
          <PopUp
            isOpen={isGenerateSecretConfirmationOpen}
            title={t("details.settings.clientSecret.confirmation.title")}
            body={
              <Trans
                t={t}
                i18nKey="details.settings.clientSecret.confirmation.bodyText"
              />
            }
            primaryButtonText={t(
              "details.settings.clientSecret.confirmation.confirmButton",
            )}
            secondaryButtonText={t(
              "details.settings.clientSecret.confirmation.cancelButton",
            )}
            handleOnClose={closeGenerateSecretConfirmationPopup}
            secondaryButtonAction={closeGenerateSecretConfirmationPopup}
            primaryButtonAction={submitGenerateSecret}
          />
        </>
      </AutorisationWrapper>
    </>
  );
};

export default GatewaySettingsViewpointClient;
