import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { SessionStorageKeys } from "features/authentication/constants/session-storage-keys";

export default function LoginCallbackView() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    auth
      .signinRedirectCallback()
      .then(() => {
        let redirectTo = sessionStorage.getItem(SessionStorageKeys.REDIRECT_TO);
        navigate(
          redirectTo && redirectTo !== "/" && redirectTo.length > 0
            ? redirectTo
            : "/",
        );
      })
      .catch((error: Error) => {
        if (error.message.indexOf("login_required") !== -1) {
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return <></>;
}
