import { useEffect, useState } from "react";
import EmergencyAlarmSetting, {
  preAlarmDurationOptions,
} from "../domain/models/emergency-alarm-setting";
import { ViewingMode } from "utils/viewing-utils";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { EmergencyAlarmActivationMethod } from "../domain/models/emergency-alarm-activation-method";
import { EmergencyAlarmDeviceTriggerAction } from "../domain/models/emergency-alarm-device-trigger-action";
import {
  useReadEmergencyAlarmSettingsQuery,
  useUpsertEmergencyAlarmSettingMutation,
  useDeleteEmergencyAlarmSettingMutation,
} from "../domain/reducers/emergency-alarm-setting.reducer";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { setShouldShowConfirmation } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";
import { useTranslation } from "react-i18next";
import { EmergencyAlarmLocationType } from "features/emergency-alarm-settings/domain/models/emergency-alarm-location-type";

export interface EmergencyAlarmSettingsDetailsHook {
  currentSelectedEmergencyAlarmSettings?: EmergencyAlarmSetting;
  viewingMode: ViewingMode;
  form: UseFormReturn<any, any>;
  upsertIsLoading: boolean;
  deleteIsLoading: boolean;
  selectSettingDetails(setting: EmergencyAlarmSetting): void;
  submitEmergencyAlarmSetting: (fieldValues: FieldValues) => void;
  cancelEditing(): void;
  switchToEditingMode(): void;
}

const useEmergencyAlarmSettingsDetails =
  (): EmergencyAlarmSettingsDetailsHook => {
    const { t } = useTranslation("emergencyAlarm");

    const dispatch = useDispatch();
    const [viewingMode, setViewingMode] = useState<ViewingMode>("none");
    const {
      showConfirmationPopup,
      showUnsavedChangesPopup,
      setUnsavedChanges,
    } = useConfirmationPopupContextProvider();
    const [
      currentSelectedEmergencyAlarmSettings,
      setCurrentSelectedEmergencyAlarmSettings,
    ] = useState<EmergencyAlarmSetting>();

    const { data: emergencyAlarmSettingsResponse } =
      useReadEmergencyAlarmSettingsQuery();

    const form = useForm({
      mode: "onBlur",
      defaultValues: {
        emergencyAlarmEnabled: false,
        divergentSettingEnabled: false,
        activationMethod: EmergencyAlarmActivationMethod.DeviceButton,
        deviceTriggerAction: EmergencyAlarmDeviceTriggerAction.TwoPresses,
        preAlarmDurationS: preAlarmDurationOptions[2],
        preAlarmEnabled: true,
        soundEnabled: true,
        locationType: EmergencyAlarmLocationType.None,
      },
    });

    const [
      upsertEmergencyAlarmSetting,
      {
        isLoading: upsertEmergencyAlarmSettingIsLoading,
        isSuccess: upsertEmergencyAlarmSettingIsSuccess,
        isError: upsertEmergencyAlarmSettingIsError,
        error: upsertEmergencyAlarmSettingError,
        data: upsertEmergencyAlarmSettingData,
      },
    ] = useUpsertEmergencyAlarmSettingMutation();

    useEffect(() => {
      if (
        upsertEmergencyAlarmSettingIsSuccess &&
        upsertEmergencyAlarmSettingData
      ) {
        updateCurrentEmergencyAlarmSetting(upsertEmergencyAlarmSettingData);
        setViewingMode("viewing");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [upsertEmergencyAlarmSettingIsSuccess]);

    const updateCurrentEmergencyAlarmSetting = (
      setting: EmergencyAlarmSetting,
    ) => {
      const updatedSetting: EmergencyAlarmSetting = {
        ...setting,
        name: currentSelectedEmergencyAlarmSettings?.name,
      };

      setCurrentSelectedEmergencyAlarmSettings(updatedSetting);

      form.reset({
        ...setting,
        divergentSettingEnabled: !setting.isDefaultTemplateSetting,
      });
    };

    const [
      deleteEmergencyAlarmSetting,
      {
        isLoading: deleteEmergencyAlarmSettingIsLoading,
        isError: deleteEmergencyAlarmSettingIsError,
        error: deleteEmergencyAlarmSettingError,
        data: deleteEmergencyAlarmSettingData,
      },
    ] = useDeleteEmergencyAlarmSettingMutation();

    useEffect(() => {
      if (deleteEmergencyAlarmSettingData) {
        resetCurrentEmergencyAlarmSettingToDefault();
        setViewingMode("viewing");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteEmergencyAlarmSettingData]);

    const resetCurrentEmergencyAlarmSettingToDefault = () => {
      if (
        emergencyAlarmSettingsResponse?.defaultTemplateSetting &&
        currentSelectedEmergencyAlarmSettings
      ) {
        const revertedSetting: EmergencyAlarmSetting = {
          ...emergencyAlarmSettingsResponse?.defaultTemplateSetting,
          organisationUnitId:
            currentSelectedEmergencyAlarmSettings?.organisationUnitId,
          name: currentSelectedEmergencyAlarmSettings?.name,
          isDefaultTemplateSetting: true,
        };

        setCurrentSelectedEmergencyAlarmSettings(revertedSetting);
      }

      form.reset({
        ...emergencyAlarmSettingsResponse?.defaultTemplateSetting,
        divergentSettingEnabled: false,
      });
    };

    useEffect(() => {
      if (
        upsertEmergencyAlarmSettingIsError ||
        deleteEmergencyAlarmSettingIsError
      ) {
        const error = upsertEmergencyAlarmSettingIsError
          ? upsertEmergencyAlarmSettingError
          : deleteEmergencyAlarmSettingError;

        dispatch(
          setErrorMessage({
            error,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      upsertEmergencyAlarmSettingIsError,
      deleteEmergencyAlarmSettingIsError,
    ]);

    useEffect(() => {
      currentSelectedEmergencyAlarmSettings &&
        resetSettingDetailsForm(currentSelectedEmergencyAlarmSettings);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedEmergencyAlarmSettings]);

    const selectSettingDetails = (setting: EmergencyAlarmSetting) => {
      const hasOrganisationSetting =
        emergencyAlarmSettingsResponse &&
        emergencyAlarmSettingsResponse.emergencyAlarmSettings.length > 0;
      const hasOrganisationId =
        currentSelectedEmergencyAlarmSettings?.organisationUnitId ===
        setting.organisationUnitId;
      const isDefaultOrganisationSetting =
        !setting.organisationUnitId &&
        !currentSelectedEmergencyAlarmSettings?.organisationUnitId;
      const isOpen =
        currentSelectedEmergencyAlarmSettings &&
        (hasOrganisationId || isDefaultOrganisationSetting);

      showUnsavedChangesPopup(() => {
        if (!hasOrganisationSetting && viewingMode === "none") {
          setViewingMode("editing");
          setCurrentSelectedEmergencyAlarmSettings(setting);
        } else if (isOpen) {
          setViewingMode("none");
          setCurrentSelectedEmergencyAlarmSettings(undefined);
          form.reset();
        } else {
          setViewingMode(hasOrganisationSetting ? "viewing" : "none");
          setCurrentSelectedEmergencyAlarmSettings(
            hasOrganisationSetting ? setting : undefined,
          );
        }
      });
    };

    const resetSettingDetailsForm = (setting: EmergencyAlarmSetting) => {
      form.reset({
        ...setting,
        divergentSettingEnabled: !setting.isDefaultTemplateSetting,
      });
    };

    const submitEmergencyAlarmSetting = (fieldValues: FieldValues) => {
      const { organisationUnitId, id } =
        currentSelectedEmergencyAlarmSettings || {};

      if (!fieldValues.divergentSettingEnabled) {
        if (organisationUnitId) {
          deleteEmergencyAlarmSetting(organisationUnitId);
        }
        return;
      }

      const setting: EmergencyAlarmSetting = {
        id: id ?? "",
        organisationUnitId: organisationUnitId ?? null,
        emergencyAlarmEnabled: fieldValues.emergencyAlarmEnabled,
        activationMethod: fieldValues.activationMethod,
        deviceTriggerAction: fieldValues.deviceTriggerAction,
        preAlarmEnabled: fieldValues.preAlarmEnabled,
        preAlarmDurationS: fieldValues.preAlarmDurationS,
        soundEnabled: fieldValues.soundEnabled,
        locationType: fieldValues.locationType,
        isDefaultTemplateSetting: true,
      };

      if (
        !setting.organisationUnitId &&
        !setting.emergencyAlarmEnabled &&
        form.formState.isDirty
      ) {
        showConfirmationPopup(
          () => upsertEmergencyAlarmSetting(setting),
          t("details.disableEmergencyAlarmPopup.title"),
          t("details.disableEmergencyAlarmPopup.text"),
          t("details.disableEmergencyAlarmPopup.confirm"),
          t("details.disableEmergencyAlarmPopup.cancel"),
        );
      } else {
        upsertEmergencyAlarmSetting(setting);
      }
    };

    useEffect(() => {
      setUnsavedChanges(form.formState.isDirty);

      dispatch(
        setShouldShowConfirmation({
          shouldShowConfirmation: form.formState.isDirty,
        }),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.formState.isDirty]);

    const switchToEditingMode = () => setViewingMode("editing");

    const cancelEditing = () => {
      setViewingMode("viewing");
      form.reset();
    };

    return {
      currentSelectedEmergencyAlarmSettings,
      viewingMode,
      form,
      upsertIsLoading: upsertEmergencyAlarmSettingIsLoading,
      deleteIsLoading: deleteEmergencyAlarmSettingIsLoading,
      cancelEditing,
      switchToEditingMode,
      selectSettingDetails,
      submitEmergencyAlarmSetting,
    };
  };
export default useEmergencyAlarmSettingsDetails;
