import { ReactElement } from "react";
import PageHeader from "components/page-header/page-header";
import Permission from "features/autorisation/domain/models/permission";
import DashboardNavigation from "features/dashboard/views/dashboard-navigation";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import "./dashboard.scss";

interface IProps {}

export default function Dashboard(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("dashboard");

  return (
    <div className="dashboard-container" data-testid="dashboardContainer">
      <PageHeader
        title={t("pageTitle")}
        addActionPermission={
          Permission.CreateSystemsGatewaysConnectors ||
          Permission.CreateExternalSystem
        }
        navigationComponent={<DashboardNavigation />}
        isAddActionVisible={false}
      />
      <div className="dashboard-child-container">
        <Outlet />
      </div>
    </div>
  );
}
