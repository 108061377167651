import { Dialog } from "@mui/material";
import DetailsPopUpContent from "components/details-pop-up-content/details-pop-up-content.component";
import PopUp from "components/pop-up/pop-up.component";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import Gateway from "features/gateway/domain/models/gateway";
import { GatewayStatus } from "features/gateway/domain/models/gateway-status";
import { FieldValues, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GatewayDetailsPopupHeader from "features/gateway/views/gateway-details/gateway-details-popup-header";
import GatewaySettings from "features/gateway/views/gateway-details/gateway-settings/gateway-settings";
import GatewayInformation from "features/gateway/views/gateway-details/gateway-information.component";

const GatewayDetailsPopup = (): JSX.Element => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const submitForm = (fieldValues: FieldValues) => {
    const gateway: Gateway = {
      id: gatewayDetailsHook.currentSelectedGateway?.id,
      type: fieldValues.type,
      name: fieldValues.name?.trim(),
      ipAddress: fieldValues.ipAddress?.trim(),
      note: fieldValues.note?.trim(),
      organisationUnitId: fieldValues.organisationUnitId,
      lastCommunicationDate:
        gatewayDetailsHook.currentSelectedGateway?.lastCommunicationDate,
      shouldCreateDevicesAutomatically:
        fieldValues.shouldCreateDevicesAutomatically,
      offlinePortalPassword: fieldValues.offlinePortalPassword,
      offlinePortalUsername: fieldValues.offlinePortalUsername,
      kadexIpAddress: fieldValues.kadexIpAddress,
      kadexUsername: fieldValues.kadexUsername,
      kadexPassword: fieldValues.kadexPassword,
      childGateways: fieldValues.childGateways,
      status: GatewayStatus.notMonitored,
      isAlarmmonitorEnabled: fieldValues.isAlarmmonitorEnabled,
      isSonevoApiEnabled: fieldValues.isSonevoApiEnabled,
      isAudioEnabled: fieldValues.isAudioEnabled,
      isKadexApiEnabled: fieldValues.isKadexApiEnabled,
      isIS32ApiEnabled: fieldValues.isIS32ApiEnabled,
    };

    gatewayDetailsHook.submitGateway(gateway);
  };

  const popUpStyle = {
    style: {
      height: "960px",
      width: "1227px",
      maxWidth: "1227px",
      paddingBottom: "0px",
    },
  };

  return (
    <>
      <Dialog
        open={gatewayDetailsHook.isDetailsOpen}
        onClose={() =>
          gatewayDetailsHook.closeDetails(
            gatewayDetailsHook.form.formState.isDirty,
          )
        }
        PaperProps={popUpStyle}
      >
        <FormProvider {...gatewayDetailsHook.form}>
          <DetailsPopUpContent
            onSubmit={gatewayDetailsHook.form.handleSubmit(submitForm)}
            leftContent={<GatewayInformation />}
            rightContent={<GatewaySettings />}
            header={<GatewayDetailsPopupHeader />}
          />
        </FormProvider>
      </Dialog>
      <PopUp
        isOpen={gatewayDetailsHook.isIpAddressPopupOpen}
        title={t("details.ipAddressPopup.title")}
        body={t("details.ipAddressPopup.bodyText")}
        primaryButtonText={t("details.ipAddressPopup.confirmButton")}
        secondaryButtonText={t("details.ipAddressPopup.cancelButton")}
        handleOnClose={gatewayDetailsHook.closeIpAddressPopup}
        secondaryButtonAction={gatewayDetailsHook.closeIpAddressPopup}
        primaryButtonAction={() =>
          gatewayDetailsHook.ipAddressConfirmationAction?.()
        }
      />
    </>
  );
};

export default GatewayDetailsPopup;
