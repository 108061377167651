import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function MotionDetectedIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M10 0.199951C9 0.199951 8.2 0.999951 8.2 1.99995C8.2 2.99995 9 3.79995 10 3.79995C11 3.79995 11.8 2.99995 11.8 1.99995C11.8 0.999951 11 0.199951 10 0.199951ZM15.67 0.999951C15.67 2.94399 16.4423 4.8084 17.8169 6.18304C19.1915 7.55769 21.056 8.32995 23 8.32995V6.99995C21.4087 6.99995 19.8826 6.36781 18.7574 5.24259C17.6321 4.11737 17 2.59125 17 0.999951H15.67ZM18.33 0.999951C18.33 3.57995 20.42 5.66995 23 5.66995V4.32995C21.16 4.32995 19.67 2.83995 19.67 0.999951H18.33ZM21 0.999951C21 1.53038 21.2107 2.03909 21.5858 2.41416C21.9609 2.78924 22.4696 2.99995 23 2.99995V0.999951H21ZM7.92 4.02995C7.75 4.02995 7.58 4.05995 7.42 4.10995L2 5.79995V11H3.8V7.32995L5.91 6.66995L2 22H3.8L6.67 13.89L9 17V22H10.8V15.59L8.31 11.05L9.04 8.17995L10.12 9.99995H15V8.19995H11.38L9.38 4.86995C9.08 4.36995 8.54 4.02995 7.92 4.02995Z" />
    </SvgIcon>
  );
}
