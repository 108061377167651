import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useReadScenariosQuery } from "features/scenario/domain/reducers/scenarios.reducer";
import Scenario from "features/scenario/models/scenario";
import { useEffect } from "react";
import { useAppDispatch } from "redux-base/store";

interface ScenarioHook {
  scenarios?: Scenario[];
  isLoading: boolean;
  isSuccess: boolean;
}

const useScenario = (): ScenarioHook => {
  const dispatch = useAppDispatch();
  const {
    data: readScenariosResponse,
    isSuccess,
    isLoading,
    error,
  } = useReadScenariosQuery();

  useEffect(() => {
    error &&
      dispatch(
        setErrorMessage({
          error,
        }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    scenarios: readScenariosResponse?.scenarios,
    isLoading,
    isSuccess,
  };
};

export default useScenario;
