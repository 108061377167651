import { TextField, Typography } from "@mui/material";
import "./tenant-details-information.scss";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useFormContext } from "react-hook-form";
import { useTenantsContextProvider } from "features/tenants/providers/tenants-provider";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FullPageLoadingIndicator from "components/loading-indicator/full-page-loading-indicator.component";
import ElevatedButton from "components/elevated-button/elevated-button";

function TenantDetailsInformation(): ReactElement {
  const { t } = useTranslation("tenants");
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const {
    viewingMode,
    currentTenant,
    isUpdatingTenant,
    onAccessTenant,
    accessTenantIsLoading,
    allowOnAccessTenant,
  } = useTenantsContextProvider();

  return (
    <div className="tenant-details-information-container">
      <Typography
        variant="h2"
        className="tenant-title"
        data-testid="tenantPopupTitle"
      >
        {currentTenant?.displayName}
      </Typography>
      <div
        className="information-part-container"
        data-testid="tenantPopupNoteContainer"
      >
        {isUpdatingTenant && <LoadingIndicator />}
        {!isUpdatingTenant && (
          <>
            {viewingMode === "viewing" &&
              allowOnAccessTenant(currentTenant!) && (
                <ElevatedButton
                  icon={<OpenInNewIcon />}
                  label={t("tenantDetails.accessTenantButton")}
                  onClick={() => currentTenant && onAccessTenant(currentTenant)}
                />
              )}
            {!(viewingMode === "viewing" && !currentTenant?.note) && (
              <ViewingModeController
                viewingMode={viewingMode}
                name="note"
                control={control}
                label={t("tenantDetails.noteLabel")}
                render={({ field }) => (
                  <TextField
                    {...field}
                    inputProps={{
                      "data-testid": "noteInput",
                      maxLength: 255,
                    }}
                    id="noteInput"
                    label={t("tenantDetails.noteLabel")}
                    variant="outlined"
                    error={!!errors.note}
                    helperText={errors.note?.message?.toString()}
                    multiline
                    minRows={8}
                    maxRows={12}
                    className="form-field"
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      <FullPageLoadingIndicator
        isLoading={accessTenantIsLoading}
        useTimeout={false}
      />
    </div>
  );
}

export default TenantDetailsInformation;
