import { Moment } from "moment";
import { Dispatch, SetStateAction, useState } from "react";

export interface IDateRangePickerState {
  dateRangePickerIsOpen: boolean;
  startDate: Moment | undefined;
  endDate: Moment | undefined;
  temporaryStartDate: Moment | undefined;
  temporaryEndDate: Moment | undefined;
  maxEndDate: Moment | undefined;
  setDateRangePickerIsOpen: Dispatch<SetStateAction<boolean>>;
  setStartDate: Dispatch<SetStateAction<Moment | undefined>>;
  setEndDate: Dispatch<SetStateAction<Moment | undefined>>;
  setTemporaryStartDate: Dispatch<SetStateAction<Moment | undefined>>;
  setTemporaryEndDate: Dispatch<SetStateAction<Moment | undefined>>;
  setMaxEndDate: Dispatch<SetStateAction<Moment | undefined>>;
}

export const useDateRangePickerState = (): IDateRangePickerState => {
  const [dateRangePickerIsOpen, setDateRangePickerIsOpen] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment | undefined>();
  const [endDate, setEndDate] = useState<Moment | undefined>();
  const [temporaryStartDate, setTemporaryStartDate] = useState<
    Moment | undefined
  >();
  const [temporaryEndDate, setTemporaryEndDate] = useState<
    Moment | undefined
  >();
  const [maxEndDate, setMaxEndDate] = useState<Moment | undefined>();

  return {
    dateRangePickerIsOpen,
    startDate,
    endDate,
    temporaryStartDate,
    temporaryEndDate,
    maxEndDate,
    setDateRangePickerIsOpen,
    setStartDate,
    setEndDate,
    setTemporaryStartDate,
    setTemporaryEndDate,
    setMaxEndDate,
  };
};
