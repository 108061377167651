import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styles from "features/gateway/views/gateway-details/gateway-settings/gateway-settings-offline-portal-credentials.module.scss";
import { useGatewaysContextProvider } from "features/gateway/context/gateway-provider";
import useGatewayValidation from "features/gateway/hooks/gateway-validation-hook";

const GatewaySettingsOfflinePortalCredentials = (): JSX.Element => {
  const { t } = useTranslation("gateway");

  const { gatewayDetailsHook } = useGatewaysContextProvider();

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { validatePassword } = useGatewayValidation();
  const [showPassword, setShowPassword] = useState(false);
  const watchIsAlarmmonitorEnabled = watch("isAlarmmonitorEnabled");

  const registerOptions = {
    offlinePortalUsername: {
      required: watchIsAlarmmonitorEnabled
        ? t("details.information.requiredHelperText")
        : false,
    },
    offlinePortalPassword: {
      required: watchIsAlarmmonitorEnabled
        ? t("details.information.requiredHelperText")
        : false,
      validate: watchIsAlarmmonitorEnabled ? validatePassword : () => true,
    },
  };

  const isViewing = gatewayDetailsHook.viewingMode === "viewing";

  const currentGateway = gatewayDetailsHook.currentSelectedGateway;
  const hasNoUsername =
    !currentGateway?.offlinePortalUsername ||
    currentGateway?.offlinePortalUsername === "";
  const hasNoPassword =
    !currentGateway?.offlinePortalPassword ||
    currentGateway?.offlinePortalPassword === "";

  const hiddenPassword = "**********";

  return (
    <>
      {isViewing && (
        <div className={`${styles.container} ${styles.viewing}`}>
          <Typography variant="subtitle1" data-testid="usernameLabel">
            {t("details.settings.offlinePortalCredentials.username")}:{" "}
            {hasNoUsername
              ? t(`details.settings.offlinePortalCredentials.noUsernameDefined`)
              : currentGateway?.offlinePortalUsername}
          </Typography>
          <Typography variant="subtitle1" data-testid="passwordLabel">
            {t("details.settings.offlinePortalCredentials.password")}:{" "}
            {hasNoPassword
              ? t(`details.settings.offlinePortalCredentials.noPasswordDefined`)
              : hiddenPassword}
          </Typography>
        </div>
      )}
      {!isViewing && (
        <div className={styles.container}>
          <Controller
            name={"offlinePortalUsername"}
            control={control}
            rules={registerOptions.offlinePortalUsername}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `offlinePortalUsername`,
                }}
                id="offlinePortalUsername"
                label={t("details.settings.offlinePortalCredentials.username")}
                variant="outlined"
                error={!!errors.offlinePortalUsername}
                helperText={errors.offlinePortalUsername?.message?.toString()}
                className={styles.textfield}
              />
            )}
          />
          <Controller
            name={"offlinePortalPassword"}
            control={control}
            rules={registerOptions.offlinePortalPassword}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  "data-testid": `offlinePortalPassword`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="offlinePortalPassword"
                label={t("details.settings.offlinePortalCredentials.password")}
                variant="outlined"
                error={!!errors.offlinePortalPassword}
                helperText={errors.offlinePortalPassword?.message?.toString()}
                className={styles.textfield}
              />
            )}
          />
        </div>
      )}
    </>
  );
};

export default GatewaySettingsOfflinePortalCredentials;
