export enum ScenarioType {
  None = "None",
  Bed = "Bed",
  Room = "Room",
  Video = "Video",
  CurrentLocation = "CurrentLocation",
  Acoustic = "Acoustic",
  Door = "Door",
  Intruder = "Intruder",
  Bathroom = "Bathroom",
}
