import { SvgIcon } from "@mui/material";
import { ReactElement } from "react";

export default function SolidPeopleRobberyIcon(): ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M22.8844 2.77031C22.4109 4.66875 20.7047 6 18.75 6C16.7953 6 15.0891 4.66875 14.6156 2.77031L14.2031 1.13438C14.0063 0.332813 13.1906 -0.154688 12.3844 0.046875C11.5781 0.248438 11.0953 1.05937 11.2969 1.86562L11.7047 3.50156C12.2203 5.56406 13.5891 7.23281 15.375 8.17031V22.5C15.375 23.3297 16.0453 24 16.875 24C17.7047 24 18.375 23.3297 18.375 22.5V16.5H19.125V22.5C19.125 23.3297 19.7953 24 20.625 24C21.4547 24 22.125 23.3297 22.125 22.5V8.17031C23.9109 7.23281 25.2797 5.56406 25.7953 3.50156L26.2031 1.86562C26.4047 1.06406 25.9172 0.248438 25.1109 0.046875C24.3047 -0.154688 23.4937 0.332813 23.2969 1.13438L22.8891 2.77031H22.8844ZM18.75 4.5C19.9922 4.5 21 3.49219 21 2.25C21 1.00781 19.9922 0 18.75 0C17.5078 0 16.5 1.00781 16.5 2.25C16.5 3.49219 17.5078 4.5 18.75 4.5ZM3.75 4.5C4.99219 4.5 6 3.49219 6 2.25C6 1.00781 4.99219 0 3.75 0C2.50781 0 1.5 1.00781 1.5 2.25C1.5 3.49219 2.50781 4.5 3.75 4.5ZM3.375 6C1.72031 6 0.375 7.34531 0.375 9V22.5C0.375 23.3297 1.04531 24 1.875 24C2.70469 24 3.375 23.3297 3.375 22.5V16.5H4.125V22.5C4.125 23.3297 4.79531 24 5.625 24C6.45469 24 7.125 23.3297 7.125 22.5V11.8453L7.73438 12.8063C8.01094 13.2375 8.48906 13.5047 9 13.5047H11.25C12.0797 13.5047 12.75 12.8344 12.75 12.0047C12.75 11.175 12.0797 10.5047 11.25 10.5047H9.825L8.07187 7.74375C7.3875 6.65625 6.19219 6 4.90781 6H3.375Z" />
    </SvgIcon>
  );
}
