import { ReactElement } from "react";
import "./monitoring-bar.scss";
import { Monitoring } from "../domain/models/tenant";
import { useTenantsContextProvider } from "../providers/tenants-provider";
import { AggregatedType } from "../domain/models/aggregated-monitor-data";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface IProps {
  monitoring?: Monitoring[];
  aggregatedType: AggregatedType;
}

const MonitoringBar = ({
  monitoring,
  aggregatedType,
}: IProps): ReactElement => {
  const { aggregatedMonitoring } = useTenantsContextProvider();
  const { t } = useTranslation("tenants");
  const monitoringItems = aggregatedMonitoring(
    monitoring ?? [],
    aggregatedType,
  );

  return (
    <div className="monitoring-bar">
      {monitoringItems?.length === 0 ? (
        <Typography variant="body2">{t("noAggregateData")}</Typography>
      ) : (
        monitoringItems.map((item, i) => (
          <div
            key={`${item.type}-${i}`}
            className={item.type.toLowerCase()}
            style={{ flexGrow: item.count }}
          ></div>
        ))
      )}
    </div>
  );
};

export default MonitoringBar;
