import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import PreValidateIdpRequest from "features/authentication/domain/models/pre-validate-idp-request";
import authenticationService from "features/authentication/services/authentication.service";
import { baseQuery } from "redux-base/create-api-utils";
import { IState } from "redux-base/state";
import { constructIdpHintFromEmail } from "utils/idp-hint-utils";

export interface AuthenticationState extends IState {
  isAuthenticated: boolean;
}

const initialState = {
  loading: false,
  error: false,
  success: false,
  isAuthenticated: false,
} as AuthenticationState;

const login = createAsyncThunk(
  "authentication/login",
  async (email: string) => {
    await authenticationService.login(
      constructIdpHintFromEmail(email),
      "",
      email,
    );
  },
);

const logout = createAsyncThunk("authentication/logout", async () => {
  await authenticationService.logout();
});

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
    });
  },
});

export const authenticationActions = { login, logout };
export default authenticationSlice.reducer;

const baseUrl = "/api/v1/authentication";

export const authenticationApi = createApi({
  reducerPath: "authenticationApi",
  baseQuery,
  endpoints: (build) => ({
    preValidate: build.query<void, string>({
      query: (email) => ({
        url: `${baseUrl}/PreValidateIdp`,
        body: {
          idpHint: constructIdpHintFromEmail(email),
        } as PreValidateIdpRequest,
        method: "POST",
      }),
    }),
    backchannelLogout: build.mutation<void, void>({
      query: () => ({
        url: `${baseUrl}/BackchannelLogout`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyPreValidateQuery, useBackchannelLogoutMutation } =
  authenticationApi;
