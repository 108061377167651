import { User } from "oidc-client";
import { createContext, useContext, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import Token from "features/autorisation/domain/models/token";
import authenticationService from "features/authentication/services/authentication.service";
import { Profile } from "features/authentication/domain/models/profile";
import Permission from "features/autorisation/domain/models/permission";
import TenantAccess from "features/tenants/domain/models/tenant-access";
import usePrevious from "hooks/previous-hook";
import { resetState } from "redux-base/store";
import Tenant from "features/tenants/domain/models/tenant";
import { AuthenticationConstants } from "../constants/authentication-constants";
import { splitFullNameToInitials } from "utils/string-utils";

interface IAuthenticationContext {
  signinRedirectCallback: () => Promise<User>;
  signoutRedirectCallback: () => Promise<void>;
  user: User | null;
  profile: Profile | undefined;
  tenant: string;
  temporaryTenantAccess: (
    accessToken: TenantAccess,
    tenant: Tenant | null,
  ) => void;
  revertTemporaryTenantAccess: () => void;
  isTemporaryTenantAccessActive: boolean;
  hasPermission: (permission: Permission) => boolean;
  hasAtLeastOnePermissionOf: (permissions?: Permission[]) => boolean;
  shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant: (
    shouldShowForTenantManagerOnly?: boolean,
  ) => boolean;
}

const AuthenticationContext = createContext<IAuthenticationContext>(
  {} as IAuthenticationContext,
);

const useAuth = () => useContext(AuthenticationContext);

function AuthenticationProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const [user, setUser] = useState<User | null>(null);
  const [permissions, setPermissions] = useState<string[]>();
  const [profile, setProfile] = useState<Profile>();
  const [tenant, setTenant] = useState<string>("");
  const [currentAccessToken, setCurrentAccessToken] = useState<TenantAccess>();

  const [isTemporaryTenantAccessActive, setIsTemporaryTenantAccessActive] =
    useState(false);
  const previousIsTemporaryTenantAccessActive = usePrevious(
    isTemporaryTenantAccessActive,
  );

  useEffect(() => {
    if (
      isTemporaryTenantAccessActive === previousIsTemporaryTenantAccessActive
    ) {
      return;
    }

    if (!isTemporaryTenantAccessActive) {
      authenticationService.revertUserOverride();
    } else if (currentAccessToken) {
      authenticationService.overrideUser(currentAccessToken);
    }
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemporaryTenantAccessActive]);

  authenticationService.setNewUserCallback(determineStateInformation);

  const signinRedirectCallback = async (): Promise<User> => {
    let currentUser = await authenticationService.signinRedirectCallback();
    determineStateInformation(currentUser);

    return currentUser;
  };

  const signoutRedirectCallback = async (): Promise<void> => {
    authenticationService.signoutRedirectCallback();

    setCurrentAccessToken(undefined);
    setIsTemporaryTenantAccessActive(false);
  };

  function determineStateInformation(newUser: User) {
    setUser(newUser);

    let decodedToken = jwt_decode<Token>(newUser.access_token);
    setPermissions(decodedToken.realm_roles.permissions);

    const fullName = isTemporaryTenantAccessActive
      ? profile?.fullName
      : decodedToken.name;
    setProfile({
      customerRealmName: decodedToken.customer_realm_name,
      fullName,
      initials: splitFullNameToInitials(fullName),
    });

    setTenant(decodedToken.customer_realm);
  }

  const temporaryTenantAccess = (
    accessToken: TenantAccess,
    tenant: Tenant | null,
  ) => {
    setCurrentAccessToken(accessToken);
    setIsTemporaryTenantAccessActive(true);
    document.title = `${AuthenticationConstants.TITLE} (${tenant?.displayName})`;
  };

  const revertTemporaryTenantAccess = () => {
    setCurrentAccessToken(undefined);
    setIsTemporaryTenantAccessActive(false);
    document.title = AuthenticationConstants.TITLE;
  };

  const shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant = (
    shouldShowForTenantManagerOnly?: boolean,
  ): boolean =>
    shouldShowForTenantManagerOnly === true &&
    tenant !== process.env.REACT_APP_TENANT_MANAGER_REALM_NAME;

  const value: IAuthenticationContext = {
    user,
    profile,
    tenant,
    temporaryTenantAccess,
    revertTemporaryTenantAccess,
    isTemporaryTenantAccessActive,
    signinRedirectCallback,
    signoutRedirectCallback,
    hasPermission: (requestedPermission) =>
      permissions?.includes(requestedPermission.toString()) ?? false,
    hasAtLeastOnePermissionOf: (requestedPermissions) => {
      if (!requestedPermissions) {
        return true;
      }

      for (const requestedPermission of requestedPermissions) {
        if (permissions?.includes(requestedPermission.toString())) {
          return true;
        }
      }

      return false;
    },
    shouldShowForTenantManagerOnlyAndCurrentTenantIsOtherTenant,
  };

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export { AuthenticationProvider, useAuth };
