export default interface MalfunctionNotification {
  id: string;
  isActivelyMailing: boolean;
  recipients: MalfunctionNotificationRecipient[];
}

export interface MalfunctionNotificationRecipient {
  id?: string;
  templateId?: string;
  email: string;
  faultTypes: string[];
}

export enum FaultTypes {
  Gateway = "gateway",
  Device = "device",
  Battery = "battery",
}
