export interface AggregatedMonitoring {
  type: AggregatedMonitoringStatus;
  count: number;
}

export enum AggregatedType {
  Devices = "Devices",
  Gateways = "Gateways",
}

export enum AggregatedMonitoringStatus {
  Red = "Red",
  Orange = "Orange",
  Green = "Green",
  Grey = "Grey",
}
