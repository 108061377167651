import { ReactElement, useEffect, useState } from "react";
import "./monitoring-filter.component.scss";
import { MonitoringType } from "./monitoring-type";
import MonitoringIcon from "./monitoring-icon.component";
import { Grid, Typography } from "@mui/material";
import Constants from "style/constants";

interface IProps {
  type?: MonitoringType;
  countColorOverride?: string;
  count: number;
  countColor?: string;
  isSelected: boolean;
  onSelected: (selected: boolean) => void;
  text: string;
}

function MonitoringFilter(props: Readonly<IProps>): ReactElement {
  const [isSelected, setIsSelected] = useState<boolean>(props.isSelected);

  function getColor() {
    if (props.countColorOverride) {
      return props.countColorOverride;
    }

    return props.count > 0 ? props.countColor : Constants.Colors.onSurface;
  }

  useEffect(() => {
    if (isSelected !== props.isSelected) {
      setIsSelected(props.isSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected]);

  return (
    <Grid
      container
      direction={"column"}
      className={`monitoring-filter-container ${isSelected ? "selected" : ""}`}
      data-testid={`monitoring-filter-${props.type}`}
      onClick={() => {
        setIsSelected(!isSelected);
        props.onSelected(!isSelected);
      }}
      sx={{
        flexWrap: "nowrap",
        minWidth: 150,
      }}
    >
      <div className="text-row">
        {props.type && (
          <MonitoringIcon
            type={props.type}
            withTooltip={false}
            visible={true}
            size={24}
          />
        )}
        <Typography
          variant="subtitle1"
          sx={{
            color: Constants.Colors.onSurface,
          }}
          className="max-lines"
        >
          {props.text}
        </Typography>
      </div>
      <Typography
        variant="h2"
        sx={{
          color: getColor(),
        }}
      >
        {props.count}
      </Typography>
    </Grid>
  );
}

export default MonitoringFilter;
