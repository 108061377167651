import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { store } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return (
    <MuiAlert
      data-testid="error-alert"
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

function ErrorMessage(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation("errorHandling");

  const appInsights = useAppInsightsContext();

  const [localErrorMessageTranslationKey, setLocalErrorMessageTranslationKey] =
    useState<string | null>(null);

  function updateLocalErrorMessage(): void {
    const errorMessageKey =
      store.getState().errorHandling.errorMessageTranslationKey;

    if (localErrorMessageTranslationKey !== errorMessageKey) {
      if (errorMessageKey) {
        appInsights?.trackException({
          error: new Error(
            `An error message has popped up with the message: ${t(
              errorMessageKey,
            )}`,
          ),
          severityLevel: SeverityLevel.Warning,
        });
      }

      setLocalErrorMessageTranslationKey(errorMessageKey);
    }
  }

  store.subscribe(updateLocalErrorMessage);

  return (
    <>
      {localErrorMessageTranslationKey && (
        <Snackbar
          data-testid="error-message-snackbar"
          open={localErrorMessageTranslationKey !== null}
          autoHideDuration={5000}
          onClose={() => dispatch(setErrorMessage({ error: undefined }))}
        >
          <Alert
            onClose={() => dispatch(setErrorMessage({ error: undefined }))}
            severity="error"
          >
            {localErrorMessageTranslationKey &&
              t(localErrorMessageTranslationKey)}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ErrorMessage;
